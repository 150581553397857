import * as React from 'react';
import LiquidityCard from '../../components/LiquidityCard';

const Liquidity = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='d-flex w-100'>
        <div className='col-12 col-md-9 col-lg-7 col-xl-6 mx-auto mt-2'>
          <div className='home-container03'></div>
          <LiquidityCard />
        </div>
      </div>
    </div>
  );
};

export default Liquidity;
