import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_DEPLOY_VESTING_TRANSACTION } from 'api/mutations';
import { useQueryParams } from 'hooks';
import TextField from 'stakegold-core-dapp/components/TextField';

export interface Milestone {
  epoch?: string;
  percent?: string;
}

interface DeployVestingContractProps {
  processBatchTransactions: (transactions: Transaction[][]) => void;
}

const DeployVestingContract = ({
  processBatchTransactions
}: DeployVestingContractProps) => {
  const { groupId } = useQueryParams();
  const { address } = useGetAccount();

  const [milestones, setMilestones] = useState<Milestone[]>([
    { epoch: '', percent: '' }
  ]);

  const percent = useMemo(() => {
    return milestones.reduce(
      (sum, milestone) => sum + Number(milestone.percent ?? 0),
      0
    );
  }, [milestones]);

  const milestonesValid = useMemo(() => {
    const invalidMilestone = milestones.find(
      (milestone) =>
        !milestone.epoch ||
        milestone.epoch.length <= 0 ||
        !milestone.percent ||
        milestone.percent.length <= 0
    );
    return invalidMilestone === undefined;
  }, [milestones]);

  const [deployVestingTransaction, { data: deployVestingData }] = useMutation(
    CREATE_DEPLOY_VESTING_TRANSACTION
  );

  const handleDeployVesting = () => {
    deployVestingTransaction({
      variables: {
        sender: address,
        groupId: groupId,
        unlockMilestones: milestones
      }
    });
  };

  useEffect(() => {
    if (deployVestingData && deployVestingData.deployVesting) {
      processBatchTransactions([
        [deployVestingData.deployVesting as Transaction]
      ]);
    }
  }, [deployVestingData]);

  return (
    <div className='d-flex container mb-3 justify-content-center'>
      <div className='d-flex flex-column align-items-center'>
        <h5>Vesting contract</h5>
        <h6>Step 1/2: Setup unlock schedule</h6>
        <div className='form-group mt-5'>
          {milestones.map((milestone, index) => (
            <div
              className='form-row align-items-end justify-content-center mb-3'
              key={index}
            >
              <div className='d-flex flex-md-row flex-column align-items-end justify-content-center'>
                <TextField
                  labelClassName='exrond-text text-white font-size-1 font-weight-600'
                  divWrapperClassName='exrond-input swap-form-group-light primary'
                  inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
                  type='number'
                  placeholder='Days'
                  value={milestone.epoch}
                  label={'Days after Token Generation Event'}
                  callback={(newValue) => {
                    const newMilestones = [...milestones];
                    newMilestones[index].epoch = newValue;
                    setMilestones(newMilestones);
                  }}
                />

                <TextField
                  labelClassName='exrond-text text-white font-size-1 font-weight-600 ml-0 ml-md-2'
                  divWrapperClassName='exrond-input swap-form-group-light primary ml-md-2 ml-0'
                  inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
                  type='number'
                  value={milestone.percent}
                  placeholder='Unlock percent'
                  callback={(newValue) => {
                    const newMilestones = [...milestones];
                    newMilestones[index].percent = newValue;
                    setMilestones(newMilestones);
                  }}
                />

                <div className='align-self-start align-self-md-end mt-1 mt-md-0 mb-md-4 ml-md-2'>
                  <button
                    className='exrond-btn-light'
                    type='submit'
                    style={{ backgroundColor: '#ff4444' }}
                    onClick={() => {
                      const newMilestones = [...milestones];
                      newMilestones.splice(index, 1);
                      setMilestones(newMilestones);
                    }}
                  >
                    <span
                      style={{ whiteSpace: 'nowrap' }}
                      className='exrond-text text-white font-size-1 font-weight-600'
                    >
                      Remove milestone
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className='mt-3'>
            <button
              className='exrond-btn-light'
              onClick={() => {
                setMilestones([...milestones, { epoch: '', percent: '' }]);
              }}
            >
              <span className='exrond-text text-white font-size-1 font-weight-600'>
                Add milestone
              </span>
            </button>
          </div>
        </div>

        <span
          style={{ color: percent !== 100 ? 'red' : 'green' }}
        >{`Unlock percentage: ${percent}% / 100%`}</span>

        <div className='mt-3'>
          <button
            className='exrond-btn-light'
            disabled={percent !== 100 || !milestonesValid}
            onClick={() => handleDeployVesting()}
          >
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Deploy
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeployVestingContract;
