import React from 'react';
import ExrPageContent from './ExrPageContent';

const ExrPage = () => {
  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer px-0 m-0'>
          <div className='card-body p-1 mt-2 mt-lg-5'>
            <ExrPageContent />
          </div>
        </div>
      </div>
      <div className='launchpad-footer-banner'></div>
    </div>
  );
};

export default ExrPage;
