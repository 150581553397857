import React, { useContext, useMemo } from 'react';
import { EsdtToken } from 'api/types/pairs';
import { SwapContext } from 'pages/SwapV2/Context';
import './SwapTokenOption.scss';

interface SwapTokenOptionBuyProps {
  token?: EsdtToken;
  className?: string;
}

const SwapTokenOptionBuy = ({ token, className }: SwapTokenOptionBuyProps) => {
  const { tokensWithBuyAmount } = useContext(SwapContext);

  const formattedValue = useMemo(() => {
    const amount = tokensWithBuyAmount.find(
      (tokenWithAmount) =>
        tokenWithAmount.token.identifier === token?.identifier
    )?.buyAmount;

    if (amount === undefined) {
      return '0';
    }

    const formatter = Intl.NumberFormat('en', { notation: 'compact' });

    return formatter.format(Number(amount));
  }, [tokensWithBuyAmount, token]);

  return (
    <>
      <span className='swap-option__token-name'>Buy</span>
      <span
        className={`swap-option__token-balance swap-option__text--${
          className || ''
        }`}
      >
        {formattedValue}
      </span>
    </>
  );
};

export default SwapTokenOptionBuy;
