import React, { useState } from 'react';
import { Transaction } from '@multiversx/sdk-core/out';
import { Collapse } from 'react-bootstrap';
import { VestingContract } from 'api/types/AdminSettings';
import { useQueryParams } from 'hooks';
import VestingSettings from 'pages/Settings/VestingSettings';

interface VestingContractProps {
  vestingContract: VestingContract;
  transactionStatus: any;
  refetchingData: boolean;
  processBatchTransactions: (
    transactions: Transaction[][],
    vestingAddress?: string
  ) => void;
}

const VestingContractItem = ({
  vestingContract,
  transactionStatus,
  refetchingData,
  processBatchTransactions
}: VestingContractProps) => {
  const settings = vestingContract.settings;

  const { vestingAddress } = useQueryParams();

  const [expanded, setExpanded] = useState(
    transactionStatus === 'pending' &&
      vestingAddress === vestingContract.address
  );

  return (
    <>
      <h3 className='mb-3'>Vesting contracts</h3>
      <div className='mb-4'>
        <div
          className='card swap-form d-flex flex-row'
          aria-controls='details'
          aria-expanded={expanded}
          style={{ zIndex: 0 }}
        >
          <div className='d-flex py-2 flex-column flex-lg-row flex-fill align-items-lg-center justify-content-lg-between'>
            <div className='header-item mx-1 d-flex flex-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Asset token id</span>
              <div>{settings?.assetTokenId}</div>
            </div>
            <div className='header-item mx-1 d-flex flex-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Locked asset token id</span>
              <div>
                {settings?.lockedAssetTokenId
                  ? settings?.lockedAssetTokenId
                  : '-'}
              </div>
            </div>
            <div className='header-item mx-1 d-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>State</span>
              <div>{settings?.state}</div>
            </div>
            <div className='header-item mx-1 d-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Init epoch</span>
              <div>{settings?.initEpoch}</div>
            </div>
            <div>
              <button
                type='submit'
                className='btn btn-sm btn-stake-action btn-success m-0 w-100 w-lg-30'
                onClick={() => setExpanded((prev) => !prev)}
              >
                Settings
              </button>
            </div>
          </div>
        </div>
        <div>
          <Collapse in={expanded}>
            <div id='details'>
              <VestingSettings
                vestingContract={vestingContract}
                showLoading={transactionStatus === 'pending' || refetchingData}
                processBatchTransactions={(transactions) =>
                  processBatchTransactions(
                    transactions,
                    vestingContract.address
                  )
                }
              />
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default VestingContractItem;
