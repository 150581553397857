import { EsdtToken } from 'api/types/pairs';

export class PairAnalytics {
  popular: EsdtToken[] = [];
  new: EsdtToken[] = [];

  constructor(init?: Partial<PairAnalytics>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): PairAnalytics {
    return new PairAnalytics({
      popular: response.popular.map((t: any) => EsdtToken.fromResponse(t)),
      new: response.new.map((t: any) => EsdtToken.fromResponse(t))
    });
  }
}
