import { gql } from '@apollo/client';

export const CREATE_UNBOND_TRANSACTION = gql`
  mutation unbond($sender: String!, $unbondArgs: GenericFarmActionArgsModel!) {
    transaction: unbond(sender: $sender, unbondArgs: $unbondArgs) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
