import { ClaimPolicy } from 'pages/Faucet/Deposit/types';
import { EsdtToken } from './pairs';

export class FaucetInfo {
  totalClaimableUsd = '0';
  claimInfo: ClaimInfo = new ClaimInfo();
  faucets?: FaucetDetails[];

  constructor(init?: Partial<FaucetInfo>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FaucetInfo {
    return new FaucetInfo({
      totalClaimableUsd: response.totalClaimableUsd,
      claimInfo: ClaimInfo.fromResponse(response.claimInfo),
      faucets:
        response.faucets?.map((faucet: any) =>
          FaucetDetails.fromResponse(faucet)
        ) ?? []
    });
  }
}

export class ClaimInfo {
  feeToken?: EsdtToken;
  feeAmount = '0';

  constructor(init?: Partial<ClaimInfo>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): ClaimInfo {
    return new ClaimInfo({
      feeToken: response.feeToken
        ? EsdtToken.fromResponse(response.feeToken)
        : undefined,
      feeAmount: response.feeAmount
    });
  }
}

export class FaucetDetails {
  token: EsdtToken = new EsdtToken();
  faucetId = '';
  status!: FaucetStatus;
  claimPolicy!: ClaimPolicy;
  rewardPool = '0';
  reward = '0';
  valueUsd = '0';
  actionButton?: FaucetActionButton;
  progress?: FaucetProgress;

  constructor(init?: Partial<FaucetDetails>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FaucetDetails {
    return new FaucetDetails({
      token: EsdtToken.fromResponse(response.token),
      faucetId: response.faucetId,
      status: FaucetStatus[response.status as keyof typeof FaucetStatus],
      claimPolicy:
        ClaimPolicy[response.claimPolicy as keyof typeof ClaimPolicy],
      rewardPool: response.rewardPool,
      reward: response.reward,
      valueUsd: response.valueUsd,
      actionButton:
        FaucetActionButton[
          response?.actionButton as keyof typeof FaucetActionButton
        ],
      progress: response.progress
        ? FaucetProgress.fromResponse(response.progress)
        : undefined
    });
  }
}

export class FaucetProgress {
  startTimestamp = '';
  endTimestamp = '';

  constructor(init?: Partial<FaucetProgress>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FaucetProgress {
    return new FaucetProgress({
      startTimestamp: response.startTimestamp,
      endTimestamp: response.endTimestamp
    });
  }
}

export enum FaucetStatus {
  CLAIMABLE = 'CLAIMABLE',
  RECHARGING = 'RECHARGING',
  EXPIRED = 'EXPIRED',
  ALL = 'ALL'
}

export enum FaucetActionButton {
  CLAIM = 'Claim',
  BUY = 'Buy',
  STAKE = 'Stake',
  RECHARGING = 'Recharging',
  EXPIRED = 'Expired',
  CLAIMED = 'Claimed'
}
