import React, { ReactElement, useContext, useMemo } from 'react';
import { EsdtToken } from 'api/types/pairs';
import { ReactComponent as LiquidityGreenIcon } from 'assets/img/ic_liquidity.svg';
import { ReactComponent as LiquidityRedIcon } from 'assets/img/ic_liquidity_red.svg';
import { ReactComponent as LiquidityYelloIcon } from 'assets/img/ic_liquidity_yellow.svg';
import { ReactComponent as LockIcon } from 'assets/img/ic_lock.svg';
import { TOKEN_PLACEHOLDER } from 'config';
import { formatTicker } from 'pages/Faucet/FaucetCard/utils';
import './SwapTokenOption.scss';
import { SwapContext, SwapPanelContext } from 'pages/SwapV2/Context';
import { SwapCardType } from 'pages/SwapV2/types';

interface SwapTokenOptionProps {
  token: EsdtToken;
  className?: string;
  markClassName?: string;
  info: ReactElement;
}

const SwapTokenOption = ({
  token,
  className,
  markClassName,
  info
}: SwapTokenOptionProps) => {
  const { mainColor, secondaryColor, cardType } = useContext(SwapPanelContext);

  const { liquidities, currentPair } = useContext(SwapContext);

  const liquidityIcon = useMemo(() => {
    const otherToken =
      cardType === SwapCardType.RECEIVER
        ? currentPair?.tokenIn
        : currentPair?.tokenOut;

    if (!otherToken) {
      return undefined;
    }

    const liquidityStatus = liquidities
      .find((liquidity) => liquidity.identifier === token.identifier)
      ?.relations.find(
        (relation) => relation.identifier === otherToken.identifier
      )?.status;

    switch (liquidityStatus) {
      case 'GREEN':
        return (
          <LiquidityGreenIcon
            className={`swap-option__liquidity-icon swap-option__liquidity-icon--green swap-option__liquidity-icon--${
              className || ''
            }`}
          />
        );
      case 'YELLOW':
        return (
          <LiquidityYelloIcon
            className={`swap-option__liquidity-icon swap-option__liquidity-icon--yellow swap-option__liquidity-icon--${
              className || ''
            }`}
          />
        );
      case 'RED':
        return (
          <LiquidityRedIcon
            className={`swap-option__liquidity-icon swap-option__liquidity-icon--red swap-option__liquidity-icon--${
              className || ''
            }`}
          />
        );
      default:
        return undefined;
    }
  }, [cardType, currentPair, liquidities]);

  return (
    <div className='d-flex flex-row align-items-center swap-option w-100'>
      <div
        className={`swap-option__mark swap-option__mark--${
          markClassName || ''
        }`}
      />
      <div className='d-flex flex-row'>
        <img
          src={token.icon ?? TOKEN_PLACEHOLDER}
          className={`swap-token-icon swap-option__icon swap-option__icon--border-${secondaryColor} p-0`}
        />
        <div
          className={`swap-option__lock-container swap-option__lock-container--${mainColor}`}
        >
          <LockIcon
            className={`swap-option__lock-icon swap-option__lock-icon--${className}`}
          />
        </div>
      </div>
      <div className='d-flex flex-row align-items-center justify-content-between w-100 mx-2'>
        <div className='swap-option__container'>
          <span
            className={`swap-option__token-ticker swap-option__text--${
              className || ''
            }`}
          >
            {formatTicker(token.ticker)}
          </span>
          <span className='swap-option__token-name'>{token.name}</span>
        </div>
        <div className='d-flex align-items-center flex-grow-1'>
          {liquidityIcon}
        </div>
        <div className='swap-option__container mr-4'>{info}</div>
      </div>
    </div>
  );
};

export default SwapTokenOption;
