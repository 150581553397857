import * as React from 'react';
import '../../../assets/sass/exrond.scss';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import useBatchTransactions from '@buidly/ioiom-staking-dapp/hooks/useBatchTransactions';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { debounce } from 'lodash';
import { Spinner } from 'react-bootstrap';
import { useGetPairs } from 'api/hooks/useGetPairs';
import { Pair } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import { slippageVar } from 'cache';
import ActionOrConnect from 'components/ActionOrConnect';
import Loader from 'components/Loader';
import { SwapCardType } from 'components/SwapCard/types';
import {
  computeNewPair,
  computeOtherToken,
  computeTokenAmount
} from 'components/SwapWidget/utils';
import { EGLD, WEGLD } from 'config';
import { useAccountDetails } from 'hooks';
import { KEY_PREV_PAIR } from 'utils/Constants';
import AddLiquidityInputSelector from '../AddLiquidityInputSelector';
import {
  AddLiquidityArgs,
  AddLiquidityTokenArgs,
  ADD_LIQUIDITY_MUTATION,
  GET_EQUIVALENT_QUERY
} from '../liquidity';
import LiquidityInfoBox from '../LiquidityInfoBox';
import PlusLiquidityButton from '../PlusLiquidityButton';

interface SelectablePair {
  pair: Pair;
  direction: 'in' | 'out';
}

interface GetEquivalentResponseQuery {
  getEquivalent: {
    amountIn: string;
    amountOut: string;
    tokenInID: string;
    tokenOutID: string;
  };
}

const AddLiquidity = () => {
  const { data: pairs } = useGetPairs();
  const { accountDetails } = useAccountDetails();
  const { address } = useGetAccount();
  const slippage = useReactiveVar(slippageVar);
  const [currentPair, setCurrentPair] = React.useState<{
    pair: Pair;
    direction: 'in' | 'out';
  }>();

  const [tokenAmountsMap, setTokenAmountsMap] = React.useState(
    new Map<string, string>()
  );

  const [lastCardUpdated, setLastCardUpdated] = React.useState<SwapCardType>(
    SwapCardType.SENDER
  );

  const [getEquivalentQuery, { data: equivalentPriceData }] = useLazyQuery(
    GET_EQUIVALENT_QUERY,
    { notifyOnNetworkStatusChange: true }
  );

  const [validSwapCards, setValidSwapCards] = React.useState({
    sender: false,
    receiver: false
  });

  const enableAddLiquidityButton =
    validSwapCards.sender && validSwapCards.receiver;

  const refetchBasedOnEquivalentObject = (
    equivalentObject: GetEquivalentResponseQuery['getEquivalent'],
    pair: Pair
  ) => {
    const firstToken = pair.firstToken;
    const secondToken = pair.secondToken;

    const firstTokenAmount =
      equivalentObject.tokenInID === WEGLD.identifier ||
      equivalentObject.tokenInID === EGLD.identifier
        ? equivalentObject.amountIn
        : equivalentObject.amountOut;

    const secondTokenAmount =
      equivalentObject.tokenInID === WEGLD.identifier ||
      equivalentObject.tokenInID === EGLD.identifier
        ? equivalentObject.amountOut
        : equivalentObject.amountIn;

    const firstTokenAmountFormatted = new BigNumber(firstTokenAmount)
      .shiftedBy(-firstToken.decimals)
      .toFixed();

    const secondTokenAmountFormatted = new BigNumber(secondTokenAmount)
      .shiftedBy(-secondToken.decimals)
      .toFixed();

    return new Map(
      tokenAmountsMap
        .set(firstToken.identifier, firstTokenAmountFormatted)
        .set(secondToken.identifier, secondTokenAmountFormatted)
    );
  };

  const handleUpdatePrice = () => {
    const pair = currentPair?.pair;
    if (!equivalentPriceData || !equivalentPriceData.getEquivalent || !pair) {
      return;
    }

    const equivalentObject: GetEquivalentResponseQuery['getEquivalent'] =
      equivalentPriceData.getEquivalent;

    setTokenAmountsMap(refetchBasedOnEquivalentObject(equivalentObject, pair));
  };

  const [sendBatchTransactions] = useBatchTransactions();
  const [
    addLiquidityMutation,
    { data: addLiquidityMutationData, loading: addLiquidityMutationLoading }
  ] = useMutation(ADD_LIQUIDITY_MUTATION, {
    notifyOnNetworkStatusChange: true
  });

  const getAddLiquidityInputArgs = (): AddLiquidityArgs => {
    return {
      pairAddress: currentPair?.pair.address ?? '',
      tolerance: slippage,
      tokens: [
        {
          identifier: currentPair?.pair.firstToken.identifier,
          amount: computeTokenAmount(
            currentPair?.pair.firstToken,
            tokenAmountsMap
          )
        },
        {
          identifier: currentPair?.pair.secondToken.identifier,
          amount: computeTokenAmount(
            currentPair?.pair.secondToken,
            tokenAmountsMap
          )
        }
      ] as AddLiquidityTokenArgs[]
    };
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    addLiquidityMutation({
      variables: {
        sender: address,
        input: getAddLiquidityInputArgs()
      }
    });
  };

  const handleTokenChanged = (
    tokenIdentifier: string,
    direction: 'in' | 'out',
    type: SwapCardType
  ) => {
    const newPair = computeNewPair(
      tokenIdentifier,
      direction,
      type,
      pairs,
      currentPair?.pair
    );
    if (newPair) {
      setCurrentPair({
        pair: newPair,
        direction: direction
      });
    }
  };

  const updatePrices = (
    tokenIdentifier: string,
    amountsMap: Map<string, string>
  ) => {
    if (currentPair?.pair) {
      const tokenAmount = amountsMap.get(tokenIdentifier) ?? '0';
      if (tokenAmount === '0') {
        const otherToken = computeOtherToken(tokenIdentifier, {
          firstToken: currentPair.pair.firstToken,
          secondToken: currentPair.pair.secondToken,
          direction: currentPair.direction
        });

        if (otherToken) {
          setTokenAmountsMap(
            new Map(tokenAmountsMap.set(otherToken?.identifier, '0'))
          );
        }
      } else {
        updateTokenPriceQuery(tokenIdentifier, currentPair, amountsMap);
      }
    }
  };

  const updateTokenPriceQuery = React.useCallback(
    debounce((tokenIdentifier, selectedPair: SelectablePair, amountsMap) => {
      const isFirstToken =
        selectedPair.pair.firstToken.identifier === tokenIdentifier;
      getEquivalentQuery({
        variables: {
          pairAddress: selectedPair.pair.address,
          tokenInID: tokenIdentifier,
          amount: computeTokenAmount(
            isFirstToken
              ? selectedPair.pair.firstToken
              : selectedPair.pair.secondToken,
            amountsMap
          )
        }
      });
    }, 800),
    []
  );

  const handleInputChanged = (
    tokenIdentifier: string,
    value: string,
    type: SwapCardType
  ) => {
    setLastCardUpdated(type);
    const amountsMap = new Map(tokenAmountsMap.set(tokenIdentifier, value));
    setTokenAmountsMap(amountsMap);
    updatePrices(tokenIdentifier, amountsMap);
  };

  const handleValidCard = (cardType: 'sender' | 'receiver', value: boolean) => {
    setValidSwapCards({ ...validSwapCards, [cardType]: value });
  };

  React.useMemo(() => {
    handleUpdatePrice();
  }, [equivalentPriceData]);

  const isLoading = React.useMemo(() => {
    if (pairs === undefined || accountDetails === undefined) {
      return true;
    }

    return false;
  }, [pairs, currentPair, accountDetails]);

  React.useEffect(() => {
    if (!addLiquidityMutationData || !addLiquidityMutationData.addLiquidity) {
      return;
    }

    sendBatchTransactions(addLiquidityMutationData.addLiquidity);
  }, [addLiquidityMutationData, addLiquidityMutationLoading]);

  React.useEffect(() => {
    if (!currentPair) {
      return;
    }

    const pair = currentPair.pair;

    localStorage.setItem(KEY_PREV_PAIR(address), JSON.stringify(currentPair));

    if (lastCardUpdated === SwapCardType.SENDER) {
      updatePrices(
        currentPair?.direction === 'out'
          ? pair.secondToken?.identifier
          : pair.firstToken?.identifier,
        tokenAmountsMap
      );
    } else {
      updatePrices(
        currentPair?.direction === 'out'
          ? pair.firstToken?.identifier
          : pair.secondToken?.identifier,
        tokenAmountsMap
      );
    }
  }, [currentPair, lastCardUpdated]);

  React.useEffect(() => {
    if (!pairs || pairs.length === 0) {
      return;
    }

    const currentPairAddress = currentPair?.pair?.address;

    if (!currentPairAddress || currentPairAddress?.length === 0) {
      const prevPairJson = localStorage.getItem(KEY_PREV_PAIR(address));
      localStorage.removeItem(KEY_PREV_PAIR(address));

      if (prevPairJson != null) {
        setCurrentPair(JSON.parse(prevPairJson));
      } else {
        setCurrentPair({
          pair: pairs[0],
          direction: 'out'
        });
      }

      return;
    }

    const foundPair = pairs.find((pair) => pair.address === currentPairAddress);
    if (!foundPair) {
      return;
    }

    // First token can be EGLD, make sure to keep it in that case
    setCurrentPair({
      pair: {
        ...foundPair,
        firstToken: currentPair?.pair.firstToken ?? foundPair.firstToken
      },
      direction: currentPair?.direction ?? 'out'
    });
  }, [pairs]);

  return (
    <div
      className={
        'd-flex flex-column align-items-center justify-content-between'
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AddLiquidityInputSelector
            currentPair={currentPair?.pair}
            pairs={pairs ?? []}
            direction={currentPair?.direction ?? 'out'}
            inputValues={tokenAmountsMap}
            type={SwapCardType.SENDER}
            selectOnChange={handleTokenChanged}
            inputOnChange={handleInputChanged}
            setEnableSwap={(evt) => handleValidCard('sender', evt)}
          />
          <div className='d-flex justify-content-center mt-2'>
            <PlusLiquidityButton />
          </div>
          <AddLiquidityInputSelector
            currentPair={currentPair?.pair}
            pairs={pairs ?? []}
            direction={currentPair?.direction ?? 'out'}
            type={SwapCardType.RECEIVER}
            inputValues={tokenAmountsMap}
            selectOnChange={handleTokenChanged}
            inputOnChange={handleInputChanged}
            setEnableSwap={(evt) => handleValidCard('receiver', evt)}
          />
          <div className='w-100 d-flex justify-content-center'>
            {currentPair && (
              <LiquidityInfoBox
                backgroundColor={colors.colorPrimaryDark}
                pair={currentPair.pair}
              />
            )}
          </div>
          <div className='home-container18 mt-3'>
            <ActionOrConnect className='w-100'>
              <a
                className={`align-self-center btn-swap w-100 ${
                  enableAddLiquidityButton ? '' : 'disabled'
                }`}
                onClick={(e) => handleSubmit(e)}
              >
                {isLoading ? <Spinner /> : <span>Add Liquidity</span>}
              </a>
            </ActionOrConnect>
          </div>
        </>
      )}
    </div>
  );
};

export default AddLiquidity;
