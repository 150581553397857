import { gql } from '@apollo/client';

export const PAIR_ANALYTICS_QUERY = gql`
  query pairAnalytics {
    pairAnalytics {
      popular {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
      new {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
    }
  }
`;
