import * as React from 'react';
import './SwapSvg.scss';

interface SwapSvgProps {
  hidden?: boolean;
  onClick: () => void;
}

const SwapSvg = ({ hidden = false, onClick }: SwapSvgProps) => {
  if (hidden) {
    return null;
  }

  return (
    <div className='swap-svg-wrapper' onClick={onClick}>
      <svg viewBox='0 0 1024 1024' className='swap-svg'>
        <path d='M768 170l170 172h-128v298q0 70-50 120t-120 50-120-50-50-120v-298q0-34-26-60t-60-26-60 26-26 60v298h128l-170 170-170-170h128v-298q0-70 50-121t120-51 120 51 50 121v298q0 34 26 60t60 26 60-26 26-60v-298h-128z'></path>
      </svg>
    </div>
  );
};

export default SwapSvg;
