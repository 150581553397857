import React from 'react';
import '../../SwapSettings.scss';
import { formatTicker } from '@buidly/ioiom-staking-dapp/utils/utils';
import { ReactComponent as RouteArrowIcon } from 'assets/img/swap-info/route-arrow.svg';
import { ReactComponent as SwapRouteIcon } from 'assets/img/swap-info/swap-route.svg';

interface SwapRouteProps {
  tokenRoute: string[];
}

export const SwapRoute = ({ tokenRoute }: SwapRouteProps) => {
  return (
    <div className='swap-settings__swap-route'>
      <SwapRouteIcon className='mr-1' />
      <span className='swap-settings__text'>Swap Route:</span>
      <ul>
        {tokenRoute.slice(0, -1).map((token, index) => (
          <li className='d-flex align-items-center' key={index}>
            <span className='swap-settings__text mr-1'>
              {formatTicker(token)}
            </span>
            <RouteArrowIcon className='mr-1' />
            <span className='swap-settings__text'>
              {formatTicker(tokenRoute[index + 1])}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
