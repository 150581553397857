import React from 'react';
import { Spinner } from 'react-bootstrap';
import { EsdtToken } from 'api/types/pairs';
import { useGetTokenUsdValue } from 'pages/SwapV2/hooks';
import './SwapTokenOption.scss';

interface SwapTokenOptionCurrentValueProps {
  token?: EsdtToken;
  className?: string;
}

const SwapTokenOptionCurrentValue = ({
  token,
  className
}: SwapTokenOptionCurrentValueProps) => {
  const { usdValue, loading } = useGetTokenUsdValue(token);

  const formattedUsdValue =
    usdValue !== undefined
      ? usdValue.toFixed(usdValue.isGreaterThanOrEqualTo(1) ? 2 : 6)
      : undefined;

  return (
    <>
      <span className='swap-option__token-name'>Current value</span>
      <span
        className={`swap-option__token-balance swap-option__text--${
          className || ''
        }`}
      >
        {loading ? (
          <Spinner as='span' animation='border' size='sm' color='#fff' />
        ) : formattedUsdValue !== undefined ? (
          <>${formattedUsdValue}</>
        ) : (
          '-'
        )}
      </span>
    </>
  );
};

export default SwapTokenOptionCurrentValue;
