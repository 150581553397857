import { gql } from '@apollo/client';

export const UNBOND_LIQUIDITY = gql`
  mutation UNBOND_LIQUIDITY($sender: String!, $tokenID: String!) {
    transactions: unbondLiquidity(sender: $sender, tokenId: $tokenID) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
