import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocalStorage } from '@buidly/dapp-core/dist/hooks';
import { useGetRegisterGroupSettings } from '@buidly/ioiom-staking-dapp/RegisterV2/hooks/useGetRegisterGroupSettings';
import { Transaction } from '@multiversx/sdk-core/out';
import {
  useGetAccount,
  useGetActiveTransactionsStatus
} from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CREATE_REGISTER_GROUP_TRANSACTION } from 'api/mutations';
import { EsdtTokenSimplified } from 'api/tokens/esdtToken.model';
import colors from 'assets/sass/variables.module.scss';
import Loader from 'components/Loader';
import { TOKEN_PLACEHOLDER } from 'config';
import { useAccountDetails } from 'hooks';
import { refetchGroups } from 'hooks/useGroups';
import { formatTicker } from 'pages/Faucet/FaucetCard/utils';
import { routeNames } from 'routes';
import * as transactionsRequest from 'stakegold-core-dapp/helpers';
import * as Constants from '../../utils';

const RegisterPage = () => {
  const { address } = useGetAccount();
  const [selectedToken, setSelectedToken] = useState<EsdtTokenSimplified>();
  const [availableTokens, setAvailableTokens] = useState<EsdtTokenSimplified[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'unlockSessionId',
    null
  );
  const { success, pending } = useGetActiveTransactionsStatus();
  const navigate = useNavigate();

  const colourStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      color: state.selectProps.menuColor,
      padding: 10
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colors.colorPrimaryDark
        : colors.colorPrimary,
      '&:hover': {
        backgroundColor: colors.colorPrimaryDark
      }
    })
  };

  const { registerGroupSettings, loading } = useGetRegisterGroupSettings();
  const { accountDetails } = useAccountDetails();

  const registerGroupFee = new BigNumber(
    registerGroupSettings?.registerGroupFee ?? 0
  )
    .shiftedBy(-(registerGroupSettings?.registerGroupFeeToken.decimals ?? 0))
    .toFixed();

  useEffect(() => {
    if (registerGroupSettings) {
      setAvailableTokens(
        registerGroupSettings.tokens.map((esdtToken: any) =>
          EsdtTokenSimplified.fromResponse(esdtToken)
        )
      );
    }
  }, [registerGroupSettings]);

  const hasEnoughFunds = useMemo(() => {
    if (
      registerGroupSettings?.registerGroupFee === undefined ||
      accountDetails === undefined
    ) {
      return false;
    }

    const registerGroupFeeToken = registerGroupSettings.registerGroupFeeToken;
    const feeTokenBalance = accountDetails.esdtTokens.find(
      (token) => token.identifier === registerGroupFeeToken.identifier
    )?.balance;

    if (feeTokenBalance === undefined) {
      return false;
    }

    return new BigNumber(registerGroupSettings.registerGroupFee).isLessThan(
      feeTokenBalance
    );
  }, [accountDetails, registerGroupSettings]);

  const validateTokenId = (): {
    tokenName: string;
    tokenId: string;
    errorMessage?: string;
  } => {
    if (!selectedToken) {
      return {
        tokenName: '',
        tokenId: '',
        errorMessage: 'Invalid token id'
      };
    }

    const tokenSplit = selectedToken.identifier.split('-');
    if (
      tokenSplit.length !== 2 ||
      tokenSplit[0].length <= 1 ||
      tokenSplit[1].length !== 6
    ) {
      return {
        tokenName: '',
        tokenId: '',
        errorMessage: 'Invalid token id'
      };
    }

    return {
      tokenName: tokenSplit[0],
      tokenId: selectedToken.identifier,
      errorMessage: undefined
    };
  };

  const processTransactions = async (
    groupId: string,
    transaction: Transaction
  ) => {
    try {
      localStorage.setItem('pendingGroupId', groupId);

      const response = await transactionsRequest.sendAndSignTransactions(
        [transaction],
        'registerGroup'
      );

      setSessionId(response.sessionId);
    } catch (error) {
      console.log('processTransaction error', error);
    }
  };

  const [registerGroupTransaction, { data: registerGroupData, error, client }] =
    useMutation(CREATE_REGISTER_GROUP_TRANSACTION);

  useEffect(() => {
    if (registerGroupData && registerGroupData.registerGroup) {
      const unwrappedData = registerGroupData.registerGroup;
      processTransactions(
        unwrappedData.groupId,
        unwrappedData.transaction as Transaction
      );
    }

    if (error) {
      setErrorMessage(error?.toString());
    }
  }, [registerGroupData, error]);

  useEffect(() => {
    if (success) {
      refetchGroups(client);
      const pendingGroupId = localStorage.getItem('pendingGroupId');
      localStorage.removeItem('pendingGroupId');
      if (pendingGroupId == null) {
        return;
      }

      localStorage.setItem(Constants.KEY_NO_CACHE(pendingGroupId), 'true');
      navigate({
        pathname: routeNames.stakingAdmin,
        search: `?groupId=${pendingGroupId}`
      });
    }
  }, [success]);

  const handleRegisterGroup = (event: any) => {
    event.preventDefault();
    const { tokenName, tokenId, errorMessage: err } = validateTokenId();
    if (err) {
      setErrorMessage(err);
      return;
    }

    registerGroupTransaction({
      variables: {
        sender: address,
        registerGroupArgs: {
          tokenId: tokenId,
          groupId: tokenName
        }
      }
    });
    setErrorMessage(undefined);
  };

  const onTokenSelected = (selectedOption: any) => {
    setSelectedToken(selectedOption);
  };

  const formatOptionLabel = (token: EsdtTokenSimplified) => (
    <div className='d-flex flex-row align-items-center'>
      <>
        <img
          src={token.icon ?? TOKEN_PLACEHOLDER}
          className='p-0 token-icon token-select'
          style={{ width: '32px', height: '32px' }}
        />
        <span className='ml-3'>{token.name}</span>
      </>
    </div>
  );

  return (
    <div className='exrond-text text-white d-flex flex-fill align-items-center container'>
      <div className='row w-100 d-flex justify-content-center'>
        <div className='col-12 col-md-8'>
          <form onSubmit={handleRegisterGroup}>
            <div
              className='w-100 exrond-swap-card'
              style={{ backgroundColor: colors.colorPrimaryDark }}
            >
              <span className='swap-title'>Register token</span>
              {pending || loading ? (
                <div className='w-100 mt-5'>
                  <Loader />
                </div>
              ) : (
                <div className='w-100'>
                  {registerGroupSettings && (
                    <div className='exrond-text text-white w-100'>
                      Register your token once for {registerGroupFee}{' '}
                      {formatTicker(
                        registerGroupSettings.registerGroupFeeToken.identifier
                      )}{' '}
                      . After that, you can setup your farms.
                    </div>
                  )}
                  <div className='mt-3 d-flex flex-column flex-xl-row w-100'>
                    <div className='w-100'>
                      <Select
                        className={'exrond-input primary exrond-select'}
                        classNamePrefix={'exrond'}
                        formatOptionLabel={formatOptionLabel}
                        options={availableTokens}
                        styles={colourStyles}
                        onChange={onTokenSelected}
                        placeholder={'Select token'}
                        getOptionValue={(option) => option.identifier}
                      />
                    </div>
                    <div>
                      <button
                        disabled={!hasEnoughFunds}
                        className='exrond-btn mt-3 mt-xl-0 ml-0 ml-xl-5'
                        type='submit'
                      >
                        <span className='exrond-text text-white font-size-1 font-weight-600'>
                          Register
                        </span>
                      </button>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className='text-danger mt-2'>{errorMessage}</div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
