import React, { useState } from 'react';
import { GenericFarmFilterArgs } from '@buidly/ioiom-staking-dapp/types';
import { LiquidVesting, Tokenomics } from 'components/BlueChips';
import { BLUE_CHIPS_COLLECTION_ID, EXRD_TOKEN_ID } from 'config';
import { useMediaQuery } from 'hooks';
import NftStakingPageContent from 'pages/StakeV2/NftStaking/NftStakingPageContent';
import TokensStakingPageContent from 'pages/StakeV2/TokensStaking/TokenStakingPageContent';
import ExrPageHeaderDesktop from './BlueChipsHeader/ExrPageHeaderDesktop';
import ExrPageHeaderMobile from './BlueChipsHeader/ExrPageHeaderMobile';
import './ExrPage.scss';

const ExrPageContent = () => {
  const [tokenDisplay, setTokenDisplay] = useState(false);
  const [nftDisplay, setNftDisplay] = useState(false);
  const [vestingClicked, setVestingClicked] = useState(false);
  const [tokenomicsClicked, setTokenomicsClicked] = useState(false);
  const [mintControlsShown, setMintControlsShown] = useState(false);
  const [mintSuccessful, setMintSuccessful] = useState<number | null>(null);

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const nftFilterArgs: GenericFarmFilterArgs = {
    farmingTokenIdentifier: {
      includes: [BLUE_CHIPS_COLLECTION_ID]
    },
    rewardTokenIdentifier: {
      excludes: [EXRD_TOKEN_ID]
    }
  };

  const tokenFilterArgs: GenericFarmFilterArgs = {
    farmingTokenIdentifier: {
      includes: [EXRD_TOKEN_ID]
    }
  };

  const handleMobileMintClick = (showMint: boolean) => {
    setMintControlsShown(showMint);
    if (!showMint) {
      setTokenDisplay(false);
      setNftDisplay(false);
      return;
    }

    if (!isDesktop) {
      setTokenomicsClicked(false);
      setVestingClicked(false);
      setTokenDisplay(true);
      setNftDisplay(true);
    }
  };

  const handleMintSuccessful = () => {
    setMintSuccessful((prev) => {
      if (prev === null) {
        return 0;
      }

      return prev + 1;
    });
  };

  const handleMobileTokenomicsClicked = (value: boolean) => {
    setMintControlsShown(false);
    setTokenDisplay(false);
    setNftDisplay(false);
    setTokenomicsClicked(value);
  };

  const handleMobileVestingClicked = (value: boolean) => {
    setMintControlsShown(false);
    setTokenDisplay(false);
    setNftDisplay(false);
    setVestingClicked(value);
  };

  return (
    <>
      <div className='px-3'>
        {isDesktop ? (
          <ExrPageHeaderDesktop
            vestingClicked={vestingClicked}
            setVestingClicked={setVestingClicked}
            tokenomicsClicked={tokenomicsClicked}
            setTokenomicsClicked={setTokenomicsClicked}
            controlsShown={mintControlsShown}
            showControls={(show) => setMintControlsShown(show)}
            mintSuccessful={handleMintSuccessful}
          />
        ) : (
          <ExrPageHeaderMobile
            vestingClicked={vestingClicked}
            setVestingClicked={handleMobileVestingClicked}
            tokenomicsClicked={tokenomicsClicked}
            setTokenomicsClicked={handleMobileTokenomicsClicked}
            controlsShown={mintControlsShown}
            showControls={handleMobileMintClick}
            mintSuccessful={handleMintSuccessful}
          />
        )}
        {tokenomicsClicked && (
          <Tokenomics
            onHide={() => setTokenomicsClicked(false)}
            onMint={() => {
              setTokenomicsClicked(false);
              setMintControlsShown(true);

              if (!isDesktop) {
                setTokenDisplay(true);
                setNftDisplay(true);
              }
            }}
          />
        )}
        {vestingClicked && (
          <LiquidVesting
            onHide={() => setVestingClicked(false)}
            onMint={() => {
              setVestingClicked(false);
              setMintControlsShown(true);

              if (!isDesktop) {
                setTokenDisplay(true);
                setNftDisplay(true);
              }
            }}
            expanded={!isDesktop}
            mintSuccessful={mintSuccessful}
          />
        )}
      </div>
      {(isDesktop || (!isDesktop && !vestingClicked && !tokenomicsClicked)) && (
        <>
          <div className='col-12 py-spacer m-0'>
            {!tokenDisplay && (
              <>
                <p className='exr-page__sub-titles'>Stake BlueChips</p>
                <div className='mt-2 mt-lg-5'>
                  <NftStakingPageContent
                    filterArgs={nftFilterArgs}
                    setNftDisplay={setNftDisplay}
                  />
                </div>
              </>
            )}
            {!nftDisplay && (
              <>
                <p className='exr-page__sub-titles'>Stake EXR</p>
                <div className='mt-2 mt-lg-5'>
                  <TokensStakingPageContent
                    filterArgs={tokenFilterArgs}
                    setTokenDisplay={setTokenDisplay}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ExrPageContent;
