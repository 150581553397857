import BigNumber from 'bignumber.js';
import { TOKEN_PLACEHOLDER } from 'config';

export class LockedTokenCollection {
  public collection = '';
  public tokens: LockedToken[] = [];

  constructor(init?: Partial<LockedTokenCollection>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): LockedTokenCollection {
    return new LockedTokenCollection({
      collection: response.collection,
      tokens: response.tokens?.map((token: any) =>
        LockedToken.fromResponse(token)
      )
    });
  }
}

export class LockedToken {
  public identifier = '';
  public collection = '';
  public nonce = 0;
  public name = '';
  public balance = new BigNumber(0);
  public decimals = 0;
  public ticker = '';
  public icon = '';
  public unlockSchedule: UnlockMilestone[] = [];
  public assets?: any;

  constructor(init?: Partial<LockedToken>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): LockedToken {
    return new LockedToken({
      ...response,
      balance: new BigNumber(response.balance ?? 0),
      unlockSchedule: response.unlockSchedule?.map((schedule: any) =>
        UnlockMilestone.fromResponse(schedule)
      ),
      icon:
        response.assets?.pngUrl ?? response.assets?.svgUrl ?? TOKEN_PLACEHOLDER
    });
  }
}

export class UnlockMilestone {
  public epoch = 0;
  public percent = 0;
  public unlockDate?: string;

  constructor(init?: Partial<UnlockMilestone>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): UnlockMilestone {
    return new UnlockMilestone({
      epoch: response.epoch,
      percent: response.percent,
      unlockDate: response.unlockDate
    });
  }
}
