import React, { useEffect, useRef, useState } from 'react';
import { FaucetDetails } from 'api/types/faucets';
import { useSticky } from 'hooks';
import FaucetClaimAllButton from 'pages/Faucet/FaucetHeader/FaucetClaimAllView.tsx/FaucetClaimAllButton';
import FaucetButton from './FaucetButton';

export interface FaucetButtonProps {
  faucet?: FaucetDetails;
  onClaimClicked: (
    faucet: FaucetDetails,
    payWithEgld: boolean | undefined
  ) => void;
  onClaimAllClicked?: (payWithEgld: boolean | undefined) => void;
  pending: boolean;
  containerClassName?: string;
  disabled: boolean;
}

const StickyFaucetButton = ({
  faucet,
  pending,
  disabled,
  onClaimClicked,
  onClaimAllClicked
}: FaucetButtonProps) => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const isSticky = useSticky(stickyRef);
  const [refUpdated, setRefUpdated] = useState(false);

  useEffect(() => {
    if (stickyRef.current && !refUpdated) {
      setRefUpdated(true);
    }
  }, [stickyRef]);

  if (disabled) {
    return null;
  }

  return (
    <div
      ref={stickyRef}
      className={`d-flex d-lg-none sticky-faucet-button ${
        isSticky ? 'exrond-gradient-container' : ''
      }`}
    >
      {!faucet ? (
        <FaucetClaimAllButton
          onClaimAllClicked={(payWithEgld) => onClaimAllClicked?.(payWithEgld)}
          pending={pending}
          disabled={disabled}
        />
      ) : (
        <FaucetButton
          containerClassName='w-100'
          onClaimClicked={(payWithEgld) => onClaimClicked(faucet, payWithEgld)}
          pending={pending}
          actionButton={faucet.actionButton}
        />
      )}
    </div>
  );
};

export default StickyFaucetButton;
