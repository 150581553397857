import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { ACCOUNT_DETAILS } from 'api/queries';
import { AccountDetails } from '../api/types';

const useAccountDetails = () => {
  const { address } = useGetAccountInfo();

  const { data, loading, error } = useQuery(ACCOUNT_DETAILS, {
    variables: {
      address: address
    }
  });

  return useMemo(() => {
    if (!data?.accountDetails) {
      return { loading, accountDetails: undefined };
    }

    return {
      loading,
      accountDetails: AccountDetails.fromResponse(data.accountDetails)
    };
  }, [data, loading, error]);
};

export const refetchAccountDetails = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [ACCOUNT_DETAILS]
  });
};

export { useAccountDetails };
