import React, { useEffect } from 'react';
import {
  useGetNftFarms,
  usePerformNftTransaction
} from '@buidly/ioiom-staking-dapp/NftStaking/hooks';
import { MobileConnectButton } from '@buidly/ioiom-staking-dapp/TokenStaking/ui';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Loader, PageState } from '@multiversx/sdk-dapp/UI';
import { BLUE_CHIPS_COLLECTION_ID, EXRD_TOKEN_ID } from 'config';
import { LiquidVestingCard } from './Components';

interface LiquidVestingContentProps {
  expanded: boolean;
  onHide: () => void;
  onMint: () => void;
  mintSuccessful: number | null;
}

const LiquidVestingContent = ({
  expanded,
  onHide,
  onMint,
  mintSuccessful
}: LiquidVestingContentProps) => {
  const { data, loading, error, refetch } = useGetNftFarms({
    farmingTokenIdentifier: {
      includes: [BLUE_CHIPS_COLLECTION_ID]
    },
    rewardTokenIdentifier: {
      includes: [EXRD_TOKEN_ID]
    }
  });

  const { performTransaction, success, pending } = usePerformNftTransaction();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  if (loading) {
    return (
      <div className='d-flex flex-column'>
        <div className='card liquid-vesting d-flex flex-column'>
          <div className='liquid-vesting__empty' />
          <Loader noText className='liquid-vesting-loader' />
        </div>
      </div>
    );
  }

  if (error || data?.length === 0) {
    return (
      <div className='d-flex flex-column'>
        <div className='card liquid-vesting d-flex flex-column'>
          <div className='liquid-vesting__empty' />
          <PageState
            icon={faX}
            title='An error has occurred. Please try again later!'
          />
        </div>
      </div>
    );
  }

  return (
    <div className='d-flex flex-column'>
      {data.slice(0, 1).map((farm) => (
        <LiquidVestingCard
          farm={farm}
          key={`${farm.groupId}${farm.rewardToken.identifier}`}
          pending={pending}
          success={success}
          onActionClick={performTransaction}
          onCancelDetailsClick={onHide}
          expanded={expanded}
          onMint={onMint}
          mintSuccessful={mintSuccessful}
        />
      ))}
      <MobileConnectButton />
    </div>
  );
};

export default LiquidVestingContent;
