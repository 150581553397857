import { gql } from '@apollo/client';

export const SWAP_QUERY = gql`
  query swap($sender: String!, $input: SwapTokensArgs!) {
    swap(sender: $sender, input: $input) {
      tokenInID
      amountIn
      tokenOutID
      amountOut
      tolerance
      tokensPriceDeviationPercent
      tokenRoute
      tokenInExchangeRate
      pairs {
        firstToken {
          identifier
          name
          decimals
          supply
          ticker
          balance
          assets {
            svgUrl
            pngUrl
          }
        }
        secondToken {
          identifier
          name
          decimals
          supply
          ticker
          balance
          assets {
            svgUrl
            pngUrl
          }
        }
      }
      tokenInMetadata {
        ticker
        identifier
        name
        decimals
        assets {
          svgUrl
          pngUrl
        }
      }
      tokenOutExchangeRate
      tokenOutMetadata {
        ticker
        identifier
        name
        decimals
        assets {
          svgUrl
          pngUrl
        }
      }
      liquidityProviderFees
      pricesImpact
      transactions {
        nonce
        value
        sender
        receiver
        gasPrice
        gasLimit
        data
        chainID
        version
        options
      }
      swapDetails {
        initialLiquidity {
          firstTokenAmount
          secondTokenAmount
          firstTokenMetadata {
            ticker
            identifier
            name
            decimals
            assets {
              svgUrl
              pngUrl
            }
          }
          secondTokenMetadata {
            ticker
            identifier
            name
            decimals
            assets {
              svgUrl
              pngUrl
            }
          }
          usdAmount
        }
        totalLiquidity {
          firstTokenAmount
          secondTokenAmount
          firstTokenMetadata {
            ticker
            identifier
            name
            decimals
            assets {
              svgUrl
              pngUrl
            }
          }
          secondTokenMetadata {
            ticker
            identifier
            name
            decimals
            assets {
              svgUrl
              pngUrl
            }
          }
          usdAmount
        }
      }
    }
  }
`;
