import React, { useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI';
import { Modal } from 'react-bootstrap';
import { routeNames } from 'routes';

interface ConnectModalProps {
  show: boolean;
  onHide: () => void;
}

const ConnectModal = ({ show, onHide }: ConnectModalProps) => {
  const { address } = useGetAccount();
  const isLoggedIn = Boolean(address);

  useEffect(() => {
    if (isLoggedIn) {
      onHide();
    }
  }, [isLoggedIn]);

  const commonProps = {
    callbackRoute: routeNames.home,
    nativeAuth: true // optional
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Connect to a wallet</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column'>
        <ExtensionLoginButton
          loginButtonText='MultiversX DeFi Wallet'
          {...commonProps}
        />
        <WalletConnectLoginButton
          loginButtonText='xPortal App'
          isWalletConnectV2={true}
          {...commonProps}
        />
        <WebWalletLoginButton
          loginButtonText='MultiversX Web Wallet'
          {...commonProps}
        />
        <LedgerLoginButton loginButtonText='Ledger' {...commonProps} />
      </Modal.Body>
    </Modal>
  );
};

export default ConnectModal;
