import React from 'react';
import { LockedTokenCollection as LockedTokenCollectionEntity } from 'api/tokens/locked.token';
import LockedTokenCollection from '../LockedTokenCollection';

interface LockedTokenCollectionListProps {
  lockedTokens: LockedTokenCollectionEntity[];
}

const LockedTokenCollectionList = ({
  lockedTokens
}: LockedTokenCollectionListProps) => {
  return (
    <>
      <b>
        <p className='mt-2'>Locked tokens</p>
      </b>
      {lockedTokens.length > 0 ? (
        lockedTokens.map((lockedToken, index) => (
          <li
            className='list-group-item mb-2 list-group-item-bordered'
            key={index}
          >
            <LockedTokenCollection lockedToken={lockedToken} />
          </li>
        ))
      ) : (
        <div className='text-center'>No locked tokens</div>
      )}
    </>
  );
};

export default LockedTokenCollectionList;
