import DeployNftContract from '@buidly/ioiom-staking-dapp/Contracts/DeployNftContract/DeployNftContract';
import StakingContractsPage from '@buidly/ioiom-staking-dapp/Contracts/StakingContractsPage';
import RegisterNftPage from '@buidly/ioiom-staking-dapp/RegisterV2/RegisterNft/RegisterNftPage';
import RegisterV2Page from '@buidly/ioiom-staking-dapp/RegisterV2/RegisterV2Page';
import { dAppName } from 'config';
import Admin from 'pages/Admin';
import ExrPage from 'pages/Exr/ExrPage';
import FaucetDeposit from 'pages/Faucet/Deposit/FaucetDeposit';
import Faucet from 'pages/Faucet/Faucet';
import GroupsPage from 'pages/Groups/GroupPage';
import RegisterPage from 'pages/Register';
import NftStakingPage from 'pages/StakeV2/NftStaking/NftStakingPage';
import TokensStakingPage from 'pages/StakeV2/TokensStaking/TokensStakingPage';
import StakingAdminPage from 'pages/StakingAdmin/StakingAdmin';
import SwapV2 from 'pages/SwapV2/SwapV2';
import withPageTitle from './components/PageTitle';
import Home from './pages/Home';
import Liquidity from './pages/Liquidity';
import Merge from './pages/Merge';
import NFTs from './pages/NFTs';
import Trade from './pages/Trade';

export const routeNames = {
  home: '/',
  transaction: '/transaction',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  swap: '/swap',
  trade: '/trade',
  merge: '/merge',
  nfts: '/nfts',
  admin: '/admin',
  stakingAdmin: '/stakingAdmin',
  createFaucet: '/create-rewards-pool',
  rewards: '/rewards',
  tokens: '/tokens',
  registerGroup: '/registerGroup',
  liquidity: '/liquidity',
  tokensStaking: '/staking/tokens',
  nftsStaking: '/staking/nfts',
  register: '/register',
  registerNft: '/register/nft',
  stakingContracts: '/staking/contracts',
  deployNftContract: '/deploy/nft',
  exrBlueChips: '/exr'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.swap,
    title: 'Swap',
    component: SwapV2
  },
  {
    path: routeNames.liquidity,
    title: 'Liquidity',
    component: Liquidity
  },
  {
    path: routeNames.exrBlueChips,
    title: 'Blue Chips',
    component: ExrPage
  },
  {
    path: routeNames.trade,
    title: 'Trade',
    component: Trade
  },
  {
    path: routeNames.merge,
    title: 'Merge',
    component: Merge
  },
  {
    path: routeNames.nfts,
    title: 'NFTs',
    component: NFTs
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: Admin
  },
  {
    path: routeNames.registerGroup,
    title: 'Register',
    component: RegisterPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.register,
    title: 'Register',
    component: RegisterV2Page,
    authenticatedRoute: true
  },
  {
    path: routeNames.tokens,
    title: 'My tokens',
    component: GroupsPage
  },
  {
    path: routeNames.stakingAdmin,
    title: 'Admin',
    component: StakingAdminPage
  },
  {
    path: routeNames.createFaucet,
    title: 'Create Rewards Pool',
    component: FaucetDeposit
  },
  {
    path: routeNames.rewards,
    title: 'Rewards',
    component: Faucet
  },
  {
    path: routeNames.tokensStaking,
    title: 'Tokens Staking',
    component: TokensStakingPage
  },
  {
    path: routeNames.nftsStaking,
    title: 'NFT Staking',
    component: NftStakingPage
  },
  {
    path: routeNames.stakingContracts,
    title: 'Staking Contracts',
    component: StakingContractsPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.deployNftContract,
    title: 'Deploy NFT contract',
    component: DeployNftContract,
    authenticatedRoute: true
  },
  {
    path: routeNames.registerNft,
    title: 'Register NFT Contract',
    component: RegisterNftPage,
    authenticatedRoute: true
  },
  {
    path: routeNames.register,
    title: 'Register',
    component: RegisterV2Page,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
