import React from 'react';
import { EsdtToken } from 'api/types/pairs';
import SwapAnalyticsToken from '../SwapAnalyticsToken/SwapAnalyticsToken';

interface SwapAnalyticsListProps {
  tokens: EsdtToken[];
  setOptionsShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwapAnalyticsList = ({
  tokens,
  setOptionsShown
}: SwapAnalyticsListProps) => {
  return (
    <div className='d-flex flex-row mb-1'>
      {tokens.map((token) => (
        <SwapAnalyticsToken
          setOptionsShown={setOptionsShown}
          key={token.identifier}
          token={token}
        />
      ))}
    </div>
  );
};

export default SwapAnalyticsList;
