import React, { ReactElement } from 'react';
import './SwapPanel.scss';
import { SwapPanelProvider } from 'pages/SwapV2/Context';
import { SwapCardType } from 'pages/SwapV2/types';
import SwapPanel from './SwapPanel';

interface SwapPanelProps {
  title: string;
  className?: string;
  mainColor: string;
  secondaryColor: string;
  zeroBalanceHeader: ReactElement;
  zeroBalanceInfoElement?: ReactElement;
  zeroBalanceClassName?: string;
  zeroBalanceMarkClassName?: string;
  cardType: SwapCardType;
  expanded: boolean;
  otherCardExpanded: boolean;
  setCardExpanded: (cardType: SwapCardType) => void;
}

const SwapPanelWrapper = ({
  title,
  className,
  zeroBalanceHeader,
  zeroBalanceInfoElement,
  mainColor,
  secondaryColor,
  zeroBalanceClassName,
  zeroBalanceMarkClassName,
  cardType,
  expanded,
  otherCardExpanded,
  setCardExpanded
}: SwapPanelProps) => {
  return (
    <SwapPanelProvider
      mainColor={mainColor}
      secondaryColor={secondaryColor}
      zeroBalanceClassName={zeroBalanceClassName}
      zeroBalanceMarkClassName={zeroBalanceMarkClassName}
      zeroBalanceHeader={zeroBalanceHeader}
      cardType={cardType}
      expanded={expanded}
      otherCardExpanded={otherCardExpanded}
      setCardExpanded={setCardExpanded}
      zeroBalanceInfoElement={zeroBalanceInfoElement}
    >
      <SwapPanel title={title} className={className} />
    </SwapPanelProvider>
  );
};

export default SwapPanelWrapper;
