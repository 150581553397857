import { gql } from '@apollo/client';

export const CREATE_REINVEST_TRANSACTION = gql`
  mutation reinvest(
    $sender: String!
    $reinvestArgs: GenericFarmActionArgsModel!
  ) {
    transaction: reinvest(sender: $sender, reinvestArgs: $reinvestArgs) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
