import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_DEPLOY_CHILD_CONTRACT_TRANSACTION } from 'api/mutations';
import { StakingContract } from 'api/types/AdminSettings';
import { useQueryParams } from 'hooks';
import TextField from 'stakegold-core-dapp/components/TextField';
import { isOutsideBounds } from 'stakegold-core-dapp/helpers';

interface DeployChildContractProps {
  stakingContract: StakingContract;
  processBatchTransactions: (transactions: Transaction[][]) => void;
  rewardTokenId: string;
}

const DeployChildContract = ({
  stakingContract,
  processBatchTransactions,
  rewardTokenId
}: DeployChildContractProps) => {
  const { groupId } = useQueryParams();

  const { address } = useGetAccount();

  const [maxApr, setMaxApr] = useState<string>();
  const [minUnbondEpochs, setMinUnbondEpochs] = useState<string>();
  const [error, setError] = useState<string | undefined>(undefined);

  const [deployChildContractTransaction, { data: deployChildContractData }] =
    useMutation(CREATE_DEPLOY_CHILD_CONTRACT_TRANSACTION);

  const handleDeployChildContract = () => {
    const aprOutsideBounds = isOutsideBounds('Max APR', 10, 1000, maxApr);
    if (aprOutsideBounds) {
      setError(aprOutsideBounds);
      return;
    }

    const epochsOutsideBonds = isOutsideBounds(
      'Unbond period',
      0,
      365,
      minUnbondEpochs
    );
    if (epochsOutsideBonds) {
      setError(epochsOutsideBonds);
      return;
    }

    setError(undefined);

    deployChildContractTransaction({
      variables: {
        sender: address,
        groupId: groupId,
        deployChildContractArgs: {
          isFarmingTokenLocked: stakingContract.farmingTokenLocked,
          areRewardsLocked: stakingContract.rewardTokenLocked,
          maxApr: Number(maxApr),
          minUnbondEpochs: Number(minUnbondEpochs),
          rewardTokenId
        }
      }
    });
  };

  useEffect(() => {
    if (
      deployChildContractData &&
      deployChildContractData.deployChildContract
    ) {
      processBatchTransactions([
        [deployChildContractData.deployChildContract as Transaction]
      ]);
    }
  }, [deployChildContractData]);

  return (
    <div className='d-flex container mb-3 justify-content-center'>
      <div className='d-flex flex-column align-items-center'>
        <h5>Staking contract</h5>
        <h6>Step 1/2: Deploy child contract</h6>
        <div className='d-flex flex-column align-items-center mt-5'>
          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input swap-form-group-light primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            type='number'
            placeholder='Max APR'
            callback={(newValue) => setMaxApr(newValue)}
          />

          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input swap-form-group-light primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            type='number'
            placeholder='Unbond period in days'
            callback={(newValue) => setMinUnbondEpochs(newValue)}
          />

          <button
            className='exrond-btn-light mt-3'
            onClick={() => handleDeployChildContract()}
          >
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Deploy
            </span>
          </button>
        </div>
        {error && <div className='text-danger mt-3'>{error}</div>}
      </div>
    </div>
  );
};

export default DeployChildContract;
