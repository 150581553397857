import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { TOKEN_USD_VALUE_QUERY } from 'api/queries/tokenUsdValue';
import { EsdtToken } from 'api/types/pairs';
import { EGLD, WEGLD } from 'config';

const POLLING_INTERVAL = 12_000;

const useGetTokenUsdValue = (token?: EsdtToken) => {
  const { pending } = useGetActiveTransactionsStatus();

  const { data, loading, error, startPolling, stopPolling } = useQuery(
    TOKEN_USD_VALUE_QUERY,
    {
      variables: {
        token:
          token?.identifier === EGLD.identifier
            ? WEGLD.identifier
            : token?.identifier,
        amount: String(Math.pow(10, token?.decimals ?? 0))
      },
      skip: token === undefined,
      pollInterval: POLLING_INTERVAL
    }
  );

  useEffect(() => {
    if (pending) {
      stopPolling();
    } else {
      startPolling(POLLING_INTERVAL);
    }

    return () => {
      stopPolling();
    };
  }, [pending]);

  return useMemo(() => {
    const usdValue = data?.tokenUsdValue
      ? new BigNumber(data.tokenUsdValue)
      : undefined;
    return { usdValue, loading };
  }, [data, loading, error]);
};

export { useGetTokenUsdValue };
