import React, { useContext, useMemo, useState } from 'react';
import './SwapAnalyticsFilters.scss';
import { Spinner } from 'react-bootstrap';
import { SwapPanelContext } from 'pages/SwapV2/Context';
import { useGetPairAnalytics } from 'pages/SwapV2/hooks/useGetPairAnalytics';
import SwapAnalyticsList from '../SwapAnalyticsList/SwapAnalyticsList';
import SwapAnalyticsHeader from './SwapAnalyticsHeader';

interface SwapAnalyticsFilterProps {
  setOptionsShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwapAnalyticsFilters = ({
  setOptionsShown
}: SwapAnalyticsFilterProps) => {
  const { expanded, search } = useContext(SwapPanelContext);

  const { analytics, loading } = useGetPairAnalytics();

  const [filter, setFilter] = useState<'Popular' | 'New'>(
    analytics.popular.length > 0 ? 'Popular' : 'New'
  );

  const selectedTokens = useMemo(() => {
    return filter === 'Popular' ? analytics.popular : analytics.new;
  }, [filter, analytics]);

  if (expanded && search.length > 0) {
    return null;
  }

  if (loading) {
    return (
      <div className='mx-2 d-flex flex-column mt-2'>
        <div className='d-flex flex-row swap-analytics-filter justify-content-center'>
          <Spinner
            as='span'
            animation='border'
            size='sm'
            style={{ color: '#fff' }}
          />
        </div>
      </div>
    );
  }

  if (analytics.popular.length === 0 && analytics.new.length === 0) {
    return null;
  }

  return (
    <div className='mx-2 d-flex flex-column'>
      <div className='d-flex flex-row swap-analytics-filter'>
        <SwapAnalyticsHeader
          selectedFilter={filter}
          filter='Popular'
          onFilterSelected={setFilter}
          tokens={analytics.popular}
        />
        <SwapAnalyticsHeader
          selectedFilter={filter}
          filter='New'
          onFilterSelected={setFilter}
          tokens={analytics.new}
        />
      </div>
      <SwapAnalyticsList
        setOptionsShown={setOptionsShown}
        tokens={selectedTokens}
      />
    </div>
  );
};

export default SwapAnalyticsFilters;
