import { gql } from '@apollo/client';

export const CREATE_SETUP_FARM_TRANSACTION = gql`
  mutation setupFarmRewards(
    $sender: String!
    $groupId: String!
    $stakingScAddress: String!
    $setupFarmArgs: SetupFarmArgs!
  ) {
    setupFarmRewards(
      sender: $sender
      groupId: $groupId
      stakingScAddress: $stakingScAddress
      setupFarmArgs: $setupFarmArgs
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
