import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { routeNames } from 'routes';

export interface DesktopMainNavbarProps {
  onStakeClicked: () => void;
}

const DesktopMainNavbar = ({ onStakeClicked }: DesktopMainNavbarProps) => {
  return (
    <div className='navbar-container'>
      <Nav className='exrond_navbar'>
        <NavItem className='item'>
          <NavLink to={routeNames.swap}>
            Swap
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                width: '8px',
                height: '8px',
                color: '#23F7DD',
                marginBottom: '7px'
              }}
            />
          </NavLink>
        </NavItem>
        <NavItem className='item'>
          <NavLink to={routeNames.exrBlueChips}>
            $EXR
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                width: '8px',
                height: '8px',
                color: '#23F7DD',
                marginBottom: '7px'
              }}
            />
          </NavLink>
        </NavItem>
        <NavItem className='item'>
          <a onClick={onStakeClicked}>Stake</a>
        </NavItem>
        <NavItem className='item'>
          <NavLink to={routeNames.rewards}>Rewards</NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default DesktopMainNavbar;
