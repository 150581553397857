import React, { useMemo } from 'react';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { ReactComponent as AddIcon } from 'assets/img/ic_add.svg';
import { DECIMALS } from 'config';
import { PairLiquidity } from 'pages/SwapV2/types';
import { SwapIconWithTokenOverlay } from './Components';

const SwapLiquidityStepItem = ({ step }: { step: PairLiquidity }) => {
  const usdAmount = useMemo(() => {
    return new BigNumber(step.usdAmount).toFormat(2);
  }, [step]);

  return (
    <tr>
      <td>
        <SwapIconWithTokenOverlay
          token={step.firstTokenMetadata}
          className='mr-2'
        />
        <FormatAmount
          value={step.firstTokenAmount}
          decimals={step.firstTokenMetadata.decimals}
          digits={DECIMALS}
          token=''
          showLastNonZeroDecimal={false}
        />
      </td>
      <td>
        <AddIcon
          width={7}
          height={15}
          stroke='#13EA75'
          className='swap-settings__liquidity--spacing'
        />
      </td>
      <td>
        <SwapIconWithTokenOverlay
          token={step.secondTokenMetadata}
          className='mr-2'
        />
        <FormatAmount
          value={step.secondTokenAmount}
          decimals={step.secondTokenMetadata.decimals}
          digits={DECIMALS}
          token=''
          showLastNonZeroDecimal={false}
        />
      </td>
      <td>
        <span className='swap-settings__liquidity--spacing text-green'>=</span>
      </td>
      <td>
        <span>${usdAmount}</span>
      </td>
    </tr>
  );
};

export default SwapLiquidityStepItem;
