import { useLocalStorage } from '@buidly/dapp-core/dist/hooks';
import {
  AbiRegistry,
  Address,
  SmartContract,
  SmartContractAbi
} from '@multiversx/sdk-core/out';
import { getChainID } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import json from 'abi/collection-minter.abi.json';
import { sendAndSignTransactions } from 'apiRequests/transactions';
import { BLUE_CHIPS_MINTER_ADDRESS } from 'config';

const abiRegistry = AbiRegistry.create(json);
const abi = new SmartContractAbi(abiRegistry);

export const vampiresSmartContract = new SmartContract({
  address: new Address(BLUE_CHIPS_MINTER_ADDRESS),
  abi
});

const useMintBlueChips = () => {
  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'mintBlueChipsSessionId',
    null
  );

  const baseGasLimit = 5_000_000;
  const gasLimit = 7_000_000;

  const mintBlueChips = async (numNfts: number) => {
    try {
      const interaction = vampiresSmartContract.methods
        .mint([numNfts])
        .withValue(new BigNumber(numNfts).multipliedBy(1).shiftedBy(18))
        .withGasLimit(baseGasLimit + gasLimit * Number(numNfts))
        .withChainID(getChainID());

      const { sessionId: returnedSessionId } = await sendAndSignTransactions(
        [interaction.buildTransaction()],
        'Mint BlueChips'
      );

      setSessionId(returnedSessionId);
    } catch (err) {
      console.error('Unable to call mint transaction', err);
    }
  };

  return { mintBlueChips, sessionId };
};

export { useMintBlueChips };
