import { EsdtToken } from './pairs';

export enum PairState {
  NONE = 'NONE',
  PAIR_CREATED = 'PAIR_CREATED',
  LP_TOKEN_ISSUED = 'LP_TOKEN_ISSUED',
  LOCAL_ROLES_SET = 'LOCAL_ROLES_SET',
  INITIAL_LIQUIDITY_ADDED = 'INITIAL_LIQUIDITY_ADDED',
  SETUP_COMPLETE = 'SETUP_COMPLETE'
}

export class OngoingPairSetup {
  pairAddress?: string;
  firstToken?: EsdtToken;
  secondToken?: EsdtToken;
  status = PairState.NONE;

  constructor(init?: Partial<OngoingPairSetup>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): OngoingPairSetup {
    return new OngoingPairSetup({
      pairAddress: response.pairAddress,
      firstToken: response.firstToken
        ? EsdtToken.fromResponse(response.firstToken)
        : undefined,
      secondToken: response.secondToken
        ? EsdtToken.fromResponse(response.secondToken)
        : undefined,
      status: response.status
    });
  }
}

export { EsdtToken };
