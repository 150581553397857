import { gql } from '@apollo/client';

export const QUERY_STAKING_GROUP_FARMS = gql`
  query stakingGroupFarms(
    $address: String!
    $groupId: String!
    $rewardToken: String!
  ) {
    stakingGroupFarms(
      address: $address
      groupId: $groupId
      rewardToken: $rewardToken
    ) {
      farmingToken {
        __typename
        ... on NftCollectionModel {
          collection
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
        ... on EsdtTokenModel {
          identifier
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
      }
      rewardToken {
        __typename
        ... on NftCollectionModel {
          collection
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
        ... on EsdtTokenModel {
          identifier
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
      }
      areRewardsLocked
      totalStaked
      endsDate
      apr
      staked
      earned
      unbound
      farmState
      farmAddress
      isCompoundAllowed
      positions {
        unlockDate
        farmToken {
          identifier
          collection
          name
          decimals
          ticker
          assets {
            svgUrl
            pngUrl
          }
          balance
          nonce
          decodedAttributes {
            identifier
            attributes
            rewardPerShare
            compoundedReward
            currentFarmAmount
            remainingEpochs
            unlockDate
          }
        }
        rewardToken {
          __typename
          ... on NftCollectionModel {
            collection
            decimals
            name
            balance
            ticker
            assets {
              svgUrl
              pngUrl
            }
          }
          ... on EsdtTokenModel {
            identifier
            decimals
            name
            balance
            ticker
            assets {
              svgUrl
              pngUrl
            }
          }
        }
      }
    }
  }
`;
