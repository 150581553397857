import React from 'react';
import { countDecimalPlaces } from '@buidly/ioiom-staking-dapp/utils/utils';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { EsdtToken } from 'api/types/pairs';
import { DECIMALS } from 'config';
import './SwapTokenOption.scss';

interface SwapTokenOptionBalanceProps {
  token: EsdtToken;
  className?: string;
}

const SwapTokenOptionBalance = ({
  token,
  className
}: SwapTokenOptionBalanceProps) => {
  return (
    <>
      <span className='swap-option__token-name'>Balance</span>
      <span
        className={`swap-option__token-balance swap-option__text--${
          className || ''
        }`}
      >
        <FormatAmount
          value={token.balance ?? '0'}
          decimals={token.decimals}
          egldLabel={' '}
          digits={countDecimalPlaces(
            token.balance ?? '0',
            token.decimals,
            DECIMALS
          )}
          showLastNonZeroDecimal={false}
        />
      </span>
    </>
  );
};

export default SwapTokenOptionBalance;
