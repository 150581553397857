import * as React from 'react';
import colors from 'assets/sass/variables.module.scss';

const MaintenancePage = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 mx-auto'>
          <div
            className='w-100 exrond-swap-card text-center text-white exrond-text'
            style={{ backgroundColor: colors.colorPrimaryDark }}
          >
            <h2 className='w-100 font-weight-bold'>Under maintenance</h2>
            <br></br>
            <h4 className='w-100'>Funds are safe</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
