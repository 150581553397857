import React, { useMemo } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import Select from 'react-select';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import { TOKEN_PLACEHOLDER } from 'config';
import TextField from 'stakegold-core-dapp/components/TextField';
import { selectColourStyles } from 'utils';
import { claimPolicies, ClaimPolicy, ClaimPolicyDetails } from '../types';

export interface FaucetDetailsProps {
  amount?: string;
  token?: EsdtToken;
  availableAmount: BigNumber;
  claimPolicy?: ClaimPolicyDetails;
  claimableAmount?: string;
  maxClaims?: string;
  error?: string;
  onSubmit: (event: any) => void;
  onAmountChanged: (value: string) => void;
  onMaxClicked: () => void;
  onClaimPolicyChanged: (policy: ClaimPolicyDetails) => void;
  onClaimableAmountChanged: (value: string) => void;
  onMaxClaimsChanged: (value: string) => void;
  onBackClicked: () => void;
}

const FaucetDetails = ({
  amount,
  token,
  availableAmount,
  claimPolicy,
  claimableAmount,
  maxClaims,
  error,
  onSubmit,
  onAmountChanged,
  onMaxClicked,
  onClaimPolicyChanged,
  onClaimableAmountChanged,
  onMaxClaimsChanged,
  onBackClicked
}: FaucetDetailsProps) => {
  const formatClaimPolicyLabel = (policy: ClaimPolicyDetails) => {
    return <span>{policy.label}</span>;
  };

  const formatTokenLabel = (esdt: EsdtToken) => {
    return (
      <div className='d-flex flex-row align-items-center'>
        <img
          src={esdt.icon ?? TOKEN_PLACEHOLDER}
          className='token-icon token-select p-0 mr-2'
          style={{ width: '32px', height: '32px' }}
        />
        <span className='home-text13'>{esdt.ticker}</span>
      </div>
    );
  };

  const maxClaimsLabel = useMemo(() => {
    let claimPolicyLabel: string | undefined;

    switch (claimPolicy?.value) {
      case ClaimPolicy.DAILY:
        claimPolicyLabel = 'day';
        break;
      case ClaimPolicy.WEEKLY:
        claimPolicyLabel = 'week';
        break;
      case ClaimPolicy.MONTHLY:
        claimPolicyLabel = 'month';
        break;
      default:
        claimPolicyLabel = undefined;
        break;
    }

    if (claimPolicyLabel === undefined) {
      return '';
    }

    return `Max claims per ${claimPolicyLabel}`;
  }, [claimPolicy]);

  return (
    <form onSubmit={onSubmit}>
      <>
        <div className='d-flex align-items-center mb-5'>
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ height: '16px', color: '#b4bcc3', padding: '8px' }}
            onClick={onBackClicked}
          />
          <span className='swap-title m-0'>Create Rewards Pool</span>
        </div>

        <div
          className={
            'd-flex align-items-center exrond-liquidity-input-selector primary'
          }
        >
          <input
            className={
              'form-control input-amount input-stake exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent pl-2'
            }
            type='number'
            placeholder='Amount'
            value={amount}
            onChange={(event) => {
              if (!token) {
                return;
              }

              onAmountChanged(event.target.value);
            }}
          />
          <span
            className='exrond-text font-weight-600 font-size-1 text-white align-items-center mr-2 btn'
            onClick={onMaxClicked}
          >
            MAX
          </span>
          <Select
            className={
              'exrond-liquidity-input-selector secondary exrond-select inline-block'
            }
            classNamePrefix={'exrond'}
            styles={selectColourStyles(
              colors.colorPrimaryDark,
              colors.colorPrimary,
              colors.colorPrimary
            )}
            getOptionValue={(option) => option.identifier}
            formatOptionLabel={(option) => formatTokenLabel(option)}
            value={token}
            isDisabled={true}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
          />
        </div>
        <div className='w-100 d-flex justify-content-end'>
          <span className='exrond-text font-size-09 text-white mr-2'>
            Balance:
          </span>
          <span className='exrond-text font-size-09 text-white'>
            <FormatAmount
              className='exrond-text text-white mr-2'
              value={availableAmount.toFixed()}
              decimals={token?.decimals}
              egldLabel={token?.ticker ?? ' '}
              digits={4}
              showLastNonZeroDecimal={false}
            />
          </span>
        </div>

        <Select
          className={'exrond-input primary exrond-select mt-2'}
          classNamePrefix={'exrond'}
          placeholder={'Select claim policy'}
          options={claimPolicies}
          styles={selectColourStyles()}
          formatOptionLabel={(option) => formatClaimPolicyLabel(option)}
          value={claimPolicy}
          getOptionValue={(option) => option.value}
          onChange={(policy) => {
            policy && onClaimPolicyChanged(policy);
          }}
        />

        <div className='w-100 mt-2'>
          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            value={claimableAmount}
            type='number'
            placeholder={'Claimable amount'}
            callback={(newValue) => onClaimableAmountChanged(newValue)}
          />
        </div>

        {claimPolicy && claimPolicy.value !== ClaimPolicy.LIFETIME && (
          <div className='w-100 mt-2'>
            <TextField
              labelClassName='exrond-text text-white font-size-1 font-weight-600'
              divWrapperClassName='exrond-input primary'
              inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
              value={maxClaims}
              type='number'
              placeholder={maxClaimsLabel}
              callback={(newValue) => onMaxClaimsChanged(newValue)}
            />
          </div>
        )}

        <button className='exrond-btn mt-3'>
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Create
          </span>
        </button>
        {error && <div className='text-danger mt-4'>{error}</div>}
      </>
    </form>
  );
};

export default FaucetDetails;
