import { EsdtToken, EsdtTokenSimplified } from 'api/tokens/esdtToken.model';
import { VestingSettings } from './VestingSettings';

export interface AdminSettings {
  groupId?: string;
  groupToken?: EsdtToken;
  vestingContract?: VestingContract;
  stakingContracts: StakingContract[];
  shouldAllowVestingRegister: boolean;
  customRewardTokenIds: EsdtTokenSimplified[];
}

export interface VestingContract {
  address: string;
  state: VestingState;
  settings?: VestingSettings;
}

export interface StakingContract {
  address: string;
  state: FarmState;
  vestingScAddress?: string;
  farmingTokenLocked: boolean;
  rewardTokenId: string | null;
  rewardTokenDecimals: number | null;
  rewardTokenLocked: boolean;
  rewardsLeft?: string;
  apr?: number;
  maxApr?: number;
  totalValueStaked?: string;
}

export enum VestingState {
  NONE = 'NONE',
  DEPLOYED = 'DEPLOYED',
  LOCKED_TOKEN_REGISTERED = 'LOCKED_TOKEN_REGISTERED',
  LOCAL_ROLES_SET = 'LOCAL_ROLES_SET',
  TRANSFER_ROLE_FOR_ROUTER_SET = 'TRANSFER_ROLE_FOR_ROUTER_SET',
  SETUP_COMPLETE = 'SETUP_COMPLETE'
}

export enum FarmState {
  NONE = 'NONE',
  DEPLOYED = 'DEPLOYED',
  FARM_TOKEN_REGISTERED = 'FARM_TOKEN_REGISTERED',
  LOCAL_ROLES_SET = 'LOCAL_ROLES_SET',
  TRANSFER_ROLE_FOR_ROUTER_SET = 'TRANSFER_ROLE_FOR_ROUTER_SET',
  LOCKED_ASSET_TOKEN_ID_SET = 'LOCKED_ASSET_TOKEN_ID_SET',
  STAKING_SC_ADDRESS_SET = 'STAKING_SC_ADDRESS_SET',
  SETUP_COMPLETE = 'SETUP_COMPLETE'
}
