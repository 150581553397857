import React from 'react';
import colors from 'assets/sass/variables.module.scss';
import CreateFaucet from './components/CreateFaucet';

const FaucetDeposit = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='d-flex w-100'>
        <div className='col-12 col-md-9 col-lg-7 col-xl-6 mx-auto mt-2'>
          <div className='home-container03'></div>
          <div
            className='w-100 exrond-swap-card'
            style={{ backgroundColor: colors.colorPrimaryDark }}
          >
            <CreateFaucet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaucetDeposit;
