import { gql } from '@apollo/client';

export const ACCOUNT_DETAILS = gql`
  query account_details($address: String!) {
    accountDetails(address: $address) {
      address
      egldBalance
      esdtTokens {
        name
        identifier
        balance
        decimals
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
      lockedTokens {
        collection
        tokens {
          name
          collection
          identifier
          balance
          decimals
          ticker
          nonce
          assets {
            svgUrl
            pngUrl
          }
          unlockSchedule {
            epoch
            percent
            unlockDate
          }
        }
      }
      farmTokens {
        name
        identifier
        balance
        decimals
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
    }
  }
`;
