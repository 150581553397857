import { gql } from '@apollo/client';

export const CREATE_PAIR = gql`
  mutation setupFarmRewards($sender: String!, $secondTokenID: String!) {
    transactions: createPair(sender: $sender, secondTokenID: $secondTokenID) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;

export const FINISH_PAIR_SETUP = gql`
  mutation pairSetupTransactions(
    $sender: String!
    $pairAddress: String!
    $tokens: [InputTokenModel!]
  ) {
    transactions: pairSetupTransactions(
      sender: $sender
      addLiquidityArgs: { pairAddress: $pairAddress, tokens: $tokens }
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
