import * as React from 'react';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import colors from 'assets/sass/variables.module.scss';
import { routeNames } from 'routes';
const PageNotFound = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 mx-auto'>
          <div
            className='w-100 exrond-swap-card'
            style={{ backgroundColor: colors.colorPrimaryDark }}
          >
            <h3 className='exrond-text text-white mx-4'>
              Sorry, this page isn&apos;t available. The link you followed may
              be broken, or the page may have been removed.
            </h3>
            <div className='align-self-end'>
              <Link
                className=' exrond-text font-size-1 font-weight-800 text-white'
                to={routeNames.home}
              >
                Go back to homepage
                <FontAwesomeIcon
                  className='ml-2'
                  icon={faArrowLeftLong}
                  color='white'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
