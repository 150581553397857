import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_CLAIM_FAUCETS_TRANSACTION } from 'api/mutations/ClaimFaucets';
import { FAUCET_CLIENT_NAME } from 'apollo-client';

const useGetClaimFaucetTransaction = () => {
  const { address } = useGetAccount();

  const [claimFaucets, { status, error }] = useGetAndSendTransactions(
    CREATE_CLAIM_FAUCETS_TRANSACTION,
    {
      context: {
        clientName: FAUCET_CLIENT_NAME
      }
    }
  );

  const performClaimTransaction = (
    faucetIds: string[],
    payWithEgld: boolean | undefined
  ) => {
    claimFaucets({
      variables: {
        payWithEgld,
        sender: address,
        args: {
          faucetIds
        }
      }
    });
  };

  return {
    performClaimTransaction,
    error,
    pending: status === 'pending',
    success: status === 'success'
  };
};

export { useGetClaimFaucetTransaction };
