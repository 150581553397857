import { Transaction } from '@multiversx/sdk-core/out';
import { EsdtToken, Pair } from 'api/types/pairs';

export class SwapData {
  tokenInID = '';
  amountIn = '';
  tokenOutID = '';
  amountOut = '';
  tolerance = '';
  transactions: Transaction[][] = [];
  tokensPriceDeviationPercent: number | null = null;
  tokenRoute: string[] = [];
  tokenInExchangeRate = '';
  tokenOutExchangeRate = '';
  tokenInMetadata!: EsdtToken;
  tokenOutMetadata!: EsdtToken;
  fees: string[] = [];
  pricesImpact: string[] = [];
  swapDetails!: SwapDetails;
  pairs: Pair[] = [];

  constructor(init?: Partial<SwapData>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): SwapData {
    return new SwapData({
      tokenInID: response.tokenInID,
      amountIn: response.amountIn,
      tokenOutID: response.tokenOutID,
      amountOut: response.amountOut,
      tolerance: response.tolerance,
      transactions: response.transactions as Transaction[][],
      tokensPriceDeviationPercent: response.tokensPriceDeviationPercent,
      tokenRoute: response.tokenRoute,
      tokenInExchangeRate: response.tokenInExchangeRate,
      tokenOutExchangeRate: response.tokenOutExchangeRate,
      tokenInMetadata: EsdtToken.fromResponse(response.tokenInMetadata),
      tokenOutMetadata: EsdtToken.fromResponse(response.tokenOutMetadata),
      fees: response.liquidityProviderFees as string[],
      pricesImpact: response.pricesImpact,
      swapDetails: SwapDetails.fromResponse(response.swapDetails),
      pairs: response.pairs.map((pair: any) => Pair.fromResponse(pair))
    });
  }
}

export class SwapDetails {
  initialLiquidity!: PairLiquidity[];
  totalLiquidity!: PairLiquidity[];

  constructor(init?: Partial<SwapDetails>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): SwapDetails {
    return new SwapDetails({
      initialLiquidity: response.initialLiquidity?.map((liquidity: any) =>
        PairLiquidity.fromResponse(liquidity)
      ),
      totalLiquidity: response.totalLiquidity?.map((liquidity: any) =>
        PairLiquidity.fromResponse(liquidity)
      )
    });
  }
}

export class PairLiquidity {
  firstTokenAmount = '';
  firstTokenMetadata!: EsdtToken;
  secondTokenAmount = '';
  secondTokenMetadata!: EsdtToken;
  usdAmount = '';

  constructor(init?: Partial<PairLiquidity>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): PairLiquidity {
    return new PairLiquidity({
      firstTokenAmount: response.firstTokenAmount,
      secondTokenAmount: response.secondTokenAmount,
      firstTokenMetadata: EsdtToken.fromResponse(response.firstTokenMetadata),
      secondTokenMetadata: EsdtToken.fromResponse(response.secondTokenMetadata),
      usdAmount: response.usdAmount
    });
  }
}
