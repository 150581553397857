import React, { useContext, useMemo, useRef, useState } from 'react';
import { countDecimalPlaces } from '@buidly/ioiom-staking-dapp/utils/utils';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import CurrencyInput from 'react-currency-input-field';
import { DECIMALS, EGLD } from 'config';
import { useAccountDetails } from 'hooks';
import { SwapContext, SwapPanelContext } from 'pages/SwapV2/Context';
import './SwapAmount.scss';
import { getSwapTokenBalance } from 'pages/SwapV2/helpers';
import { useGetCardToken } from 'pages/SwapV2/hooks';
import useGetSwapAmounts from 'pages/SwapV2/hooks/useGetSwapAmounts';

const EGLD_FEES = 0.05;

interface SwapAmountProps {
  className: string;
}

const SwapAmount = ({ className }: SwapAmountProps) => {
  const { secondaryColor, cardType } = useContext(SwapPanelContext);

  const { swapLoading } = useContext(SwapContext);

  const { selectedToken, amount } = useGetCardToken(cardType);
  const { updateAmount } = useGetSwapAmounts(cardType);
  const { accountDetails } = useAccountDetails();

  const isValueSetManually = useRef<boolean>(false);

  const value = useMemo(() => {
    if (amount === undefined || amount.length === 0) {
      return '';
    }

    if (isValueSetManually.current === false) {
      const digits = countDecimalPlaces(amount, 0, DECIMALS);
      return parseFloat(amount).toFixed(digits);
    }

    isValueSetManually.current = false;
    return amount;
  }, [amount, selectedToken]);

  const balance = useMemo(() => {
    return getSwapTokenBalance(selectedToken, accountDetails).toFixed();
  }, [accountDetails, selectedToken]);

  const handleOnChange = (newVal: string | undefined) => {
    if (newVal === value) {
      return;
    }

    if (newVal === undefined || newVal.length === 0) {
      updateAmount('');
      return;
    }

    isValueSetManually.current = true;
    updateAmount(newVal);
  };

  const maxButtonBalance = useMemo(() => {
    if (selectedToken === undefined) {
      return undefined;
    }

    let balanceToSet = new BigNumber(balance);

    if (selectedToken?.identifier === EGLD.identifier) {
      const feesWithDecimals = new BigNumber(EGLD_FEES).shiftedBy(
        EGLD.decimals
      );
      balanceToSet = balanceToSet.minus(feesWithDecimals);
    }

    if (balanceToSet.isNegative()) {
      return undefined;
    }

    return balanceToSet;
  }, [selectedToken, balance]);

  const handleMaxClick = () => {
    if (maxButtonBalance === undefined) {
      return;
    }

    updateAmount(
      maxButtonBalance.shiftedBy(-(selectedToken?.decimals ?? 0)).toFixed()
    );
  };

  return (
    <div
      className={`d-flex swap-amount align-items-center swap-amount--${secondaryColor} ${className}`}
    >
      <CurrencyInput
        placeholder='Amount'
        defaultValue={''}
        value={value}
        allowNegativeValue={false}
        decimalsLimit={selectedToken?.decimals ?? 2}
        onValueChange={(_value) => handleOnChange(_value)}
        disabled={swapLoading}
        className={`swap-amount__input swap-amount__input--${
          swapLoading ? 'disabled' : ''
        } flex-grow-1 pr-2`}
      />
      <div className='swap-amount__balance-container mr-2'>
        <span className='swap-amount__balance'>Balance</span>
        <span className='swap-amount__amount'>
          <FormatAmount
            value={balance ?? '0'}
            decimals={selectedToken?.decimals ?? 0}
            egldLabel={' '}
            digits={countDecimalPlaces(
              balance ?? '0',
              selectedToken?.decimals ?? 0,
              DECIMALS
            )}
            showLastNonZeroDecimal={false}
          />
        </span>
      </div>
      <span
        className={`swap-amount__max py-2 ${
          maxButtonBalance === undefined ? 'swap-amount__max--disabled' : ''
        }`}
        onClick={handleMaxClick}
      >
        MAX
      </span>
    </div>
  );
};

export default SwapAmount;
