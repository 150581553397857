import React, { useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import useBatchTransactions from '@buidly/ioiom-staking-dapp/hooks/useBatchTransactions';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transaction } from '@multiversx/sdk-core/out';
import { useSearchParams } from 'react-router-dom';
import { AdminSettings, FarmState } from 'api/types/AdminSettings';
import Loader from 'components/Loader';
import { useAdminSettings } from 'hooks/useAdminSettings';
import StakingContractItem from './StakingContracts/StakingContractItem';
import VestingContractItem from './VestingContracts/VestingContract';

const KEY_GROUP_ID = 'groupId';
const KEY_FARMING_TOKEN_LOCKED = 'farmingTokenLocked';
const KEY_REWARD_TOKEN_LOCKED = 'rewardTokenLocked';
const KEY_VESTING_ADDRESS = 'vestingAddress';
const KEY_REWARD_TOKEN_ID = 'rewardTokenId';

const AdminPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = new URLSearchParams(searchParams.toString());
  const { groupId } = Object.fromEntries(query);

  const { adminSettings, loading, networkStatus, refetch } =
    useAdminSettings(groupId);

  const [adminData, setAdminData] = useState<AdminSettings | undefined>();
  const [refetchingData, setRefetchingData] = useState(false);

  useEffect(() => {
    switch (networkStatus) {
      case NetworkStatus.refetch:
        if (!refetchingData) {
          setRefetchingData(true);
        }
        break;
      case NetworkStatus.setVariables:
        break;
      default:
        setRefetchingData(false);
        break;
    }
  }, [networkStatus]);

  useEffect(() => {
    // When we call refetch, adminSettings becomes undefined,
    // but we want to keep the lists while it's getting new data
    if (adminSettings) {
      setAdminData(adminSettings);
    }
  }, [adminSettings]);

  const [sendBatchTransactions, { status }] = useBatchTransactions();

  const availableTokensUnlocked = useMemo(() => {
    return adminSettings?.customRewardTokenIds.filter(
      (token) =>
        !adminSettings.stakingContracts.find(
          (contract) =>
            contract.rewardTokenId === token.identifier &&
            !contract.farmingTokenLocked
        )
    );
  }, [adminSettings]);

  const availableTokensLocked = useMemo(() => {
    return adminSettings?.customRewardTokenIds.filter(
      (token) =>
        !adminSettings.stakingContracts.find(
          (contract) =>
            contract.rewardTokenId === token.identifier &&
            contract.farmingTokenLocked
        )
    );
  }, [adminSettings]);

  /**
   * If the query does not contain the groupId, try to get it from localstore
   * This happens when the user signs a transaction in webwallet and is redirected
   * back to the app
   */
  useEffect(() => {
    if (groupId || status !== 'pending') {
      return;
    }

    const newQueryParameters = new URLSearchParams();

    const localGroupId = localStorage.getItem(KEY_GROUP_ID) ?? '';
    if (localGroupId.length === 0) {
      return;
    }
    newQueryParameters.append(KEY_GROUP_ID, localGroupId);

    const farmingTokenLocked =
      localStorage.getItem(KEY_FARMING_TOKEN_LOCKED) ?? '';
    const rewardTokenLocked =
      localStorage.getItem(KEY_REWARD_TOKEN_LOCKED) ?? '';
    const rewardTokenId = localStorage.getItem(KEY_REWARD_TOKEN_ID) ?? '';
    if (
      farmingTokenLocked.length > 0 &&
      rewardTokenLocked.length > 0 &&
      rewardTokenId.length > 0
    ) {
      newQueryParameters.append(KEY_FARMING_TOKEN_LOCKED, farmingTokenLocked);
      newQueryParameters.append(KEY_REWARD_TOKEN_LOCKED, rewardTokenLocked);
      newQueryParameters.append(KEY_REWARD_TOKEN_ID, rewardTokenId);
    }

    const vestingAddress = localStorage.getItem(KEY_VESTING_ADDRESS) ?? '';
    if (vestingAddress.length > 0) {
      newQueryParameters.append(KEY_VESTING_ADDRESS, vestingAddress);
    }

    setSearchParams(newQueryParameters);

    localStorage.removeItem(KEY_GROUP_ID);
    localStorage.removeItem(KEY_VESTING_ADDRESS);
    localStorage.removeItem(KEY_FARMING_TOKEN_LOCKED);
    localStorage.removeItem(KEY_REWARD_TOKEN_LOCKED);
    localStorage.removeItem(KEY_REWARD_TOKEN_ID);
  }, [groupId, status]);

  useEffect(() => {
    if (status === 'success') {
      // There is a bug in Apollo Client. The first time refetch is called
      // the network status is 2 instead of 4, so we need to set the flag manually
      setRefetchingData(true);
      refetch({ noCache: true });
    }
  }, [status]);

  if (loading && !refetchingData) {
    return (
      <div className='screen-centered'>
        <Loader />
      </div>
    );
  }

  const processBatchTransactions = async (
    transactions: Transaction[][],
    vestingAddress?: string,
    farmingTokenLocked?: boolean,
    rewardTokenLocked?: boolean,
    rewardTokenId?: string
  ) => {
    try {
      localStorage.setItem(KEY_GROUP_ID, groupId);
      if (
        farmingTokenLocked !== undefined &&
        rewardTokenLocked !== undefined &&
        rewardTokenId !== undefined
      ) {
        localStorage.setItem(
          KEY_FARMING_TOKEN_LOCKED,
          farmingTokenLocked.toString()
        );
        localStorage.setItem(
          KEY_REWARD_TOKEN_LOCKED,
          rewardTokenLocked.toString()
        );
        localStorage.setItem(KEY_REWARD_TOKEN_ID, rewardTokenId);
      }

      if (vestingAddress !== undefined) {
        localStorage.setItem(KEY_VESTING_ADDRESS, vestingAddress);
      }

      sendBatchTransactions(
        transactions,
        `${window.location.pathname}?groupId=${groupId}&farmingTokenLocked=${farmingTokenLocked}&rewardTokenLocked=${rewardTokenLocked}&rewardTokenId=${rewardTokenId}&vestingAddress=${vestingAddress}`
      );
    } catch (error) {
      console.log('processTransaction error', error);
    }
  };

  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer m-0'>
          <div className='card-body p-1'>
            <div className='text-center my-5'>
              <h2>{groupId}</h2>
              <h3 className='h6 font-weight-light mb-2 pt-1'>
                Manage and setup your smart contracts
              </h3>
            </div>
            {adminData?.stakingContracts && (
              <>
                <h3 className='mb-3'>Staking contracts</h3>
                {adminData?.stakingContracts?.map((contract, position) => (
                  <StakingContractItem
                    refetchingData={refetchingData}
                    transactionStatus={status}
                    processBatchTransactions={processBatchTransactions}
                    key={position}
                    groupToken={adminData?.groupToken}
                    stakingContract={contract}
                    vestingContract={adminData?.vestingContract}
                    availableTokens={adminData.customRewardTokenIds}
                  />
                ))}
                <StakingContractItem
                  refetchingData={refetchingData}
                  transactionStatus={status}
                  processBatchTransactions={processBatchTransactions}
                  groupToken={adminData?.groupToken}
                  stakingContract={{
                    rewardTokenId: null,
                    rewardTokenLocked: false,
                    address: '',
                    farmingTokenLocked: false,
                    state: FarmState.NONE,
                    rewardTokenDecimals: null
                  }}
                  vestingContract={adminData?.vestingContract}
                  availableTokens={availableTokensUnlocked}
                  key={adminData?.stakingContracts?.length + 1}
                />
                {adminData?.shouldAllowVestingRegister && (
                  <StakingContractItem
                    refetchingData={refetchingData}
                    transactionStatus={status}
                    processBatchTransactions={processBatchTransactions}
                    groupToken={adminData?.groupToken}
                    stakingContract={{
                      rewardTokenId: null,
                      rewardTokenLocked: false,
                      address: '',
                      farmingTokenLocked: true,
                      state: FarmState.NONE,
                      rewardTokenDecimals: null
                    }}
                    vestingContract={adminData?.vestingContract}
                    availableTokens={availableTokensLocked}
                    key={adminData?.stakingContracts?.length + 2}
                  />
                )}
              </>
            )}
            {adminData?.vestingContract && (
              <VestingContractItem
                refetchingData={refetchingData}
                transactionStatus={status}
                processBatchTransactions={processBatchTransactions}
                vestingContract={adminData?.vestingContract}
              />
            )}
            {adminData?.shouldAllowVestingRegister === false && (
              <span>
                <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                Another vesting contract is deployed with this token. Please
                contact Exrond admin for more information.
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='launchpad-footer-banner admin'></div>
    </div>
  );
};

export default AdminPage;
