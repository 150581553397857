import React, { useMemo } from 'react';
import '../../SwapSettings.scss';
import { formatTicker } from '@buidly/ioiom-staking-dapp/utils/utils';
import BigNumber from 'bignumber.js';
import { Pair } from 'api/types/pairs';
import { ReactComponent as PriceImpactIcon } from 'assets/img/swap-info/price-impact.svg';
import { ReactComponent as ProviderFeeIcon } from 'assets/img/swap-info/provider-fee.svg';
import { SwapIconWithTokenOverlay } from '../SwapIconWithTokenOverlay';

interface SwapImpactProps {
  pricesImpact: string[];
  fees: string[];
  tokenRoute: string[];
  pairs: Pair[];
}

const PRICE_IMPACT_THRESHOLD = 0.1;

export const SwapImpact = ({
  pricesImpact,
  fees,
  tokenRoute,
  pairs
}: SwapImpactProps) => {
  const formattedPriceImpacts = useMemo(() => {
    return pricesImpact.map((fee) => {
      if (Number(fee) < PRICE_IMPACT_THRESHOLD) {
        return `< ${PRICE_IMPACT_THRESHOLD}`;
      }
      const impactBig = new BigNumber(fee);
      return impactBig.toFormat(impactBig.isGreaterThanOrEqualTo(1) ? 2 : 6);
    });
  }, [pricesImpact]);

  const formattedFees = useMemo(() => {
    return fees.map((fee) => {
      const feeBig = new BigNumber(fee);
      return feeBig.toFormat(feeBig.isGreaterThanOrEqualTo(1) ? 2 : 6);
    });
  }, [fees]);

  const getPriceImpactPair = (index: number): Pair | undefined => {
    if (index > pairs.length - 1) {
      return undefined;
    }

    return pairs[index];
  };

  return (
    <div className='swap-settings__impact mt-3'>
      <div className='d-flex flex-row'>
        <span className='swap-settings__text'>
          <PriceImpactIcon className='mr-1' /> Price Impact:
        </span>
        <ul>
          {formattedPriceImpacts.map((impact, index) => (
            <li
              className='swap-settings__text d-flex flex-row mb-2'
              key={index}
            >
              <SwapIconWithTokenOverlay
                token={getPriceImpactPair(index)?.firstToken}
                className='mr-2'
              />
              {impact}%
              <SwapIconWithTokenOverlay
                token={getPriceImpactPair(index)?.secondToken}
                className='ml-2'
              />
            </li>
          ))}
        </ul>
      </div>
      <div className='d-flex flex-row'>
        <span className='swap-settings__text'>
          <ProviderFeeIcon className='mr-1' />
          Liquidity Provider Fee:
        </span>
        <ul>
          {formattedFees.map((fee, index) => (
            <li className='swap-settings__text mb-2' key={index}>
              {fee} {formatTicker(tokenRoute[index])}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
