import React, { useContext } from 'react';
import { ReactComponent as ZeroBalanceIcon } from 'assets/img/ic_zero_balance.svg';
import { SwapPanelContext } from 'pages/SwapV2/Context';
import SwapSelectHeader from './SwapSelectHeader';

const SwapZeroBalanceHeader = () => {
  const { secondaryColor } = useContext(SwapPanelContext);

  return (
    <SwapSelectHeader
      text='Zero balance'
      icon={<ZeroBalanceIcon className={`swap-svg--${secondaryColor}`} />}
    />
  );
};

export default SwapZeroBalanceHeader;
