import { gql } from '@apollo/client';

export const GET_TOKEN_GROUPS = gql`
  query groupTokens($address: String!) {
    groupTokens(address: $address) {
      groupId
      token {
        __typename
        ... on NftCollectionModel {
          collection
          assets {
            svgUrl
            pngUrl
          }
        }
        ... on EsdtTokenModel {
          identifier
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
      }
    }
  }
`;
