import BigNumber from 'bignumber.js';
import { EsdtTokenSimplified } from 'api/tokens/esdtToken.model';
import { LockedTokenCollection } from 'api/tokens/locked.token';

export class AccountDetails {
  egldBalance: BigNumber = new BigNumber(0);
  esdtTokens: EsdtTokenSimplified[] = [];
  lockedTokenCollections: LockedTokenCollection[] = [];
  farmTokens: EsdtTokenSimplified[] = [];

  constructor(init?: Partial<AccountDetails>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): AccountDetails {
    return new AccountDetails({
      egldBalance: new BigNumber(response.egldBalance),
      esdtTokens: response.esdtTokens.map((esdtToken: any) =>
        EsdtTokenSimplified.fromResponse(esdtToken)
      ),
      lockedTokenCollections: response.lockedTokens?.map((lockedToken: any) =>
        LockedTokenCollection.fromResponse(lockedToken)
      ),
      farmTokens: response.farmTokens.map((esdtToken: any) =>
        EsdtTokenSimplified.fromResponse(esdtToken)
      )
    });
  }
}
