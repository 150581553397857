import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loader = () => (
  <div className='exrond-loader'>
    <div className='loading-container'>
      <FontAwesomeIcon icon={faSpinner} className='icon mb-2' size='2x' />
      <span>Loading..</span>
    </div>
  </div>
);

export default Loader;
