import React, { ReactNode } from 'react';
import './BlueChipFooterWrapper.scss';

export interface BlueChipFooterWrapperProps {
  children: ReactNode;
}

const BlueChipFooterWrapper = ({ children }: BlueChipFooterWrapperProps) => {
  return <div className='bluechip-footer'>{children}</div>;
};

export default BlueChipFooterWrapper;
