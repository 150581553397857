import React, { useMemo } from 'react';
import '../../SwapSettings.scss';
import BigNumber from 'bignumber.js';
import { ReactComponent as TotalLiquidityIcon } from 'assets/img/swap-info/total-liquidity.svg';
import { PairLiquidity } from 'pages/SwapV2/types';

import SwapLiquidityStepItem from '../../SwapLiquidityStepItem';

interface SwapTotalLiquidityProps {
  totalLiquidity: PairLiquidity[];
}

export const SwapTotalLiquidity = ({
  totalLiquidity
}: SwapTotalLiquidityProps) => {
  const totalAmountUsd = useMemo(() => {
    return totalLiquidity
      .reduce((prev, curr) => prev.plus(curr.usdAmount), new BigNumber(0))
      .toFormat(2);
  }, [totalLiquidity]);

  return (
    <div className='swap-settings__total-liquidity mt-4'>
      <div className='swap-settings__liquidity-total'>
        <div className='swap-settings__text d-flex flex-row align-items-center'>
          <TotalLiquidityIcon className='mr-1' /> Total Liquidity:
        </div>
        <div className='d-flex flex-row align-items-center swap-settings__text ml-3'>
          ${totalAmountUsd}
        </div>
      </div>
      <table>
        <tbody className='swap-settings__text'>
          {totalLiquidity.map((step: PairLiquidity, index: number) => (
            <SwapLiquidityStepItem key={index} step={step} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
