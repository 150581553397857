import React from 'react';

const UnlockSchedule = ({
  unlockSchedule
}: {
  unlockSchedule: {
    percent: number;
    unlockAtDate: string;
  }[];
}) => {
  return (
    <ul className='list-group'>
      {unlockSchedule.map((milestone, key) => {
        return (
          <li className='list-group-item bg-transparent p-1' key={key}>
            {milestone.percent / 1000}% - {milestone.unlockAtDate}
          </li>
        );
      })}
    </ul>
  );
};

export default UnlockSchedule;
