export class TokenLiquidity {
  identifier!: string;
  relations: TokenLiquidityRelation[] = [];

  constructor(init?: Partial<TokenLiquidity>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): TokenLiquidity {
    return new TokenLiquidity({
      identifier: response.identifier,
      relations: response.relations.map((relation: any) =>
        TokenLiquidityRelation.fromResponse(relation)
      )
    });
  }
}

export class TokenLiquidityRelation {
  identifier!: string;
  status!: string;

  constructor(init?: Partial<TokenLiquidityRelation>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): TokenLiquidityRelation {
    return new TokenLiquidityRelation({
      identifier: response.identifier,
      status: response.status
    });
  }
}
