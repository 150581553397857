import * as React from 'react';
import { Spinner } from '@buidly/dapp-core/dist/components';
import {
  useGetAccount,
  useGetActiveTransactionsStatus
} from '@multiversx/sdk-dapp/hooks';
import { useGetCurrentEpoch } from 'api/hooks/useGetCurrentEpoch';
import { useGetLiquidityPositions } from 'api/hooks/useGetLiquidityPositions';
import Loader from 'components/Loader';
import MyLiquidityItem from '../MyLiquidityItem';
import './MyLiquidity.scss';

const MyLiquidity = () => {
  const { address } = useGetAccount();
  const {
    data: liquidityPositionsData,
    loading: liquidityPositionLoading,
    error: liquidityPositionError
  } = useGetLiquidityPositions(address);
  const { pending } = useGetActiveTransactionsStatus();
  const { epoch } = useGetCurrentEpoch();

  return (
    <div className='w-100 d-flex align-items-center justiy-content-center'>
      <div className='my-liquidity-pools-wrapper m-2'>
        {pending ? (
          <div className='no-liquidity-positions'>
            <Spinner />
          </div>
        ) : liquidityPositionLoading ? (
          <Loader />
        ) : liquidityPositionError ? (
          <div className='no-liquidity-positions '>
            <span className='text-danger mt-2 ml-3 flex-grow-1'>
              {liquidityPositionError.message}
            </span>
          </div>
        ) : liquidityPositionsData && liquidityPositionsData.length > 0 ? (
          liquidityPositionsData?.map((liquidityPosition, index) => (
            <MyLiquidityItem
              key={index}
              liquidity={liquidityPosition}
              currentEpoch={epoch}
              index={index}
            />
          ))
        ) : (
          <div className='no-liquidity-positions'>
            <span className='exrond-text'>No current liquidity pools</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLiquidity;
