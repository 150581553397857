import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as NumberUtils from '@buidly/dapp-core/dist/utils/number';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area
} from 'recharts';
import { CREATE_SETUP_FARM_TRANSACTION } from 'api/mutations/SetupFarmMutation';
import { EsdtToken } from 'api/tokens/esdtToken.model';
import { StakingContract } from 'api/types/AdminSettings';
import colors from 'assets/sass/variables.module.scss';
import { useAccountDetails, useQueryParams } from 'hooks';
import { useAdminSettings } from 'hooks/useAdminSettings';
import TextField from 'stakegold-core-dapp/components/TextField';
import TooltipSlider from 'stakegold-core-dapp/components/TooltipSlider';
import {
  calculateFarmRewards,
  isOutsideBounds
} from 'stakegold-core-dapp/helpers';

export const getChartData = (
  duration: number,
  topUpRewardsAmount: number,
  stakingContract: StakingContract,
  groupToken?: EsdtToken
): { valueStaked: string; APR: number }[] => {
  const result: { valueStaked: string; APR: number }[] = [];

  const { rewardsPerYear } = calculateFarmRewards(duration, topUpRewardsAmount);
  const maxApr = stakingContract.maxApr ?? 0;
  const supply = new BigNumber(groupToken?.supply ?? 0).toNumber();

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  const values = [1, 5, 15, 30, 50, 75];
  for (const value of values) {
    const valueStaked = (value / 100) * supply;
    const actualApr = Math.min(maxApr, (rewardsPerYear / valueStaked) * 100);
    result.push({
      valueStaked: formatter.format(valueStaked),
      APR: actualApr
    });
  }

  return result;
};

interface DeployChildContractProps {
  stakingContract: StakingContract;
  processBatchTransactions: (transactions: Transaction[][]) => void;
}

const SetupFarmRewards = ({
  stakingContract,
  processBatchTransactions
}: DeployChildContractProps) => {
  const { groupId } = useQueryParams();
  const { address } = useGetAccount();

  const { adminSettings } = useAdminSettings(groupId);
  const { accountDetails } = useAccountDetails();

  const [duration, setDuration] = useState('');
  const [topUpRewardsAmount, setTopUpRewardsAmount] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);

  const maxTokens = useMemo(() => {
    let rewardToken = null;
    if (
      stakingContract.rewardTokenId === adminSettings.groupToken?.identifier
    ) {
      rewardToken = adminSettings.groupToken;
    } else {
      rewardToken = adminSettings.customRewardTokenIds.find(
        (token) => token.identifier === stakingContract.rewardTokenId
      );
    }

    if (!rewardToken) {
      rewardToken = accountDetails?.esdtTokens?.find(
        (token) => token.identifier === stakingContract.rewardTokenId
      );
    }

    return NumberUtils.toDenominated(
      new BigNumber(rewardToken?.balance ?? 0),
      rewardToken?.decimals
    ).toNumber();
  }, [adminSettings, stakingContract, accountDetails]);

  const [setupFarmRewardsTransaction, { data: setupFarmData }] = useMutation(
    CREATE_SETUP_FARM_TRANSACTION
  );

  useEffect(() => {
    if (setupFarmData && setupFarmData.setupFarmRewards) {
      const transactions = setupFarmData.setupFarmRewards as Transaction[][];
      if (transactions.length === 0) {
        return;
      }

      processBatchTransactions(transactions);
    }
  }, [setupFarmData]);

  const handleSetup = () => {
    const durationOutsideBounds = isOutsideBounds('Duration', 1, 730, duration);
    if (durationOutsideBounds) {
      setError(durationOutsideBounds);
      return;
    }

    const tokensOutsideBounds = isOutsideBounds(
      'Number of tokens',
      1,
      maxTokens,
      topUpRewardsAmount
    );
    if (tokensOutsideBounds) {
      setError(tokensOutsideBounds);
      return;
    }

    setError(undefined);

    const topUpAmount = Number(topUpRewardsAmount);
    const durationAsNumber = Number(duration);
    const { rpb } = calculateFarmRewards(durationAsNumber, topUpAmount);

    setupFarmRewardsTransaction({
      variables: {
        sender: address,
        groupId: groupId,
        stakingScAddress: stakingContract.address,
        setupFarmArgs: {
          perBlockRewardAmount: rpb,
          topUpAmount: topUpAmount,
          duration: durationAsNumber,
          hasLockedToken:
            stakingContract.farmingTokenLocked ||
            stakingContract.rewardTokenLocked
        }
      }
    });
  };

  const data = useMemo(() => {
    if (isOutsideBounds('Duration', 1, 730, duration)) {
      return [{}];
    }

    if (isOutsideBounds('Number of tokens', 1, maxTokens, topUpRewardsAmount)) {
      return [{}];
    }

    const topUpAmount = Number(topUpRewardsAmount);
    const durationAsNumber = Number(duration);

    return getChartData(
      durationAsNumber,
      topUpAmount,
      stakingContract,
      adminSettings?.groupToken
    );
  }, [duration, topUpRewardsAmount, adminSettings]);

  return (
    <div className='d-flex container mb-3 justify-content-center'>
      <div className='d-flex flex-column align-items-center w-100'>
        <h5>Staking contract</h5>
        <h6>Step 2/2: Setup farm rewards</h6>
        <div className='d-flex flex-column align-items-center mt-5 w-100'>
          <div className='d-flex flex-column mt-3 contracts'>
            <TextField
              labelClassName='exrond-text text-white font-size-1 font-weight-600'
              divWrapperClassName='exrond-input swap-form-group-light primary'
              inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
              value={duration}
              type='number'
              placeholder='Duration (in days)'
              callback={(newValue) => setDuration(newValue)}
            />
            <TooltipSlider
              railStyle={{ backgroundColor: '#26334a' }}
              tipFormatter={(val) => `${val}`}
              step={1}
              onChange={(value) => {
                const input = value as number;
                setDuration(input.toString());
              }}
              value={Number(duration)}
              tipProps={''}
              min={0}
              max={730}
            />
          </div>
          <div className='d-flex flex-column mt-3 contracts'>
            <TextField
              labelClassName='exrond-text text-white font-size-1 font-weight-600'
              divWrapperClassName='exrond-input swap-form-group-light primary'
              inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
              value={topUpRewardsAmount}
              type='number'
              placeholder='Number of tokens'
              callback={(newValue) => setTopUpRewardsAmount(newValue)}
            />
            <TooltipSlider
              railStyle={{ backgroundColor: '#26334a' }}
              tipFormatter={(val) => `${val}`}
              tipProps={''}
              step={1}
              onChange={(value) => {
                const input = value as number;
                setTopUpRewardsAmount(input.toString());
              }}
              value={Number(topUpRewardsAmount)}
              min={0}
              max={maxTokens}
            />
          </div>

          <div className='d-flex flex-column mt-3 w-100'>
            <span className='text-center'>
              APR simulator based on total value staked
            </span>
            <div className='w-100 d-flex justify-content-center'>
              <ResponsiveContainer width={'90%'} height={400}>
                <ComposedChart
                  data={data}
                  margin={{ top: 20, right: 20, bottom: 70, left: 20 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop
                        offset='5%'
                        stopColor={colors.colorPrimaryDark}
                        stopOpacity={0.1}
                      />
                      <stop offset='95%' stopColor='#000' stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey='valueStaked'
                    label={{
                      value: 'Total value staked',
                      fill: 'white',
                      position: 'insideBottom',
                      offset: -20
                    }}
                  />
                  <YAxis
                    label={{
                      value: 'APR',
                      fill: 'white',
                      angle: -90,
                      position: 'insideLeft'
                    }}
                  />
                  <Tooltip
                    labelFormatter={(label) => `Value staked : ${label}`}
                    labelStyle={{ color: 'black' }}
                    itemStyle={{ color: 'black' }}
                  />
                  <Line
                    type='monotone'
                    dataKey='APR'
                    stroke={colors.colorPrimaryDark}
                    dot={false}
                  />
                  <Area
                    type='monotone'
                    dataKey='APR'
                    stroke={colors.colorPrimaryDark}
                    strokeWidth={2}
                    fillOpacity={1}
                    tooltipType='none'
                    fill='url(#colorUv)'
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>

          <button className='exrond-btn-light' onClick={() => handleSetup()}>
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Deploy
            </span>
          </button>
        </div>
        {error && <div className='text-danger mt-3'>{error}</div>}
      </div>
    </div>
  );
};

export default SetupFarmRewards;
