import { useSearchParams } from 'react-router-dom';

const useQueryParams = (): { [k: string]: string } => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const query = new URLSearchParams(searchParams.toString());

  return Object.fromEntries(query);
};

export { useQueryParams };
