import React, { useEffect, useMemo, useState } from 'react';
import './MintButtonContainer.scss';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetAccount,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Spinner } from 'react-bootstrap';
import ActionOrConnect from 'components/ActionOrConnect';
import { useMediaQuery } from 'hooks';
import { useMintBlueChips } from 'hooks/useMintBlueChips';

const disallowedSymbols = ['e', 'E', '+', '-', '.'];
const MAX_MINT_NFTS = 70;

interface MintButtonContainerProps {
  controlsShown: boolean;
  miniHeader?: boolean;
  showStickyButtons: boolean;
  showControls: (show: boolean) => void;
  mintSuccessful: () => void;
}

export const MintButtonContainer = ({
  controlsShown,
  miniHeader = false,
  showStickyButtons,
  showControls,
  mintSuccessful
}: MintButtonContainerProps) => {
  const [numNfts, setNumNfts] = useState('1');
  const mintDefaultValues = ['1', '5', '10'];
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [selectedNumNfts, setSelectedNumNfts] = useState<string | undefined>(
    mintDefaultValues[0]
  );
  const [customNumNfts, setCustomNumNfts] = useState<string>('');

  const { balance } = useGetAccount();

  const { mintBlueChips, sessionId } = useMintBlueChips();

  const trackTransactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: mintSuccessful
  });

  const insufficientFunds = useMemo(() => {
    const price = new BigNumber(1).multipliedBy(numNfts).shiftedBy(18);
    return new BigNumber(balance).isLessThan(price);
  }, [balance, numNfts]);

  useEffect(() => {
    setNumNfts(selectedNumNfts ?? customNumNfts);
  }, [selectedNumNfts, customNumNfts]);

  const handleMintValueChanged = (selected: string) => {
    setSelectedNumNfts(selected);
    setCustomNumNfts('');
  };

  const handleCustomMintValueChanged = (newVal: string) => {
    setSelectedNumNfts(newVal.length === 0 ? mintDefaultValues[0] : undefined);
    setCustomNumNfts(newVal);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (disallowedSymbols.includes(event.key)) {
      event.preventDefault();
      return;
    }
  };

  const btnTitle = useMemo(() => {
    if (!controlsShown) {
      return 'Mint BlueChips for 1 EGLD';
    }

    return `Mint ${numNfts} BlueChips for ${numNfts} EGLD`;
  }, [numNfts, controlsShown]);

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;

    const newValue = parseInt(inputElement.value);
    if (newValue <= 0) {
      setCustomNumNfts('');
      return;
    }

    if (newValue > MAX_MINT_NFTS) {
      setCustomNumNfts(MAX_MINT_NFTS.toString());
    }
  };

  const handleOnClick = () => {
    const numberOfNfts = Number(numNfts);
    if (!controlsShown || isNaN(numberOfNfts) || insufficientFunds) {
      showControls(true);
      return;
    }

    mintBlueChips(numberOfNfts);
  };

  return (
    <div
      className={`mint__container ${
        controlsShown && !isDesktop ? 'mint__container--mobile-expanded' : ''
      } ${miniHeader ? 'mint__container--mobile-mini' : ''}`}
    >
      <ActionOrConnect className='mint__main-btn mint__main-btn__connect'>
        <button
          className='mint__main-btn'
          onClick={handleOnClick}
          disabled={insufficientFunds}
        >
          {trackTransactionStatus.isPending ?? false ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : insufficientFunds ? (
            'Insufficient funds'
          ) : (
            btnTitle
          )}
        </button>
      </ActionOrConnect>
      {controlsShown && (
        <div className='d-flex flex-grow-1 justify-content-between w-100 mt-3'>
          {mintDefaultValues.map((buttonValue, index) => (
            <button
              key={index}
              className={`mint__btn-nfts ${
                selectedNumNfts === buttonValue
                  ? 'mint__btn-nfts--selected'
                  : ''
              }`}
              onClick={() => handleMintValueChanged(buttonValue)}
            >
              {buttonValue}
            </button>
          ))}
          <input
            type='number'
            placeholder='Custom'
            className={`mint__input ${
              customNumNfts.length > 0 ? 'mint__input--selected' : ''
            }`}
            max={MAX_MINT_NFTS}
            value={customNumNfts}
            onChange={(e) => handleCustomMintValueChanged(e.target.value)}
            onKeyDown={handleOnKeyDown}
            onKeyUp={handleOnKeyUp}
          />
          <button
            className='mint__btn-nfts'
            onClick={() => {
              showControls(false);
              handleMintValueChanged(mintDefaultValues[0]);
            }}
          >
            X
          </button>
        </div>
      )}
      {showStickyButtons && (
        <div className='d-flex d-lg-none px-3 mt-3 w-100 staking-sticky-container exrond-gradient-container'>
          <div className='container d-flex justify-content-center'>
            <div className='d-flex flex-row sticky-button'>
              <button
                onClick={() => showControls(false)}
                className='bottom-buttons__back'
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  color='#fff'
                  className='bottom-buttons__back-icon'
                />
                Back
              </button>
              <button
                disabled={insufficientFunds}
                onClick={handleOnClick}
                className='bottom-buttons__mint'
              >
                {insufficientFunds
                  ? 'Insufficient funds'
                  : `Mint ${numNfts} BlueChips`}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
