import * as React from 'react';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './LiquidityCard.scss';
import AddLiquidity from './AddLiquidity';
import LiquidityTabWrapper from './LiquidityTabWrapper';
import MyLiquidity from './MyLiquidity';

const LiquidityCard = () => {
  const [key, setKey] = React.useState<string>(
    localStorage.getItem('liquidityTab') || 'addLiquidity'
  );
  const { address } = useGetAccount();
  const isLoggedIn = Boolean(address);

  const onSelectTab = (tab: string) => {
    localStorage.setItem('liquidityTab', tab);
    setKey(tab);
  };

  return (
    <div className='card exrond-liquidity-card'>
      <Tabs
        defaultActiveKey={key}
        id='controlled-tab'
        activeKey={key}
        onSelect={(k) => onSelectTab(k as string)}
        justify
      >
        {isLoggedIn && (
          <Tab
            tabClassName='d-flex justify-content-center align-items-center text-center h-100 w-100 p-4 left'
            eventKey={'myLiquidity'}
            title={'My liquidity'}
          >
            <LiquidityTabWrapper>
              <MyLiquidity />
            </LiquidityTabWrapper>
          </Tab>
        )}
        <Tab
          tabClassName={`d-flex justify-content-center align-items-center text-center h-100 w-100 p-4 right ${
            !isLoggedIn && 'left'
          }`}
          eventKey={'addLiquidity'}
          title={'Add Liquidity'}
        >
          <LiquidityTabWrapper>
            <AddLiquidity />
          </LiquidityTabWrapper>
        </Tab>
      </Tabs>
    </div>
  );
};

export default LiquidityCard;
