import * as React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import * as NumberUtils from '@buidly/dapp-core/dist/utils/number';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { Modal } from 'react-bootstrap';
import { slippageVar } from 'cache';
import { DECIMALS, TOKEN_PLACEHOLDER } from 'config';
import TooltipSlider from 'stakegold-core-dapp/components/TooltipSlider';
import { sendAndSignTransactions } from 'stakegold-core-dapp/helpers';
import {
  LiquidityPositionsResponse,
  RemoveLiquidityArgs,
  REMOVE_LIQUIDITY_MUTATION
} from '../liquidity';
import {
  calculateReceivedAmountsFromPercentage,
  getPercentageFromInput
} from '../utils';

interface ModalProps {
  show: boolean;
  onHide: () => void;
  liquidity: LiquidityPositionsResponse;
}

const MyLiquidityRemoveModal = ({ show, onHide, liquidity }: ModalProps) => {
  const slippage = useReactiveVar(slippageVar);
  const { address } = useGetAccount();
  const [inputValue, setInputValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [amountPercent, setAmountPercent] = React.useState(0);
  const [firstTokenReceived, setFirstTokenReceived] = React.useState<
    BigNumber | undefined
  >(undefined);
  const [secondTokenReceived, setSecondTokenReceived] = React.useState<
    BigNumber | undefined
  >(undefined);
  const [removeLiquidityMutation, { data: removeLiquidityData }] = useMutation(
    REMOVE_LIQUIDITY_MUTATION
  );

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%'
  };

  const handleSliderOnChange = (input: number) => {
    if (liquidity) {
      setInputValue(
        NumberUtils.toDenominatedString(
          new BigNumber(liquidity.lpToken.balance ?? 0).multipliedBy(
            new BigNumber(input / 100)
          ),
          liquidity.lpToken.decimals,
          liquidity.lpToken.decimals
        )
      );
    }
  };

  const setReceivedValues = (percent: number) => {
    const { _firstTokenReceived, _secondTokenReceived } =
      calculateReceivedAmountsFromPercentage(percent, liquidity);

    setFirstTokenReceived(_firstTokenReceived);
    setSecondTokenReceived(_secondTokenReceived);
  };

  const setPercentage = (percent: number) => {
    if (!isNaN(percent)) {
      setAmountPercent(percent);
      setReceivedValues(percent);
    }
  };

  const onRemoveLiquidity = () => {
    removeLiquidityMutation({
      variables: {
        sender: address,
        input: {
          pairAddress: liquidity.pair.address,
          liquidity: new BigNumber(inputValue)
            .shiftedBy(liquidity.lpToken.decimals)
            .toFixed(),
          liquidityTokenID: liquidity.lpToken.identifier,
          tolerance: slippage
        } as RemoveLiquidityArgs
      }
    });
    onHide();
  };

  const isRemoveLiquidityButtonValid =
    !firstTokenReceived?.isEqualTo(0) && !secondTokenReceived?.isEqualTo(0);

  React.useEffect(() => {
    if (removeLiquidityData && removeLiquidityData.removeLiquidity) {
      sendAndSignTransactions(
        removeLiquidityData.removeLiquidity,
        'Remove liquidity'
      );
    }
  }, [removeLiquidityData]);

  React.useEffect(() => {
    if (!liquidity) {
      return;
    }

    const { percentage, error } = getPercentageFromInput(inputValue, liquidity);
    setPercentage(percentage);
    setErrorMessage(error);
  }, [inputValue]);

  React.useEffect(() => {
    //Reset amount percent when the position changes (new modal shown)
    setInputValue('');
    setPercentage(0);
  }, [liquidity]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='align-items-center'>
        <Modal.Title className='exrond-text font-size-1 font-weight-600'>
          Remove Liquidity from {liquidity.pair.secondToken.ticker} -{' '}
          {liquidity.pair.firstToken.ticker} Pool
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormatAmount
          className='exrond-text font-size-1 font-weight-600'
          value={liquidity.lpToken.balance ?? '0'}
          decimals={liquidity.lpToken.decimals}
          egldLabel={' '}
          digits={DECIMALS}
          showLastNonZeroDecimal={false}
          // addCommas={true}
        />{' '}
        <span className='exrond-text font-size-1 font-weight-600'>
          LP tokens
        </span>
        <div className='d-flex flex-row form-group swap-form-group swap-form-field mt-4'>
          <div className='d-flex flex-column input-group pl-3 pr-3'>
            <input
              type='number'
              className='form-control input-amount input-stake'
              placeholder='Amount'
              onChange={(event) => {
                setInputValue(event.target.value);
              }}
              required
              step='any'
              value={inputValue}
            />
          </div>
          <button
            className='btn btn-sm btn-primary mr-2 align-self-center p-0 pl-2 pr-2'
            style={{ height: '21px', fontSize: '10px' }}
            onClick={() => {
              liquidity &&
                setInputValue(
                  NumberUtils.toDenominatedString(
                    new BigNumber(liquidity.lpToken.balance ?? 0),
                    liquidity.lpToken.decimals,
                    liquidity.lpToken.decimals
                  )
                );
            }}
          >
            MAX
          </button>
        </div>
        {errorMessage && (
          <div className='text-danger mt-2 ml-3 flex-grow-1'>
            {errorMessage}
          </div>
        )}
        <div className='d-flex flex-row my-5'>
          <TooltipSlider
            marks={marks}
            step={1}
            onChange={(value: any) => {
              const input = value as number;
              handleSliderOnChange(input);
            }}
            value={amountPercent}
            tipProps={''}
          />
        </div>
        <span>You will receive:</span>
        <div className='d-flex flex-column mt-2 py-1'>
          <div className='d-flex flex-row align-items-center p-2'>
            <div className='d-flex flex-grow-1 align-items-center'>
              <img
                src={
                  liquidity.pair.secondToken.assets?.pngUrl ??
                  liquidity.pair.secondToken.assets?.svgUrl ??
                  TOKEN_PLACEHOLDER
                }
                className='p-0 token-icon token-select mr-2'
                style={{ width: '32px', height: '32px' }}
              />
              {liquidity.pair.secondToken.ticker}
            </div>
            {!secondTokenReceived ? (
              '-'
            ) : (
              <FormatAmount
                value={secondTokenReceived.toFixed(0)}
                decimals={liquidity.pair.secondToken.decimals ?? 0}
                egldLabel={' '}
                digits={DECIMALS}
                showLastNonZeroDecimal={false}
                // addCommas={true}
              />
            )}
          </div>
          <div className='d-flex flex-row align-items-center p-2'>
            <div className='d-flex flex-grow-1 align-items-center'>
              <img
                src={
                  liquidity.pair.firstToken.assets?.pngUrl ??
                  liquidity.pair.firstToken.assets?.svgUrl ??
                  TOKEN_PLACEHOLDER
                }
                className='p-0 token-icon token-select mr-2'
                style={{ width: '32px', height: '32px' }}
              />
              {liquidity.pair.firstToken.ticker}
            </div>
            {!firstTokenReceived ? (
              '-'
            ) : (
              <FormatAmount
                value={firstTokenReceived.toFixed(0)}
                decimals={liquidity.pair.firstToken.decimals ?? 0}
                egldLabel={' '}
                digits={DECIMALS}
                showLastNonZeroDecimal={false}
                // addCommas={true}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='flex-nowrap'>
        <button className='btn btn-lg btn-primary w-50' onClick={onHide}>
          Cancel
        </button>
        <button
          className='btn btn-lg btn-primary w-50'
          onClick={onRemoveLiquidity}
          disabled={!isRemoveLiquidityButtonValid}
        >
          Remove Liquidity
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyLiquidityRemoveModal;
