import { TOKEN_PLACEHOLDER } from 'config';

export class EsdtToken {
  identifier = '';
  ticker = '';
  name = '';
  decimals = 0;
  balance = '0';
  supply = '0';
  icon = '';

  constructor(init?: Partial<EsdtToken>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): EsdtToken {
    const token = new EsdtToken({
      identifier: response.identifier,
      ticker: response.ticker,
      name: response.name,
      decimals: response.decimals,
      balance: response.balance ?? '0',
      supply: response.supply ?? '0',
      icon:
        response.assets?.pngUrl ?? response.assets?.svgUrl ?? TOKEN_PLACEHOLDER
    });
    return token;
  }
}

export interface SwapTokens {
  firstToken: EsdtToken | undefined;
  secondToken: EsdtToken | undefined;
  direction: 'in' | 'out';
}

export class Pair {
  address = '';
  firstTokenPrice = '0';
  secondTokenPrice = '0';
  firstTokenReserve = '0';
  secondTokenReserve = '0';
  firstToken: EsdtToken = new EsdtToken();
  secondToken: EsdtToken = new EsdtToken();

  constructor(init?: Partial<Pair>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): Pair {
    return new Pair({
      address: response.address,
      firstTokenPrice: response.firstTokenPrice,
      secondTokenPrice: response.secondTokenPrice,
      firstTokenReserve: response.firstTokenReserve,
      secondTokenReserve: response.secondTokenReserve,
      firstToken: EsdtToken.fromResponse(response.firstToken),
      secondToken: EsdtToken.fromResponse(response.secondToken)
    });
  }
}
