import React, { useMemo } from 'react';
import '../../SwapSettings.scss';
import BigNumber from 'bignumber.js';
import { ReactComponent as InitialLiquidityIcon } from 'assets/img/swap-info/initial-liquidity.svg';
import { ReactComponent as LockedLiquidityIcon } from 'assets/img/swap-info/locked-liquidity.svg';
import { PairLiquidity } from 'pages/SwapV2/types';
import SwapLiquidityStepItem from '../../SwapLiquidityStepItem';

interface SwapInitialLiquidityLockedProps {
  initialLiquidity: PairLiquidity[];
}

export const SwapInitialLiquidityLocked = ({
  initialLiquidity
}: SwapInitialLiquidityLockedProps) => {
  const totalAmountUsd = useMemo(() => {
    return initialLiquidity
      .reduce((prev, curr) => prev.plus(curr.usdAmount), new BigNumber(0))
      .toFormat(2);
  }, [initialLiquidity]);

  if (initialLiquidity.length === 0) {
    return null;
  }

  return (
    <div className='swap-settings__initial-liquidity mt-4'>
      <div className='swap-settings__liquidity-total'>
        <div className='swap-settings__text d-flex flex-row align-items-center'>
          <InitialLiquidityIcon className='mr-1' /> Initial Liquidity Locked:
        </div>
        <div className='d-flex flex-row align-items-center swap-settings__text ml-3'>
          ${totalAmountUsd}
          <LockedLiquidityIcon className='ml-2' />
        </div>
      </div>
      <table>
        <tbody className='swap-settings__text'>
          {initialLiquidity.map((step: PairLiquidity, index: number) => (
            <SwapLiquidityStepItem key={index} step={step} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
