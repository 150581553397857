import React from 'react';
import BigNumber from 'bignumber.js';
import { EsdtTokenSimplified } from 'api/tokens/esdtToken.model';
import { TOKEN_PLACEHOLDER } from 'config';
import Token from '../Token';

interface TokensListProps {
  title: string;
  emptyViewTitle: string;
  tokens: EsdtTokenSimplified[];
}

const TokensList = ({ title, emptyViewTitle, tokens }: TokensListProps) => {
  return (
    <>
      <p className='mt-2'>
        <b>{title}</b>
      </p>
      {tokens.length > 0 ? (
        tokens.map((esdtToken, index) => (
          <li
            className='list-group-item mb-2 list-group-item-bordered'
            key={index}
          >
            <Token
              icon={esdtToken.icon ?? TOKEN_PLACEHOLDER}
              name={esdtToken.name}
              balance={esdtToken.balance ?? new BigNumber(0)}
              decimals={esdtToken.decimals}
              ticker={esdtToken.ticker}
            />
          </li>
        ))
      ) : (
        <div className='text-center'>{emptyViewTitle}</div>
      )}
    </>
  );
};

export default TokensList;
