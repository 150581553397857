import { gql } from '@apollo/client';

export const GET_TOKENS = gql`
  query tokens {
    tokens {
      token {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          svgUrl
          pngUrl
          __typename
        }
        __typename
      }
      buyAmount
    }
  }
`;
