import BigNumber from 'bignumber.js';

export const calculateTotalReward = (
  expiryDate: string,
  dailyReward: string,
  nfts: number
): BigNumber => {
  const currentDate = new Date();
  const expiryDateObj = new Date(expiryDate);
  const timeDifference = expiryDateObj.getTime() - currentDate.getTime();

  if (timeDifference <= 0) {
    return new BigNumber(0);
  } else {
    const hoursRemaining = Math.ceil(timeDifference / (1000 * 3600));
    const hourlyReward = new BigNumber(dailyReward).dividedBy(24);

    const totalReward = new BigNumber(hourlyReward)
      .multipliedBy(nfts)
      .multipliedBy(hoursRemaining);
    return totalReward;
  }
};
