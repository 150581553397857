import { NftTokenState } from '@buidly/ioiom-staking-dapp/types';
import { BlueChipFilterButtonDetails } from '../types';

export const getBlueChipTokenDetailsByState = (
  state: NftTokenState | undefined
): BlueChipFilterButtonDetails => {
  switch (state) {
    case NftTokenState.UNSTAKED:
      return new BlueChipFilterButtonDetails({
        name: 'Available',
        color: '#004DE2',
        defaultTextColor: 'text-white',
        selectedTextColor: 'text-white'
      });
    case NftTokenState.STAKED:
      return new BlueChipFilterButtonDetails({
        name: 'Vested',
        color: '#6BEAFD'
      });
    case NftTokenState.UNBOUND:
      return new BlueChipFilterButtonDetails({
        name: 'Unbound',
        color: '#6BEAFD'
      });
    case NftTokenState.CLAIMABLE:
      return new BlueChipFilterButtonDetails({
        name: 'Claimable',
        color: '#6BEAFD'
      });
    default:
      return new BlueChipFilterButtonDetails({
        name: 'All',
        color: '#004DE2',
        defaultTextColor: 'text-white',
        selectedTextColor: 'text-white'
      });
  }
};
