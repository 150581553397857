import * as React from 'react';
import { PairState } from 'api/types/admin';
import Loader from 'components/Loader';
import { useOngoingPairSetup } from 'pages/Admin/hooks/useOngoingPairSetup';
import AddInitialLiquidity from '../AddInitialLiquidity';
import CreatePair from '../CreatePair';
import Resume from '../Resume';

const Step = () => {
  const ongoingSetup = useOngoingPairSetup();

  if (!ongoingSetup) {
    return <Loader />;
  }

  switch (ongoingSetup.status) {
    case PairState.PAIR_CREATED:
    case PairState.LP_TOKEN_ISSUED:
    case PairState.LOCAL_ROLES_SET:
      return (
        <AddInitialLiquidity
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          pairAddress={ongoingSetup.pairAddress!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          firstToken={ongoingSetup.firstToken!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          secondToken={ongoingSetup.secondToken!}
        />
      );
    case PairState.INITIAL_LIQUIDITY_ADDED:
      return (
        <Resume // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          pairAddress={ongoingSetup.pairAddress!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          firstToken={ongoingSetup.firstToken!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          secondToken={ongoingSetup.secondToken!}
        />
      );
    default:
      return <CreatePair />;
  }
};

export default Step;
