import { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { TOKEN_LIQUIDITIES } from 'api/queries';
import { TokenLiquidity } from '../types/TokenLiquidity';

const useGetTokenLiquidities = (): {
  liquidities: TokenLiquidity[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error } = useQuery(TOKEN_LIQUIDITIES);

  return useMemo(() => {
    const liquidities: TokenLiquidity[] =
      data?.tokenLiquidities?.map((liquidity: any) =>
        TokenLiquidity.fromResponse(liquidity)
      ) ?? [];

    return { liquidities, loading, error };
  }, [data, loading, error]);
};

export { useGetTokenLiquidities };
