import { gql } from '@apollo/client';

export const CREATE_DEPLOY_CHILD_CONTRACT_TRANSACTION = gql`
  mutation deployChildContract(
    $sender: String!
    $groupId: String!
    $deployChildContractArgs: DeployChildContractArgs!
  ) {
    deployChildContract(
      sender: $sender
      groupId: $groupId
      deployChildContractArgs: $deployChildContractArgs
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
