import React, { useContext } from 'react';
import { formatTicker } from '@buidly/ioiom-staking-dapp/utils/utils';
import { EsdtToken } from 'api/types/pairs';
import { TOKEN_PLACEHOLDER } from 'config';
import '../SwapSelect/SwapSelect.scss';
import './SwapAnalyticsToken.scss';
import { useMediaQuery } from 'hooks';
import { SwapContext, SwapPanelContext } from 'pages/SwapV2/Context';
import { useGetCardToken } from 'pages/SwapV2/hooks';

interface SwapAnalyticsTokenProps {
  token: EsdtToken;
  setOptionsShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwapAnalyticsToken = ({
  token,
  setOptionsShown
}: SwapAnalyticsTokenProps) => {
  const { cardType, setCardExpanded } = useContext(SwapPanelContext);
  const { setSwapPairAmounts, stopSwapPolling } = useContext(SwapContext);
  const { setSelectedToken } = useGetCardToken(cardType);
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const handleTokenSelected = () => {
    setSelectedToken(token);

    setOptionsShown(false);
    stopSwapPolling();
    setSwapPairAmounts({
      amountIn: '',
      amountOut: ''
    });

    if (!isDesktop) {
      setCardExpanded(cardType);
    }
  };

  return (
    <div
      className='cursor-pointer d-flex flex-column align-items-center flex-fill'
      onClick={handleTokenSelected}
    >
      <img
        src={token.icon ?? TOKEN_PLACEHOLDER}
        className='swap-token-icon swap-analytics-token__icon p-0'
      />
      <span className='swap-analytics-token__title mt-1'>
        {formatTicker(token.ticker)}
      </span>
    </div>
  );
};

export default SwapAnalyticsToken;
