import React, { useReducer, useState } from 'react';
import * as NumberUtils from '@buidly/dapp-core/dist/utils/number';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { Loader } from '@multiversx/sdk-dapp/UI';
import { useNavigate } from 'react-router-dom';
import { EsdtToken } from 'api/types/pairs';
import { useGetCreateFaucetTransaction } from 'pages/Faucet/hooks/useGetCreateFaucetTransaction';
import { useGetFaucetAvailableAmount } from 'pages/Faucet/hooks/useGetFaucetAvailableAmount';
import { routeNames } from 'routes';
import {
  depositReducer,
  initialDepositState,
  SET_AMOUNT,
  SET_CLAIMABLE_AMOUNT,
  SET_CLAIM_POLICY,
  SET_FAUCET_STEP,
  SET_INITIAL_STATE,
  SET_MAX_CLAIMS,
  SET_TOKEN
} from '../depositReducer';
import { FaucetStep } from '../types';
import { validateForm } from '../utils';
import FaucetDetails from './FaucetDetails';
import SelectFaucetToken from './SelectFaucetToken';

const CreateFaucet = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(depositReducer, initialDepositState);
  const { token, amount, claimPolicy, claimableAmount, maxClaims, step } =
    state;

  const [error, setError] = useState('');

  const availableAmount = useGetFaucetAvailableAmount(token);

  const handleOnTransactionSuccess = () => {
    navigate(routeNames.rewards);
  };

  const handleOnTransactionFailure = () => {
    dispatch({ type: SET_INITIAL_STATE });
  };

  const { performCreateFaucet, sessionId } = useGetCreateFaucetTransaction();

  const trackTransactionStatus = useTrackTransactionStatus({
    transactionId: sessionId,
    onSuccess: handleOnTransactionSuccess,
    onFail: handleOnTransactionFailure,
    onTimedOut: handleOnTransactionFailure
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formError = validateForm(
      amount,
      availableAmount,
      token,
      claimPolicy,
      claimableAmount,
      maxClaims
    );

    if (formError !== undefined) {
      setError(formError);
      return;
    }

    setError('');
    performCreateFaucet(
      token,
      amount,
      claimPolicy?.value,
      claimableAmount,
      maxClaims
    );
  };

  if (trackTransactionStatus.isPending) {
    return <Loader className='text-white' />;
  }

  const onMaxClicked = () => {
    if (!token) {
      return;
    }

    const max = NumberUtils.toDenominatedString(
      availableAmount,
      token.decimals
    );

    dispatch({ type: SET_AMOUNT, amount: max });
  };

  if (step === FaucetStep.STEP_SELECT_TOKEN) {
    return (
      <SelectFaucetToken
        onTokenSelected={(esdt: EsdtToken) =>
          dispatch({ type: SET_TOKEN, token: esdt })
        }
        selectedToken={token}
        onContinueClick={() =>
          dispatch({
            type: SET_FAUCET_STEP,
            step: FaucetStep.STEP_FAUCET_DETAILS
          })
        }
      />
    );
  }

  return (
    <FaucetDetails
      amount={amount}
      token={token}
      availableAmount={availableAmount}
      claimPolicy={claimPolicy}
      claimableAmount={claimableAmount}
      maxClaims={maxClaims}
      error={error}
      onSubmit={handleSubmit}
      onAmountChanged={(value) => dispatch({ type: SET_AMOUNT, amount: value })}
      onMaxClicked={onMaxClicked}
      onClaimPolicyChanged={(value) =>
        dispatch({ type: SET_CLAIM_POLICY, claimPolicy: value })
      }
      onClaimableAmountChanged={(value) =>
        dispatch({
          type: SET_CLAIMABLE_AMOUNT,
          claimableAmount: value
        })
      }
      onMaxClaimsChanged={(value) =>
        dispatch({
          type: SET_MAX_CLAIMS,
          maxClaims: value
        })
      }
      onBackClicked={() => dispatch({ type: SET_INITIAL_STATE })}
    />
  );
};

export default CreateFaucet;
