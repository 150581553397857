import React, { useContext, useEffect, useState } from 'react';
import { SwapContext } from 'pages/SwapV2/Context';
import SwapButton from '../SwapButton/SwapButton';
import SwapSettings from '../SwapSettings/SwapSettings';
import '../SwapContent/SwapContent.scss';

export const SwapButtonContainer = () => {
  const [showSettings, setShowSettings] = useState(false);

  const {
    swapLoading,
    swapDisabled,
    transactionsPending,
    swapData,
    sendSwapTransactions
  } = useContext(SwapContext);

  const loading = swapLoading || transactionsPending;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    sendSwapTransactions();
  };

  const handleSettings = (event: any) => {
    event.stopPropagation();
    if (swapData === undefined) {
      return;
    }

    setShowSettings(!showSettings);
  };

  useEffect(() => {
    if (swapData === undefined) {
      setShowSettings(false);
    }
  }, [swapData]);

  return (
    <div className='swap-actions-container'>
      <SwapSettings show={showSettings} />
      <SwapButton
        disabled={swapDisabled || loading}
        isLoading={loading}
        settingsEnabled={showSettings}
        handleSubmit={handleSubmit}
        handleSettings={handleSettings}
      />
    </div>
  );
};
