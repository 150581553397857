import React, { useMemo } from 'react';
import { Loader } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import Select from 'react-select';
import { EsdtToken } from 'api/types/pairs';
import { TOKEN_PLACEHOLDER } from 'config';
import { useAccountDetails } from 'hooks';
import { selectColourStyles } from 'utils';
import { useGetCreateFaucetSettings } from '../../hooks/useGetCreateFaucetSettings';

export interface SelectFaucetTokenProps {
  onTokenSelected: (token: EsdtToken) => void;
  onContinueClick: () => void;
  selectedToken?: EsdtToken;
}

const SelectFaucetToken = ({
  selectedToken,
  onTokenSelected,
  onContinueClick
}: SelectFaucetTokenProps) => {
  const { faucetSettings, loading } = useGetCreateFaucetSettings();
  const { accountDetails } = useAccountDetails();
  const registrationInfo = faucetSettings?.registrationInfo;

  const feeAmount = !registrationInfo
    ? undefined
    : new BigNumber(registrationInfo.feeAmount).shiftedBy(
        -registrationInfo.token.decimals
      );

  const feeToken = registrationInfo?.token;

  const feeError = useMemo(() => {
    if (!feeAmount) {
      return undefined;
    }

    if (feeAmount.isZero() || !feeToken) {
      return undefined;
    }

    const feeTokenBalance = accountDetails?.esdtTokens.find(
      (token) => token.identifier === feeToken.identifier
    )?.balance;

    const isLessThanFee = new BigNumber(feeTokenBalance ?? 0).isLessThan(
      feeAmount
    );

    if (isLessThanFee) {
      return `You do not have enough ${registrationInfo?.token.ticker} tokens to pay the fee`;
    }

    return undefined;
  }, [accountDetails, registrationInfo, feeToken, feeAmount]);

  const formatTokenLabel = (esdt: EsdtToken) => {
    return (
      <div className='d-flex flex-row align-items-center'>
        <img
          src={esdt.icon ?? TOKEN_PLACEHOLDER}
          className='token-icon token-select p-0 mr-2'
          style={{ width: '32px', height: '32px' }}
        />
        <span className='home-text13'>{esdt.ticker}</span>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loader className='text-white' />
      ) : (
        <>
          <span className='swap-title'>Create Rewards Pool</span>
          <span className='exrond-text text-white mb-2'>{`Create your rewards pool ${
            feeAmount && registrationInfo && feeAmount.isGreaterThan(0)
              ? `for ${feeAmount} ${registrationInfo.token.name}`
              : ''
          }`}</span>

          <div className='mt-2 d-flex flex-column flex-xl-row w-100'>
            <Select
              className={'exrond-input primary exrond-select m-0'}
              classNamePrefix={'exrond'}
              styles={selectColourStyles()}
              placeholder={'Select token'}
              options={faucetSettings?.tokens}
              isLoading={loading}
              formatOptionLabel={(option) => formatTokenLabel(option)}
              getOptionValue={(option) => option.identifier}
              value={selectedToken}
              onChange={(esdt) => {
                esdt && onTokenSelected(esdt);
              }}
            />

            <div>
              <button
                disabled={selectedToken === undefined || feeError !== undefined}
                onClick={onContinueClick}
                className='exrond-btn ml-0 ml-xl-3 mt-3 mt-xl-0'
              >
                <span className='exrond-text text-white font-size-1 font-weight-600'>
                  Continue
                </span>
              </button>
            </div>
          </div>
          {feeError && <div className='text-danger mt-2'>{feeError}</div>}
        </>
      )}
    </>
  );
};

export default SelectFaucetToken;
