import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { Spinner } from '@buidly/dapp-core/dist/components';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Modal } from 'react-bootstrap';
import { useAccountDetails } from 'hooks';
import { refetchAccountDetails } from 'hooks/useAccountDetails';
import AccountAddress from './AccountAddress';
import AccountTokens from './AccountTokens';

interface AccountModalProps {
  show: boolean;
  onHide: () => void;
}

const AccountModal = ({ show, onHide }: AccountModalProps) => {
  const { accountDetails } = useAccountDetails();

  const client = useApolloClient();

  const { pending, success } = useGetActiveTransactionsStatus();

  useEffect(() => {
    if (success) {
      refetchAccountDetails(client);
    }
  }, [success]);

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='align-items-center'>
        <Modal.Title>Account details</Modal.Title>
        <span className='btn btn-primary btn-xs' onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </Modal.Header>
      <Modal.Body>
        <>
          <AccountAddress />
          {pending ? (
            <div
              className='d-flex flex-row justify-content-center mt-4'
              style={{ width: '100%', height: '100px' }}
            >
              <Spinner />
            </div>
          ) : (
            <>{accountDetails && <AccountTokens account={accountDetails} />}</>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <button className='exrond-btn w-100' onClick={handleLogout}>
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Disconnect
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountModal;
