import { ApolloClient, useQuery } from '@apollo/client';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { CREATE_PAIR_TOKENS } from 'api/queries/admin';
import { EsdtToken } from 'api/types/pairs';

const useCreatePairTokens = (): EsdtToken[] => {
  const { address } = useGetAccountInfo();

  const { data } = useQuery(CREATE_PAIR_TOKENS, {
    variables: {
      address: address
    }
  });

  if (!data?.createPairTokens) {
    return [];
  }

  return data.createPairTokens.map(EsdtToken.fromResponse);
};

export const refetchCreatePairTokens = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [CREATE_PAIR_TOKENS]
  });
};

export { useCreatePairTokens };
