import { ApolloClient, useQuery } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { GET_TOKEN_GROUPS } from 'api/queries';
import { Group } from 'api/types/Group';

const useGroups = () => {
  const { address } = useGetAccount();

  const { data, loading } = useQuery(GET_TOKEN_GROUPS, {
    variables: {
      address: address
    }
  });

  return { groups: data?.groupTokens as Group[], loading };
};

export const refetchGroups = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [GET_TOKEN_GROUPS]
  });
};

export { useGroups };
