import React from 'react';
import { EsdtTokenSimplified } from '../api/tokens/esdtToken.model';
import { EsdtToken } from '../api/types/pairs';
import { TOKEN_PLACEHOLDER } from '../config';

export const formatOptionLabel = (option: EsdtTokenSimplified | EsdtToken) => {
  return (
    <div className='exrond-token-list-item'>
      <img
        src={option.icon ?? TOKEN_PLACEHOLDER}
        className='p-0 token-icon'
        style={{ width: '32px', height: '32px', marginRight: '20px' }}
      />
      <span className='home-text13'>{option.ticker}</span>
      <span className='home-text14'>({option.name})</span>
    </div>
  );
};
