import React from 'react';
import { ApolloClient, ApolloError, useQuery } from '@apollo/client';
import { GET_PAIRS } from 'api/queries/pairs';
import { Pair } from 'api/types/pairs';

export const POLLING_INTERVAL = 6000;

const useGetPairs = (): {
  data: Pair[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
} => {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    GET_PAIRS,
    {
      pollInterval: POLLING_INTERVAL
    }
  );

  return React.useMemo(() => {
    return {
      data: data?.pairs?.map(Pair.fromResponse),
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export const refetchPairs = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [GET_PAIRS]
  });
};

export { useGetPairs };
