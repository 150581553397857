import React, { useMemo } from 'react';
import { ConfirmableTokenStakingButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableTokenStakingButton';
import { NftTokenWrapper } from '@buidly/ioiom-staking-dapp/NftStaking/model/nftToken';
import UnboundCard from '@buidly/ioiom-staking-dapp/NftStaking/ui/NftToken/Components/UnboundCard';
import { TokenStakingButton } from '@buidly/ioiom-staking-dapp/TokenStaking/ui';
import {
  NftFarmDetails,
  NftTokenState
} from '@buidly/ioiom-staking-dapp/types';
import { ReactComponent as FiltersIcon } from 'assets/img/blue-chips/ic_filters.svg';
import colors from 'assets/sass/variables.module.scss';
import { useMediaQuery } from 'hooks';
import './BlueChipActionButtons.scss';

export interface BlueChipActionButtonsProps {
  nftFarmDetails: NftFarmDetails;
  selectedNfts: Map<string, NftTokenWrapper>;
  hasStakedNfts: boolean;
  hasClaimableNfts: boolean;
  farmExpired: boolean;
  pending: boolean;
  farmAddress: string;
  onUnselectAllClick: () => void;
  onUnboundAllClick: (payWithEgld: boolean) => void;
  onUnstakeAllClick: (payWithEgld: boolean) => void;
  onFiltersClick: () => void;
}

export const BlueChipActionButtons = ({
  nftFarmDetails,
  selectedNfts,
  hasStakedNfts,
  hasClaimableNfts,
  farmExpired,
  pending,
  farmAddress,
  onUnselectAllClick,
  onUnboundAllClick,
  onUnstakeAllClick,
  onFiltersClick
}: BlueChipActionButtonsProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const selectedState = useMemo(() => {
    if (selectedNfts.size === 0) {
      return undefined;
    }

    return Array.from(selectedNfts.values())[0].state;
  }, [selectedNfts]);

  const showUnboundAllButton =
    selectedNfts.size === 0 && !farmExpired && hasStakedNfts && isDesktop;
  const showUnstakeAllButton =
    selectedNfts.size === 0 && !farmExpired && hasClaimableNfts && isDesktop;

  const unselectButtonColor = useMemo(() => {
    switch (selectedState) {
      case NftTokenState.UNSTAKED:
        return '#6BEAFD';
      case NftTokenState.STAKED:
        return colors.colorWarning;
      case NftTokenState.CLAIMABLE:
        return colors.colorSuccess;
      default:
        return undefined;
    }
  }, [selectedState]);

  return (
    <div className='d-flex flex-row mr-0 mr-lg-3'>
      {nftFarmDetails.unboundPeriod > 0 && (
        <UnboundCard
          days={nftFarmDetails.unboundPeriod}
          className='secondary'
        />
      )}
      {selectedNfts.size > 0 && unselectButtonColor && isDesktop && (
        <TokenStakingButton
          className='ml-3 text-black'
          baseColor={unselectButtonColor}
          selectedColor={unselectButtonColor}
          buttonTitle='Unselect All'
          onClick={onUnselectAllClick}
          selected={false}
          loading={pending}
        />
      )}
      {showUnboundAllButton && (
        <ConfirmableTokenStakingButton
          baseColor={colors.colorWarning}
          selectedColor={colors.colorWarning}
          className='text-black ml-3'
          buttonTitle='Unbound All'
          onClick={(e: any) => onUnboundAllClick(e.payWithEgld)}
          selected={false}
          loading={pending}
          contractAddress={farmAddress}
          loaderClassName='token-staking-button w-100 w-lg-auto'
          style={{
            backgroundColor: colors.colorWarning
          }}
        />
      )}
      {showUnstakeAllButton && (
        <ConfirmableTokenStakingButton
          baseColor={colors.colorSuccess}
          selectedColor={colors.colorSuccess}
          className='text-black ml-3'
          buttonTitle='Unvest All'
          onClick={(e: any) => onUnstakeAllClick(e.payWithEgld)}
          selected={false}
          loading={pending}
          contractAddress={farmAddress}
          loaderClassName='token-staking-button w-100 w-lg-auto'
          style={{
            backgroundColor: colors.colorSuccess
          }}
        />
      )}
      {!isDesktop && (
        <button
          className='d-flex align-items-center justify-content-center bluechip-filter-button ml-3'
          onClick={onFiltersClick}
        >
          <FiltersIcon />
        </button>
      )}
    </div>
  );
};
