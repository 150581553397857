import React from 'react';
import '../ExrPage.scss';
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'assets/sass/variables.module.scss';
import { MintButtonContainer } from 'components/BlueChips/MintButtonContainer';
import { useMediaQuery } from 'hooks';

interface ExrPageHeaderDesktopProps {
  setVestingClicked: (value: boolean) => void;
  vestingClicked: boolean;
  setTokenomicsClicked: (value: boolean) => void;
  tokenomicsClicked: boolean;
  controlsShown: boolean;
  showControls: (show: boolean) => void;
  mintSuccessful: () => void;
}

const ExrPageHeaderDesktop = ({
  setVestingClicked,
  vestingClicked,
  setTokenomicsClicked,
  tokenomicsClicked,
  controlsShown,
  showControls,
  mintSuccessful
}: ExrPageHeaderDesktopProps) => {
  const displayText = useMediaQuery('(min-width: 1200px)');

  return (
    <>
      <div className='blue-chips-header'>
        <div className='blue-chips-header__right-top-star'></div>
        <div className='blue-chips-header__right-down-crystals'></div>
        <div className='blue-chips-header__center-stars'></div>
        <div className='blue-chips-header__left-tokens'></div>
        <div className='blue-chips-header__left-top-star'></div>
        <div className='blue-chips-header__left-down-crystals'></div>
        {displayText && (
          <>
            <p className='blue-chips-header__text-medium-1'>
              Say hello to Exrond&apos;s
            </p>
            <p className='blue-chips-header__text-big'>BLUE CHIPS</p>
            <p className='blue-chips-header__text-medium-2'>
              A new way for you to earn $EXR everyday!
            </p>
          </>
        )}
        <button
          className={`d-flex flex-row justify-content-center align-items-center blue-chips-header__bottom-buttons ${
            tokenomicsClicked && 'blue-chips-header__bottom-buttons--clicked'
          }`}
          onClick={() => {
            setTokenomicsClicked(!tokenomicsClicked);
            setVestingClicked(false);
          }}
        >
          $EXR Tokenomics
          {tokenomicsClicked ? (
            <FontAwesomeIcon
              icon={faXmark}
              width='6px'
              height='10px'
              color={colors.colorWhite}
              className='ml-3'
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronRight}
              width='6px'
              height='10px'
              color={colors.colorWhite}
              className='ml-3'
            />
          )}
        </button>
        <button
          className={`d-flex flex-row justify-content-center align-items-center blue-chips-header__bottom-buttons blue-chips-header__bottom-buttons__vesting ${
            vestingClicked && 'blue-chips-header__bottom-buttons--clicked'
          }`}
          onClick={() => {
            setVestingClicked(!vestingClicked);
            setTokenomicsClicked(false);
          }}
        >
          Liquid Vesting
          {vestingClicked ? (
            <FontAwesomeIcon
              icon={faXmark}
              width='6px'
              height='10px'
              color={colors.colorWhite}
              className='ml-3'
            />
          ) : (
            <FontAwesomeIcon
              icon={faChevronRight}
              width='6px'
              height='10px'
              color={colors.colorWhite}
              className='ml-3'
            />
          )}
        </button>
        <div className='blue-chips-header__mint-btn-container'>
          <MintButtonContainer
            controlsShown={controlsShown}
            showControls={showControls}
            showStickyButtons={false}
            mintSuccessful={mintSuccessful}
          />
        </div>
      </div>
    </>
  );
};

export default ExrPageHeaderDesktop;
