import React from 'react';
import BigNumber from 'bignumber.js';
import { AccountDetails } from 'api/types';
import { EGLD } from 'config';
import LockedTokenCollectionList from '../LockedTokenCollectionList';
import Token from '../Token';
import TokensList from '../TokensList';

interface AccountTokensProps {
  account: AccountDetails;
}

const AccountTokens = ({ account }: AccountTokensProps) => {
  return (
    <ul className='list-group mt-3'>
      <li className='list-group-item mb-2 list-group-item-bordered'>
        <Token
          icon={EGLD.icon}
          name={EGLD.name}
          balance={account.egldBalance ?? new BigNumber(0)}
          decimals={EGLD.decimals}
          ticker={EGLD.ticker}
        />
      </li>
      <TokensList
        title='ESDT Tokens'
        emptyViewTitle='No tokens'
        tokens={account.esdtTokens}
      />
      <LockedTokenCollectionList
        lockedTokens={account.lockedTokenCollections}
      />
      <TokensList
        title='Farm Tokens'
        emptyViewTitle='No farm tokens'
        tokens={account.farmTokens}
      />
    </ul>
  );
};

export default AccountTokens;
