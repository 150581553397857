import React from 'react';
import { FaucetStatus } from 'api/types/faucets';
import FaucetClaimAllView from './FaucetClaimAllView.tsx/FaucetClaimAllView';
import FaucetFilters from './FaucetFilters/FaucetFilters';

export interface FaucetHeaderProps {
  onFilterSelected: (status: FaucetStatus) => void;
  onClaimAllClicked: (payWithEgld: boolean | undefined) => void;
  totalClaimableUsd?: string;
  pending: boolean;
  selectedFilter: FaucetStatus;
}

const FaucetHeader = ({
  onFilterSelected,
  onClaimAllClicked,
  totalClaimableUsd,
  pending,
  selectedFilter
}: FaucetHeaderProps) => {
  return (
    <div className='d-flex flex-lg-row mb-3'>
      <FaucetFilters
        onFilterSelected={onFilterSelected}
        selectedFilter={selectedFilter}
      />
      <FaucetClaimAllView
        valueUsd={totalClaimableUsd ?? '0'}
        onClaimAllClicked={onClaimAllClicked}
        pending={pending}
        disabled={
          selectedFilter !== FaucetStatus.ALL &&
          selectedFilter !== FaucetStatus.CLAIMABLE
        }
      />
    </div>
  );
};

export default FaucetHeader;
