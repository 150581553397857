import * as React from 'react';
import { SwapContent } from './Components';
import './SwapV2.scss';

const SwapV2 = () => {
  return (
    <div className='d-flex flex-fill align-items-center swap-container'>
      <div className='d-flex w-100 align-self-end align-self-lg-center'>
        <div className='col-12'>
          <SwapContent />
        </div>
      </div>
    </div>
  );
};

export default SwapV2;
