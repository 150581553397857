import React, { useState } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { Navbar as BsNavbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AccountButton from 'components/AccountButton';
import AccountSettings from 'components/AccountSettings';
import ActionOrConnect from 'components/ActionOrConnect';
import { routeNames } from 'routes';
import { ReactComponent as ExrondLogoResponsive } from '../../../../assets/img/ex.svg';
import { ReactComponent as ExrondLogo } from '../../../../assets/img/exrond.svg';
import DesktopMainNavbar from './DesktopMainNavbar';
import DesktopStakeNavbar from './DesktopStakeNavbar';

const DesktopNavbar = () => {
  const { address } = useGetAccountInfo();

  const isLoggedIn = Boolean(address);

  const [stakeMenuShown, setStakeMenuShown] = useState(false);

  return (
    <BsNavbar className='navbar-wrapper'>
      <div className='d-flex flex-column w-100'>
        <div className='container-fluid'>
          <Link to={routeNames.home}>
            <ExrondLogo className='exrond-logo' />
            <ExrondLogoResponsive className='exrond-logo-mini' />
          </Link>
          {stakeMenuShown ? (
            <DesktopStakeNavbar
              onBackClicked={() => setStakeMenuShown(false)}
            />
          ) : (
            <DesktopMainNavbar onStakeClicked={() => setStakeMenuShown(true)} />
          )}
          <div className='connect-button-wrapper d-flex'>
            <ActionOrConnect>
              <AccountButton />
            </ActionOrConnect>
            {isLoggedIn && <AccountSettings />}
          </div>
        </div>
      </div>
    </BsNavbar>
  );
};

export default DesktopNavbar;
