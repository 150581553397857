import { gql } from '@apollo/client';

export const ONGOING_PAIR_SETUP = gql`
  query ongoingPairSetup($address: String!) {
    ongoingPairSetup(sender: $address) {
      pairAddress
      firstToken {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
      secondToken {
        identifier
        name
        decimals
        balance
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
      status
    }
  }
`;

export const CREATE_PAIR_TOKENS = gql`
  query createPairTokens($address: String!) {
    createPairTokens(address: $address) {
      identifier
      name
      decimals
      balance
      ticker
    }
  }
`;
