import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_FINISH_VESTING_TRANSACTION } from 'api/mutations';
import { useQueryParams } from 'hooks';

export interface Milestone {
  epoch?: string;
  percent?: string;
}

interface DeployVestingContractProps {
  processBatchTransactions: (transactions: Transaction[][]) => void;
}

const FinishVestingSetup = ({
  processBatchTransactions
}: DeployVestingContractProps) => {
  const { groupId } = useQueryParams();
  const { address } = useGetAccount();

  const [finishVestingTransaction, { data }] = useMutation(
    CREATE_FINISH_VESTING_TRANSACTION
  );

  const handleDeployVesting = () => {
    finishVestingTransaction({
      variables: {
        sender: address,
        groupId: groupId
      }
    });
  };

  useEffect(() => {
    if (data && data.finishVestingSetup) {
      const transactions = data.finishVestingSetup as Transaction[][];
      if (transactions.length === 0) {
        return;
      }

      processBatchTransactions(transactions);
    }
  }, [data]);

  return (
    <div className='d-flex container justify-content-center'>
      <div className='d-flex flex-column align-items-center'>
        <h5>Vesting contract</h5>
        <h6>Step 2/2: Finish vesting contract setup</h6>
        <div className='mt-3'>
          <button
            className='exrond-btn-light'
            onClick={() => handleDeployVesting()}
          >
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Finish
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinishVestingSetup;
