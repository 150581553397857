import BigNumber from 'bignumber.js';
import { LiquidityPositionsResponse } from './liquidity';

export const getPercentageFromInput = (
  inputValue: string,
  liquidity: LiquidityPositionsResponse
): { percentage: number; error: string } => {
  if (inputValue.length === 0) {
    return { percentage: 0, error: 'Invalid amount' };
  }

  const amountValueWithDecimals = new BigNumber(inputValue).shiftedBy(
    liquidity.lpToken.decimals ?? 0
  );

  if (!amountValueWithDecimals?.isPositive()) {
    return { percentage: 0, error: 'Invalid amount' };
  }

  if (
    amountValueWithDecimals?.isGreaterThan(
      liquidity.lpToken.balance ?? new BigNumber(0)
    )
  ) {
    return { percentage: 100, error: 'Insufficient funds' };
  }

  if (
    amountValueWithDecimals &&
    new BigNumber(liquidity.lpToken.balance ?? 0).isGreaterThan(
      new BigNumber(0)
    )
  ) {
    const percent = amountValueWithDecimals
      .multipliedBy(new BigNumber(100))
      .dividedBy(new BigNumber(liquidity.lpToken.balance ?? 0))
      .toNumber();
    return { percentage: percent, error: '' };
  }

  return { percentage: 0, error: '' };
};

export const calculateReceivedAmountsFromPercentage = (
  percent: number,
  liquidity: LiquidityPositionsResponse
): {
  _firstTokenReceived: BigNumber | undefined;
  _secondTokenReceived: BigNumber | undefined;
} => {
  const firstTokenValue = liquidity.firstTokenAmount ?? new BigNumber(0);
  const secondTokenValue = liquidity.secondTokenAmount ?? new BigNumber(0);

  const floatPercent = new BigNumber(percent / 100);

  const _firstTokenReceived = !firstTokenValue
    ? undefined
    : floatPercent.multipliedBy(firstTokenValue);

  const _secondTokenReceived = !secondTokenValue
    ? undefined
    : floatPercent.multipliedBy(secondTokenValue);

  return { _firstTokenReceived, _secondTokenReceived };
};
