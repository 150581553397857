import React from 'react';
import { ConfirmableButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableButton';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CREATE_UNLOCK_TOKEN_TRANSACTION } from 'api/mutations/UnlockTokenMutation';
import { LockedToken as LockedTokenEntity } from 'api/tokens/locked.token';
import { ReactComponent as Info } from 'assets/img/info.svg';
import { DECIMALS, TOKEN_PLACEHOLDER } from 'config';
import UnlockSchedule from '../UnlockSchedule';

interface LockedTokenProps {
  token: LockedTokenEntity;
  showUnlockButton: boolean;
}

const LockedToken = ({ token, showUnlockButton }: LockedTokenProps) => {
  const { address } = useGetAccount();
  let shouldDisplayUnlockButton = false;

  const unlockScheduleFormatted = token.unlockSchedule.map((milestone) => {
    if (milestone.unlockDate === 'Unlockable now') {
      shouldDisplayUnlockButton = true;
    }

    return { percent: milestone.percent, unlockAtDate: milestone.unlockDate };
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderTooltip = (unlockSchedule, props) => (
    <Tooltip {...props}>
      <UnlockSchedule unlockSchedule={unlockSchedule} />
    </Tooltip>
  );

  const [unlockTokenTransaction, { status }] = useGetAndSendTransactions(
    CREATE_UNLOCK_TOKEN_TRANSACTION
  );

  const handleUnlock = async (payWithEgld: boolean) => {
    const args = {
      identifier: token.collection,
      nonce: token.nonce ?? 0,
      amount: token.balance
    };

    unlockTokenTransaction({
      variables: {
        sender: address,
        token: args,
        payWithEgld
      }
    });
  };

  return (
    <div>
      <div className='d-flex flex-row align-items-center mt-2'>
        <div className='token-image p-0'>
          <img
            src={token.icon ?? TOKEN_PLACEHOLDER}
            className='token-icon p-0 mr-2'
            style={{ width: '35px', height: '35px' }}
          />
        </div>

        <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center w-100'>
          <div className='d-flex flex-row'>
            <span>{token.name}</span>
            <OverlayTrigger
              placement='top'
              overlay={(props) => renderTooltip(unlockScheduleFormatted, props)}
            >
              <Info
                className='mx-2'
                style={{ width: '20px', height: '20px' }}
              />
            </OverlayTrigger>

            {shouldDisplayUnlockButton && showUnlockButton && (
              <ConfirmableButton
                onClick={(event: any) => {
                  handleUnlock(event.payWithEgld);
                }}
                loaderClassName='exrond-btn-light btn-small unlock-loader'
                className='exrond-btn-light btn-small unlock-loader'
                disabled={status === 'pending'}
              >
                <span className='exrond-text text-white font-size-08 font-weight-300'>
                  Unlock
                </span>
              </ConfirmableButton>
            )}
          </div>
          <div className='d-flex flex-grow-1 justify-content-end ml-0 ml-md-3'>
            <FormatAmount
              value={token.balance.toFixed()}
              decimals={token.decimals}
              egldLabel={' '}
              digits={DECIMALS}
              showLastNonZeroDecimal={false}
              // addCommas={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedToken;
