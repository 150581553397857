import * as React from 'react';
import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import { EGLD } from 'config';
import { useAccountDetails } from 'hooks/useAccountDetails';

const useGetAvailableAmount = (token: EsdtToken | undefined): BigNumber => {
  const { accountDetails } = useAccountDetails();

  const availableAmount = React.useMemo(() => {
    if (!token) {
      return new BigNumber(0);
    }

    if (token.identifier === EGLD.identifier) {
      return new BigNumber(accountDetails?.egldBalance ?? 0);
    }

    const accountToken = accountDetails?.esdtTokens.find(
      (t) => t.identifier === token.identifier
    );

    if (!accountToken) {
      return new BigNumber(0);
    }

    const tokenBalance = accountToken.balance;
    // const tokenSupply = new BigNumber(token.supply ?? '0');
    const minimum = BigNumber.min(tokenBalance, tokenBalance);
    return minimum;
  }, [token, accountDetails]);

  return availableAmount;
};

export { useGetAvailableAmount };
