import { useContext, useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import { useAccountDetails } from 'hooks';
import { SwapContext } from '../Context';
import { getSwapTokenBalance } from '../helpers';
import { SwapCardType, SwapPair } from '../types';

const useGetCardToken = (
  cardType: SwapCardType
): {
  selectedToken: EsdtToken | undefined;
  amount: string;
  setSelectedToken: (newToken: EsdtToken) => void;
} => {
  const { currentPair, setCurrentPair, swapPairAmounts, setCardError } =
    useContext(SwapContext);

  const { accountDetails } = useAccountDetails();

  const amount = useMemo(() => {
    if (cardType === SwapCardType.SENDER) {
      return swapPairAmounts.amountIn;
    }

    return swapPairAmounts.amountOut;
  }, [swapPairAmounts.amountIn, swapPairAmounts.amountOut, cardType]);

  const selectedToken = useMemo(() => {
    if (!currentPair) {
      return undefined;
    }

    if (cardType === SwapCardType.SENDER) {
      return currentPair.tokenIn;
    }

    return currentPair.tokenOut;
  }, [currentPair]);

  const setSelectedToken = (newToken: EsdtToken) => {
    setCurrentPair((prev) => {
      const updatedPair: SwapPair = {
        ...prev,
        tokenIn: prev?.tokenIn,
        tokenOut: prev?.tokenOut
      };

      if (cardType === SwapCardType.SENDER) {
        updatedPair.tokenIn = newToken;
      } else {
        updatedPair.tokenOut = newToken;
      }

      return updatedPair;
    });
  };

  const balance = useMemo(() => {
    return getSwapTokenBalance(selectedToken, accountDetails).toFixed();
  }, [accountDetails, selectedToken]);

  const balanceError = useMemo((): boolean => {
    const currentValue = new BigNumber(amount.length > 0 ? amount : 0);
    const balanceBig = new BigNumber(balance).shiftedBy(
      -(selectedToken?.decimals ?? 0)
    );

    if (currentValue.isZero()) {
      return true;
    }

    if (cardType === SwapCardType.SENDER) {
      if (currentValue.isGreaterThan(balanceBig)) {
        return true;
      }
    }

    return false;
  }, [balance, amount, cardType]);

  useEffect(() => {
    setCardError((prev) => {
      return {
        sender: cardType === SwapCardType.SENDER ? balanceError : prev.sender,
        receiver:
          cardType === SwapCardType.RECEIVER ? balanceError : prev.receiver
      };
    });
  }, [balanceError, cardType]);

  return { selectedToken, amount, setSelectedToken };
};

export { useGetCardToken };
