import { useQuery } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { GET_ADMIN_SETTINGS } from 'api/queries/adminSettings';
import { AdminSettings } from 'api/types/AdminSettings';
import * as Constants from '../utils';

const useAdminSettings = (groupId: string) => {
  const { address } = useGetAccount();

  const noCache = localStorage.getItem(Constants.KEY_NO_CACHE(groupId));

  const { data, refetch, networkStatus, loading } = useQuery(
    GET_ADMIN_SETTINGS,
    {
      variables: {
        address: address ?? '',
        groupId: groupId,
        noCache: noCache != null
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        localStorage.removeItem(Constants.KEY_NO_CACHE(groupId));
      }
    }
  );

  return {
    adminSettings: data?.adminSettings as AdminSettings,
    loading,
    networkStatus,
    refetch
  };
};

export { useAdminSettings };
