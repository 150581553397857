import { gql } from '@apollo/client';

export const GET_PAIRS = gql`
  query GET_PAIRS_QUERY {
    pairs {
      address
      firstTokenPrice
      secondTokenPrice
      firstTokenReserve
      secondTokenReserve
      firstToken {
        identifier
        name
        decimals
        supply
        ticker
        balance
        assets {
          svgUrl
          pngUrl
        }
      }
      secondToken {
        identifier
        name
        decimals
        supply
        ticker
        balance
        assets {
          svgUrl
          pngUrl
        }
      }
    }
  }
`;
