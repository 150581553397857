import { gql } from '@apollo/client';

export const CREATE_DEPLOY_VESTING_TRANSACTION = gql`
  mutation deployVesting(
    $sender: String!
    $groupId: String!
    $unlockMilestones: [UnlockMilestoneArgs!]!
  ) {
    deployVesting(
      sender: $sender
      groupId: $groupId
      unlockMilestones: $unlockMilestones
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
