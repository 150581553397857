import React, { ReactElement, useContext } from 'react';
import { EsdtToken } from 'api/types/pairs';
import '../SwapSelect.scss';
import { SwapContext } from 'pages/SwapV2/Context';
import SwapTokenOption from '../SwapTokenOption/SwapTokenOption';
import SwapTokenOptionBalance from '../SwapTokenOption/SwapTokenOptionBalance';

interface SwapSelectListProps {
  header?: ReactElement;
  tokens: EsdtToken[];
  className?: string;
  markClassName?: string;
  zeroBalanceInfoElement?: ReactElement;
  hasBuyAmount?: boolean;
  handleItemSelected: (token: EsdtToken, buyAmount?: string) => void;
}

const SwapSelectList = ({
  header,
  tokens,
  className,
  markClassName,
  zeroBalanceInfoElement,
  hasBuyAmount = false,
  handleItemSelected
}: SwapSelectListProps) => {
  const { tokensWithBuyAmount } = useContext(SwapContext);

  const getBuyAmount = (token: EsdtToken): string | undefined => {
    if (!hasBuyAmount) {
      return undefined;
    }

    const amount = tokensWithBuyAmount.find(
      (tokenWithAmount) =>
        tokenWithAmount.token.identifier === token?.identifier
    )?.buyAmount;

    return amount;
  };

  return (
    <div className='position-relative'>
      {tokens.length > 0 && header}
      {tokens?.map((option, index) => (
        <div
          key={index}
          className='cursor-pointer divider'
          onClick={() => {
            handleItemSelected(option, getBuyAmount(option));
          }}
        >
          <SwapTokenOption
            token={option}
            className={className}
            markClassName={markClassName}
            info={
              zeroBalanceInfoElement ? (
                React.cloneElement(zeroBalanceInfoElement, {
                  token: option,
                  className
                })
              ) : (
                <SwapTokenOptionBalance token={option} className={className} />
              )
            }
          />
        </div>
      ))}
    </div>
  );
};

export default SwapSelectList;
