import { gql } from '@apollo/client';

export const GET_ADMIN_SETTINGS = gql`
  query getAdminSettings(
    $address: String!
    $groupId: String!
    $noCache: Boolean!
  ) {
    adminSettings(address: $address, groupId: $groupId, noCache: $noCache) {
      shouldAllowVestingRegister
      groupId
      groupToken {
        identifier
        balance
        decimals
        supply
        assets {
          svgUrl
          pngUrl
        }
      }
      vestingContract {
        address
        state
        settings {
          assetTokenId
          lockedAssetTokenId
          initEpoch
          defaultUnlockPeriod {
            epoch
            percent
          }
          state
        }
      }
      stakingContracts {
        address
        state
        vestingScAddress
        farmingTokenLocked
        rewardTokenId
        rewardTokenDecimals
        rewardTokenLocked
        rewardsLeft
        apr
        maxApr
        totalValueStaked
      }
      customRewardTokenIds {
        identifier
        name
        balance
        decimals
        ticker
      }
    }
  }
`;
