import * as React from 'react';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import {
  useGetAccount,
  useGetActiveTransactionsStatus
} from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Collapse, Spinner } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { UNBOND_LIQUIDITY } from 'api/mutations/UnbondLiquidity';
import { UNLOCK_LIQUIDITY } from 'api/mutations/UnlockLiquidity';
import { ReactComponent as ExpandLess } from 'assets/img/expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/img/expand_more.svg';
import './MyLiquidityItem.scss';
import { ReactComponent as LockIcon } from 'assets/img/ic_lock.svg';
import { TOKEN_PLACEHOLDER } from 'config';
import { LiquidityPositionsResponse } from '../liquidity';
import MyLiquidityRemoveModal from '../MyLiquidityRemoveModal';
interface MyLiquidityItemProps {
  index: number;
  liquidity: LiquidityPositionsResponse;
  currentEpoch: number;
}
const MyLiquidityItem = ({
  index,
  liquidity,
  currentEpoch
}: MyLiquidityItemProps) => {
  const [expandedItem, setExpandedItem] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const { address } = useGetAccount();
  const [
    unbondLiquidityTransaction,
    { status: statusUnbond, error: errorUnbond }
  ] = useGetAndSendTransactions(UNBOND_LIQUIDITY);
  const [
    unlockLiquidityTransaction,
    { status: statusUnlock, error: errorUnlock }
  ] = useGetAndSendTransactions(UNLOCK_LIQUIDITY);

  const { pending } = useGetActiveTransactionsStatus();

  const getTokenAmount = (
    token: { decimals: number; balance: string },
    amount: string | undefined = undefined
  ) => {
    return new BigNumber(amount === undefined ? token.balance : amount)
      .shiftedBy(-token.decimals)
      .toFixed(4);
  };

  const isItemInitialLocked = liquidity.lockedLiquidity !== null;

  const unbondLiquidity = () => {
    unbondLiquidityTransaction({
      variables: {
        sender: address,
        tokenID: liquidity.lpToken.identifier
      }
    });
  };

  const unlockLiquidity = () => {
    unlockLiquidityTransaction({
      variables: {
        sender: address,
        tokenID: liquidity.lpToken.identifier
      }
    });
  };

  const handleOnClick = () => {
    isItemInitialLocked
      ? liquidity.lockedLiquidity?.isUnlocked
        ? liquidity.lockedLiquidity.unlockEpoch <= currentEpoch
          ? unbondLiquidity()
          : void 0
        : unlockLiquidity()
      : setShowModal(true);
  };

  const buttonLabel = isItemInitialLocked
    ? liquidity.lockedLiquidity?.isUnlocked
      ? 'Unbond'
      : 'Unlock'
    : 'Remove';

  const buttonEnabled =
    isItemInitialLocked &&
    liquidity.lockedLiquidity &&
    liquidity.lockedLiquidity.unlockEpoch <= currentEpoch;

  const remainingEpochs = liquidity.lockedLiquidity?.unlockEpoch
    ? liquidity.lockedLiquidity?.unlockEpoch - currentEpoch
    : 0;

  const isTransactionLoading = React.useMemo(() => {
    if (statusUnbond === 'pending' || statusUnlock === 'pending') {
      return true;
    }
    return pending;
  }, [statusUnbond, statusUnlock, pending]);

  return (
    <>
      <div
        className={
          'card my-liquidity-item ' + `${expandedItem ? 'active' : ''}`
        }
      >
        <div className='card-header d-flex align-items-center justify-content-between rounded'>
          <div className='d-flex align-items-center'>
            <div className='mr-3'>
              <div className='exrond-pool-icon icon-lg'>
                <img
                  className='exrond-token-symbol'
                  src={
                    liquidity.pair.secondToken.assets?.pngUrl ??
                    liquidity.pair.secondToken.assets?.svgUrl ??
                    TOKEN_PLACEHOLDER
                  }
                ></img>
                <div className='exrond-token-symbol'>
                  <img
                    src={
                      liquidity.pair.firstToken.assets?.pngUrl ??
                      liquidity.pair.firstToken.assets?.svgUrl ??
                      TOKEN_PLACEHOLDER
                    }
                    className='exrond-token-symbol'
                  ></img>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <span className='exrond-text text-white font-weight-800 font-size-1'>
                  {liquidity.pair.secondToken.ticker} -{' '}
                  {liquidity.pair.firstToken.ticker}
                </span>
                {isItemInitialLocked && (
                  <LockIcon className='ml-2 lock-icon__success' />
                )}
              </div>
              <span className='exrond-text text-white font-weight-600 font-size-1'>
                {getTokenAmount(liquidity.lpToken)} LP
              </span>
            </div>
          </div>
          <div
            className='d-flex align-items-center'
            onClick={() => setExpandedItem(!expandedItem)}
          >
            {expandedItem ? (
              <ExpandLess className='c-pointer' />
            ) : (
              <ExpandMore className='c-pointer' />
            )}
          </div>
        </div>
        <Collapse in={expandedItem}>
          <div id='liquidity-item-expanded'>
            <div className='card-body py-4'>
              <div className='d-flex flex-fill align-items-center justify-content-between mb-2'>
                <div className='mr-2'>
                  <img
                    src={
                      liquidity.pair.secondToken.assets?.pngUrl ??
                      liquidity.pair.secondToken.assets?.svgUrl ??
                      TOKEN_PLACEHOLDER
                    }
                    className='exrond-token-symbol'
                  ></img>
                </div>
                <div className='d-flex flex-fill flex-wrap justify-content-between'>
                  <span className='pr-2 exrond-text text-white font-size-1 font-weight-500'>
                    Current {liquidity.pair.secondToken.ticker}
                  </span>
                  <span className='exrond-text text-white font-size-1 font-weight-600'>
                    {getTokenAmount(
                      liquidity.pair.secondToken,
                      liquidity.secondTokenAmount
                    )}
                  </span>
                </div>
              </div>
              <div className='d-flex flex-fill align-items-center justify-content-between mb-2'>
                <div className='d-flex flex-fill flex-wrap justify-content-between'>
                  <span className='pr-2 exrond-text text-white font-size-1 font-weight-500'>
                    <img
                      src={
                        liquidity.pair.firstToken.assets?.pngUrl ??
                        liquidity.pair.firstToken.assets?.svgUrl ??
                        TOKEN_PLACEHOLDER
                      }
                      className='exrond-token-symbol mr-2'
                    ></img>
                    Current {liquidity.pair.firstToken.ticker}
                  </span>
                  <span className='exrond-text text-white font-size-1 font-weight-600'>
                    {getTokenAmount(
                      liquidity.pair.firstToken,
                      liquidity.firstTokenAmount
                    )}
                  </span>
                </div>
              </div>
              <div className='d-flex flex-fill align-items-center justify-content-between mb-2'>
                <div className='mr-2'>
                  <div className='token-symbol'></div>
                </div>
              </div>
            </div>
            <div className='card-footer pt-3 pb-2'>
              <div className='mb-3'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center flex-fill'>
                    <div className='mr-2 mt-2'>
                      <img
                        className='exrond-token-symbol'
                        src={
                          liquidity.lpToken.assets?.pngUrl ??
                          liquidity.lpToken.assets?.svgUrl ??
                          TOKEN_PLACEHOLDER
                        }
                      ></img>
                    </div>
                    <div className='d-flex flex-fill justify-content-between flex-wrap'>
                      <div className='d-flex align-items-center mt-2'>
                        <div className='d-flex flex-wrap align-items-center'>
                          <span className='mr-2 exrond-text text-white font-size-1 font-weight-600'>
                            {getTokenAmount(liquidity.lpToken)}
                          </span>
                          <div className='mr-1 text-white font-size-1 font-weight-600'>
                            {liquidity.lpToken.ticker}
                          </div>
                        </div>
                      </div>
                      <div className='mt-2' id={`liquidity-button_${index}`}>
                        <button
                          className='btn btn-primary btn-remove'
                          disabled={
                            isItemInitialLocked
                              ? !buttonEnabled
                              : isTransactionLoading
                              ? true
                              : false
                          }
                          onClick={() => handleOnClick()}
                        >
                          {isTransactionLoading ? <Spinner /> : buttonLabel}
                        </button>
                      </div>
                      {isItemInitialLocked && !buttonEnabled && (
                        <Tooltip
                          anchorSelect={`#liquidity-button_${index}`}
                          content={`Liquidity can be unlocked in ${remainingEpochs} epoch${
                            remainingEpochs > 1 ? 's' : ''
                          }.`}
                        />
                      )}
                    </div>
                    {(errorUnbond || errorUnlock) && (
                      <div className='text-danger mt-4'>
                        {errorUnbond
                          ? errorUnbond
                          : errorUnlock
                          ? errorUnlock
                          : 'An error occurred'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <MyLiquidityRemoveModal
        show={showModal}
        onHide={() => setShowModal(false)}
        liquidity={liquidity}
      />
    </>
  );
};

export default MyLiquidityItem;
