import { gql } from '@apollo/client';

export const CREATE_STAKE_TRANSACTION = gql`
  mutation stake(
    $sender: String!
    $groupId: String!
    $stakingArgs: StakingArgsModel!
  ) {
    transaction: stake(
      sender: $sender
      groupId: $groupId
      stakingArgs: $stakingArgs
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
