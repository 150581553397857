import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_FAUCET_SETTINGS } from 'api/queries/createFaucetSettings';
import { FaucetSettings } from 'api/types/FaucetSettings';
import { FAUCET_CLIENT_NAME } from 'apollo-client';

const useGetCreateFaucetSettings = () => {
  const { address } = useGetAccount();

  const { data, loading, error } = useQuery(CREATE_FAUCET_SETTINGS, {
    variables: {
      sender: address
    },
    context: {
      clientName: FAUCET_CLIENT_NAME
    }
  });

  return useMemo(() => {
    const faucetSettings =
      data && data.createFaucetSettings
        ? FaucetSettings.fromResponse(data.createFaucetSettings)
        : undefined;

    return { faucetSettings, loading, error };
  }, [data, loading, error]);
};

export { useGetCreateFaucetSettings };
