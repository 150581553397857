import React, { useEffect, useState } from 'react';
import { Transaction } from '@multiversx/sdk-core/out';
import {
  FarmState,
  StakingContract,
  VestingContract,
  VestingState
} from 'api/types/AdminSettings';
import Loader from 'components/Loader';
import { useQueryParams } from 'hooks';
import DeployChildContract from '../RegisterStaking/01_DeployChildContract/DeployChildContract';
import SetupFarmRewards from '../RegisterStaking/02_SetupFarmRewards/SetupFarmRewards';
import DeployVestingContract from '../RegisterVesting/01_DeployVestingContract/DeployVestingContract';
import FinishVestingSetup from '../RegisterVesting/02_FinishVestingSetup/FinishVestingSetup';

interface RegisterContractProps {
  stakingContract: StakingContract;
  vestingContract?: VestingContract;
  transactionStatus: any;
  refetchingData: boolean;
  contractRegistered: () => void;
  processBatchTransactions: (transactions: Transaction[][]) => void;
  rewardTokenId: string;
}

enum RegisterVestingStep {
  UnlockMilestoneStep = 'Unlock milestone setup',
  FinishVestingSetupStep = 'Finish vesting contract setup'
}

enum RegisterStakingStep {
  DeployChildContractStep = 'Deploy child contract',
  SetupFarmRewardsStep = 'Setup farm rewards'
}

const RegisterContract = ({
  stakingContract,
  vestingContract,
  transactionStatus,
  refetchingData,
  contractRegistered,
  processBatchTransactions,
  rewardTokenId
}: RegisterContractProps) => {
  const { farmingTokenLocked, rewardTokenLocked } = useQueryParams();

  const [step, setStep] = useState<RegisterStakingStep | RegisterVestingStep>();

  const loading =
    (transactionStatus === 'pending' &&
      farmingTokenLocked === stakingContract.farmingTokenLocked.toString() &&
      rewardTokenLocked === stakingContract.rewardTokenLocked.toString()) ||
    refetchingData;

  useEffect(() => {
    if (checkVestingRequired()) {
      return;
    }

    switch (stakingContract?.state) {
      case FarmState.NONE: {
        setStep(RegisterStakingStep.DeployChildContractStep);
        break;
      }
      case FarmState.SETUP_COMPLETE: {
        contractRegistered();
        break;
      }
      default: {
        setStep(RegisterStakingStep.SetupFarmRewardsStep);
      }
    }
  }, [stakingContract, vestingContract]);

  const checkVestingRequired = (): boolean => {
    const vestingRequired =
      stakingContract.farmingTokenLocked || stakingContract.rewardTokenLocked;

    if (!vestingRequired) {
      return false;
    }

    if (!vestingContract) {
      setStep(RegisterVestingStep.UnlockMilestoneStep);
      return true;
    }

    if (vestingContract.state !== VestingState.SETUP_COMPLETE) {
      switch (vestingContract?.state) {
        case VestingState.NONE: {
          setStep(RegisterVestingStep.UnlockMilestoneStep);
          break;
        }
        default: {
          setStep(RegisterVestingStep.FinishVestingSetupStep);
        }
      }
      return true;
    }

    return false;
  };

  const renderStep = (
    registerContractStep: RegisterStakingStep | RegisterVestingStep | undefined
  ) => {
    switch (registerContractStep) {
      case RegisterStakingStep.DeployChildContractStep:
        return (
          <DeployChildContract
            stakingContract={stakingContract}
            processBatchTransactions={processBatchTransactions}
            rewardTokenId={rewardTokenId}
          />
        );
      case RegisterStakingStep.SetupFarmRewardsStep:
        return (
          <SetupFarmRewards
            stakingContract={stakingContract}
            processBatchTransactions={processBatchTransactions}
          />
        );
      case RegisterVestingStep.UnlockMilestoneStep:
        return (
          <DeployVestingContract
            processBatchTransactions={processBatchTransactions}
          />
        );
      case RegisterVestingStep.FinishVestingSetupStep:
        return (
          <FinishVestingSetup
            processBatchTransactions={processBatchTransactions}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <div className='card swap-form stake-footer px-5'>
        <div className='card-body'>
          {loading ? (
            <div className='text-center'>
              <Loader />
            </div>
          ) : (
            renderStep(step)
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterContract;
