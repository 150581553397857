import React, { useEffect, useMemo } from 'react';
import { ConfirmableTokenStakingButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableTokenStakingButton';
import { computeGroupProgress } from '@buidly/ioiom-staking-dapp/helpers';
import { getNftsByAction } from '@buidly/ioiom-staking-dapp/NftStaking/helpers/nftTokens.helpers';
import { useGetNftTokens } from '@buidly/ioiom-staking-dapp/NftStaking/hooks';
import {
  NftAction,
  NftActionDetailed,
  NftFarm,
  convertNftTokenStateToNftAction
} from '@buidly/ioiom-staking-dapp/types';
import { countDecimalPlaces } from '@buidly/ioiom-staking-dapp/utils/utils';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { NftTokenWrapper } from 'api/tokens/nftToken';
import { ReactComponent as LiquidIcon } from 'assets/img/blue-chips/ic_liquid.svg';
import colors from 'assets/sass/variables.module.scss';
import { DECIMALS } from 'config';
import { useMediaQuery } from 'hooks';
import { formatTicker } from 'pages/Faucet/FaucetCard/utils';
import {
  getBlueChipButtonColorByAction,
  getBlueChipButtonTitleByAction
} from '../../helpers';
import { calculateTotalReward } from '../../helpers/calculateTotalReward';
import { getDefaultBlueChipAction } from '../../helpers/getDefaultBlueChipAction';
import { useGetTokenValue } from '../../hooks';
import '../LiquidVestingCard/LiquidVestingCard.scss';

interface LiquidVestingExrRewardsProps {
  farm: NftFarm;
  nftTokensExpanded: boolean;
  pending: boolean;
  nftAction: NftActionDetailed;
  farmEnding: boolean;
  mintSuccessful: number | null;
  handleFarmAction: (
    selectedAction: NftAction,
    selectedNfts: NftTokenWrapper[],
    payWithEgld: boolean
  ) => void;
  handleOnClaimAll: (payWithEgld: boolean) => void;
  handleOnMint: (payWithEgld: boolean) => void;
}

export const LiquidVestingExrRewards = ({
  farm,
  nftTokensExpanded,
  pending,
  nftAction,
  farmEnding,
  mintSuccessful,
  handleFarmAction,
  handleOnClaimAll,
  handleOnMint
}: LiquidVestingExrRewardsProps) => {
  const {
    data,
    loading: nftsLoading,
    refetch: refetchNftTokens
  } = useGetNftTokens(farm);
  const isDesktop = useMediaQuery('(min-width: 992px)');

  useEffect(() => {
    if (mintSuccessful == null) {
      return;
    }

    refetchNftTokens();
  }, [mintSuccessful]);

  const hasBlueChips = useMemo(() => {
    if (data === undefined) {
      return false;
    }

    return data.unstaked.length > 0;
  }, [data]);

  const earned = useMemo(() => {
    return new BigNumber(farm.myEarnedAmount);
  }, [farm]);

  const totalReward = useMemo(() => {
    return calculateTotalReward(
      farm.expiryDate,
      farm.dailyReward,
      data?.staked?.length ?? 0
    );
  }, [farm, data]);

  const tokenValue = useGetTokenValue(
    farm.rewardToken.identifier,
    totalReward.toFixed()
  );

  const farmExpired = useMemo(() => {
    return computeGroupProgress(farm.expiryDate, farm.creationDate).expired;
  }, [farm]);

  const selectedNftsAction: NftAction = useMemo(() => {
    const action =
      nftAction.nfts.length > 0
        ? convertNftTokenStateToNftAction(nftAction.nfts[0].state)
        : getDefaultBlueChipAction(farmExpired, data, isDesktop);

    return action ?? getDefaultBlueChipAction(farmExpired, data, isDesktop);
  }, [nftAction, farmExpired, data]);

  const actionButtonColor = useMemo(() => {
    return getBlueChipButtonColorByAction(
      !isDesktop ? selectedNftsAction : nftAction.action,
      farmExpired,
      farmEnding
    );
  }, [nftAction, selectedNftsAction, farmEnding, farmExpired]);

  const actionButtonTitle = useMemo(() => {
    if (!isDesktop) {
      switch (selectedNftsAction) {
        case NftAction.UNBOUND:
          return 'Unbound All';
        case NftAction.UNSTAKE:
          return 'Unvest All';
        default:
          return hasBlueChips ? 'Vest All' : 'Mint';
      }
    }
    return getBlueChipButtonTitleByAction(
      nftAction.action,
      nftAction.nfts.length,
      nftTokensExpanded,
      hasBlueChips
    );
  }, [nftAction, nftTokensExpanded, selectedNftsAction, hasBlueChips]);

  const handleFarmActionClick = (payWithEgld: boolean) => {
    if (nftAction.action === NftAction.STAKE && !hasBlueChips) {
      handleOnMint(payWithEgld);
      return;
    }

    if (!isDesktop) {
      // There is only stake all, unstake all and unbound all in this case
      handleFarmAction(
        selectedNftsAction,
        getNftsByAction(selectedNftsAction, data),
        payWithEgld
      );
      return;
    }

    // If there is no nfts selected, then the user want to stake all, unstake all or unbound all
    const nfts =
      nftAction.nfts.length > 0
        ? nftAction.nfts
        : getNftsByAction(nftAction.action, data);

    handleFarmAction(nftAction.action, nfts, payWithEgld);
  };

  return (
    <div className='liquid-vesting__claim-container'>
      <div className='liquid-vesting__liquid-container'>
        <div className='liquid-vesting__total-claim'>
          <span className='liquid-vesting__total-claim__exr'>
            <FormatAmount
              value={totalReward.toFixed(0)}
              decimals={farm.rewardToken.decimals}
              egldLabel={formatTicker(farm.rewardToken.identifier)}
              digits={countDecimalPlaces(
                totalReward.toFixed(0),
                farm.rewardToken.decimals,
                DECIMALS
              )}
              showLastNonZeroDecimal={false}
            />
          </span>
          <span className='liquid-vesting__total-claim__egld'>
            ={' '}
            <FormatAmount
              value={tokenValue?.toFixed(0) ?? '0'}
              decimals={18}
              egldLabel={'EGLD'}
              digits={countDecimalPlaces(
                tokenValue?.toFixed(0) ?? '0',
                farm.rewardToken.decimals,
                DECIMALS
              )}
              showLastNonZeroDecimal={false}
            />
          </span>
        </div>
        <div className='liquid-vesting__connecting-line'>
          <LiquidIcon className='liquid-vesting__liquid' />
          <div className='liquid-vesting__dotted-line' />
        </div>
        <div>
          <ConfirmableTokenStakingButton
            title='Claim'
            amount={earned.toFixed()}
            decimals={farm.rewardToken.decimals}
            buttonTitle='Claim'
            baseColor={'#141E2F'}
            selectedColor={farmExpired ? colors.colorError : '#6BEAFD'}
            className='liquid-vesting__button liquid-vesting__claimable'
            onClick={(e: any) => handleOnClaimAll(e.payWithEgld ?? false)}
            selected={nftAction.action === NftAction.CLAIM}
            loading={pending}
            disabled={
              earned.isZero() || (earned.isZero() && farmExpired) || pending
            }
            contractAddress={farm.farmAddress}
            loaderClassName='token-staking-button liquid-vesting__button'
            style={{
              backgroundColor: farmExpired ? colors.colorError : '#141E2F'
            }}
          />
        </div>
      </div>
      <div className='mr-2 liquid-vesting__vest-btn-container'>
        <ConfirmableTokenStakingButton
          baseColor={'#141E2F'}
          selectedColor={
            farmExpired && nftAction.action === NftAction.STAKE
              ? colors.colorGray
              : actionButtonColor
          }
          className='liquid-vesting__button'
          buttonTitle={actionButtonTitle}
          onClick={(e: any) => handleFarmActionClick(e.payWithEgld ?? false)}
          selected={false}
          loading={pending || nftsLoading}
          disabled={
            (farmExpired && nftAction.action === NftAction.STAKE) ||
            pending ||
            nftsLoading
          }
          contractAddress={farm.farmAddress}
          loaderClassName='token-staking-button liquid-vesting__button'
          style={{
            backgroundColor:
              farmExpired && nftAction.action === NftAction.STAKE
                ? colors.colorGray
                : actionButtonColor
          }}
          skip={
            (!hasBlueChips && nftAction.action === NftAction.STAKE) ||
            !nftTokensExpanded
          }
        />
      </div>
      <FontAwesomeIcon
        className={`liquid-vesting__chevron ${
          nftTokensExpanded ? 'liquid-vesting__chevron--expanded' : ''
        }`}
        icon={faChevronUp}
      />
    </div>
  );
};
