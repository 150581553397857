import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import {
  API_FAUCET_GRAPHQL,
  API_GRAPHQL,
  API_NFT_STAKING_GRAPHQL
} from 'config';

export const FAUCET_CLIENT_NAME = 'faucet';
export const NFT_STAKING_CLIENT_NAME = 'nftStaking';

const apiLink = new HttpLink({
  uri: API_GRAPHQL
});

const apiFaucet = new HttpLink({
  uri: API_FAUCET_GRAPHQL
});

const apiNftStaking = new HttpLink({
  uri: API_NFT_STAKING_GRAPHQL
});

const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === FAUCET_CLIENT_NAME,
    apiFaucet,
    ApolloLink.split(
      (operation) =>
        operation.getContext().clientName === NFT_STAKING_CLIENT_NAME,
      apiNftStaking,
      apiLink
    )
  ),
  cache: new InMemoryCache()
});

export default client;
