import React, { useEffect, useState } from 'react';
import {
  useGetNftFarms,
  usePerformNftTransaction
} from '@buidly/ioiom-staking-dapp/NftStaking/hooks';
import { NftStakingCard } from '@buidly/ioiom-staking-dapp/NftStaking/ui/NftStakingCard/NftStakingCard';
import { MobileConnectButton } from '@buidly/ioiom-staking-dapp/TokenStaking/ui';
import {
  GenericFarmFilterArgs,
  NftFarm
} from '@buidly/ioiom-staking-dapp/types';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Loader, PageState } from '@multiversx/sdk-dapp/UI';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';

interface NftStakingPageContentProps {
  filterArgs?: GenericFarmFilterArgs;
  setNftDisplay?: (state: boolean) => void;
}

const NftStakingPageContent = ({
  filterArgs,
  setNftDisplay
}: NftStakingPageContentProps) => {
  const { data, loading, error, refetch } = useGetNftFarms(filterArgs);

  const [selectedNftFarm, setSelectedNftFarm] = useState<NftFarm | undefined>();

  const { performTransaction, success, pending } = usePerformNftTransaction();

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  if (loading) {
    return (
      <div className='screen-centered'>
        <Loader noText />
      </div>
    );
  }

  if (error) {
    return (
      <PageState
        icon={faX}
        className='text-muted'
        title='An error has occurred. Please try again later!'
      />
    );
  }

  if (data?.length === 0) {
    return <PageState className='text-muted' title='There are no farms yet.' />;
  }

  if (selectedNftFarm === undefined) {
    return (
      <div className='d-flex flex-column'>
        {data.map((farm) => (
          <NftStakingCard
            farm={farm}
            onDetailsClick={() => {
              if (!getIsLoggedIn()) {
                return;
              }

              setSelectedNftFarm(farm);

              setNftDisplay?.(true);
            }}
            key={`${farm.groupId}${farm.rewardToken.identifier}`}
            onActionClick={performTransaction}
            success={success}
            pending={pending}
          />
        ))}
        <MobileConnectButton />
      </div>
    );
  }

  return (
    <NftStakingCard
      farm={selectedNftFarm}
      expanded={true}
      onCancelDetailsClick={() => {
        setSelectedNftFarm(undefined);
        {
          setNftDisplay && setNftDisplay(false);
        }
      }}
      onActionClick={performTransaction}
      success={success}
      pending={pending}
    />
  );
};

export default NftStakingPageContent;
