import React from 'react';
import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import { formatTicker, toAbbreviatedNumber } from '../utils';

export interface FaucetRewardProps {
  reward: string;
  token: EsdtToken;
  valueUsd: string;
  className?: string;
}

const FaucetReward = ({
  reward,
  token,
  valueUsd,
  className = 'd-flex'
}: FaucetRewardProps) => {
  const value = new BigNumber(reward).shiftedBy(-token.decimals).toNumber();
  const formattedValueUsd = new BigNumber(valueUsd).decimalPlaces(2).toFixed();

  return (
    <div
      className={`header-item mx-1 ${className} flex-column justify-content-center mb-2 mb-lg-0 flex-shrink-1`}
    >
      <span className='reward-card-title'>Reward</span>
      <div className='d-flex flex-row align-items-center text-right text-lg-center'>
        <span className='reward-card-subtitle'>
          {`${toAbbreviatedNumber(value)} ${formatTicker(token.ticker)}`}
        </span>
        <span
          className='reward-card-subtitle d-block d-lg-none text-right text-nowrap ml-1'
          style={{ color: colors.colorGray }}
        >
          / $ {formattedValueUsd}
        </span>
      </div>
    </div>
  );
};

export default FaucetReward;
