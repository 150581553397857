import { useContext, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { slippageVar } from 'cache';
import { SwapContext } from '../Context';
import { SwapCardType, SwapPair } from '../types';

const useGetSwapAmounts = (
  cardType: SwapCardType
): {
  updateAmount: (newAmount: string) => void;
} => {
  const slippage = useReactiveVar(slippageVar);

  const { currentPair, swapPairAmounts, setSwapPairAmounts, debouncedSwap } =
    useContext(SwapContext);

  useEffect(() => {
    // Run only if we programatically filled the input for the sender and the amountOut is empty
    // or if we filled the input for the receiver and the amountIn is empty
    if (
      (cardType === SwapCardType.SENDER && swapPairAmounts.amountOut === '') ||
      (cardType === SwapCardType.RECEIVER && swapPairAmounts.amountIn === '')
    ) {
      // Get the amount that we put programatically
      const newAmount =
        cardType === SwapCardType.SENDER
          ? swapPairAmounts.amountIn
          : swapPairAmounts.amountOut;

      runDebouncedSwap(newAmount, currentPair);
    }
  }, [swapPairAmounts, currentPair]);

  const updateAmount = (newAmount: string) => {
    setSwapPairAmounts((prev) => {
      return {
        amountIn: cardType === SwapCardType.SENDER ? newAmount : prev.amountIn,
        amountOut:
          cardType === SwapCardType.RECEIVER ? newAmount : prev.amountOut
      };
    });

    runDebouncedSwap(newAmount, currentPair);
  };

  const runDebouncedSwap = (newAmount: string, pair: SwapPair | undefined) => {
    if (newAmount.length === 0) {
      return;
    }

    const amountInBig =
      cardType === SwapCardType.SENDER
        ? new BigNumber(newAmount)
            .shiftedBy(pair?.tokenIn?.decimals ?? 0)
            .toFixed()
        : undefined;

    const amountOutBig =
      cardType === SwapCardType.RECEIVER
        ? new BigNumber(newAmount)
            .shiftedBy(pair?.tokenOut?.decimals ?? 0)
            .toFixed()
        : undefined;

    debouncedSwap({
      tokenInID: pair?.tokenIn?.identifier,
      amountIn: amountInBig,
      tokenOutID: pair?.tokenOut?.identifier,
      amountOut: amountOutBig,
      tolerance: slippage
    });
  };

  return { updateAmount };
};

export default useGetSwapAmounts;
