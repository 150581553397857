import { gql } from '@apollo/client';

export const CREATE_WRAP_TRANSACTION = gql`
  mutation wrapEgld($sender: String!, $value: String!) {
    wrapEgld(sender: $sender, value: $value) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
