import BigNumber from 'bignumber.js';
import { EsdtToken, Pair, SwapTokens } from 'api/types/pairs';
import { SwapCardType } from 'components/SwapCard/types';
import { DECIMALS, EGLD, WEGLD } from 'config';

export const computeNewSwapPair = (
  token: EsdtToken,
  direction: 'in' | 'out',
  type: SwapCardType,
  currentPair: SwapTokens | undefined
) => {
  const newPair = { ...currentPair };

  if (type === SwapCardType.SENDER) {
    if (direction === 'out') {
      newPair.secondToken = token;
    } else {
      newPair.firstToken = token;
    }
  } else {
    if (direction === 'in') {
      newPair.secondToken = token;
    } else {
      newPair.firstToken = token;
    }
  }

  return newPair;
};

export const computeNewPair = (
  tokenIdentifier: string,
  direction: 'in' | 'out',
  type: SwapCardType,
  pairs: Pair[] | undefined,
  currentPair: Pair | undefined
) => {
  if (!pairs) {
    return;
  }

  if (
    (tokenIdentifier === EGLD.identifier ||
      tokenIdentifier === WEGLD.identifier) &&
    currentPair !== undefined
  ) {
    return cloneAndSwapFirstToken(tokenIdentifier, currentPair);
  }

  let newPair: Pair | undefined;
  if (type === SwapCardType.SENDER) {
    if (direction === 'out') {
      newPair = pairs.find((p) => p.secondToken.identifier === tokenIdentifier);
    }
  } else {
    if (direction === 'in') {
      newPair = pairs.find((p) => p.secondToken.identifier === tokenIdentifier);
    }
  }

  if (
    currentPair?.firstToken?.identifier === EGLD.identifier ||
    currentPair?.firstToken?.identifier === WEGLD.identifier
  ) {
    return cloneAndSwapFirstToken(currentPair.firstToken.identifier, newPair);
  }

  return newPair;
};

const cloneAndSwapFirstToken = (
  tokenIdentifier: string,
  currentPair?: Pair
) => {
  const newPair = { ...currentPair } as Pair;
  newPair.firstToken = {
    ...newPair.firstToken,
    identifier: tokenIdentifier,
    name: tokenIdentifier === EGLD.identifier ? EGLD.name : WEGLD.name,
    ticker: tokenIdentifier === EGLD.identifier ? EGLD.ticker : WEGLD.ticker
  };

  return newPair;
};

export const computeTokenAmount = (
  token: EsdtToken | undefined,
  amounts: Map<string, string>
) => {
  if (!token) {
    return '0';
  }

  const tokenAmount = amounts.get(token.identifier);
  if (!tokenAmount) {
    return '0';
  }

  return new BigNumber(tokenAmount).shiftedBy(token.decimals).toFixed();
};

export const computeOtherToken = (
  firstTokenIdentifier?: string,
  pair?: SwapTokens
): EsdtToken | undefined => {
  if (!pair) {
    return undefined;
  }

  const otherToken =
    pair.firstToken?.identifier === firstTokenIdentifier
      ? pair.secondToken
      : pair.firstToken;

  return otherToken;
};

export const computeSwapTokenAmount = (
  tokenID: string,
  amount: string,
  tokens: EsdtToken[]
): string => {
  const token = tokens.find((t) => t.identifier === tokenID);

  if (!token) {
    return '0';
  }

  return new BigNumber(amount)
    .shiftedBy(-token.decimals)
    .decimalPlaces(DECIMALS)
    .toFixed();
};

export const computeTransactionArgs = (
  pair: Pair,
  tokenAmountsMap: Map<string, string>,
  direction: 'in' | 'out',
  tolerance: number
) => {
  if (direction === 'in') {
    return {
      tokenInID: pair.firstToken.identifier,
      amountIn: computeTokenAmount(pair.firstToken, tokenAmountsMap),
      tokenOutID: pair.secondToken.identifier,
      amountOut: computeTokenAmount(pair.secondToken, tokenAmountsMap),
      tolerance
    };
  } else {
    return {
      tokenInID: pair.secondToken.identifier,
      amountIn: computeTokenAmount(pair.secondToken, tokenAmountsMap),
      tokenOutID: pair.firstToken.identifier,
      amountOut: computeTokenAmount(pair.firstToken, tokenAmountsMap),
      tolerance
    };
  }
};

export const computeSwapTransactionArgs = (
  tokenIdentifier: string,
  pair: SwapTokens,
  tokenAmountsMap: Map<string, string>,
  direction: 'in' | 'out',
  tolerance: number
) => {
  let tokenInID: string | undefined;
  let amountIn: string | undefined;
  let tokenOutID: string | undefined;
  let amountOut: string | undefined;

  // First token is EGLD or WEGLD
  const egldFirstToken = pair.firstToken?.identifier === tokenIdentifier;
  const otherToken = computeOtherToken(tokenIdentifier, pair);

  if (egldFirstToken) {
    const amount = computeTokenAmount(pair.firstToken, tokenAmountsMap);
    if (direction === 'out') {
      tokenOutID = tokenIdentifier;
      amountOut = amount;
      tokenInID = otherToken?.identifier;
    } else {
      tokenInID = tokenIdentifier;
      amountIn = amount;
      tokenOutID = otherToken?.identifier;
    }
  } else {
    const amount = computeTokenAmount(pair.secondToken, tokenAmountsMap);
    if (direction === 'out') {
      tokenInID = tokenIdentifier;
      amountIn = amount;
      tokenOutID = otherToken?.identifier;
    } else {
      tokenOutID = tokenIdentifier;
      amountOut = amount;
      tokenInID = otherToken?.identifier;
    }
  }

  return {
    tokenInID,
    amountIn,
    tokenOutID,
    amountOut,
    tolerance
  };
};
