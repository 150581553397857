import * as React from 'react';
import './AddLiquidityInputSelector.scss';
import '../../../assets/sass/exrond.scss';
import * as NumberUtils from '@buidly/dapp-core/dist/utils/number';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash';
import Select, { SingleValue } from 'react-select';
import { EsdtToken, Pair } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import { useGetAvailableAmount } from 'components/SwapCard/hooks/useGetAvailableAmount';
import { useGetCardToken } from 'components/SwapCard/hooks/useGetCardToken';
import { useGetCardTokens } from 'components/SwapCard/hooks/useGetCardTokens';
import { SwapCardType } from 'components/SwapCard/types';
import { TOKEN_PLACEHOLDER } from 'config';

const auxInputColor = {
  primary: colors.colorPrimary,
  secondary: colors.colorPrimaryDark
};
interface AddLiquidityInputSelectorProp {
  currentPair?: Pair;
  pairs: Pair[];
  direction: 'in' | 'out';
  type: SwapCardType;
  inputValues: Map<string, string>;
  selectOnChange: (
    tokenIdentifier: string,
    direction: 'in' | 'out',
    type: SwapCardType
  ) => void;
  inputOnChange: (
    identifier: string,
    value: string,
    type: SwapCardType
  ) => void;
  setEnableSwap: (value: boolean) => void;
}
const AddLiquidityInputSelector = ({
  currentPair,
  pairs,
  direction,
  type,
  inputValues,
  selectOnChange,
  inputOnChange,
  setEnableSwap
}: AddLiquidityInputSelectorProp) => {
  const allTokens = useGetCardTokens(pairs, direction, type);
  const tokens = React.useMemo(
    () => uniqBy(allTokens, 'identifier'),
    [allTokens]
  );
  const token = useGetCardToken(currentPair, direction, type);
  const availableAmount = useGetAvailableAmount(token);
  const [initialState, setInitialState] = React.useState<boolean>(true);

  const selectOption = (option: SingleValue<EsdtToken>) => {
    if (option) {
      selectOnChange(option.identifier, direction, type);
    }
  };

  const inputValue = React.useMemo(() => {
    if (!token) {
      return '';
    }

    const value = inputValues.get(token.identifier);
    if (!value) {
      return '';
    }

    const numDecimals = value.split('').reverse().join('').indexOf('.');
    const diff = token.decimals - numDecimals;
    if (diff < 0) {
      return value.slice(0, diff);
    }

    return value;
  }, [inputValues]);

  const inputError = React.useMemo((): string | undefined => {
    if (!token) {
      return '';
    }

    const currentValue = new BigNumber(inputValue).shiftedBy(token.decimals);
    if (currentValue.isGreaterThan(availableAmount)) {
      return 'Not enough balance';
    }

    if (inputValue === '') {
      return 'No value provided';
    }

    if (inputValue === '0') {
      return 'The value must be greater than 0';
    }

    return undefined;
  }, [inputValue, availableAmount]);

  React.useEffect(() => {
    setEnableSwap(inputError === undefined);
  }, [inputError]);

  const customStylesSelect = {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      color: state.selectProps.menuColor,
      padding: 10
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? auxInputColor['secondary']
        : auxInputColor['primary'],
      '&:hover': {
        backgroundColor: auxInputColor['secondary']
      }
    })
  };

  const onMaxClicked = () => {
    if (!token) {
      return;
    }

    const max = NumberUtils.toDenominatedString(
      availableAmount,
      token.decimals
    );

    inputOnChange(token.identifier, max, type);
  };

  const formatOptionLabel = (option: EsdtToken) => {
    return (
      <div className='d-flex flex-row align-items-center'>
        <img
          src={option.icon ?? TOKEN_PLACEHOLDER}
          className='token-icon token-select p-0 mr-2'
          style={{ width: '32px', height: '32px' }}
        />
        <span className='home-text13'>{option.ticker}</span>
      </div>
    );
  };

  return (
    <>
      <div
        className={
          'd-flex align-items-center mt-4 exrond-liquidity-input-selector secondary'
        }
      >
        <input
          className={
            'exrond-liquidity-input-selector-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
          }
          type='number'
          value={inputValue}
          min={0}
          minLength={0}
          onChange={(event) => {
            setInitialState(false);
            if (!token) {
              return;
            }

            inputOnChange(token.identifier, event.target.value, type);
          }}
        />
        <span
          className='exrond-text font-weight-600 font-size-1 text-white align-items-center mr-2 btn'
          onClick={onMaxClicked}
        >
          MAX
        </span>
        <Select
          className={'exrond-liquidity-input-selector primary exrond-select'}
          classNamePrefix={'exrond'}
          styles={customStylesSelect}
          placeholder={'Select token'}
          options={tokens}
          formatOptionLabel={(option) => formatOptionLabel(option)}
          getOptionValue={(option) => option.identifier}
          value={token}
          onChange={selectOption}
        />
      </div>
      <div
        className={`w-100 d-flex mt-2 ${
          inputError && !initialState
            ? 'justify-content-between'
            : 'justify-content-end'
        }`}
      >
        {inputError && !initialState && (
          <div className='d-flex flex-row align-items-center text-danger'>
            <span className='exrond-text font-size-09 mr-3'>{inputError}</span>
          </div>
        )}
        <div>
          <span className='exrond-text font-size-09 text-white mr-2'>
            Balance:
          </span>
          <span className='exrond-text font-size-09 text-white'>
            <FormatAmount
              className='exrond-text text-white mr-2'
              value={availableAmount.toFixed()}
              decimals={token?.decimals}
              egldLabel={token?.ticker ?? ' '}
              digits={4}
              showLastNonZeroDecimal={false}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default AddLiquidityInputSelector;
