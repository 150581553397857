import { gql } from '@apollo/client';

export const CREATE_UNSTAKE_TRANSACTION = gql`
  mutation unstake(
    $sender: String!
    $groupId: String!
    $unstakingArgs: UnstakingArgsModel!
  ) {
    transaction: unstake(
      sender: $sender
      groupId: $groupId
      unstakingArgs: $unstakingArgs
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
