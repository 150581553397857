import { gql } from '@apollo/client';

export const CREATE_FAUCET_SETTINGS = gql`
  query createFaucetSettings($sender: String!) {
    createFaucetSettings(sender: $sender) {
      tokens {
        name
        identifier
        balance
        decimals
        ticker
        assets {
          svgUrl
          pngUrl
        }
      }
      registrationInfo {
        token {
          name
          identifier
          balance
          decimals
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
        feeAmount
      }
    }
  }
`;
