import { gql } from '@apollo/client';

export const CREATE_UNLOCK_TOKEN_TRANSACTION = gql`
  mutation unlockToken(
    $sender: String!
    $token: InputTokenModel!
    $payWithEgld: Boolean!
  ) {
    transactions: unlockToken(
      sender: $sender
      token: $token
      payWithEgld: $payWithEgld
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
