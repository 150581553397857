import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EsdtToken } from 'api/types/pairs';
import '../../SwapSettings.scss';

interface SwapIconWithTokenOverlayProps {
  token: EsdtToken | undefined;
  className?: string;
}

export const SwapIconWithTokenOverlay = ({
  token,
  className
}: SwapIconWithTokenOverlayProps) => {
  const renderTooltip = (props: any) => (
    <Tooltip {...props}>{token?.identifier}</Tooltip>
  );

  return (
    <OverlayTrigger placement='top' overlay={(props) => renderTooltip(props)}>
      <img
        src={token?.icon ?? ''}
        className={`swap-settings-icon p-0 ${className || ''}`}
      />
    </OverlayTrigger>
  );
};
