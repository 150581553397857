import React from 'react';
import {
  useGetAccountInfo,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';

const AccountAddress = () => {
  const { network } = useGetNetworkConfig();
  const { address } = useGetAccountInfo();

  return (
    <div>
      <p>
        <b>Your wallet:</b>
      </p>
      <b>
        <a
          className='text-break exrond-text text-white font-size-09 font-weight-600'
          href={`${network.explorerAddress}/address/${address}`}
          rel='noreferrer'
          target='_blank'
        >
          {address}
        </a>
      </b>
    </div>
  );
};

export default AccountAddress;
