import React from 'react';
import LiquidVestingContent from './LiquidVestingContent';
import './LiquidVesting.scss';

interface LiquidVestingProps {
  expanded: boolean;
  onHide: () => void;
  onMint: () => void;
  mintSuccessful: number | null;
}

export const LiquidVesting = ({
  expanded,
  onHide,
  onMint,
  mintSuccessful
}: LiquidVestingProps) => {
  return (
    <div className='exrond-text liquid-vesting-container'>
      <LiquidVestingContent
        onMint={onMint}
        onHide={onHide}
        expanded={expanded}
        mintSuccessful={mintSuccessful}
      />
    </div>
  );
};
