import React, { useContext } from 'react';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwapPanelContext } from 'pages/SwapV2/Context';
import './SwapSearch.scss';

const SwapSearch = () => {
  const { search, setSearch, mainColor, expanded } =
    useContext(SwapPanelContext);

  const handleCloseClick = () => {
    setSearch('');
  };

  return (
    <div
      className={`swap-search__container ${
        expanded ? 'swap-search__container__last-element' : ''
      }`}
    >
      {expanded && search.length > 0 && (
        <span className='p-2 swap-search__title'>
          Search
          <FontAwesomeIcon
            icon={faSearch}
            className='ml-1 swap-search__search-icon'
          />
        </span>
      )}
      <div className='d-flex flex-row position-relative'>
        <input
          value={search}
          type='text'
          placeholder='Search'
          onChange={(e) => setSearch(e.target.value)}
          className={`w-100 swap-search swap-search--${mainColor}`}
        />
        {expanded && search.length > 0 && (
          <div
            className='swap-search__close-container'
            onClick={handleCloseClick}
          >
            <FontAwesomeIcon icon={faClose} color='#fff' />
          </div>
        )}
      </div>
    </div>
  );
};

export default SwapSearch;
