import { NftAction } from '@buidly/ioiom-staking-dapp/types';

export const getBlueChipButtonTitleByAction = (
  action: NftAction | undefined,
  nfts: number,
  nftTokensExpanded: boolean,
  hasBlueChips: boolean
) => {
  if (!action) {
    return hasBlueChips ? 'Vest all' : 'Mint';
  }

  if (action === NftAction.CLAIM) {
    return 'Claim';
  }

  const suffix = nfts === 0 ? 'all' : nfts.toString();

  if (action === NftAction.STAKE) {
    if (!hasBlueChips) {
      return 'Mint';
    }
    return !nftTokensExpanded ? 'Vest' : `Vest ${suffix}`;
  }

  if (action === NftAction.UNSTAKE) {
    return !nftTokensExpanded ? 'Unvest' : `Unvest ${suffix}`;
  }

  const title = `${action} ${suffix}`;
  return title;
};
