import BigNumber from 'bignumber.js';
import { AccountDetails } from 'api/types';
import { EsdtToken } from 'api/types/pairs';
import { EGLD } from 'config';

export const getSwapTokenBalance = (
  token: EsdtToken | undefined,
  accountDetails: AccountDetails | undefined
): BigNumber => {
  if (!token) {
    return new BigNumber(0);
  }

  if (token.identifier === EGLD.identifier) {
    return new BigNumber(accountDetails?.egldBalance ?? 0);
  }

  const accountToken = accountDetails?.esdtTokens.find(
    (t) => t.identifier === token.identifier
  );

  if (!accountToken) {
    return new BigNumber(0);
  }

  return accountToken.balance;
};
