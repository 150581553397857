import React from 'react';
import FilterButton from '@buidly/ioiom-staking-dapp/components/FilterButton/FilterButton';
import {
  NftFarmDetails,
  NftTokenState
} from '@buidly/ioiom-staking-dapp/types';
import { useMediaQuery } from 'hooks';
import { getBlueChipTokenDetailsByState } from '../../helpers/getBlueChipTokenDetailsByState';
import { BlueChipFilterButton } from '../BlueChipFilterButton';

export interface BlueChipsFiltersProps {
  nftFarmDetails: NftFarmDetails | undefined;
  onFilterSelected: (state: NftTokenState | undefined) => void;
  selectedFilter: NftTokenState | undefined;
}

export const BlueChipsFilters = ({
  nftFarmDetails,
  selectedFilter,
  onFilterSelected
}: BlueChipsFiltersProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  if (nftFarmDetails === undefined) {
    return null;
  }

  if (!isDesktop) {
    return (
      <div className='d-flex flex-row flex-grow-1 justify-content-start'>
        <BlueChipFilterButton
          details={getBlueChipTokenDetailsByState(selectedFilter)}
          selected={true}
        />
      </div>
    );
  }

  return (
    <div className='d-flex flex-row flex-grow-1 justify-content-start'>
      <BlueChipFilterButton
        details={getBlueChipTokenDetailsByState(undefined)}
        selected={selectedFilter === undefined}
        onClick={() => onFilterSelected(undefined)}
      />
      {nftFarmDetails.unstaked.length > 0 && (
        <BlueChipFilterButton
          details={getBlueChipTokenDetailsByState(NftTokenState.UNSTAKED)}
          selected={selectedFilter === NftTokenState.UNSTAKED}
          containerClassName={'ml-3'}
          onClick={() => onFilterSelected(NftTokenState.UNSTAKED)}
        />
      )}
      {nftFarmDetails.staked.length > 0 && (
        <BlueChipFilterButton
          details={getBlueChipTokenDetailsByState(NftTokenState.STAKED)}
          containerClassName={'ml-3'}
          selected={selectedFilter === NftTokenState.STAKED}
          onClick={() => onFilterSelected(NftTokenState.STAKED)}
        />
      )}
      {nftFarmDetails.unbound.length > 0 && (
        <BlueChipFilterButton
          details={getBlueChipTokenDetailsByState(NftTokenState.UNBOUND)}
          selected={selectedFilter === NftTokenState.UNBOUND}
          containerClassName={'ml-3'}
          onClick={() => onFilterSelected(NftTokenState.UNBOUND)}
        />
      )}
      {nftFarmDetails.claimable.length > 0 && (
        <FilterButton
          details={getBlueChipTokenDetailsByState(NftTokenState.CLAIMABLE)}
          selected={selectedFilter === NftTokenState.CLAIMABLE}
          containerClassName={'ml-3'}
          onClick={() => onFilterSelected(NftTokenState.CLAIMABLE)}
        />
      )}
    </div>
  );
};
