import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import colors from 'assets/sass/variables.module.scss';
import { routeNames } from 'routes';
export interface DesktopStakeNavbarProps {
  onBackClicked: () => void;
}

const DesktopStakeNavbar = ({ onBackClicked }: DesktopStakeNavbarProps) => {
  return (
    <div className='navbar-container'>
      <div
        onClick={onBackClicked}
        className='card card-rewards h-100 d-flex flex-row align-items-center px-4'
        style={{
          borderTopLeftRadius: '21px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: '21px',
          borderBottomRightRadius: '8px',
          cursor: 'pointer',
          minHeight: '46px',
          right: '-20px'
        }}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          width='12px'
          height='7px'
          color={colors.colorPrimaryLight}
        />
        <span className='ml-2'>Stake</span>
      </div>
      <Nav
        className='exrond_navbar'
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <NavItem className='item'>
          <NavLink to={routeNames.tokensStaking}>Tokens</NavLink>
        </NavItem>
        <NavItem className='item'>
          <NavLink to={routeNames.nftsStaking}>NFTs</NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default DesktopStakeNavbar;
