export const isOutsideBounds = (
  label: string,
  min: number,
  max: number,
  value?: string
): string | undefined => {
  const val = Number(value);
  if (isNaN(val)) {
    return `Invalid ${label}`;
  }

  if (val < min || val > max) {
    const message =
      val < 1
        ? `${label} must be greater or equal than ${min}`
        : `${label} must be less or equal than ${max}`;
    return message;
  }

  return undefined;
};

export const calculateFarmRewards = (
  duration: number,
  topUpRewardsAmount: number
): { rpb: number; rewardsPerYear: number } => {
  const durationInBlocks = Number(duration) * 24 * 60 * 10;
  const topUpAmount = Number(topUpRewardsAmount);

  const rpb = topUpAmount / durationInBlocks;
  const rewardsPerYear = rpb * 10 * 60 * 24 * 365;

  return { rpb, rewardsPerYear };
};
