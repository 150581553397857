import { EsdtToken } from './pairs';

export class FaucetSettings {
  tokens: EsdtToken[] = [];
  registrationInfo?: RegistrationInfo;

  constructor(init?: Partial<FaucetSettings>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): FaucetSettings {
    return new FaucetSettings({
      tokens:
        response.tokens?.map((esdtToken: any) =>
          EsdtToken.fromResponse(esdtToken)
        ) ?? [],
      registrationInfo: response.registrationInfo
        ? RegistrationInfo.fromResponse(response.registrationInfo)
        : undefined
    });
  }
}

export class RegistrationInfo {
  token!: EsdtToken;
  feeAmount = '0';

  constructor(init?: Partial<RegistrationInfo>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): RegistrationInfo {
    return new RegistrationInfo({
      token: EsdtToken.fromResponse(response.token),
      feeAmount: response.feeAmount
    });
  }
}
