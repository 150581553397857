import React, { useContext, useEffect, useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Spinner } from 'react-bootstrap';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import './SwapSelect.scss';
import { useMediaQuery } from 'hooks';
import { SwapContext, SwapPanelContext } from 'pages/SwapV2/Context';
import { useGetCardToken } from 'pages/SwapV2/hooks';
import useClickOutside from 'pages/SwapV2/hooks/useClickOutside';
import { SwapCardType } from 'pages/SwapV2/types';
import SwapAnalyticsFilter from '../SwapAnalyticsFilters/SwapAnalyticsFilters';
import SwapSearch from '../SwapSearch/SwapSearch';
import SwapWalletHeader from './Header/SwapWalletHeader';
import SwapSelectList from './SwapSelectList/SwapSelectList';
import SwapTokenOption from './SwapTokenOption/SwapTokenOption';
import SwapTokenOptionCurrentValue from './SwapTokenOption/SwapTokenOptionCurrentValue';

interface SwapSelectProps {
  className?: string;
}

const SwapSelect = ({ className }: SwapSelectProps) => {
  const [optionsShown, setOptionsShown] = useState(false);
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    walletTokens,
    zeroBalanceTokens,
    secondaryColor,
    loading,
    zeroBalanceHeader,
    zeroBalanceClassName,
    zeroBalanceMarkClassName,
    zeroBalanceInfoElement,
    cardType,
    expanded,
    search,
    setCardExpanded
  } = useContext(SwapPanelContext);

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const { setSwapPairAmounts, stopSwapPolling } = useContext(SwapContext);

  const { selectedToken, setSelectedToken } = useGetCardToken(cardType);

  useEffect(() => {
    setOptionsShown(expanded);
  }, [expanded]);

  const handleChange = (selected: EsdtToken, buyAmount?: string) => {
    setSelectedToken(selected);
    setOptionsShown(false);
    stopSwapPolling();
    const amount = buyAmount ?? '';
    setSwapPairAmounts({
      amountIn: cardType === SwapCardType.SENDER ? amount : '',
      amountOut: cardType === SwapCardType.RECEIVER ? amount : ''
    });
    if (!isDesktop) {
      setCardExpanded(cardType);
    }
  };

  useClickOutside([collapsibleRef, containerRef], () => {
    if (isDesktop) {
      setOptionsShown(false);
    }
  });

  const handleCardClick = () => {
    if (loading) {
      return;
    }

    if (!isDesktop) {
      setCardExpanded(cardType);
      return;
    }

    setOptionsShown((prev) => !prev);
  };

  return (
    <div className={`d-flex flex-column ${className || ''}`}>
      <div
        ref={containerRef}
        className={`d-flex flex-row align-items-center swap-select swap-select--${secondaryColor} ${
          optionsShown ? 'swap-select__open' : ''
        }`}
        onClick={handleCardClick}
      >
        {selectedToken ? (
          <SwapTokenOption
            token={selectedToken}
            info={<SwapTokenOptionCurrentValue token={selectedToken} />}
          />
        ) : (
          <span className='swap-select__placeholder'>Select token</span>
        )}
        <div className='swap-select__ic-right mr-1'>
          {loading ? (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              style={{ color: colors.colorPrimaryLight }}
            />
          ) : (
            <FontAwesomeIcon
              color={colors.colorLightGray}
              icon={optionsShown ? faChevronUp : faChevronDown}
            />
          )}
        </div>
      </div>
      <div className='position-relative' ref={collapsibleRef}>
        <Collapse in={optionsShown}>
          <div
            className={`swap-select__options swap-select--${secondaryColor} ${
              expanded ? 'swap-select__options--expanded' : ''
            }`}
          >
            <SwapAnalyticsFilter setOptionsShown={setOptionsShown} />
            <SwapSearch />
            <div
              className={`swap-select__options-list swap-select__options-list--${secondaryColor} ${
                expanded
                  ? `swap-select__options-list--expanded${
                      search.length > 0 ? '-search' : ''
                    }`
                  : ''
              }`}
            >
              <SwapSelectList
                tokens={walletTokens ?? []}
                header={<SwapWalletHeader />}
                handleItemSelected={handleChange}
              />
              <SwapSelectList
                tokens={zeroBalanceTokens ?? []}
                header={zeroBalanceHeader}
                handleItemSelected={handleChange}
                className={zeroBalanceClassName}
                markClassName={zeroBalanceMarkClassName}
                zeroBalanceInfoElement={zeroBalanceInfoElement}
                hasBuyAmount={cardType === SwapCardType.RECEIVER}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default SwapSelect;
