import React from 'react';
import {
  countDecimalPlaces,
  formatTicker
} from '@buidly/ioiom-staking-dapp/utils/utils';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import { EsdtToken } from 'api/types/pairs';
import { ReactComponent as ExchangeRateIcon } from 'assets/img/swap-info/exchange-rate.svg';
import { DECIMALS } from 'config';
import '../../SwapSettings.scss';

interface SwapExchangeRateProps {
  tokenInID: string;
  tokenOutID: string;
  tokenInExchangeRate: string;
  tokenOutMetadata: EsdtToken;
}

export const SwapExchangeRate = ({
  tokenInID,
  tokenOutID,
  tokenInExchangeRate,
  tokenOutMetadata
}: SwapExchangeRateProps) => {
  return (
    <div className='swap-settings__exchange-rate'>
      <div>
        <ExchangeRateIcon className='mr-1' />
        <span className='swap-settings__text'>Exchange Rate:</span>
      </div>
      <div>
        <span className='swap-settings__text ml-3'>
          1 {formatTicker(tokenInID)} =
        </span>
        <FormatAmount
          className='swap-settings__text ml-1'
          value={tokenInExchangeRate}
          decimals={tokenOutMetadata.decimals}
          digits={countDecimalPlaces(
            tokenInExchangeRate,
            tokenOutMetadata.decimals,
            DECIMALS
          )}
          token=''
          showLastNonZeroDecimal={false}
        />
        <span className='swap-settings__text ml-1'>
          {formatTicker(tokenOutID)}
        </span>
      </div>
    </div>
  );
};
