import { gql } from '@apollo/client';

export const CREATE_CLAIM_FAUCETS_TRANSACTION = gql`
  mutation claimFaucets(
    $sender: String!
    $args: ClaimArgs!
    $payWithEgld: Boolean!
  ) {
    transactions: claimFaucets(
      sender: $sender
      args: $args
      payWithEgld: $payWithEgld
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
