import React, { useMemo } from 'react';

import colors from 'assets/sass/variables.module.scss';

export interface ProgressBarProps {
  value: number;
  label: string;
  claimed: boolean;
  className?: string;
  expired?: boolean;
  endingSoon?: boolean;
  progressColor?: string;
}

const ProgressBar = ({
  value,
  label,
  claimed,
  className = 'd-flex',
  expired = false,
  endingSoon = false,
  progressColor
}: ProgressBarProps) => {
  const containerStyles = {
    borderRadius: '6px',
    background: colors.colorPrimary,
    height: '22px',
    width: '100%',
    flexDirection: claimed ? ('row-reverse' as const) : ('row' as const)
  };

  const widthPercent = claimed ? 100 - value : value;

  const markColor = useMemo(() => {
    if (progressColor) {
      return progressColor;
    }

    if (endingSoon || claimed) {
      return colors.colorWarning;
    }

    if (expired) {
      return colors.colorPrimaryDark;
    }

    return colors.colorPrimaryLight;
  }, [expired, claimed, endingSoon, progressColor]);

  const textColor = useMemo(() => {
    if (claimed || endingSoon) {
      return 'text-black';
    }

    return 'text-white';
  }, [claimed]);

  const progressStyles = {
    backgroundColor: markColor,
    borderRadius: 'inherit',
    width: `${widthPercent}%`
  };

  const labelStyles = {
    fontWeight: '500',
    fontSize: '0.65rem'
  };

  const labelTreshold = 45;

  return (
    <div className={`${className} p-1`} style={containerStyles}>
      <div
        className={`d-flex h-100 align-content-center flex-wrap pr-1 justify-content-${
          claimed ? 'start' : 'end'
        }`}
        style={progressStyles}
      >
        {widthPercent >= labelTreshold && (
          <span
            className={`exrond-text p${claimed ? 'l' : 'r'}-2 ${textColor}`}
            style={labelStyles}
          >
            {!expired ? label : 'Ended'}
          </span>
        )}
      </div>
      {widthPercent < labelTreshold && (
        <span
          className={`exrond-text p${claimed ? 'r' : 'l'}-2 text-white`}
          style={labelStyles}
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
