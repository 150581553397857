import React from 'react';
import { faBars, faClose, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import {
  Col,
  Collapse,
  Container,
  Nav,
  Navbar as BsNavbar,
  NavItem,
  Row
} from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import colors from 'assets/sass/variables.module.scss';
import AccountSettings from 'components/AccountSettings';
import ActionOrConnect from 'components/ActionOrConnect';
import MobileAccountButton from 'components/MobileAccountButton';
import { routeNames } from 'routes';
import { ReactComponent as ExrondLogoResponsive } from '../../../assets/img/ex.svg';

const MobileNavbar = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  const [showMenu, setShowMenu] = React.useState(false);

  const [stakingMenuOpen, setStakingMenuOpen] = React.useState(false);

  if (!showMenu) {
    return (
      <BsNavbar className='navbar-wrapper mobile'>
        <div className='d-flex flex-column w-100'>
          <div className='container-fluid'>
            <Link to={routeNames.home}>
              <ExrondLogoResponsive className='exrond-logo-mini' />
            </Link>
            <div className='connect-button-wrapper d-flex'>
              <ActionOrConnect>
                <MobileAccountButton />
              </ActionOrConnect>
              {isLoggedIn && <AccountSettings />}
              <a
                className='btn btn-primary exrond-connect-btn ml-2'
                style={{ minWidth: 'unset' }}
                onClick={() => setShowMenu(!showMenu)}
              >
                <FontAwesomeIcon icon={showMenu ? faClose : faBars} />
              </a>
            </div>
          </div>
        </div>
      </BsNavbar>
    );
  }

  return (
    <div className='expanded-navbar' onClick={() => setShowMenu(false)}>
      <BsNavbar className='navbar-wrapper mobile'>
        <div className='d-flex flex-column w-100'>
          <div className='container-fluid'>
            <div></div>
            <div className='connect-button-wrapper d-flex'>
              <a
                className='btn btn-primary exrond-connect-btn ml-2'
                style={{ minWidth: 'unset' }}
                onClick={() => setShowMenu(!showMenu)}
              >
                <FontAwesomeIcon icon={faClose} />
              </a>
            </div>
          </div>
        </div>
      </BsNavbar>
      <div className='navbar-container mobile'>
        <Nav>
          <NavItem className='item'>
            <NavLink to={routeNames.swap}>
              Swap
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  width: '8px',
                  height: '8px',
                  color: '#23F7DD',
                  marginBottom: '7px'
                }}
              />
            </NavLink>
          </NavItem>
          <NavItem className='item'>
            <NavLink to={routeNames.exrBlueChips}>
              $EXR
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  width: '8px',
                  height: '8px',
                  color: '#23F7DD',
                  marginBottom: '7px'
                }}
              />
            </NavLink>
          </NavItem>
          <NavItem
            className='item'
            onClick={(event: any) => {
              event.stopPropagation();
              setStakingMenuOpen((prev) => !prev);
            }}
          >
            <a>Stake</a>
          </NavItem>
          <Collapse in={stakingMenuOpen}>
            <Container fluid className='nav'>
              <Row>
                <Col className='text-white submenu-item' xs={6}>
                  <NavItem
                    className='item m-0'
                    style={{ backgroundColor: colors.colorPrimary }}
                  >
                    <NavLink to={routeNames.tokensStaking}>Tokens</NavLink>
                  </NavItem>
                </Col>
                <Col className='text-white submenu-item' xs={6}>
                  <NavItem
                    className='item m-0'
                    style={{ backgroundColor: colors.colorPrimary }}
                  >
                    <NavLink to={routeNames.nftsStaking}>NFTs</NavLink>
                  </NavItem>
                </Col>
              </Row>
            </Container>
          </Collapse>
          <NavItem className='item'>
            <NavLink to={routeNames.rewards}>Rewards</NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default MobileNavbar;
