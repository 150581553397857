import { EsdtToken, Pair } from 'api/types/pairs';
import { EGLD } from 'config';
import { SwapCardType } from '../types';

const useGetCardTokens = (
  pairs: Pair[],
  direction: 'in' | 'out',
  cardType: SwapCardType
): EsdtToken[] => {
  let tokens: EsdtToken[];
  if (cardType === SwapCardType.SENDER) {
    tokens = pairs.map((pair) => {
      return direction === 'in' ? pair.firstToken : pair.secondToken;
    });
  } else {
    tokens = pairs.map((pair) => {
      return direction === 'in' ? pair.secondToken : pair.firstToken;
    });
  }

  if (
    tokens.length > 0 &&
    ((direction === 'out' && cardType === SwapCardType.RECEIVER) ||
      (direction === 'in' && cardType === SwapCardType.SENDER))
  ) {
    tokens.push(
      new EsdtToken({
        identifier: EGLD.identifier,
        ticker: EGLD.ticker,
        name: EGLD.name,
        decimals: EGLD.decimals,
        icon: EGLD.icon
      })
    );
  }

  return tokens;
};

export { useGetCardTokens };
