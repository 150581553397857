import React, { useContext, useState } from 'react';
import SwapSvg from 'components/utils/SwapSvg';
import { SwapContext } from 'pages/SwapV2/Context';
import { SwapCardType } from 'pages/SwapV2/types';
import SwapPanelWrapper from '../SwapPanel/SwapPanelWrapper';
import SwapAllTokensHeader from '../SwapSelect/Header/SwapAllTokensHeader';
import SwapZeroBalanceHeader from '../SwapSelect/Header/SwapZeroBalanceHeader';
import SwapTokenOptionBuy from '../SwapSelect/SwapTokenOption/SwapTokenOptionBuy';

export const SwapContentBody = () => {
  const { changeDirection } = useContext(SwapContext);

  const [senderOptionsExpanded, setSenderOptionsExpanded] = useState(false);
  const [receiverOptionsExpanded, setReceiverOptionsExpanded] = useState(false);

  const setCardExpanded = (cardType: SwapCardType) => {
    switch (cardType) {
      case SwapCardType.SENDER:
        setSenderOptionsExpanded((prev) => !prev);
        break;
      case SwapCardType.RECEIVER:
        setReceiverOptionsExpanded((prev) => !prev);
        break;
      default:
        console.log('Unknown card type');
    }
  };

  return (
    <div className='d-flex flex-column flex-lg-row justify-content-between position-relative align-items-center'>
      <SwapPanelWrapper
        title='You send'
        mainColor='primary-dark'
        secondaryColor='primary'
        zeroBalanceClassName='disabled'
        zeroBalanceMarkClassName='disabled'
        zeroBalanceHeader={<SwapZeroBalanceHeader />}
        cardType={SwapCardType.SENDER}
        expanded={senderOptionsExpanded}
        otherCardExpanded={receiverOptionsExpanded}
        setCardExpanded={setCardExpanded}
      />
      <SwapSvg
        onClick={changeDirection}
        hidden={receiverOptionsExpanded || senderOptionsExpanded}
      />
      <SwapPanelWrapper
        title='You get'
        mainColor='primary'
        secondaryColor='primary-dark'
        zeroBalanceMarkClassName='disabled'
        zeroBalanceHeader={<SwapAllTokensHeader />}
        zeroBalanceInfoElement={<SwapTokenOptionBuy />}
        cardType={SwapCardType.RECEIVER}
        expanded={receiverOptionsExpanded}
        otherCardExpanded={senderOptionsExpanded}
        setCardExpanded={setCardExpanded}
      />
    </div>
  );
};
