import React, { useEffect, useState } from 'react';
import { useGetStakingGroups } from '@buidly/ioiom-staking-dapp/TokenStaking/hooks/useGetStakingGroups';
import { usePerformTransaction } from '@buidly/ioiom-staking-dapp/TokenStaking/hooks/usePerformTransaction';
import { MobileTokenStakePage } from '@buidly/ioiom-staking-dapp/TokenStaking/MobileStake/MobileTokenStakePage';
import {
  FarmAction,
  FarmActionDetailed
} from '@buidly/ioiom-staking-dapp/TokenStaking/types/FarmAction';
import { StakingGroup } from '@buidly/ioiom-staking-dapp/TokenStaking/types/StakingGroup';
import { StakingGroupFarm } from '@buidly/ioiom-staking-dapp/TokenStaking/types/StakingGroupFarm';
import {
  MobileConnectButton,
  TokenStakingCard
} from '@buidly/ioiom-staking-dapp/TokenStaking/ui';
import { GenericFarmFilterArgs } from '@buidly/ioiom-staking-dapp/types';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks';
import { Loader, PageState } from '@multiversx/sdk-dapp/UI';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';

interface TokensStakingPageContentProps {
  filterArgs?: GenericFarmFilterArgs;
  setTokenDisplay?: (state: boolean) => void;
}

const TokensStakingPageContent = ({
  filterArgs,
  setTokenDisplay
}: TokensStakingPageContentProps) => {
  const [selectedStakingGroup, setSelectedStakingGroup] = useState<
    StakingGroup | undefined
  >();

  // Used only for mobile
  const [farmActionDetailed, setFarmActionDetailed] =
    useState<FarmActionDetailed>({ action: FarmAction.STAKE });
  const [showStakeComponent, setShowStakeComponent] = useState<{
    show: boolean;
    payWithEgld: boolean;
  }>({
    show: false,
    payWithEgld: false
  });

  const { success } = useGetActiveTransactionsStatus();
  const { data, loading, error, refetch } = useGetStakingGroups(filterArgs);

  const {
    performTransaction,
    success: txSuccess,
    pending: txPending
  } = usePerformTransaction();

  useEffect(() => {
    if (!selectedStakingGroup) {
      return;
    }

    setFarmActionDetailed({ action: FarmAction.STAKE });
  }, [selectedStakingGroup]);

  useEffect(() => {
    if (success) {
      refetch();
    }
  }, [success]);

  const handleOnFarmActionClick = (
    action: FarmAction,
    farm?: StakingGroupFarm
  ) => {
    if (
      farmActionDetailed?.farm?.farmAddress === farm?.farmAddress &&
      farmActionDetailed?.action === action
    ) {
      // Reset to default
      setFarmActionDetailed({ action: FarmAction.STAKE });
      return;
    }

    setFarmActionDetailed({ action, farm } as FarmActionDetailed);
  };

  const handleOnStickyNegativeButtonClick = () => {
    if (farmActionDetailed.action === FarmAction.STAKE) {
      setSelectedStakingGroup(undefined);
    } else {
      setFarmActionDetailed({ action: FarmAction.STAKE });
    }
  };

  const handleShowStakeComponent = (payWithEgld: boolean) => {
    setShowStakeComponent({ show: true, payWithEgld });
  };

  if (loading) {
    return (
      <div className='screen-centered'>
        <Loader noText={true} />
      </div>
    );
  }

  if (error) {
    return (
      <PageState
        icon={faX}
        className='text-muted'
        title='An error has occurred. Please try again later!'
      />
    );
  }

  if (data?.length === 0) {
    return <PageState className='text-muted' title='There are no farms yet.' />;
  }

  if (selectedStakingGroup === undefined) {
    return (
      <div className='d-flex flex-column'>
        {data.map((group) => (
          <TokenStakingCard
            stakingGroup={group}
            onDetailsClick={() => {
              if (!getIsLoggedIn()) {
                return;
              }

              setSelectedStakingGroup(group);

              setTokenDisplay?.(false);
            }}
            onActionClick={(action) => performTransaction(action)}
            key={`${group.groupId}${group.rewardToken.details.identifier}`}
            pending={txPending}
            success={txSuccess}
          />
        ))}
        <MobileConnectButton />
      </div>
    );
  }

  if (showStakeComponent.show) {
    return (
      <MobileTokenStakePage
        onCancel={() =>
          setShowStakeComponent({ show: false, payWithEgld: false })
        }
        groupId={selectedStakingGroup.groupId}
        onStakeClick={(action) => {
          setShowStakeComponent({ show: false, payWithEgld: false });
          performTransaction(action);
        }}
        rewardToken={selectedStakingGroup.rewardToken.details.identifier}
        payWithEgld={showStakeComponent.payWithEgld}
      />
    );
  }

  {
    setTokenDisplay && setTokenDisplay(true);
  }

  return (
    <TokenStakingCard
      expanded={true}
      onActionClick={(action) => performTransaction(action)}
      onFarmActionClick={handleOnFarmActionClick}
      onCancelDetailsClick={handleOnStickyNegativeButtonClick}
      onShowMobileStakeClick={handleShowStakeComponent}
      farmActionDetailed={farmActionDetailed}
      stakingGroup={selectedStakingGroup}
      pending={false}
      success={false}
    />
  );
};

export default TokensStakingPageContent;
