import { gql } from '@apollo/client';

export const CREATE_HARVEST_TRANSACTION = gql`
  mutation harvest(
    $sender: String!
    $harvestArgs: GenericFarmActionArgsModel!
  ) {
    transaction: harvest(sender: $sender, harvestArgs: $harvestArgs) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
