import React from 'react';
import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import { formatTicker, toAbbreviatedNumber } from '../utils';

export interface FaucetRewardPoolProps {
  rewardPool: string;
  token: EsdtToken;
}

const FaucetRewardPool = ({ rewardPool, token }: FaucetRewardPoolProps) => {
  const value = new BigNumber(rewardPool).shiftedBy(-token.decimals).toNumber();

  return (
    <div className='header-item mx-1 d-none d-lg-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
      <span className='reward-card-title'>Reward Pool</span>
      <span className='reward-card-subtitle'>
        {`${toAbbreviatedNumber(value)} ${formatTicker(token.ticker)}`}
      </span>
    </div>
  );
};

export default FaucetRewardPool;
