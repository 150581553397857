import React, { useMemo, useState } from 'react';
import { NftTokenWrapper } from '@buidly/ioiom-staking-dapp/NftStaking/model/nftToken';
import NftTokenMark from '@buidly/ioiom-staking-dapp/NftStaking/ui/NftToken/Components/NftTokenMark';
import { NftTokenState } from '@buidly/ioiom-staking-dapp/types';
import BlueChipTokenIcon from 'assets/img/blue-chips/ic_bluechip_token.svg';
import { useMediaQuery } from 'hooks';
import './BlueChipToken.scss';
import { getBlueChipTokenColors } from '../../helpers/getBlueChipTokenColors';
import { BlueChipMarkIcon } from '../BlueChipMarkIcon';

export interface BlueChipTokenProps {
  token: NftTokenWrapper;
  selected: boolean;
  farmExpired: boolean;
  pending: boolean;
  onTokenSelected: (token: NftTokenWrapper) => void;
}

export const BlueChipToken = ({
  token,
  selected,
  farmExpired,
  pending,
  onTokenSelected
}: BlueChipTokenProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 992px)');

  const showLabel = useMemo(() => {
    return isHovered || token.state === NftTokenState.UNBOUND;
  }, [isHovered]);

  const cardColors = useMemo(() => {
    return getBlueChipTokenColors(token.state, selected, farmExpired);
  }, [token.state, selected, farmExpired]);

  const handleMouseEnter = () => {
    if (!isDesktop || token.state === NftTokenState.UNBOUND || pending) {
      return;
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className='d-flex flex-column mr-3 mt-3 mt-lg-4 mr-lg-4 justify-content-center'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor: 'pointer'
      }}
      onClick={() => {
        if (
          (farmExpired && token.state === NftTokenState.UNSTAKED) ||
          pending
        ) {
          return;
        }

        onTokenSelected(token);
      }}
    >
      <div className='d-flex justify-content-center'>
        <div className='position-relative'>
          <div
            className='bluechip-staking-image-container'
            style={{
              border: cardColors.borderColor
                ? `3px solid ${cardColors.borderColor}`
                : 'none'
            }}
          >
            <img
              className='bluechip-staking-image'
              style={{
                opacity: !selected ? 0.4 : 1
              }}
              src={BlueChipTokenIcon}
            />
          </div>
          {(!farmExpired || token.state !== NftTokenState.UNSTAKED) && (
            <div
              className={`token-label d-flex flex-row justify-content-center align-items-center ${
                token.state === NftTokenState.CLAIMABLE ? 'right' : 'left'
              }`}
              style={{
                backgroundColor: cardColors.markColor,
                marginLeft: token.state === NftTokenState.STAKED ? '3px' : '0px'
              }}
            >
              {showLabel && (
                <NftTokenMark
                  token={token}
                  textColor={cardColors.textColor}
                  selected={selected}
                />
              )}
              <BlueChipMarkIcon
                hovered={isHovered}
                className='mx-1 mx-lg-2'
                selected={selected}
                state={token.state}
              />
            </div>
          )}
        </div>
      </div>
      <span className='text-white font-weight-500 font-size-10p text-center mt-2'>
        {token.name}
      </span>
    </div>
  );
};
