import React, { useEffect, useState } from 'react';
import { Group } from 'api/types/Group';
import Loader from 'components/Loader';
import { useGroups } from 'hooks/useGroups';
import AddGroupButton from './AddGroupButton/GroupAdd';
import GroupsItem from './GroupItem/GroupItem';

const GroupsPage = () => {
  const { groups: data, loading } = useGroups();

  const [groups, setGroups] = useState<Group[]>();

  useEffect(() => {
    if (data) {
      setGroups(data);
    }
  }, [data]);

  if (loading) {
    return (
      <div className='screen-centered'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer m-0'>
          <div className='card-body p-1'>
            <div className='text-center my-5'>
              <h2>My tokens</h2>
              <h3 className='h6 font-weight-light mb-2 pt-1'>
                Manage your tokens or list new ones
              </h3>
            </div>
            <div
              className='row align-items-center justify-content-center'
              style={{ columnGap: '1rem', rowGap: '1rem' }}
            >
              <AddGroupButton />
              {groups
                ?.filter((group) => group.token != null)
                ?.map((group) => (
                  <GroupsItem
                    key={group.groupId}
                    token={group.token}
                    groupId={group.groupId}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='launchpad-footer-banner admin'></div>
    </div>
  );
};

export default GroupsPage;
