import React from 'react';
import { NftTokenState } from '@buidly/ioiom-staking-dapp/types';
import { ReactComponent as AddIcon } from 'assets/img/blue-chips/ic_add.svg';
import { ReactComponent as CheckIcon } from 'assets/img/blue-chips/ic_check.svg';
import { ReactComponent as UnboundIcon } from 'assets/img/blue-chips/ic_unbound.svg';
import { ReactComponent as UnboundingIcon } from 'assets/img/blue-chips/ic_unbounding.svg';
import { ReactComponent as StakedIcon } from 'assets/img/blue-chips/ic_vest.svg';
import { ReactComponent as XMark } from 'assets/img/blue-chips/ic_xmark.svg';
import colors from 'assets/sass/variables.module.scss';

export interface BlueChipMarkIconProps {
  selected: boolean;
  state: NftTokenState;
  hovered: boolean;
  className: string;
}

export const BlueChipMarkIcon = ({
  selected,
  state,
  hovered,
  className
}: BlueChipMarkIconProps) => {
  if (hovered) {
    if (selected) {
      return (
        <XMark
          className={className}
          stroke={
            state === NftTokenState.UNSTAKED
              ? colors.colorWhite
              : colors.colorPrimary
          }
        />
      );
    }

    return (
      <CheckIcon
        className={className}
        stroke={
          state !== NftTokenState.UNSTAKED
            ? colors.colorPrimary
            : colors.colorWhite
        }
      />
    );
  }

  if (selected) {
    return (
      <CheckIcon
        className={className}
        stroke={
          state !== NftTokenState.UNSTAKED
            ? colors.colorPrimary
            : colors.colorWhite
        }
      />
    );
  }

  switch (state) {
    case NftTokenState.STAKED:
      return <StakedIcon className={className} />;
    case NftTokenState.UNBOUND:
      return <UnboundingIcon className={className} />;
    case NftTokenState.CLAIMABLE:
      return <UnboundIcon className={className} />;
    default:
      return <AddIcon className={className} stroke={colors.colorWhite} />;
  }
};
