import React from 'react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import ActionOrConnect from 'components/ActionOrConnect';
import './SwapButton.scss';

interface SwapButtonProps {
  disabled: boolean;
  isLoading: boolean;
  settingsEnabled: boolean;
  handleSubmit: (event: any) => void;
  handleSettings: (event: any) => void;
}

const SwapButton = ({
  disabled,
  isLoading,
  settingsEnabled,
  handleSubmit,
  handleSettings
}: SwapButtonProps) => {
  return (
    <ActionOrConnect className='swap-button-connect'>
      <div className='swap-button-container'>
        <div
          className={`swap-button ${disabled ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : (
            <>Swap</>
          )}
        </div>
        <button
          className={`btn settings-button ${settingsEnabled ? 'active' : ''}`}
          onClick={handleSettings}
        >
          <FontAwesomeIcon icon={faCog} />
        </button>
      </div>
    </ActionOrConnect>
  );
};

export default SwapButton;
