import React from 'react';
import { ApolloClient, ApolloError, useQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { ESDT_PRICE } from 'api/queries/esdt';
import { POLLING_INTERVAL } from './useGetPairs';

const useGetEsdtPrice = (
  tokenId: string
): {
  data: string | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
} => {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    ESDT_PRICE,
    {
      variables: {
        tokenId
      },
      pollInterval: POLLING_INTERVAL
    }
  );

  return React.useMemo(() => {
    const returnedData = data?.getTokenPriceUSD;
    return {
      data: returnedData ? new BigNumber(returnedData).toFixed(4) : undefined,
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [tokenId, data, loading, error]);
};

export const refetchEsdtPrice = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [ESDT_PRICE]
  });
};

export { useGetEsdtPrice };
