import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';

export const API_GRAPHQL = 'https://api.exrond.com/graphql';
export const API_FAUCET_GRAPHQL = 'https://api-faucet.exrond.com/graphql';
export const API_NFT_STAKING_GRAPHQL = 'https://nft-staking.exrond.com/graphql';
export const API_ADDRESS = 'https://api.exrond.com';
export const API_MULTIVERSX = 'https://api.multiversx.com';
export const UPDATE_REFRESH_RATE = 60000;
export const DECIMALS = 4;
export const ENVIRONMENT = EnvironmentsEnum.mainnet;
export const EGLD = {
  name: 'Elrond eGold',
  identifier: 'EGLD',
  icon: '/playground_assets/elrond_logo.svg',
  ticker: 'EGLD',
  decimals: 18
};
export const WEGLD = {
  name: 'WrappedEGLD',
  identifier: 'WEGLD-bd4d79',
  icon: 'https://raw.githubusercontent.com/multiversx/mx-assets/master/tokens/WEGLD-bd4d79/logo.png',
  ticker: 'WEGLD',
  decimals: 18
};
export const TOKEN_PLACEHOLDER = '/playground_assets/token_placeholder.svg';
export const contractAddress = '';
export const dAppName = 'Exrond';
export const MINIMUM_AMOUNT = 0.001;
export const TOLERANCE = 0.1;
export const MAX_TOLERANCE = 0.9;
export const MIN_TOLERANCE = 0.0001;
export const ADD_LIQUIDITY_MIN_WEGLD_AMOUNT = 1;
export const TRACKING_ID = 'G-WG510PPRL5';
export const EXRD_TOKEN_ID = 'EXR-401c82';
export const WALLET_CONNECT_V2_PROJECT_ID = 'b41e91338c8a2bf995716e2f26460c5e';

export const BLUE_CHIPS_COLLECTION_ID = 'EXBC-9697ba';
export const BLUE_CHIPS_MINTER_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqtu3rkew66326avwhsxqyuatleecfz66ruhqst9pry2';
