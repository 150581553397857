import React, { useState } from 'react';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountModal from 'components/AccountModal';

const MobileAccountButton = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <a
        className='btn btn-primary exrond-connect-btn'
        style={{ minWidth: 'unset' }}
        onClick={handleShowModal}
      >
        <FontAwesomeIcon icon={faWallet} />
      </a>

      <AccountModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

export default MobileAccountButton;
