import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import { ClaimPolicyDetails } from './types';

export const validateForm = (
  amount: string,
  availableAmount: BigNumber,
  token?: EsdtToken,
  claimPolicy?: ClaimPolicyDetails,
  claimableAmount?: string,
  maxClaims?: string
): string | undefined => {
  if (!token) {
    return 'No token selected';
  }

  const amountBig = new BigNumber(amount).shiftedBy(token.decimals);
  if (amountBig.isGreaterThan(availableAmount)) {
    return 'Not enough balance';
  }

  if (amount === '') {
    return 'No amount provided';
  }

  if (amount === '0') {
    return 'The amount must be greater than 0';
  }

  if (claimPolicy === undefined) {
    return 'No claim policy defined';
  }

  if (claimableAmount === undefined || Number(claimableAmount) === 0) {
    return 'Invalid claimable amount';
  }

  const claimableAmountBig = new BigNumber(claimableAmount).shiftedBy(
    token.decimals
  );
  if (amountBig.isLessThan(claimableAmountBig)) {
    return 'Claimable amount must be less than total amount';
  }

  if (!amountBig.mod(claimableAmountBig).isEqualTo(0)) {
    return 'Amount is not divisible by claimable amount';
  }

  if (
    maxClaims &&
    claimableAmountBig.multipliedBy(Number(maxClaims)).isGreaterThan(amountBig)
  ) {
    return 'Total amount lower than minimum required';
  }

  return undefined;
};

export const computeTokenAmount = (token?: EsdtToken, amount?: string) => {
  if (amount === undefined) {
    return undefined;
  }

  if (!token) {
    return '0';
  }

  return new BigNumber(amount).shiftedBy(token.decimals).toFixed();
};
