import { EsdtToken } from 'api/types/pairs';
import { ClaimPolicyDetails, FaucetStep } from './types';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_CLAIM_POLICY = 'SET_CLAIM_POLICY';
export const SET_CLAIMABLE_AMOUNT = 'SET_CLAIMABLE_AMOUNT';
export const SET_MAX_CLAIMS = 'SET_MAX_CLAIMS';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_FAUCET_STEP = 'SET_FAUCET_STEP';

type Action =
  | { type: 'SET_TOKEN'; token: EsdtToken }
  | { type: 'SET_AMOUNT'; amount: string }
  | { type: 'SET_CLAIM_POLICY'; claimPolicy: ClaimPolicyDetails }
  | { type: 'SET_CLAIMABLE_AMOUNT'; claimableAmount: string }
  | { type: 'SET_MAX_CLAIMS'; maxClaims: string }
  | { type: 'SET_FAUCET_STEP'; step: FaucetStep }
  | { type: 'SET_INITIAL_STATE' };

export const initialDepositState = {
  token: undefined,
  amount: '',
  claimPolicy: undefined,
  claimableAmount: '',
  maxClaims: '',
  step: FaucetStep.STEP_SELECT_TOKEN
};

export const depositReducer = (
  state: {
    token?: EsdtToken;
    amount: string;
    claimPolicy?: ClaimPolicyDetails;
    claimableAmount: string;
    maxClaims: string;
    step: FaucetStep;
  },
  action: Action
) => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.token };
    case SET_AMOUNT:
      return { ...state, amount: action.amount };
    case SET_CLAIM_POLICY:
      return { ...state, claimPolicy: action.claimPolicy };
    case SET_CLAIMABLE_AMOUNT:
      return { ...state, claimableAmount: action.claimableAmount };
    case SET_MAX_CLAIMS:
      return { ...state, maxClaims: action.maxClaims };
    case SET_FAUCET_STEP:
      return { ...state, step: action.step };
    case SET_INITIAL_STATE:
      return initialDepositState;
    default:
      return state;
  }
};
