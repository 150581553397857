import { gql } from '@apollo/client';

export const GET_EQUIVALENT_QUERY = gql`
  query GetEquivalent(
    $pairAddress: String!
    $tokenInID: String!
    $amount: String!
  ) {
    getEquivalent(
      pairAddress: $pairAddress
      tokenInID: $tokenInID
      amount: $amount
    ) {
      tokenInID
      amountIn
      tokenOutID
      amountOut
    }
  }
`;

export const ADD_LIQUIDITY_MUTATION = gql`
  mutation AddLiquidity($input: AddLiquidityArgs!, $sender: String!) {
    addLiquidity(input: $input, sender: $sender) {
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;

export const LIQUIDITY_POSITIONS_QUERY = gql`
  query LiquidityPositions($sender: String!) {
    liquidityPositions(sender: $sender) {
      pair {
        address
        firstToken {
          identifier
          ticker
          decimals
        }
        secondToken {
          identifier
          ticker
          decimals
        }
      }
      lpToken {
        identifier
        balance
        decimals
        ticker
      }
      lockedLiquidity {
        isUnlocked
        unlockEpoch
      }
      firstTokenAmount
      secondTokenAmount
    }
  }
`;

export const REMOVE_LIQUIDITY_MUTATION = gql`
  mutation RemoveLiquidity($sender: String!, $input: RemoveLiquidityArgs!) {
    removeLiquidity(sender: $sender, input: $input) {
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;

export interface RemoveLiquidityArgs {
  pairAddress: string;
  liquidity: string;
  liquidityTokenID: string;
  tolerance: number;
}
export interface LiquidityPositionsResponse {
  lockedLiquidity: LockedLiquidityPosition | null;
  firstTokenAmount: string;
  lpToken: {
    balance: string;
    identifier: string;
    decimals: number;
    ticker: string;
    assets?: {
      svgUrl?: string;
      pngUrl?: string;
    };
  };
  pair: {
    address: string;
    firstToken: {
      balance: string;
      identifier: string;
      ticker: string;
      decimals: number;
      assets?: {
        svgUrl?: string;
        pngUrl?: string;
      };
    };
    secondToken: {
      balance: string;
      identifier: string;
      ticker: string;
      decimals: number;
      assets?: {
        svgUrl?: string;
        pngUrl?: string;
      };
    };
  };
  secondTokenAmount: string;
}
export interface LockedLiquidityPosition {
  isUnlocked: boolean;
  unlockEpoch: number;
}
export interface AddLiquidityArgs {
  pairAddress: string;
  tolerance: number;
  tokens: AddLiquidityTokenArgs[];
}

export interface AddLiquidityTokenArgs {
  identifier: string;
  amount: string;
}
