import React, { ReactElement, useState } from 'react';
import { useGetWalletAndZeroBalanceTokens } from '../hooks/useGetWalletAndZeroBalanceTokens';
import { SwapCardType } from '../types';
import { SwapPanelContext } from './SwapPanelContext';

type SwapPanelProviderProps = {
  mainColor: string;
  secondaryColor: string;
  zeroBalanceClassName?: string;
  zeroBalanceMarkClassName?: string;
  zeroBalanceHeader: ReactElement;
  zeroBalanceInfoElement?: ReactElement;
  cardType: SwapCardType;
  children: React.ReactNode;
  expanded: boolean;
  otherCardExpanded: boolean;
  setCardExpanded: (cardType: SwapCardType) => void;
};

export const SwapPanelProvider = ({
  mainColor,
  secondaryColor,
  zeroBalanceClassName,
  zeroBalanceMarkClassName,
  zeroBalanceHeader,
  zeroBalanceInfoElement,
  cardType,
  expanded,
  otherCardExpanded,
  setCardExpanded,
  children
}: SwapPanelProviderProps) => {
  const [search, setSearch] = useState('');

  const { walletTokens, zeroBalanceTokens } = useGetWalletAndZeroBalanceTokens(
    search,
    cardType
  );

  const loading = false;

  const contextValue = {
    mainColor,
    secondaryColor,
    zeroBalanceClassName,
    zeroBalanceMarkClassName,
    walletTokens,
    zeroBalanceTokens,
    loading,
    zeroBalanceHeader,
    zeroBalanceInfoElement,
    search,
    cardType,
    expanded,
    otherCardExpanded,
    setCardExpanded,
    setSearch
  };

  return (
    <SwapPanelContext.Provider value={contextValue}>
      {children}
    </SwapPanelContext.Provider>
  );
};
