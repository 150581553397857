export class BlueChipFilterButtonDetails {
  name = '';
  color = '';
  defaultTextColor = 'text-white';
  selectedTextColor = 'text-black';

  constructor(init?: Partial<BlueChipFilterButtonDetails>) {
    Object.assign(this, init);
  }
}
