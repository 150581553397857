import { NftAction, NftFarmDetails } from '@buidly/ioiom-staking-dapp/types';

export const getDefaultBlueChipAction = (
  farmExpired: boolean,
  nftFarmDetails: NftFarmDetails | undefined,
  isDesktop: boolean
): NftAction => {
  if (!nftFarmDetails) {
    return NftAction.STAKE;
  }

  const hasUnstakedNfts = nftFarmDetails.unstaked.length > 0;

  if (
    (isDesktop === true && farmExpired && !hasUnstakedNfts) ||
    (isDesktop === false && (farmExpired || !hasUnstakedNfts))
  ) {
    if (nftFarmDetails.staked.length > 0) {
      return NftAction.UNBOUND;
    }

    if (nftFarmDetails.claimable.length > 0) {
      return NftAction.UNSTAKE;
    }
  }

  return NftAction.STAKE;
};
