import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { EsdtToken } from 'api/tokens/esdtToken.model';
import { NftCollection } from 'api/tokens/nftcollection.model';
import { TOKEN_PLACEHOLDER } from 'config';
import { routeNames } from 'routes';

interface GroupItemProps {
  token: EsdtToken | NftCollection | undefined;
  groupId: string;
}

const GroupsItem = ({ token, groupId }: GroupItemProps) => {
  const navigate = useNavigate();

  const handleOnClicked = () => {
    navigate({
      pathname: routeNames.stakingAdmin,
      search: `?groupId=${groupId}`
    });
  };

  return (
    <div className='card swap-form pt-0 pb-3 col-md-5 col-lg-4 col-xl-3 mx-3'>
      <div className='card-body d-flex flex-row mt-2 py-3 px-1'>
        <img
          src={
            token?.assets?.pngUrl ?? token?.assets?.svgUrl ?? TOKEN_PLACEHOLDER
          }
          className='p-0 token-icon'
          style={{ width: '48px', height: '48px' }}
        />
        <div className='ml-3'>
          <div className='exrond-text text-white'>Group: {groupId}</div>
          <div className='exrond-text text-white'>Token: {token?.ticker}</div>
        </div>
      </div>
      <button className='exrond-btn' onClick={handleOnClicked}>
        <span className='exrond-text text-white font-size-1 font-weight-600'>
          Details
        </span>
      </button>
    </div>
  );
};

export default GroupsItem;
