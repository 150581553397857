import React from 'react';
import { Loader } from '@multiversx/sdk-dapp/UI';
import { useGetTokens } from 'api/hooks/useGetTokens';
import { useAccountDetails } from 'hooks';
import { SwapProvider } from 'pages/SwapV2/Context';
import { SwapButtonContainer } from '../SwapButton';
import { SwapContentBody } from './SwapContentBody';
import './SwapContent.scss';

export const SwapContent = () => {
  const { data: tokens, loading: tokensLoading } = useGetTokens();
  const { loading: accountDetailsLoading } = useAccountDetails();

  if (tokensLoading || accountDetailsLoading) {
    return (
      <div className='screen-centered text-white'>
        <Loader noText />
      </div>
    );
  }

  return (
    <div className='w-100 d-flex flex-column mt-3'>
      <div className='d-flex flex-column justify-content-between'>
        <SwapProvider tokensWithBuyAmount={tokens ?? []}>
          <SwapContentBody />
          <SwapButtonContainer />
        </SwapProvider>
      </div>
    </div>
  );
};
