import React from 'react';
import colors from 'assets/sass/variables.module.scss';
import { BlueChipFilterButtonDetails } from '../../types';

interface BlueChipFilterButtonProps {
  details: BlueChipFilterButtonDetails;
  selected: boolean;
  onClick?: () => void;
  containerClassName?: string;
}

export const BlueChipFilterButton = ({
  details,
  selected,
  onClick,
  containerClassName
}: BlueChipFilterButtonProps) => {
  const { name, color, defaultTextColor, selectedTextColor } = details;

  return (
    <div
      className={`d-flex flex-row rewards-filter-button card card-rewards ${containerClassName}`}
      onClick={onClick}
      style={{
        background: selected ? color : colors.colorPrimary,
        cursor: 'pointer'
      }}
    >
      <div
        className='mark'
        style={{
          background: color,
          height: '50%',
          alignSelf: 'center',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          position: 'absolute'
        }}
      />
      <span
        className={`d-flex align-items-center justify-content-center
        exrond-text ${selected ? selectedTextColor : defaultTextColor} 
        font-weight-500 text-center w-100 py-2`}
      >
        {name}
      </span>
    </div>
  );
};
