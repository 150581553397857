import * as React from 'react';
import BigNumber from 'bignumber.js';
import { EsdtToken } from 'api/types/pairs';
import { useGetCreateFaucetSettings } from './useGetCreateFaucetSettings';

const useGetFaucetAvailableAmount = (
  token: EsdtToken | undefined
): BigNumber => {
  const { faucetSettings } = useGetCreateFaucetSettings();

  const availableAmount = React.useMemo(() => {
    if (!token) {
      return new BigNumber(0);
    }

    const foundToken = faucetSettings?.tokens?.find(
      (t) => t.identifier === token.identifier
    );

    if (!foundToken) {
      return new BigNumber(0);
    }

    const amount = new BigNumber(foundToken.balance);
    return amount;
  }, [token, faucetSettings?.tokens]);

  return availableAmount;
};

export { useGetFaucetAvailableAmount };
