import React, { useMemo } from 'react';
import { ConfirmableButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableButton';
import {
  NftAction,
  NftActionDetailed,
  NftFarmDetails
} from '@buidly/ioiom-staking-dapp/types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import colors from 'assets/sass/variables.module.scss';
import { getBlueChipButtonColorByAction } from '../../helpers';
import { getBlueChipButtonTitleByAction } from '../../helpers/getBlueChipButtonTitleByAction';

export interface BlueChipStickyNftStakingButtonsProps {
  positiveButtonDisabled: boolean;
  farmExpired: boolean;
  nftAction: NftActionDetailed;
  farmEnding: boolean;
  nftFarmDetails: NftFarmDetails | undefined;
  nftFarmDetailsLoading: boolean;
  pending: boolean;
  hasBlueChips: boolean;
  farmAddres: string;
  onNegativeButtonClick: () => void;
  onPositiveButtonClick: (payWithEgld: boolean) => void;
}

export const BlueChipStickyNftStakingButtons = ({
  farmExpired,
  positiveButtonDisabled,
  nftFarmDetailsLoading,
  nftAction,
  farmEnding,
  nftFarmDetails,
  pending,
  hasBlueChips,
  farmAddres,
  onNegativeButtonClick,
  onPositiveButtonClick
}: BlueChipStickyNftStakingButtonsProps) => {
  const positiveButtonTitle = useMemo(() => {
    return getBlueChipButtonTitleByAction(
      nftAction.action,
      nftAction.nfts.length,
      true,
      hasBlueChips
    );
  }, [nftAction]);

  const positiveButtonColor = useMemo(() => {
    return getBlueChipButtonColorByAction(
      nftAction.action,
      farmExpired,
      farmEnding
    );
  }, [nftAction.action, farmEnding, farmExpired, nftFarmDetails]);

  const handlePositiveButtonClick = (event: any) => {
    if (positiveButtonDisabled || pending || nftFarmDetailsLoading) {
      return;
    }

    onPositiveButtonClick(event.payWithEgld ?? false);
  };

  const skipConfirm =
    positiveButtonDisabled ||
    pending ||
    nftFarmDetailsLoading ||
    (!hasBlueChips && nftAction.action === NftAction.STAKE);

  return (
    <div className='d-flex d-lg-none px-3 mt-3 w-100 staking-sticky-container exrond-gradient-container'>
      <div className='container d-flex justify-content-center'>
        <div className='d-flex flex-row sticky-button'>
          <button
            onClick={onNegativeButtonClick}
            className='exrond-btn small-corners w-100 d-flex align-items-center'
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              minHeight: '42px'
            }}
          >
            {true && (
              <FontAwesomeIcon
                icon={faChevronLeft}
                color='#fff'
                style={{ transform: 'translateX(10px)' }}
              />
            )}
            <span className='exrond-text text-white font-size-12p font-weight-500 w-100'>
              Back
            </span>
          </button>
          <ConfirmableButton
            skip={skipConfirm}
            onClick={handlePositiveButtonClick}
            className='exrond-btn-light-blue small-corners w-100'
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              minHeight: '42px',
              backgroundColor: positiveButtonColor
            }}
            contractAddress={farmAddres}
          >
            {pending || nftFarmDetailsLoading ? (
              <Spinner as='span' animation='border' size='sm' />
            ) : (
              <span
                className='exrond-text font-size-12p font-weight-500'
                style={{
                  color: colors.colorBlack
                }}
              >
                {positiveButtonTitle}
              </span>
            )}
          </ConfirmableButton>
        </div>
      </div>
    </div>
  );
};
