import React, { useEffect, useMemo, useState } from 'react';
import { ConfirmableButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableButton';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CREATE_MERGE_TOKENS_TRANSACTION } from 'api/mutations/MergeTokensMutation';
import {
  LockedToken as LockedTokenEntity,
  LockedTokenCollection as LockedTokenCollectionEntity
} from 'api/tokens/locked.token';
import { ReactComponent as ExpandLess } from 'assets/img/expand_less.svg';
import { ReactComponent as ExpandMore } from 'assets/img/expand_more.svg';
import { ReactComponent as Info } from 'assets/img/info.svg';
import LockedToken from '../LockedToken';

interface LockedTokenCollectionProps {
  lockedToken: LockedTokenCollectionEntity;
}

const ShowMergeTooltip = ({
  show,
  collection
}: {
  show: boolean;
  collection: string;
}) => (
  <>
    {show && (
      <OverlayTrigger
        placement='top'
        overlay={(props) => (
          <Tooltip {...props}>
            <p>Minimum 2 and maximum 5 {collection} can be merged.</p>
            <p>
              You will be asked to sign <strong>1 transaction.</strong>
            </p>
            <p>
              Unlock schedule will be averaged out when the tokens are merged.
            </p>
          </Tooltip>
        )}
      >
        <Info style={{ width: '20px', height: '20px', marginLeft: '5px' }} />
      </OverlayTrigger>
    )}
  </>
);

const LockedTokenCollection = ({ lockedToken }: LockedTokenCollectionProps) => {
  const [merge, setMerge] = useState(false);
  const [lockedTokensShown, setLockedTokensShown] = useState(false);
  const [tokensToMerge, setTokensToMerge] = useState<Record<string, boolean>>(
    {}
  );

  const { address } = useGetAccount();

  const [mergeTokensTransaction, { status }] = useGetAndSendTransactions(
    CREATE_MERGE_TOKENS_TRANSACTION
  );

  const handleMergeCheckbox = (checked: boolean, identifier: string) => {
    setTokensToMerge({
      ...tokensToMerge,
      [identifier]: checked
    });
  };

  const formattedTokensToMerge = useMemo(
    () =>
      Object.entries(tokensToMerge)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, value]) => value)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([key, _value]) => key),
    [tokensToMerge]
  );

  const handleConfirmMerge = async (payWithEgld: boolean) => {
    const filteredTokens =
      lockedToken.tokens.filter((token: LockedTokenEntity) =>
        formattedTokensToMerge?.includes(token.identifier)
      ) ?? [];
    const tokens: any[] = filteredTokens.map((token: LockedTokenEntity) => ({
      identifier: token.collection,
      nonce: token.nonce ?? 0,
      amount: token.balance
    }));

    mergeTokensTransaction({
      variables: {
        sender: address,
        tokens: tokens,
        payWithEgld
      }
    });
  };

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      setTokensToMerge({});
      setMerge(false);
    }
  }, [status]);

  const MergeButton = () => {
    if (
      merge &&
      2 <= formattedTokensToMerge.length &&
      formattedTokensToMerge.length <= 4
    ) {
      return (
        <>
          <ConfirmableButton
            onClick={(event: any) => {
              handleConfirmMerge(event.payWithEgld);
            }}
            loaderClassName='exrond-btn-light'
            className='exrond-btn-light'
            disabled={status === 'pending'}
          >
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Confirm merge
            </span>
          </ConfirmableButton>
          <ShowMergeTooltip show={merge} collection={lockedToken.collection} />
        </>
      );
    }

    if (merge) {
      return (
        <>
          <button className='exrond-btn-light' onClick={() => setMerge(false)}>
            <span className='exrond-text text-white font-size-1 font-weight-600'>
              Cancel
            </span>
          </button>
          <ShowMergeTooltip show={merge} collection={lockedToken.collection} />
        </>
      );
    }

    return (
      <>
        <button
          className='exrond-btn-light mt-3'
          onClick={() => setMerge(true)}
        >
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Merge {lockedToken.collection}
          </span>
        </button>
        <ShowMergeTooltip show={merge} collection={lockedToken.collection} />
      </>
    );
  };

  return (
    <div>
      <div
        className='d-flex'
        onClick={() => {
          setLockedTokensShown(!lockedTokensShown);
        }}
      >
        <p className='m-0'>{lockedToken.collection}</p>
        <p className='m-0'>
          {' '}
          {lockedTokensShown ? <ExpandLess /> : <ExpandMore />}
        </p>
      </div>
      <Collapse in={lockedTokensShown}>
        <div
          id='locked-tokens-list'
          style={{ textAlign: 'center', marginTop: '8px' }}
        >
          {lockedToken.tokens?.map((token, key) => {
            if (merge) {
              return (
                <div className='d-flex flex-row align-items-center' key={key}>
                  <input
                    className='mr-2'
                    type='checkbox'
                    id={token.identifier}
                    checked={tokensToMerge[token.identifier] ?? false}
                    onChange={(event: any) =>
                      handleMergeCheckbox(
                        event.target.checked,
                        token.identifier
                      )
                    }
                  />
                  <label style={{ width: '100%' }} htmlFor={token.identifier}>
                    <LockedToken token={token} showUnlockButton={!merge} />
                  </label>
                </div>
              );
            }

            return (
              <div key={key}>
                <LockedToken token={token} showUnlockButton={!merge} />
              </div>
            );
          })}
          {lockedToken.tokens.length > 0 && <MergeButton />}
        </div>
      </Collapse>
    </div>
  );
};

export default LockedTokenCollection;
