import { useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import useBatchTransactions from '@buidly/ioiom-staking-dapp/hooks/useBatchTransactions';
import { Transaction } from '@multiversx/sdk-core/out';
import { refetchAccountDetails } from 'hooks/useAccountDetails';

const useSendSwapTransactions = (transactions: Transaction[][] | undefined) => {
  const [sendBatchTransactions, { status }] = useBatchTransactions();
  const client = useApolloClient();

  const sendSwapTransactions = () => {
    if (transactions === undefined || transactions.length === 0) {
      return;
    }

    sendBatchTransactions(transactions);
  };

  const transactionsPending = useMemo(() => {
    return status === 'pending';
  }, [status]);

  useEffect(() => {
    if (status === 'success') {
      refetchAccountDetails(client);
    }
  }, [status]);

  return {
    sendSwapTransactions,
    transactionsPending
  };
};

export { useSendSwapTransactions };
