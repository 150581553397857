import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mq = window.matchMedia(query);
    setMatches(mq.matches);

    const handleMatchMediaChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    mq.addEventListener('change', handleMatchMediaChange);

    return () => {
      mq.removeEventListener('change', handleMatchMediaChange);
    };
  }, [query]);
  return matches;
};

export default useMediaQuery;
