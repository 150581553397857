import React from 'react';
import { ApolloClient, ApolloError, useQuery } from '@apollo/client';
import {
  LiquidityPositionsResponse,
  LIQUIDITY_POSITIONS_QUERY
} from 'components/LiquidityCard/liquidity';
const POLLING_INTERVAL = 6000;

const useGetLiquidityPositions = (
  address: string
): {
  data: LiquidityPositionsResponse[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
} => {
  const { data, loading, error, startPolling, stopPolling } = useQuery(
    LIQUIDITY_POSITIONS_QUERY,
    {
      variables: {
        sender: address
      },
      pollInterval: POLLING_INTERVAL
    }
  );

  return React.useMemo(() => {
    const returnedData: LiquidityPositionsResponse[] = data?.liquidityPositions;
    return {
      data: returnedData ? returnedData : undefined,
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [address, data, loading, error]);
};

export const refetchEsdtPrice = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [LIQUIDITY_POSITIONS_QUERY]
  });
};

export { useGetLiquidityPositions };
