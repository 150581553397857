import React from 'react';
import { GenericFarmFilterArgs } from '@buidly/ioiom-staking-dapp/types';
import { BLUE_CHIPS_COLLECTION_ID } from 'config';
import NftStakingPageContent from './NftStakingPageContent';

const NftStakingPage = () => {
  const nftFilterArgs: GenericFarmFilterArgs = {
    farmingTokenIdentifier: {
      excludes: [BLUE_CHIPS_COLLECTION_ID]
    }
  };

  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer m-0'>
          <div className='card-body p-1 mt-2 mt-lg-5'>
            <NftStakingPageContent filterArgs={nftFilterArgs} />
          </div>
        </div>
      </div>
      <div className='launchpad-footer-banner'></div>
    </div>
  );
};

export default NftStakingPage;
