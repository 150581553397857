import { gql } from '@apollo/client';

export const GET_FAUCETS = gql`
  query faucets($sender: String!, $status: FaucetStatus, $noCache: Boolean) {
    faucets(sender: $sender, status: $status, noCache: $noCache) {
      faucets {
        token {
          ticker
          identifier
          name
          decimals
          assets {
            svgUrl
            pngUrl
          }
        }
        faucetId
        status
        actionButton
        claimPolicy
        rewardPool
        reward
        valueUsd
        actionButton
        progress {
          startTimestamp
          endTimestamp
        }
      }
      claimInfo {
        feeToken {
          ticker
          identifier
          name
          decimals
          assets {
            svgUrl
            pngUrl
          }
        }
        feeAmount
      }
      totalClaimableUsd
    }
  }
`;
