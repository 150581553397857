import React, { useEffect, useState } from 'react';
import { FaucetProgress, FaucetStatus } from 'api/types/faucets';
import { EsdtToken } from 'api/types/pairs';
import { TOKEN_PLACEHOLDER } from 'config';
import { computeFaucetTimeLeft } from '../utils';
import FaucetReward from './FaucetReward';
import ProgressBar from './ProgressBar';

export interface FaucetNameProps {
  token: EsdtToken;
  status: FaucetStatus;
  progress?: FaucetProgress;
  reward: string;
  valueUsd: string;
}

const FaucetName = ({
  token,
  status,
  progress,
  reward,
  valueUsd
}: FaucetNameProps) => {
  const claimed = status === FaucetStatus.RECHARGING;

  const [data, setData] = useState<{
    label: string;
    progressValue?: number;
  }>(computeFaucetTimeLeft(progress, status));

  useEffect(() => {
    const updateProgressData = () => {
      setData(computeFaucetTimeLeft(progress, status));
    };

    const interval = setInterval(() => {
      updateProgressData();
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className='d-flex align-items-center mb-lg-0 header-item mr-0 mr-lg-5 flex-grow-1'
      style={{ flexBasis: '35%' }}
    >
      <div className='p-0'>
        <img
          src={token.icon ?? TOKEN_PLACEHOLDER}
          className='p-0 token-icon reward-token-icon'
        />
      </div>
      <div className='d-flex flex-column ml-3 w-100'>
        <div className='d-flex flex-row'>
          <div className='d-flex flex-column justify-content-center faucet-name'>
            <span
              className='reward-card-title'
              style={{ fontSize: '1.285rem' }}
            >
              {token.name}
            </span>
            <div className='d-flex reward-card-subtitle text-blue-gray'>
              {token.identifier}
              {/* <VerifiedLogo className='ml-1' /> */}
            </div>
            {data.progressValue !== undefined && (
              <ProgressBar
                value={data.progressValue}
                label={data.label}
                claimed={claimed}
                className={'d-none d-lg-flex mt-2 p-1 w-100'}
              />
            )}
          </div>
          <FaucetReward
            reward={reward}
            token={token}
            valueUsd={valueUsd}
            className={'d-flex d-lg-none align-items-end flex-grow-1 ml-2'}
          />
        </div>
        {data.progressValue !== undefined && (
          <ProgressBar
            value={data.progressValue}
            label={data.label}
            claimed={claimed}
            className={'d-flex d-lg-none mt-2 w-100'}
          />
        )}
      </div>
    </div>
  );
};

export default FaucetName;
