import React, { useContext, useEffect, useState } from 'react';
import './SwapSettings.scss';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwapContext } from 'pages/SwapV2/Context';
import {
  SwapExchangeRate,
  SwapImpact,
  SwapInitialLiquidityLocked,
  SwapRoute,
  SwapSlippageSettings,
  SwapTotalLiquidity
} from './Components';

interface SwapSettingsProps {
  show: boolean;
}

const SwapSettings = ({ show }: SwapSettingsProps) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const { swapData } = useContext(SwapContext);

  useEffect(() => {
    if (swapData === undefined) {
      setShowExtraInfo(false);
    }
  }, [swapData]);

  if (!show || !swapData) {
    return null;
  }

  return (
    <div className='swap-settings'>
      <SwapSlippageSettings />
      <div className='swap-settings__content'>
        <div className='swap-settings__info'>
          <SwapRoute tokenRoute={swapData.tokenRoute} />
          <SwapExchangeRate
            tokenInID={swapData.tokenInID}
            tokenOutID={swapData.tokenOutID}
            tokenInExchangeRate={swapData.tokenInExchangeRate}
            tokenOutMetadata={swapData.tokenOutMetadata}
          />
        </div>
        {showExtraInfo && (
          <>
            <SwapImpact
              pricesImpact={swapData.pricesImpact}
              fees={swapData.fees}
              tokenRoute={swapData.tokenRoute}
              pairs={swapData.pairs}
            />
            <div>
              <SwapInitialLiquidityLocked
                initialLiquidity={swapData.swapDetails.initialLiquidity}
              />
              <SwapTotalLiquidity
                totalLiquidity={swapData.swapDetails.totalLiquidity}
              />
            </div>
          </>
        )}
      </div>
      <button
        className='swap-settings__toggle'
        onClick={() => setShowExtraInfo(!showExtraInfo)}
      >
        <FontAwesomeIcon icon={showExtraInfo ? faChevronDown : faChevronUp} />
      </button>
    </div>
  );
};

export default SwapSettings;
