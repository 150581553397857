import React from 'react';
import { ActionOrConnect } from '@buidly/dapp-core/dist/components';
import { ConfirmableButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableButton';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaucetActionButton } from 'api/types/faucets';
import { routeNames } from 'routes';
import { getFaucetButtonDetails } from '../utils';

export interface FaucetButtonProps {
  actionButton?: FaucetActionButton;
  onClaimClicked: (payWithEgld: boolean | undefined) => void;
  pending: boolean;
  containerClassName?: string;
}

const FaucetButton = ({
  actionButton,
  onClaimClicked,
  pending,
  containerClassName
}: FaucetButtonProps) => {
  const navigate = useNavigate();

  if (!actionButton) {
    return <></>;
  }

  const handleButtonClick = (event: any) => {
    switch (actionButton) {
      case FaucetActionButton.CLAIM:
        onClaimClicked(event.payWithEgld ?? false);
        break;
      case FaucetActionButton.BUY:
        navigate(routeNames.swap);
        break;
      case FaucetActionButton.STAKE:
        navigate(routeNames.tokensStaking);
        break;
      default:
        break;
    }
  };

  const { name, bgColor, textColor } = getFaucetButtonDetails(actionButton);

  return (
    <div className={`${containerClassName}`}>
      <ActionOrConnect className='reward-button'>
        <ConfirmableButton
          onClick={handleButtonClick}
          skip={actionButton !== FaucetActionButton.CLAIM || pending}
          type='submit'
          disabled={pending && actionButton === FaucetActionButton.CLAIM}
          loaderClassName='reward-button'
          className='reward-button'
          style={{ background: bgColor, color: textColor }}
          isFaucet={true}
        >
          {pending && actionButton === FaucetActionButton.CLAIM ? (
            <Spinner as='span' animation='border' size='sm' />
          ) : (
            name
          )}
        </ConfirmableButton>
      </ActionOrConnect>
    </div>
  );
};

export default FaucetButton;
