import React from 'react';
import BigNumber from 'bignumber.js';

export interface FaucetValueProps {
  valueUsd: string;
}

const FaucetValue = ({ valueUsd }: FaucetValueProps) => {
  const formattedValue = new BigNumber(valueUsd).decimalPlaces(2).toFixed();
  return (
    <div className='header-item mx-1 d-none d-lg-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
      <span className='reward-card-title'>Value</span>
      <span className='reward-card-subtitle'>$ {formattedValue}</span>
    </div>
  );
};

export default FaucetValue;
