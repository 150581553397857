import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import Select from 'react-select';
import { CREATE_PAIR } from 'api/mutations/admin';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import Loader from 'components/Loader';
import { WEGLD } from 'config';
import { useCreatePairTokens } from 'pages/Admin/hooks/useCreatePairTokens';
import { refetchOngoingPairSetup } from 'pages/Admin/hooks/useOngoingPairSetup';
import '../../../../components/SwapCard/SwapCard.scss';

const CreatePair = () => {
  const client = useApolloClient();

  const secondTokens = useCreatePairTokens();
  const [secondTokenID, setSecondTokenID] = React.useState<EsdtToken>();
  const [createPair, { status, error }] =
    useGetAndSendTransactions(CREATE_PAIR);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    createPair({
      variables: {
        secondTokenID: secondTokenID?.identifier
      }
    });
  };

  React.useEffect(() => {
    // TODO check empty esdts
    if (secondTokens.length > 0) {
      setSecondTokenID(secondTokens[0]);
    }
  }, [secondTokens.length]);

  React.useEffect(() => {
    if (status === 'success') {
      console.log('refetch');
      refetchOngoingPairSetup(client);
    }
  }, [status]);

  if (status === 'pending') {
    return <Loader />;
  }

  const formatOptionLabel = (token: EsdtToken) => {
    return (
      <div>
        {token.name} <span className='home-text14'>({token.ticker})</span>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className='w-100 exrond-swap-card'
        style={{ backgroundColor: colors.colorPrimaryDark }}
      >
        <span className='swap-title'>Create Pair</span>
        <Select
          className={'exrond-input primary exrond-select'}
          classNamePrefix={'exrond'}
          placeholder={'Select token'}
          options={secondTokens}
          value={secondTokenID}
          formatOptionLabel={(option) => formatOptionLabel(option)}
          getOptionValue={(option) => option.identifier}
          onChange={(o) => {
            o && setSecondTokenID(o);
          }}
        />

        <div className={'d-flex align-items-center mt-4 exrond-input primary'}>
          <input
            className='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent home-text13'
            type='text'
            value={`WEGLD (${WEGLD.identifier})`}
            disabled={true}
            style={{ opacity: '0.5' }}
          />
        </div>
        <button className='exrond-btn mt-3'>
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Create pair
          </span>
        </button>
        {error && (
          <div className='text-danger mt-4'>{error ?? 'An error occurred'}</div>
        )}
      </div>
    </form>
    // <form onSubmit={handleSubmit}>
    //   <h2>CreatePair</h2>
    //   <div>
    //     <label>First token</label>
    //     <select
    //       onChange={(event) => setSecondTokenID(event.target.value)}
    //       value={secondTokenID}
    //     >
    //       {secondTokens.map((token) => (
    //         <option key={token.identifier} value={token.identifier}>
    //           {token.name} ({token.identifier})
    //         </option>
    //       ))}
    //     </select>
    //   </div>
    //   <div>
    //     <label>Second token</label>
    //     <span>
    //       <b>{WEGLD_ID}</b>
    //     </span>
    //     <div />
    //   </div>
    //   <button type='submit'>Create pair</button>
    //   {error && <div className='text-danger'>An error occurred</div>}
    // </form>
  );
};

export default CreatePair;
