import React, { ReactElement, useContext } from 'react';
import './SwapSelectHeader.scss';
import { SwapPanelContext } from 'pages/SwapV2/Context';

interface SwapSelectHeaderProps {
  text: string;
  icon: ReactElement;
}

const SwapSelectHeader = ({ text, icon }: SwapSelectHeaderProps) => {
  const { secondaryColor } = useContext(SwapPanelContext);

  return (
    <div
      className={`swap-header swap-header--${secondaryColor} swap-header--borderless-top`}
    >
      <span className='swap-header__text'>{text}</span>
      {icon}
    </div>
  );
};

export default SwapSelectHeader;
