import './index.css';
import './assets/sass/index.scss';
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import client from './apollo-client';
import App from './App';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
