import React from 'react';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { getCurrentEpoch } from 'apiRequests/getCurrentEpoch';

export const useGetCurrentEpoch = () => {
  const [epoch, setEpoch] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { network } = useGetNetworkConfig();
  const setCurrentEpoch = async () => {
    setLoading(true);
    getCurrentEpoch(`${network.apiAddress}/stats`)
      .then((response) => {
        setEpoch(response.data.epoch);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    setCurrentEpoch();
  }, []);

  return { epoch, loading };
};
