import { gql } from '@apollo/client';

export const CREATE_FINISH_VESTING_TRANSACTION = gql`
  mutation finishVestingSetup($sender: String!, $groupId: String!) {
    finishVestingSetup(sender: $sender, groupId: $groupId) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
