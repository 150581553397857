import * as React from 'react';
import { useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';

interface SuccessProps {
  pairAddress: string;
  firstToken: EsdtToken;
  secondToken: EsdtToken;
}

const Success = ({ pairAddress, firstToken, secondToken }: SuccessProps) => {
  const { network } = useGetNetworkConfig();

  return (
    <div
      className='w-100 exrond-swap-card'
      style={{ backgroundColor: colors.colorPrimaryDark }}
    >
      <span className='swap-title'>Pair created</span>
      <div className='exrond-text text-white'>
        <p>
          The pair{' '}
          <b>
            {firstToken.ticker} - {secondToken.ticker}
          </b>{' '}
          has been created successfully.
        </p>
        <p>
          Pair address:{' '}
          <a
            href={`${network.explorerAddress}/address/${pairAddress}`}
            rel='noreferrer'
            target='_blank'
            className='text-white font-weight-bold'
          >
            {pairAddress}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Success;
