import React, { useContext } from 'react';
import { ReactComponent as WalletIcon } from 'assets/img/ic_wallet.svg';
import { SwapPanelContext } from 'pages/SwapV2/Context';
import SwapSelectHeader from './SwapSelectHeader';

const SwapWalletHeader = () => {
  const { mainColor } = useContext(SwapPanelContext);

  return (
    <SwapSelectHeader
      text='Wallet'
      icon={<WalletIcon className={`swap-svg--${mainColor}`} />}
    />
  );
};

export default SwapWalletHeader;
