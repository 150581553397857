import React, { useMemo, useState } from 'react';
import '../../SwapSettings.scss';
import { useReactiveVar } from '@apollo/client';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as QuestionIcon } from 'assets/img/ic_question.svg';
import { slippageVar } from 'cache';
import { MAX_TOLERANCE, MIN_TOLERANCE } from 'config';

export const SwapSlippageSettings = () => {
  const slippageDefaultValues = [0.01, 0.05, 0.1];
  const slippage = useReactiveVar(slippageVar);

  const [slippageInput, setSlippageInput] = useState<string | undefined>(
    undefined
  );

  const formatSlippage = (value: number) => {
    return `${value * 100}%`;
  };

  const setSlippage = (value: string) => {
    if (value.length === 0 || isNaN(Number(value))) {
      slippageVar(slippageDefaultValues[0]);
      setSlippageInput(value);
      return;
    }

    const split = value.split('.');
    if (split.length > 1 && split[1].length > 2) {
      return;
    }

    const number = Number(value) / 100;
    if (number > MAX_TOLERANCE) {
      return;
    }

    if (number < MIN_TOLERANCE) {
      return;
    }

    setSlippageInput(value);
    slippageVar(number);
  };

  const customSlippage = useMemo(() => {
    const isCustomSlippage = !slippageDefaultValues.includes(slippage);

    if (isCustomSlippage && slippageInput === undefined) {
      setSlippageInput((slippage * 100).toString());
    }
    return isCustomSlippage;
  }, [slippageInput, slippage]);

  return (
    <div className='swap-settings__slippage'>
      {slippageDefaultValues.map((step, index) => (
        <button
          key={index}
          className={`swap-settings__slippage-btn ${
            slippage === step ? 'swap-settings__slippage-btn--active' : ''
          }`}
          onClick={() => {
            slippageVar(step);
            setSlippageInput('');
          }}
        >
          {formatSlippage(step)}
        </button>
      ))}

      <div className='flex-grow-1 position-relative'>
        <input
          className={`swap-settings__slippage-input ${
            customSlippage ? 'swap-settings__slippage-input--active' : ''
          }`}
          type='number'
          placeholder='Custom'
          name='amount'
          autoComplete='off'
          onChange={(event) => setSlippage(event.target.value)}
          value={slippageInput}
        />
        <div className='swap-settings__slippage-info'>
          <span className='swap-settings__text text-green'>Slippage</span>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip>
                Your transaction will revert if the price moves unfavorably by
                more than this percentage
              </Tooltip>
            }
          >
            <QuestionIcon className='ml-2' />
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};
