import React from 'react';
import { GenericFarmFilterArgs } from '@buidly/ioiom-staking-dapp/types';
import { EXRD_TOKEN_ID } from 'config';
import TokensStakingPageContent from './TokenStakingPageContent';

const TokensStakingPage = () => {
  const tokenFilterArgs: GenericFarmFilterArgs = {
    farmingTokenIdentifier: {
      excludes: [EXRD_TOKEN_ID]
    }
  };

  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer m-0'>
          <div className='card-body p-1 mt-2 mt-lg-5'>
            <TokensStakingPageContent filterArgs={tokenFilterArgs} />
          </div>
        </div>
      </div>
      <div className='launchpad-footer-banner'></div>
    </div>
  );
};

export default TokensStakingPage;
