import { NftAction } from '@buidly/ioiom-staking-dapp/types';
import colors from 'assets/sass/variables.module.scss';

export const getBlueChipButtonColorByAction = (
  action: NftAction,
  farmExpired: boolean,
  farmEnding: boolean
) => {
  switch (action) {
    case NftAction.STAKE:
      if (farmEnding) {
        return colors.colorPrimary;
      }

      return '#6BEAFD';
    case NftAction.UNBOUND:
      return colors.colorWarning;
    case NftAction.UNSTAKE:
      return '#6BEAFD';
    case NftAction.CLAIM:
      if (farmExpired) {
        return colors.colorError;
      }
      return '#6BEAFD';
    default:
      return '#6BEAFD';
  }
};
