import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as NumberUtils from '@buidly/dapp-core/dist/utils/number';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import BigNumber from 'bignumber.js';
import { CREATE_UNWRAP_TRANSACTION } from 'api/mutations/UnwrapEgldMutation';
import { CREATE_WRAP_TRANSACTION } from 'api/mutations/WrapEgldMutation';
import colors from 'assets/sass/variables.module.scss';
import { DECIMALS, EGLD, WEGLD } from 'config';
import * as transactionsRequest from 'stakegold-core-dapp/helpers';

interface TokenProps {
  icon: string;
  name: string;
  balance: BigNumber;
  decimals: number;
  ticker: string;
}

enum TokenOption {
  None,
  Wrap,
  Unwrap
}

const Token = ({ icon, name, balance, decimals, ticker }: TokenProps) => {
  const { address } = useGetAccount();

  const [tokenOption, setTokenOption] = useState(TokenOption.None);
  const [input, setInput] = useState('');

  const [wrapTransaction, { data: wrapData }] = useMutation(
    CREATE_WRAP_TRANSACTION
  );

  const [unwrapTransaction, { data: unwrapData }] = useMutation(
    CREATE_UNWRAP_TRANSACTION
  );

  const handleOption = () => {
    switch (tokenOption) {
      case TokenOption.Wrap:
        handleWrap();
        break;
      case TokenOption.Unwrap:
        handleUnWrap();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (wrapData && wrapData.wrapEgld) {
      processTransactions([wrapData.wrapEgld as Transaction], 'Wrap');
    }
  }, [wrapData]);

  useEffect(() => {
    if (unwrapData && unwrapData.unwrapEgld) {
      processTransactions([unwrapData.unwrapEgld as Transaction], 'Unwrap');
    }
  }, [unwrapData]);

  const handleUnWrap = () => {
    unwrapTransaction({
      variables: {
        sender: address,
        value: new BigNumber(input).shiftedBy(decimals).toFixed()
      }
    });
  };

  const handleWrap = () => {
    wrapTransaction({
      variables: {
        sender: address,
        value: new BigNumber(input).shiftedBy(decimals).toFixed()
      }
    });
  };

  const processTransactions = async (
    transactions: Transaction[],
    method: string
  ) => {
    try {
      await transactionsRequest.sendAndSignTransactions(transactions, method);
    } catch (error) {
      console.log('processTransaction error', error);
    }
  };

  const onMaxClicked = () => {
    setInput(NumberUtils.toDenominated(balance, decimals).toFixed());
  };

  return (
    <div className='d-flex flex-row align-items-center'>
      {tokenOption === TokenOption.None && (
        <div className='token-image p-0'>
          <img
            src={icon}
            className='token-icon p-0 mr-2'
            style={{ width: '35px', height: '35px' }}
          />
        </div>
      )}
      <div className='d-flex flex-column flex-md-row align-items-start align-items-md-center w-100'>
        {tokenOption === TokenOption.None ? (
          <div className='d-flex flex-row'>
            <span>{name}</span>
            {ticker === EGLD.ticker && balance.isGreaterThan(0) && (
              <button
                className='exrond-btn-light btn-small mx-2'
                onClick={() => setTokenOption(TokenOption.Wrap)}
              >
                <span className='exrond-text text-white font-size-08 font-weight-300'>
                  Wrap
                </span>
              </button>
            )}
            {ticker === WEGLD.identifier && balance.isGreaterThan(0) && (
              <button
                className='exrond-btn-light btn-small mx-2'
                onClick={() => setTokenOption(TokenOption.Unwrap)}
              >
                <span className='exrond-text text-white font-size-08 font-weight-300'>
                  Unwrap
                </span>
              </button>
            )}
          </div>
        ) : (
          <>
            <div className='form-group text-left mb-0 w-100 mb-2 mb-md-0'>
              <div
                className='d-flex flex-row swap-form-group swap-form-field align-items-center'
                style={{ backgroundColor: colors.colorPrimaryDark }}
              >
                <div className='exrond-input w-100 d-flex align-items-center'>
                  <input
                    className='form-control input-amount input-stake px-3'
                    name='amount'
                    placeholder='Amount'
                    type='text'
                    autoComplete='off'
                    onChange={(event) => setInput(event.target.value)}
                    value={input}
                  />
                </div>
                <span
                  className='exrond-text font-weight-600 font-size-1 text-white align-items-center btn'
                  onClick={onMaxClicked}
                >
                  MAX
                </span>
                <button
                  disabled={!input || input.length === 0}
                  type='submit'
                  className='exrond-btn btn-small mx-2'
                  onClick={handleOption}
                >
                  <span className='exrond-text text-white font-size-08 font-weight-300'>
                    {tokenOption === TokenOption.Wrap ? 'Wrap' : 'Unwrap'}
                  </span>
                </button>
              </div>
            </div>
          </>
        )}
        <div className='d-flex flex-grow-1 justify-content-end ml-0 ml-md-3'>
          <FormatAmount
            value={balance.toFixed()}
            decimals={decimals}
            egldLabel={ticker}
            digits={DECIMALS}
            showLastNonZeroDecimal={false}
            // addCommas={true}
          />
        </div>
      </div>
    </div>
  );
};
export default Token;
