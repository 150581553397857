import { gql } from '@apollo/client';

export const CREATE_REGISTER_GROUP_TRANSACTION = gql`
  mutation registerGroup(
    $sender: String!
    $registerGroupArgs: RegisterGroupArgsModel!
  ) {
    registerGroup(sender: $sender, registerGroupArgs: $registerGroupArgs) {
      transaction {
        nonce
        value
        sender
        receiver
        gasPrice
        gasLimit
        data
        chainID
        version
        options
      }
      groupId
    }
  }
`;
