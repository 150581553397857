import { gql } from '@apollo/client';
export const CREATE_FAUCET = gql`
  mutation createFaucet($sender: String!, $input: CreateFaucetArgs!) {
    createFaucet(sender: $sender, input: $input) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
