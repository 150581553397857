import React from 'react';
import { EsdtToken } from 'api/types/pairs';
import { ReactComponent as FireIcon } from 'assets/img/ic_fire.svg';
import './SwapAnalyticsFilters.scss';

interface SwapAnalyticsHeaderProps {
  tokens: EsdtToken[];
  selectedFilter: 'Popular' | 'New';
  filter: 'Popular' | 'New';
  onFilterSelected: (filter: 'Popular' | 'New') => void;
}

const SwapAnalyticsHeader = ({
  tokens,
  selectedFilter,
  filter,
  onFilterSelected
}: SwapAnalyticsHeaderProps) => {
  if (tokens.length === 0) {
    return null;
  }

  return (
    <span
      onClick={() => onFilterSelected(filter)}
      className={`p-2 swap-analytics-filter__title ${
        selectedFilter === filter
          ? 'swap-analytics-filter__title--selected'
          : ''
      }`}
    >
      {filter}
      <FireIcon
        className={`ml-1 swap-analytics-filter__icon ${
          selectedFilter === filter
            ? 'swap-analytics-filter__icon--selected'
            : ''
        }`}
      />
    </span>
  );
};

export default SwapAnalyticsHeader;
