/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, createContext } from 'react';
import { EsdtToken } from 'api/types/pairs';
import { SwapCardType } from '../types';

type SwapPanelContextData = {
  mainColor: string;
  secondaryColor: string;
  zeroBalanceClassName?: string;
  zeroBalanceMarkClassName?: string;
  walletTokens: EsdtToken[];
  zeroBalanceTokens: EsdtToken[];
  loading: boolean;
  zeroBalanceHeader?: ReactElement;
  zeroBalanceInfoElement?: ReactElement;
  search: string;
  cardType: SwapCardType;
  expanded: boolean;
  otherCardExpanded: boolean;
  setSearch: (text: string) => void;
  setCardExpanded: (cardType: SwapCardType) => void;
};

export const SwapPanelContext = createContext<SwapPanelContextData>({
  mainColor: 'primary',
  secondaryColor: 'primary-dark',
  walletTokens: [],
  zeroBalanceTokens: [],
  loading: false,
  zeroBalanceHeader: undefined,
  zeroBalanceInfoElement: undefined,
  search: '',
  cardType: SwapCardType.RECEIVER,
  expanded: false,
  otherCardExpanded: false,
  setSearch: (text: string) => {},
  setCardExpanded: () => {}
});
