import * as React from 'react';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import { FINISH_PAIR_SETUP } from 'api/mutations/admin';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import Loader from 'components/Loader';
import Success from '../Success';

interface ResumeProps {
  pairAddress: string;
  firstToken: EsdtToken;
  secondToken: EsdtToken;
}

const Resume = ({ pairAddress, firstToken, secondToken }: ResumeProps) => {
  const [finishSetup, { status, error }] =
    useGetAndSendTransactions(FINISH_PAIR_SETUP);

  // React.useEffect(() => {
  //   if (status === 'success') {
  //     // console.log('refetch');
  //     // refetchOngoingPairSetup(client);
  //   }
  // }, [status]);

  const isLoading = React.useMemo(() => {
    if (status === 'pending') {
      return true;
    }
    return false;
  }, [status]);

  if (isLoading) {
    return <Loader />;
  }

  if (status === 'success') {
    return (
      <Success
        pairAddress={pairAddress}
        firstToken={firstToken}
        secondToken={secondToken}
      />
    );
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    finishSetup({
      variables: {
        pairAddress,
        tokens: [
          {
            identifier: firstToken.identifier,
            nonce: 0,
            amount: '0' // dummy value
          },
          {
            identifier: secondToken.identifier,
            nonce: 0,
            amount: '0' // dummy value
          }
        ]
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className='w-100 exrond-swap-card'
        style={{ backgroundColor: colors.colorPrimaryDark }}
      >
        <span className='swap-title text-white'>Resume deploy</span>
        <button className='exrond-btn' type='submit'>
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Resume deploy
          </span>
        </button>
        {error && (
          <div className='text-danger mt-4'>{error ?? 'An error occurred'}</div>
        )}
      </div>
    </form>
  );
};

export default Resume;
