import React from 'react';
import { FaucetStatus } from 'api/types/faucets';
import { getFaucetDetailsByStatus } from 'pages/Faucet/FaucetCard/utils';
import FilterButton from './FilterButton';

export interface FaucetFiltersProps {
  onFilterSelected: (status: FaucetStatus) => void;
  selectedFilter: FaucetStatus;
}

const FaucetFilters = ({
  selectedFilter,
  onFilterSelected
}: FaucetFiltersProps) => {
  return (
    <div className='d-flex flex-row flex-grow-1 justify-content-md-start'>
      <FilterButton
        details={getFaucetDetailsByStatus(FaucetStatus.ALL)}
        selected={selectedFilter === FaucetStatus.ALL}
        onClick={() => onFilterSelected(FaucetStatus.ALL)}
      />
      <FilterButton
        details={getFaucetDetailsByStatus(FaucetStatus.CLAIMABLE)}
        selected={selectedFilter === FaucetStatus.CLAIMABLE}
        onClick={() => onFilterSelected(FaucetStatus.CLAIMABLE)}
        containerClassName={'ml-3'}
      />
      <FilterButton
        details={getFaucetDetailsByStatus(FaucetStatus.RECHARGING)}
        selected={selectedFilter === FaucetStatus.RECHARGING}
        onClick={() => onFilterSelected(FaucetStatus.RECHARGING)}
        containerClassName={'ml-3'}
      />
      <FilterButton
        details={getFaucetDetailsByStatus(FaucetStatus.EXPIRED)}
        selected={selectedFilter === FaucetStatus.EXPIRED}
        onClick={() => onFilterSelected(FaucetStatus.EXPIRED)}
        containerClassName={'ml-3'}
      />
    </div>
  );
};

export default FaucetFilters;
