import * as React from 'react';
import Steps from './components/Steps';

const Admin = () => {
  return (
    <div className='d-flex flex-fill align-items-center container'>
      <div className='d-flex justify-content-center w-100'>
        <div className='col-12 col-md-8'>
          <Steps />
        </div>
      </div>
    </div>
  );
};

export default Admin;
