import * as React from 'react';
import useGetAndSendTransactions from '@buidly/ioiom-staking-dapp/hooks/useGetAndSendTransactions';
import BigNumber from 'bignumber.js';
import { useGetEsdtPrice } from 'api/hooks/useGetEsdtPrice';
import { FINISH_PAIR_SETUP } from 'api/mutations/admin';
import { EsdtToken } from 'api/types/pairs';
import colors from 'assets/sass/variables.module.scss';
import Loader from 'components/Loader';
import { ADD_LIQUIDITY_MIN_WEGLD_AMOUNT } from 'config';
import { computeTokenAmount, computeWegldAmount } from 'pages/Admin/helpers';
import TextField from 'stakegold-core-dapp/components/TextField';
import TooltipSlider from 'stakegold-core-dapp/components/TooltipSlider';
import Success from '../Success';

const validateFirstTokenAmount = (
  firstTokenAmount: string,
  firstToken: EsdtToken
): string | undefined => {
  const amountAsNumber = Number(firstTokenAmount);
  const hasSufficientFunds =
    amountAsNumber <=
    new BigNumber(firstToken.balance)
      .shiftedBy(-firstToken.decimals)
      .toNumber();

  if (!hasSufficientFunds) {
    return 'Insufficient funds';
  }

  if (amountAsNumber < ADD_LIQUIDITY_MIN_WEGLD_AMOUNT) {
    return `Minimum amount is ${ADD_LIQUIDITY_MIN_WEGLD_AMOUNT} WEGLD`;
  }

  return undefined;
};

interface AddInitialLiquidityProps {
  pairAddress: string;
  firstToken: EsdtToken;
  secondToken: EsdtToken;
}

const AddInitialLiquidity = ({
  pairAddress,
  firstToken,
  secondToken
}: AddInitialLiquidityProps) => {
  const { data: firstTokenPrice } = useGetEsdtPrice(firstToken.identifier);

  const [secondTokenAmount, setSecondTokenAmount] = React.useState('1');
  const [secondTokenPriceUSD, setSecondTokenPriceUSD] = React.useState('1');
  const [firstTokenAmount, setFirstTokenAmount] = React.useState('1');
  const [finishSetup, { status, error }] =
    useGetAndSendTransactions(FINISH_PAIR_SETUP);

  const minimumTokenAmount = new BigNumber(1000).shiftedBy(
    -secondToken.decimals
  );

  const isValidMinimumTokenAmount = new BigNumber(
    secondTokenAmount
  ).isGreaterThanOrEqualTo(minimumTokenAmount);

  const isValidSecondTokenAmount =
    Number(secondTokenAmount) <=
    new BigNumber(secondToken.balance)
      .shiftedBy(-secondToken.decimals)
      .toNumber();

  const firstTokenAmountError = validateFirstTokenAmount(
    firstTokenAmount,
    firstToken
  );

  // React.useEffect(() => {
  //   if (status === 'success') {
  //     // console.log('refetch');
  //     // refetchOngoingPairSetup(client);
  //   }
  // }, [status]);

  const isLoading = React.useMemo(() => {
    if (!firstTokenPrice) {
      return true;
    }
    if (status === 'pending') {
      return true;
    }
    return false;
  }, [firstTokenPrice, status]);

  if (isLoading) {
    return <Loader />;
  }

  if (status === 'success') {
    return (
      <Success
        pairAddress={pairAddress}
        firstToken={firstToken}
        secondToken={secondToken}
      />
    );
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    finishSetup({
      variables: {
        pairAddress,
        tokens: [
          {
            identifier: firstToken.identifier,
            nonce: 0,
            amount: new BigNumber(firstTokenAmount)
              .shiftedBy(firstToken.decimals)
              .toFixed(0)
          },
          {
            identifier: secondToken.identifier,
            nonce: 0,
            amount: new BigNumber(secondTokenAmount)
              .shiftedBy(secondToken.decimals)
              .toFixed(0)
          }
        ]
      }
    });
  };

  const handleChangeSecondTokenAmount = (value: string) => {
    setSecondTokenAmount(value);
    setFirstTokenAmount(
      computeWegldAmount(value, secondTokenPriceUSD, firstTokenPrice ?? '0')
    );
  };

  const handleChangeSecondTokenPriceUSD = (value: string) => {
    setSecondTokenPriceUSD(value);
    setFirstTokenAmount(
      computeWegldAmount(secondTokenAmount, value, firstTokenPrice ?? '0')
    );
  };

  const handleChangeWegldAmount = (value: string) => {
    setFirstTokenAmount(value);
    setSecondTokenAmount(
      computeTokenAmount(value, secondTokenPriceUSD, firstTokenPrice ?? '0')
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className='w-100 exrond-swap-card'
        style={{ backgroundColor: colors.colorPrimaryDark }}
      >
        <span className='swap-title text-white'>Add Initial Liquidity</span>
        <div className='w-100'>
          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            value={secondTokenAmount}
            type='number'
            placeholder={`${secondToken.name} amount`}
            callback={(newValue) => handleChangeSecondTokenAmount(newValue)}
          />
          {!isValidSecondTokenAmount && (
            <div className='ml-2 text-danger'>Insufficient funds</div>
          )}
          {!isValidMinimumTokenAmount && (
            <div className='ml-2 text-danger'>
              Minimum amount for {secondToken.ticker} is{' '}
              {minimumTokenAmount.toFixed()}
            </div>
          )}
          <TooltipSlider
            railStyle={{ backgroundColor: colors.colorPrimary }}
            tipFormatter={(val) => `${val}`}
            step={0.1}
            onChange={(value) => {
              const input = value as number;
              handleChangeSecondTokenAmount(input.toString());
            }}
            value={Number(secondTokenAmount)}
            tipProps={''}
            min={0}
            max={new BigNumber(secondToken.balance)
              .shiftedBy(-secondToken.decimals)
              .toNumber()}
          />
        </div>
        <div className='w-100'>
          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            value={secondTokenPriceUSD}
            type='number'
            placeholder={`Token price in USD (1 ${firstToken.identifier} = ${firstTokenPrice} USD)`}
            callback={(newValue) => handleChangeSecondTokenPriceUSD(newValue)}
          />
          <TooltipSlider
            railStyle={{ backgroundColor: colors.colorPrimary }}
            tipFormatter={(val) => `${val}`}
            step={0.001}
            onChange={(value) => {
              const input = value as number;
              handleChangeSecondTokenPriceUSD(input.toString());
            }}
            value={Number(secondTokenPriceUSD)}
            tipProps={''}
            min={0.001}
            max={Number(firstTokenPrice ?? '0')}
          />
        </div>
        <div className='w-100'>
          <TextField
            labelClassName='exrond-text text-white font-size-1 font-weight-600'
            divWrapperClassName='exrond-input primary'
            inputClassName='exrond-input-content exrond-text font-weight-600 font-size-1 text-white bg-transparent'
            value={firstTokenAmount}
            type='number'
            placeholder={`${firstToken.name} amount`}
            callback={(newValue) => handleChangeWegldAmount(newValue)}
          />
          <TooltipSlider
            railStyle={{ backgroundColor: colors.colorPrimary }}
            tipFormatter={(val) => `${val}`}
            step={0.1}
            onChange={(value) => {
              const input = value as number;
              handleChangeWegldAmount(input.toString());
            }}
            value={Number(firstTokenAmount)}
            tipProps={''}
            min={0}
            max={new BigNumber(firstToken.balance)
              .shiftedBy(-firstToken.decimals)
              .toNumber()}
          />
          {firstTokenAmountError !== undefined && (
            <div className='ml-2 text-danger'>{firstTokenAmountError}</div>
          )}
        </div>
        <button
          className='exrond-btn'
          type='submit'
          disabled={
            firstTokenAmountError !== undefined ||
            !isValidSecondTokenAmount ||
            !isValidMinimumTokenAmount
          }
        >
          <span className='exrond-text text-white font-size-1 font-weight-600'>
            Add Liquidity
          </span>
        </button>
        {error && (
          <div className='text-danger mt-4'>{error ?? 'An error occurred'}</div>
        )}
      </div>
    </form>
  );
};

export default AddInitialLiquidity;
