import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_TOKENS } from 'api/queries/tokens';
import { TokenWithBuyAmount } from 'api/types';

export const POLLING_INTERVAL = 6000;

const useGetTokens = (): {
  data: TokenWithBuyAmount[];
  loading: boolean;
  error: ApolloError | undefined;
} => {
  const { data, loading, error, startPolling, stopPolling } =
    useQuery(GET_TOKENS);

  return React.useMemo(() => {
    return {
      data:
        data?.tokens?.map((response: any) =>
          TokenWithBuyAmount.fromResponse(response)
        ) ?? [],
      loading,
      error,
      startPolling,
      stopPolling
    };
  }, [data, loading, error]);
};

export { useGetTokens };
