import { EsdtToken } from './pairs';

export class TokenWithBuyAmount {
  token!: EsdtToken;
  buyAmount = '';

  constructor(init?: Partial<TokenWithBuyAmount>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): TokenWithBuyAmount {
    const token = new TokenWithBuyAmount({
      token: EsdtToken.fromResponse(response.token),
      buyAmount: response.buyAmount
    });
    return token;
  }
}
