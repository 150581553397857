import * as React from 'react';
import { useReactiveVar } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { Pair } from 'api/types/pairs';
import { slippageVar } from 'cache';
import Loader from 'components/Loader';
import './LiquidityInfoBox.scss';
interface LiquidityInfoBoxProps {
  backgroundColor: string;
  pair: Pair;
}
const LiquidityInfoBox = ({ backgroundColor, pair }: LiquidityInfoBoxProps) => {
  const slippage = useReactiveVar(slippageVar);

  const firstTokenPricePerSecondTokenPrice = React.useMemo(() => {
    return new BigNumber(pair.secondTokenPrice ?? '0').toFixed(6);
  }, [pair.secondTokenPrice]);

  const secondTokenPricePerFirstTokenPrice = React.useMemo(() => {
    return new BigNumber(pair.firstTokenPrice ?? '0').toFixed(6);
  }, [pair.firstTokenPrice]);

  const isLoading = React.useMemo(() => {
    if (!pair) {
      return true;
    }
    return false;
  }, [pair]);

  return (
    <div
      className='card w-100 text-center mt-2 border-info-box'
      style={{ backgroundColor }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <span className='exrond-text text-white font-size-1 font-weight-500'>
              Slippage
            </span>
            <span className='exrod-text text-white font-size-1 font-weight-500'>
              {slippage * 100}%
            </span>
          </div>

          <div className='d-flex justify-content-between mt-2'>
            <span className='exrond-text text-white font-size-1 font-weight-500 text-left'>
              {pair.secondToken.ticker} per {pair.firstToken.ticker}
            </span>
            <span className='exrod-text text-white font-size-1 font-weight-500'>
              {secondTokenPricePerFirstTokenPrice}
            </span>
          </div>
          <div className='d-flex justify-content-between mt-2'>
            <span className='exrond-text text-white font-size-1 font-weight-500 text-left'>
              {pair.firstToken.ticker} per {pair.secondToken.ticker}
            </span>
            <span className='exrod-text text-white font-size-1 font-weight-500'>
              {firstTokenPricePerSecondTokenPrice}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiquidityInfoBox;
