import { ApolloClient, useQuery } from '@apollo/client';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { ONGOING_PAIR_SETUP } from 'api/queries/admin';
import { OngoingPairSetup } from 'api/types/admin';

const useOngoingPairSetup = (): OngoingPairSetup | undefined => {
  const { address } = useGetAccountInfo();

  const { data } = useQuery(ONGOING_PAIR_SETUP, {
    variables: {
      address: address
    }
  });

  if (!data?.ongoingPairSetup) {
    return undefined;
  }

  return OngoingPairSetup.fromResponse(data.ongoingPairSetup);
};

export const refetchOngoingPairSetup = async (client: ApolloClient<object>) => {
  await client.refetchQueries({
    include: [ONGOING_PAIR_SETUP]
  });
};

export { useOngoingPairSetup };
