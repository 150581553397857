import React from 'react';
import MobileFilter from '@buidly/ioiom-staking-dapp/NftStaking/ui/NftToken/Components/MobileFilter';
import {
  NftFarmDetails,
  NftTokenState
} from '@buidly/ioiom-staking-dapp/types';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'assets/sass/variables.module.scss';

export interface MobileBlueChipsFiltersProps {
  nftFarmDetails: NftFarmDetails | undefined;
  onFilterSelected: (state: NftTokenState | undefined) => void;
  onClose: () => void;
  selectedFilter: NftTokenState | undefined;
}

export const MobileBlueChipsFilters = ({
  nftFarmDetails,
  selectedFilter,
  onFilterSelected,
  onClose
}: MobileBlueChipsFiltersProps) => {
  if (nftFarmDetails === undefined) {
    return null;
  }

  const handleFilterSelected = (state: NftTokenState | undefined) => {
    onFilterSelected(state);
    onClose();
  };

  return (
    <div className='d-flex flex-column mb-4'>
      <div className='d-flex flex-row align-items-center px-3 py-4'>
        <span className='text-white font-weight-500 font-size-14p flex-grow-1'>
          Filter NFTs
        </span>
        <button
          className='d-flex align-items-center justify-content-center bluechip-filter-button py-2'
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      <MobileFilter
        name={'All'}
        markColor={'#004DE2'}
        onClick={() => handleFilterSelected(undefined)}
        selected={selectedFilter === undefined}
      />
      {nftFarmDetails.unstaked.length > 0 && (
        <MobileFilter
          name={'Available'}
          markColor={'#004DE2'}
          onClick={() => handleFilterSelected(NftTokenState.UNSTAKED)}
          selected={selectedFilter === NftTokenState.UNSTAKED}
        />
      )}
      {nftFarmDetails.staked.length > 0 && (
        <MobileFilter
          name={'Vested'}
          markColor={'#6BEAFD'}
          onClick={() => handleFilterSelected(NftTokenState.STAKED)}
          selected={selectedFilter === NftTokenState.STAKED}
        />
      )}
      {nftFarmDetails.unbound.length > 0 && (
        <MobileFilter
          name={'Unbound'}
          markColor={colors.colorWarning}
          onClick={() => handleFilterSelected(NftTokenState.UNBOUND)}
          selected={selectedFilter === NftTokenState.UNBOUND}
        />
      )}
      {nftFarmDetails.claimable.length > 0 && (
        <MobileFilter
          name={'Claimable'}
          markColor={colors.colorSuccess}
          onClick={() => handleFilterSelected(NftTokenState.CLAIMABLE)}
          selected={selectedFilter === NftTokenState.CLAIMABLE}
        />
      )}
    </div>
  );
};
