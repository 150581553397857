import React, { useEffect, useMemo, useState } from 'react';

import { computeGroupProgress } from '@buidly/ioiom-staking-dapp/helpers';
import { getNftsByAction } from '@buidly/ioiom-staking-dapp/NftStaking/helpers/nftTokens.helpers';
import { convertToProperNftAction } from '@buidly/ioiom-staking-dapp/NftStaking/helpers/nftTransactions';
import {
  NftAction,
  NftActionDetailed,
  NftActionType,
  NftFarm,
  NftFarmDetails,
  convertNftTokenStateToNftAction
} from '@buidly/ioiom-staking-dapp/types';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';
import { NftTokenWrapper } from 'api/tokens/nftToken';
import { useMediaQuery } from 'hooks';
import { getDefaultBlueChipAction } from '../../helpers/getDefaultBlueChipAction';
import BlueChipFooterWrapper from '../BlueChipFooterWrapper/BlueChipFooterWrapper';
import { BlueChipsList } from '../BlueChipsList';
import { BlueChipStickyNftStakingButtons } from '../BlueChipStickyNftStakingButtons';
import { LiquidVestingBlueChipsAmount } from '../LiquidVestingBlueChipsAmount/LiquidVestingBlueChipsAmount';
import { LiquidVestingExrRewards } from '../LiquidVestingExrRewards';
import './LiquidVestingCard.scss';

interface LiquidVestingCardProps {
  farm: NftFarm;
  pending: boolean;
  success: boolean;
  expanded: boolean;
  mintSuccessful: number | null;
  onActionClick: (nftActionType: NftActionType) => void;
  onCancelDetailsClick: () => void;
  onMint: () => void;
}

export const LiquidVestingCard = ({
  farm,
  pending,
  success,
  expanded,
  mintSuccessful,
  onActionClick,
  onCancelDetailsClick,
  onMint
}: LiquidVestingCardProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  const [nftTokensExpanded, setNftTokensExpanded] = useState(expanded);
  const [nftFarmDetails, setNftFarmDetails] = useState<NftFarmDetails>();
  const [nftFarmDetailsLoading, setNftFarmDetailsLoading] = useState(false);
  const [nftAction, setNftAction] = useState<NftActionDetailed>({
    action: NftAction.STAKE,
    nfts: []
  } as NftActionDetailed);

  const farmProgressData = useMemo(() => {
    return computeGroupProgress(farm.expiryDate, farm.creationDate);
  }, [farm]);

  const isFarmEnding = useMemo(() => {
    return (
      !farmProgressData.expired && (farmProgressData.progressValue ?? 0) >= 90
    );
  }, [farmProgressData]);

  const [selectedNfts, setSelectedNfts] = useState<
    Map<string, NftTokenWrapper>
  >(new Map());

  useEffect(() => {
    setSelectedNfts(new Map());
  }, [farm]);

  useEffect(() => {
    if (selectedNfts.size === 0) {
      if (nftAction.action === NftAction.CLAIM) {
        return;
      }

      setNftAction({
        action: getDefaultBlueChipAction(
          farmProgressData.expired,
          nftFarmDetails,
          isDesktop
        ),
        nfts: []
      } as NftActionDetailed);
      return;
    }

    const convertedAction = convertNftTokenStateToNftAction(
      Array.from(selectedNfts.values())[0].state
    );

    if (convertedAction === undefined) {
      return;
    }

    setNftAction({
      action: convertedAction,
      nfts: Array.from(selectedNfts.values())
    } as NftActionDetailed);
  }, [selectedNfts, nftFarmDetails, farm]);

  const handleOnCardClick = (event: any) => {
    if (event.target.closest('button') || !isDesktop) {
      return;
    }

    setNftTokensExpanded(!nftTokensExpanded);
  };

  const handleActionClick = (
    action: NftAction,
    payWithEgld: boolean,
    nfts?: NftTokenWrapper[]
  ) => {
    onActionClick(
      convertToProperNftAction(
        action,
        farm.farmAddress,
        farm.groupId,
        payWithEgld,
        nfts
      )
    );
  };

  const handlePositiveButtonClick = (payWithEgld: boolean) => {
    if (nftAction.action === NftAction.STAKE && !hasBlueChips) {
      onMint();
      return;
    }

    const nfts =
      nftAction.nfts.length > 0
        ? nftAction.nfts
        : getNftsByAction(nftAction.action, nftFarmDetails);
    if (nfts.length === 0 && nftAction.action !== NftAction.CLAIM) {
      return;
    }
    handleActionClick(nftAction.action, payWithEgld, nfts);
  };

  const handleClaimAll = (payWithEgld: boolean) => {
    if (isDesktop) {
      handleActionClick(NftAction.CLAIM, payWithEgld);
      return;
    }

    if (nftAction.action === NftAction.CLAIM) {
      setNftAction({
        action: getDefaultBlueChipAction(
          farmProgressData.expired,
          nftFarmDetails,
          isDesktop
        ),
        nfts: []
      } as NftActionDetailed);

      return;
    }

    setNftAction({
      action: NftAction.CLAIM,
      nfts: []
    } as NftActionDetailed);

    setSelectedNfts(new Map());
  };

  const hasBlueChips = useMemo(() => {
    if (nftFarmDetails === undefined) {
      return false;
    }

    return nftFarmDetails.unstaked.length > 0;
  }, [nftFarmDetails]);

  return (
    <div className='d-flex flex-column'>
      <div className='card liquid-vesting d-flex flex-column'>
        <div className='liquid-vesting__empty' />
        <div className='liquid-vesting__content' onClick={handleOnCardClick}>
          <div className='liquid-vesting__mark' />
          <div className='d-flex flex-column flex-lg-row w-100'>
            <LiquidVestingBlueChipsAmount farm={farm} />
            <LiquidVestingExrRewards
              nftTokensExpanded={nftTokensExpanded}
              pending={pending}
              farm={farm}
              handleOnClaimAll={handleClaimAll}
              nftAction={nftAction}
              farmEnding={isFarmEnding}
              handleFarmAction={(providedAction, providedNfts, payWithEgld) => {
                if (!nftTokensExpanded) {
                  setNftTokensExpanded(true);
                  return;
                }

                if (providedNfts.length === 0) {
                  return;
                }

                handleActionClick(providedAction, payWithEgld, providedNfts);
              }}
              handleOnMint={onMint}
              mintSuccessful={mintSuccessful}
            />
          </div>
        </div>
        {nftTokensExpanded && (
          <BlueChipFooterWrapper>
            <BlueChipsList
              selectedNfts={selectedNfts}
              farmExpired={farmProgressData.expired}
              farm={farm}
              onNftFarmDetailsReceived={(details) => setNftFarmDetails(details)}
              onNftsSelected={setSelectedNfts}
              onUnboundAllClick={(payWithEgld) => {
                handleActionClick(
                  NftAction.UNBOUND,
                  payWithEgld,
                  nftFarmDetails?.staked ?? []
                );
              }}
              onUnstakeAllClick={(payWithEgld) => {
                handleActionClick(
                  NftAction.UNSTAKE,
                  payWithEgld,
                  nftFarmDetails?.claimable ?? []
                );
              }}
              success={success}
              pending={pending}
              onNftFarmDetailsLoading={setNftFarmDetailsLoading}
              mintSuccessful={mintSuccessful}
            />
          </BlueChipFooterWrapper>
        )}

        {getIsLoggedIn() && !isDesktop && (
          <BlueChipStickyNftStakingButtons
            farmAddres={farm.farmAddress}
            nftAction={nftAction}
            farmExpired={farmProgressData.expired}
            onNegativeButtonClick={() => {
              onCancelDetailsClick?.();
            }}
            onPositiveButtonClick={handlePositiveButtonClick}
            positiveButtonDisabled={
              farmProgressData.expired && nftAction.action === NftAction.STAKE
            }
            farmEnding={isFarmEnding}
            nftFarmDetails={nftFarmDetails}
            pending={pending}
            hasBlueChips={hasBlueChips}
            nftFarmDetailsLoading={nftFarmDetailsLoading}
          />
        )}
      </div>
    </div>
  );
};
