import React from 'react';
import { ActionOrConnect } from '@buidly/dapp-core/dist/components';
import { ConfirmableButton } from '@buidly/ioiom-staking-dapp/components/TaxPerActionButton/ConfirmableButton';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';

export interface FaucetClaimAllButtonProps {
  onClaimAllClicked: (payWithEgld: boolean | undefined) => void;
  pending: boolean;
  disabled: boolean;
}

const FaucetClaimAllButton = ({
  onClaimAllClicked,
  pending,
  disabled
}: FaucetClaimAllButtonProps) => {
  const { address } = useGetAccount();
  const loggedIn = Boolean(address);

  return (
    <ActionOrConnect className='reward-claim-all-button'>
      <ConfirmableButton
        onClick={(event: any) => {
          onClaimAllClicked(event.payWithEgld);
        }}
        loaderClassName='reward-claim-all-button'
        className='reward-claim-all-button'
        skip={pending || !loggedIn || disabled}
        disabled={pending || !loggedIn || disabled}
        isFaucet={true}
      >
        Claim all rewards
      </ConfirmableButton>
    </ActionOrConnect>
  );
};

export default FaucetClaimAllButton;
