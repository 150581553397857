import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { getTokenValue } from 'apiRequests/getTokenValue';

export const POLLING_INTERVAL = 6000;

const useGetTokenValue = (token: string, amount: string) => {
  const [tokenValue, setTokenValue] = useState<BigNumber | null>(null);

  useEffect(() => {
    const fetchTokenValue = async () => {
      if (amount.length === 0) {
        return;
      }

      try {
        const response = await getTokenValue(token, amount);
        const value = response.data.wegldValue;
        setTokenValue(new BigNumber(value));
      } catch (error) {
        console.error('Failed to fetch token value:', error);
      }
    };

    fetchTokenValue();
  }, [token, amount]);

  return tokenValue;
};

export { useGetTokenValue };
