import React from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { GET_FAUCETS } from 'api/queries';
import { FaucetInfo } from 'api/types/faucets';
import { FAUCET_CLIENT_NAME } from 'apollo-client';

const useGetFaucets = (): {
  data?: FaucetInfo;
  loading: boolean;
  error?: ApolloError;
  refetch: any;
} => {
  const { address } = useGetAccount();

  const { data, loading, error, refetch } = useQuery(GET_FAUCETS, {
    variables: {
      sender: address,
      noCache: false
    },
    context: {
      clientName: FAUCET_CLIENT_NAME
    }
  });

  return React.useMemo(() => {
    const faucets =
      data && data.faucets ? FaucetInfo.fromResponse(data.faucets) : undefined;

    return { data: faucets, loading, error, refetch };
  }, [data, loading, error]);
};

export { useGetFaucets };
