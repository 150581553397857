import React from 'react';
import '../Tokenomics.scss';
import { ProgressDetailLine } from '../models/ProgressDetailLine';
interface TokenomicsProgressBarProps {
  height?: string;
  mobileHeight?: string;
  topDetails?: ProgressDetailLine[];
  bottomDetails?: ProgressDetailLine[];
  innerLines?: number[];
  topLineVisible?: boolean;
  bottomLineVisible?: boolean;
}
export const TokenomicsProgressBar = ({
  innerLines = [],
  topDetails = [],
  bottomDetails = [],
  height = '80px',
  mobileHeight = '50px',
  topLineVisible = true,
  bottomLineVisible = true
}: TokenomicsProgressBarProps) => {
  return (
    <div className='tokenomics__progress-wrapper'>
      <div className='tokenomics__progress-details'>
        {topDetails.length > 0 && (
          <>
            <div className='tokenomics__progress-detail-ghost'>
              <div className='percentage'>30%</div>
              <div className='text'>Public Round</div>
              {topLineVisible && <div className='line'></div>}
            </div>
            {topDetails.map((detail) => (
              <div
                key={`top_${detail.percentagePosition}`}
                className='tokenomics__progress-detail'
                style={{
                  ...(detail.percentagePosition === 100
                    ? {
                        right: 0
                      }
                    : {
                        left: `${detail.percentagePosition}%`
                      }),
                  alignItems:
                    detail.percentagePosition === 100
                      ? 'end'
                      : detail.percentagePosition === 50
                      ? 'center'
                      : 'start'
                }}
              >
                <div className='percentage'>{detail.percentageText}</div>
                <div className='text'>{detail.text}</div>
                {topLineVisible && <div className='line'></div>}
              </div>
            ))}
          </>
        )}
      </div>
      <div
        className='tokenomics__progress-bar'
        style={{ height: window.innerWidth <= 768 ? mobileHeight : height }}
      >
        {innerLines.length > 0 &&
          innerLines.map((percentage) => (
            <div
              key={percentage}
              className='tokenomics__progress-bar-line'
              style={{ left: `${percentage}%` }}
            ></div>
          ))}
      </div>
      <div className='tokenomics__progress-details'>
        {bottomDetails.length > 0 && (
          <>
            <div className='tokenomics__progress-detail-ghost'>
              <div className='percentage'>%</div>
              <div className='text'>Public Round</div>
              {bottomLineVisible && <div className='line'></div>}
            </div>
            {bottomDetails.map((detail) => (
              <div
                key={`bottom_${detail.percentagePosition}`}
                className='tokenomics__progress-detail tokenomics__progress-detail-bottom'
                style={{
                  ...(detail.percentagePosition === 100
                    ? {
                        right: 0
                      }
                    : {
                        left: `${detail.percentagePosition}%`
                      }),
                  alignItems: detail.percentageAllignment
                    ? detail.percentageAllignment
                    : detail.percentagePosition === 100
                    ? 'end'
                    : detail.percentagePosition === 50
                    ? 'center'
                    : 'start'
                }}
              >
                <div className='percentage'>{detail.percentageText}</div>
                <div className='text'>{detail.text}</div>
                {bottomLineVisible && <div className='line'></div>}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
