import {
  FaucetActionButton,
  FaucetProgress,
  FaucetStatus
} from 'api/types/faucets';
import colors from 'assets/sass/variables.module.scss';
import { FilterDetails } from '../FaucetHeader/FaucetFilters/FilterButton';

export const getFaucetDetailsByStatus = (type: FaucetStatus) => {
  switch (type) {
    case FaucetStatus.CLAIMABLE:
      return {
        name: 'Claimable',
        color: colors.colorPrimaryLight
      } as FilterDetails;
    case FaucetStatus.RECHARGING:
      return {
        name: 'Recharging',
        color: colors.colorWarning
      } as FilterDetails;
    case FaucetStatus.EXPIRED:
      return { name: 'Claimed', color: colors.colorError } as FilterDetails;
    default:
      return {
        name: 'All',
        color: colors.colorPrimaryLight
      } as FilterDetails;
  }
};

export const getFaucetButtonDetails = (
  actionButton: FaucetActionButton
): { name: string; bgColor: string; textColor: string } => {
  switch (actionButton) {
    case FaucetActionButton.STAKE:
    case FaucetActionButton.BUY:
    case FaucetActionButton.RECHARGING:
      return {
        name: actionButton,
        bgColor: colors.colorWarning,
        textColor: colors.colorBlack
      };
    case FaucetActionButton.EXPIRED:
      return {
        name: actionButton,
        bgColor: colors.colorPrimary,
        textColor: colors.colorError
      };
    case FaucetActionButton.CLAIMED:
      return {
        name: actionButton,
        bgColor: colors.colorPrimary,
        textColor: colors.colorSuccess
      };
    default:
      return {
        name: actionButton,
        bgColor: colors.colorPrimaryLight,
        textColor: colors.colorWhite
      };
  }
};

export const computeFaucetTimeLeft = (
  faucetProgress: FaucetProgress | undefined,
  status: FaucetStatus
): { label: string; progressValue?: number } => {
  if (
    !faucetProgress ||
    faucetProgress.startTimestamp.length === 0 ||
    faucetProgress.endTimestamp.length === 0 ||
    (status !== FaucetStatus.CLAIMABLE && status !== FaucetStatus.RECHARGING)
  ) {
    return { label: '', progressValue: undefined };
  }

  const claimed = status === FaucetStatus.RECHARGING;

  const start = Number(faucetProgress.startTimestamp);
  const end = Number(faucetProgress.endTimestamp);

  const { progressValue, timeUnit } = computeTimeLeft(start, end);

  const label = claimed ? `Claim in ${timeUnit}` : `Ends in ${timeUnit}`;

  return { label, progressValue };
};

export const computeTimeLeft = (
  start: number,
  end: number
): { progressValue: number; timeUnit: string } => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const totalDuration = end - start;
  const currentDuration = Math.max(0, Math.min(currentTimestamp, end) - start);
  const progressValue = Math.floor((currentDuration / totalDuration) * 100);

  const secondsLeft = Math.max(0, end - currentTimestamp);
  const timeUnit = computeTimeUnit(secondsLeft);

  return { progressValue, timeUnit };
};

export const computeTimeUnit = (
  secondsLeft: number,
  showExtraInfo = true,
  showLongLabels = false
): string => {
  const daysLabel = showLongLabels ? ' Days' : 'd';
  const hoursLabel = showLongLabels ? ' Hours' : 'h';
  const minutesLabel = showLongLabels ? ' Minutes' : 'm';
  const secondsLabel = showLongLabels ? ' Seconds' : 's';

  const minutesLeft = Math.floor(secondsLeft / 60);
  const hoursLeft = Math.floor(minutesLeft / 60);
  const daysLeft = Math.floor(hoursLeft / 24);

  let timeUnit = '';
  if (daysLeft > 0) {
    timeUnit = `${daysLeft}${daysLabel}`;
    if (showExtraInfo && hoursLeft % 24 > 0) {
      timeUnit += ` ${hoursLeft % 24}${hoursLabel}`;
    }

    return timeUnit;
  }

  if (hoursLeft > 0) {
    timeUnit = `${hoursLeft}${hoursLabel}`;
    if (showExtraInfo && minutesLeft % 60 > 0) {
      timeUnit += ` ${minutesLeft % 60}${minutesLabel}`;
    }

    return timeUnit;
  }
  if (minutesLeft > 0) {
    timeUnit = `${minutesLeft}${minutesLabel}`;
    if (showExtraInfo && secondsLeft % 60 > 0) {
      timeUnit += ` ${secondsLeft % 60}${secondsLabel}`;
    }

    return timeUnit;
  }

  return `${secondsLeft}${secondsLabel}`;
};

const abbrev = ['K', 'M', 'B'];

const rnd = (n: number, precision: number): number => {
  const prec = 10 ** precision;
  return Math.round(n * prec) / prec;
};

export const toAbbreviatedNumber = (n: number): string => {
  let base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000));
  const suffix = abbrev[Math.min(abbrev.length - 1, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? `${rnd(n / 1000 ** base, 2)}${suffix}` : `${n}`;
};

export const formatTicker = (ticker?: string): string => {
  if (!ticker || ticker.length === 0) {
    return '';
  }

  const dashIndex = ticker.indexOf('-');
  return dashIndex >= 0 ? ticker.substring(0, dashIndex) : ticker;
};
