import BigNumber from 'bignumber.js';

export const computeTokenAmount = (
  wegldAmount: string,
  tokenPrice: string,
  wegldPrice: string
): string => {
  const usdPriceInWegld = new BigNumber(1).dividedBy(new BigNumber(wegldPrice));
  const tokenPriceInWegld = usdPriceInWegld.multipliedBy(
    new BigNumber(tokenPrice)
  );

  const tokenAmount = new BigNumber(wegldAmount).multipliedBy(
    new BigNumber(1).dividedBy(tokenPriceInWegld)
  );

  return tokenAmount.toFixed();
};

export const computeWegldAmount = (
  tokenAmount: string,
  tokenPrice: string,
  wegldPrice: string
): string => {
  const usdPriceInWegld = new BigNumber(1).dividedBy(new BigNumber(wegldPrice));
  const tokenPriceInWegld = usdPriceInWegld.multipliedBy(
    new BigNumber(tokenPrice)
  );
  const wegldAmount = new BigNumber(tokenAmount).multipliedBy(
    tokenPriceInWegld
  );

  return wegldAmount.toFixed();
};
