import React from 'react';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import AdminButton from '../AdminButton/index';

const AccountSettings = () => {
  // const slippage = useReactiveVar(slippageVar);

  // const [slippageInput, setSlippageInput] = useState<string | undefined>(
  //   undefined
  // );
  // const [errorMessage, setErrorMessage] = useState<string | undefined>(
  //   undefined
  // );

  // const slippageDefaultValues = [0.01, 0.05, 0.1];

  // const customSlippage = useMemo(() => {
  //   const isCustomSlippage = !slippageDefaultValues.includes(slippage);

  //   // When the component is initialized
  //   if (isCustomSlippage && slippageInput === undefined) {
  //     setSlippageInput((slippage * 100).toString());
  //   }
  //   return isCustomSlippage;
  // }, [slippageInput, slippage]);

  // const formatSlippage = (value: number) => {
  //   return `${value * 100}%`;
  // };

  // const setSlippage = (value: string) => {
  //   setSlippageInput(value);
  //   setErrorMessage(undefined);

  //   if (value.length === 0) {
  //     setErrorMessage('Only digits and one . allowed');
  //     return;
  //   }

  //   const split = value.split('.');
  //   if (split.length > 1 && split[1].length > 2) {
  //     setErrorMessage('Maximum 2 decimals');
  //     return;
  //   }

  //   const number = Number(value) / 100;
  //   if (number > MAX_TOLERANCE) {
  //     setErrorMessage(`Maximum ${MAX_TOLERANCE * 100}%`);
  //     return;
  //   }

  //   if (number < MIN_TOLERANCE) {
  //     setErrorMessage(`Minimum ${MIN_TOLERANCE * 100}%`);
  //     return;
  //   }

  //   slippageVar(number);
  // };

  return (
    <OverlayTrigger
      trigger='click'
      rootClose
      placement='bottom'
      overlay={
        <Popover
          className='exrond-settings-card'
          style={{ backgroundColor: 'rgb(20, 30, 47)', margin: 0 }}
        >
          <Popover.Body>
            <h4 className='title'>Settings</h4>
            {/* <p>Slippage tolerance</p>
            <div className='d-flex flex-row'>
              {slippageDefaultValues.map((step, index) => (
                <button
                  key={index}
                  className={`btn mr-1 ${slippage === step ? 'active' : ''}`}
                  style={{ paddingLeft: '0.75rem', paddingRight: '0.75rem' }}
                  onClick={() => {
                    slippageVar(step);
                    setSlippageInput('');
                    setErrorMessage(undefined);
                  }}
                >
                  {formatSlippage(step)}
                </button>
              ))}

              <div
                className={`d-flex flex-row swap-form-group swap-form-field align-items-center py-0 px-3 btn slippage-input ${
                  customSlippage ? 'active' : ''
                }`}
              >
                <div className='exrond-input w-100 d-flex align-items-center pr-2'>
                  <input
                    className='form-control input-amount input-stake'
                    name='amount'
                    placeholder='0.0'
                    type='number'
                    autoComplete='off'
                    onChange={(event) => setSlippage(event.target.value)}
                    value={slippageInput}
                  />
                </div>
                <span>%</span>
              </div>
            </div> */}
            <p className='mt-4'>Deploy tokens on Exrond</p>
            <div className='d-flex flex-row mt-3 mb-0'>
              <Link
                className='btn btn-primary exrond-admin-btn mr-1'
                to={routeNames.admin}
              >
                Create DEX Pair
              </Link>
              <AdminButton />
            </div>
            <Link
              className='btn btn-primary exrond-admin-btn mt-2'
              to={routeNames.createFaucet}
            >
              Create Rewards Pool
            </Link>
            <Link
              className='btn btn-primary exrond-admin-btn mt-2 ml-2'
              to={routeNames.stakingContracts}
            >
              NFT Farms
            </Link>
            <Link
              className='btn btn-primary exrond-admin-btn mt-2'
              to={routeNames.liquidity}
            >
              Liquidity
            </Link>
            {/* {errorMessage && (
              <div className='text-danger mt-2'>{errorMessage}</div>
            )} */}
          </Popover.Body>
        </Popover>
      }
    >
      <a
        className='btn btn-primary exrond-connect-btn ml-2'
        style={{ minWidth: 'unset' }}
      >
        <FontAwesomeIcon icon={faCog} />
      </a>
    </OverlayTrigger>
  );
};

export default AccountSettings;
