import { gql } from '@apollo/client';

export const CREATE_SET_LOCKED_ASSET_TOKEN_ID_TRANSACTION = gql`
  mutation setLockedAssetTokenId($sender: String!, $stakingScAddress: String!) {
    setLockedAssetTokenId(
      sender: $sender
      stakingScAddress: $stakingScAddress
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
