import React from 'react';
import { NftFarm } from '@buidly/ioiom-staking-dapp/types';
import LiquidVestingBlueChips from 'assets/img/blue-chips/liquid_vesting_blue_chips.png';
import { ReactComponent as VerifiedIcon } from 'assets/img/ic_verified.svg';
import '../LiquidVestingCard/LiquidVestingCard.scss';

interface LiquidVestingBlueChipsAmountProps {
  farm: NftFarm;
}

export const LiquidVestingBlueChipsAmount = ({
  farm
}: LiquidVestingBlueChipsAmountProps) => {
  const blueChipsMinted = farm.myStakedAmount;

  return (
    <div className='d-flex flex-row align-items-center flex-grow-1'>
      <img src={LiquidVestingBlueChips} className='liquid-vesting__bluechips' />
      <div className='liquid-vesting__text-container'>
        <div className='d-flex flex-row position-relative'>
          <span className='liquid-vesting__title'>
            {blueChipsMinted} x BlueChips
          </span>
          <VerifiedIcon className='liquid-vesting__verified' />
        </div>
        <span className='liquid-vesting__subtitle'>
          Minted for {blueChipsMinted} EGLD
        </span>
      </div>
    </div>
  );
};
