/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { DebouncedFunc, debounce } from 'lodash';
import { TokenWithBuyAmount } from 'api/types';
import { EsdtToken } from 'api/types/pairs';
import { SwapData, SwapPair, SwapPairAmounts } from '../types';
import { TokenLiquidity } from '../types/TokenLiquidity';

type SwapContextData = {
  tokens: EsdtToken[];
  tokensWithBuyAmount: TokenWithBuyAmount[];
  currentPair: SwapPair | undefined;
  swapPairAmounts: SwapPairAmounts;
  swapLoading: boolean;
  swapDisabled: boolean;
  transactionsPending: boolean;
  swapData: SwapData | undefined;
  liquidities: TokenLiquidity[];
  setSwapPairAmounts: React.Dispatch<React.SetStateAction<SwapPairAmounts>>;
  setCurrentPair: React.Dispatch<React.SetStateAction<SwapPair | undefined>>;
  debouncedSwap: DebouncedFunc<(inputArgs: any) => Promise<void>>;
  stopSwapPolling: () => void;
  changeDirection: () => void;
  setCardError: React.Dispatch<
    React.SetStateAction<{ sender: boolean; receiver: boolean }>
  >;
  sendSwapTransactions: () => void;
};

export const SwapContext = createContext<SwapContextData>({
  tokens: [],
  tokensWithBuyAmount: [],
  currentPair: undefined,
  swapPairAmounts: {
    amountIn: '',
    amountOut: ''
  },
  swapLoading: false,
  swapDisabled: false,
  transactionsPending: false,
  swapData: undefined,
  liquidities: [],
  setSwapPairAmounts: () => {},
  setCurrentPair: () => {},
  debouncedSwap: debounce(() => Promise.resolve(undefined), 0),
  stopSwapPolling: () => {},
  changeDirection: () => {},
  setCardError: () => {},
  sendSwapTransactions: () => {}
});
