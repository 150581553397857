import React from 'react';
import './Tokenomics.scss';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Actions } from 'assets/img/blue-chips/actions.svg';
import { ReactComponent as Benefits } from 'assets/img/blue-chips/benefits.svg';
import { ReactComponent as Burn } from 'assets/img/blue-chips/burn.svg';
import { ReactComponent as ExtraValue } from 'assets/img/blue-chips/extra_value.svg';
import { ReactComponent as Flower } from 'assets/img/blue-chips/flower.svg';
import { ReactComponent as LiquidVestingRewards } from 'assets/img/blue-chips/liquid_vesting_rewards.svg';
import { ReactComponent as Liquidity } from 'assets/img/blue-chips/liquidity.svg';
import { ReactComponent as Reward } from 'assets/img/blue-chips/reward.svg';
import { ReactComponent as Seed } from 'assets/img/blue-chips/seed.svg';
import { ReactComponent as Widget } from 'assets/img/blue-chips/widget.svg';
import { TokenomicsProgressBar } from './Components/TokenomicsProgressBar';

interface TokenomicsProps {
  onHide: () => void;
  onMint: () => void;
}

export const Tokenomics = ({ onHide, onMint }: TokenomicsProps) => {
  const topDetails = [
    { text: 'Public Round', percentageText: '30%', percentagePosition: 10 },
    { text: 'IOIOM Protocol', percentageText: '20%', percentagePosition: 60 }
  ];
  const bottomDetails = [
    { text: 'Seed Round', percentageText: '10%', percentagePosition: 0 },
    {
      text: 'Exchange Listings',
      percentageText: '20%',
      percentagePosition: 40
    },
    { text: 'Reserve', percentageText: '20%', percentagePosition: 80 }
  ];

  const topDetailsRoundBreakdown = [
    {
      text: 'Exrond EXR/EGLD Liquidity',
      percentageText: '10%',
      percentagePosition: 20
    },
    { text: 'Marketing', percentageText: '25%', percentagePosition: 75 }
  ];
  const bottomDetailsRoundBreakdown = [
    {
      text: 'xExchange EXR/EGLD Liquidity',
      percentageText: '20%',
      percentagePosition: 0
    },
    { text: 'Development', percentageText: '45%', percentagePosition: 35 }
  ];

  const topDetailsBurnRate = [
    {
      text: '',
      percentagePosition: 0,
      percentageText: '3,600 EXR'
    },
    {
      text: '',
      percentagePosition: 40,
      percentageText: '40,000 EXR'
    },
    {
      text: '',
      percentagePosition: 100,
      percentageText: '+200,000 EXR'
    }
  ];

  const bottomDetailsBurnRate = [
    {
      text: 'Real-time',
      percentagePosition: 0,
      percentageText: 'Present'
    },
    {
      text: 'Estimate',
      percentagePosition: 40,
      percentageText: 'Short-term',
      percentageAllignment: 'center'
    },
    {
      text: 'Projection',
      percentagePosition: 100,
      percentageText: 'Future'
    }
  ];

  return (
    <>
      <div className='tokenomics-container'>
        <div className='col-12 col-lg-10'>
          <div className='tokenomics-title my-2'>$EXR Tokenomics</div>
          <div className='tokenomics-text my-4'>
            As a fully decentralized exchange, Exrond redefines the
            possibilities of digital assets. It&apos;s not just a DEX –
            it&apos;s a gateway to a new realm of opportunity. Acting as a DEX
            aggregator on the MultiversX blockchain, Exrond offers unparalleled
            access and seamless interaction. Stake your NFTs, earn enticing
            rewards, and swap tokens effortlessly. Get ready to embrace the
            exhilarating future of decentralization with Exrond and seize the
            limitless potential of your digital assets
          </div>
          <div className='row my-4'>
            <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 col-6 col-md-3'>
              <Widget className='mr-2' />
              Truly Deflationary
            </div>
            <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 col-6 col-md-3'>
              <Actions className='mr-2' /> Actions via $EXR
            </div>
            <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 col-6 col-md-3 mt-2 mt-lg-0'>
              <Reward className='mr-2' />
              Daily Rewards
            </div>
            <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 col-6 col-md-3 mt-2 mt-lg-0'>
              <Benefits className='mr-2' />
              Long-term Benefits
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='tokenomics__flower d-flex flex-row mt-5'>
              <Flower className='mr-2 flex-shrink-0' />
              <div className='d-flex flex-column mt-3 w-100'>
                <span className='tokenomics__flower__title'>
                  You get 10,000 $EXR for every BlueChips you Vest
                </span>
                <span className='tokenomics-text mt-2 d-none d-lg-block'>
                  This is available only if you Vest your BlueChips when the
                  Vesting pool starts. Each day you don’t Vest after the pool
                  starts, you lose 10 $EXR per BlueChips from the total 10,000
                  $EXR!
                </span>
              </div>
              <Flower className='ml-1 tokenomics__flower--rotated flex-shrink-0' />
            </div>
            <span className='tokenomics-text mt-2 d-block d-lg-none'>
              This is available only if you Vest your BlueChips when the Vesting
              pool starts. Each day you don’t Vest after the pool starts, you
              lose 10 $EXR per BlueChips from the total 10,000 $EXR!
            </span>
          </div>
          <div className='d-flex flex-column flex-lg-row mb-5 mt-4'>
            <div className='w-auto w-lg-50 d-flex flex-row'>
              <LiquidVestingRewards className='mr-2 flex-shrink-0' />
              <div className='d-flex flex-column mt-1'>
                <span className='tokenomics-subtitle tokenomics-text__font-weight-600'>
                  Liquid Vesting Rewards
                </span>
                <span className='tokenomics-text mt-2'>
                  When you Vest your BlueChips, you get 10 $EXR per Day for each
                  Vested BlueChips, which is a better deal than paying
                  transactions with EGLD or swapping
                </span>
              </div>
            </div>
            <div className='w-auto w-lg-50 d-flex flex-row mt-3 mt-lg-0'>
              <ExtraValue className='mr-2 mt-2 flex-shrink-0' />
              <div className='d-flex flex-column mt-1 ml-1 ml-lg-0'>
                <span className='tokenomics-subtitle tokenomics-text__font-weight-600'>
                  Extra Value for BlueChips Holders
                </span>
                <span className='tokenomics-text mt-2'>
                  By minting BlueChips you are actively supporting Exrond
                  development and you get FREE use of the platform for 3 years
                  as a non-Power User by using your rewards
                </span>
              </div>
            </div>
          </div>
          <TokenomicsProgressBar
            topDetails={topDetails}
            innerLines={[10, 40, 60, 80]}
            bottomDetails={bottomDetails}
          />
          <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 mt-4 mb-2'>
            <Seed className='mr-2' /> Seed Round Breakdown
          </div>
          <TokenomicsProgressBar
            height={'30px'}
            mobileHeight={'30px'}
            topDetails={topDetailsRoundBreakdown}
            innerLines={[20, 35, 75]}
            bottomDetails={bottomDetailsRoundBreakdown}
          />
          <div className='row mb-5'>
            <div className='col-12 col-lg-5'>
              <div className='tokenomics-item tokenomics-subtitle tokenomics-text__font-weight-600 mt-4'>
                <Burn className='mr-2' /> Estimated $EXR Burn Rate
              </div>
              <TokenomicsProgressBar
                topLineVisible={false}
                topDetails={topDetailsBurnRate}
                bottomDetails={bottomDetailsBurnRate}
                height={'15px'}
                mobileHeight={'15px'}
              />
              <div className='my-2'>
                <span className='burnrate__text--bold'>
                  Present and Short-term Burn Rate -{' '}
                </span>
                <span className='burnrate__text'>
                  Calculated using a combination of the number of individual
                  actions taxed on Exrond and registration fees for Pairs, Pools
                  and Farms.
                </span>
              </div>
              <div>
                <span className='burnrate__text--bold'>
                  Future Projection -{' '}
                </span>
                <span className='burnrate__text'>
                  A burn rate of over 200,000 $EXR will be achieved via new burn
                  mechanisms
                </span>
              </div>
            </div>
            <div className='col-12 col-lg-5'>
              <div className='tokenomics-item tokenomics-text tokenomics-text__font-weight-600 my-4'>
                <Liquidity className='mr-2' /> Liquidity & Circulating Supply
              </div>
              <div className='burnrate__img' />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex d-lg-none px-3 mt-3 w-100 staking-sticky-container exrond-gradient-container'>
        <div className='container d-flex justify-content-center'>
          <div className='d-flex flex-row sticky-button'>
            <button onClick={onHide} className='bottom-buttons__back'>
              <FontAwesomeIcon
                icon={faChevronLeft}
                color='#fff'
                className='bottom-buttons__back-icon'
              />
              Back
            </button>
            <button onClick={onMint} className='bottom-buttons__mint'>
              Mint Blue Chips
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
