import React, { useEffect, useMemo, useState } from 'react';
import { computeNewSelectedNfts } from '@buidly/ioiom-staking-dapp/NftStaking/helpers/nftTokens.helpers';
import { useGetNftTokens } from '@buidly/ioiom-staking-dapp/NftStaking/hooks';
import { NftTokenWrapper } from '@buidly/ioiom-staking-dapp/NftStaking/model/nftToken';
import {
  NftFarm,
  NftFarmDetails,
  NftTokenState
} from '@buidly/ioiom-staking-dapp/types';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Loader, PageState } from '@multiversx/sdk-dapp/UI';
import { BlueChipActionButtons } from '../BlueChipActionButtons';
import { BlueChipsFilters } from '../BlueChipsFilters';
import { BlueChipToken } from '../BlueChipToken';
import { MobileBlueChipsFilters } from '../MobileBlueChipsFilters';

export const MAX_SELECTED_NFTS = 150;

export interface BlueChipsListProps {
  farm: NftFarm;
  farmExpired: boolean;
  selectedNfts: Map<string, NftTokenWrapper>;
  success: boolean;
  pending: boolean;
  mintSuccessful: number | null;
  onNftFarmDetailsReceived: (nftFarmDetails: NftFarmDetails) => void;
  onNftFarmDetailsLoading: (loading: boolean) => void;
  onNftsSelected: (nftMap: Map<string, NftTokenWrapper>) => void;
  onUnboundAllClick: (payWithEgld: boolean) => void;
  onUnstakeAllClick: (payWithEgld: boolean) => void;
}

export const BlueChipsList = ({
  farm,
  farmExpired,
  selectedNfts,
  success,
  pending,
  mintSuccessful,
  onUnboundAllClick,
  onUnstakeAllClick,
  onNftsSelected,
  onNftFarmDetailsReceived,
  onNftFarmDetailsLoading
}: BlueChipsListProps) => {
  const [selectedFilter, setSelectedFilter] = useState<
    NftTokenState | undefined
  >(undefined);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const { data, loading, error, refetch } = useGetNftTokens(farm);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    onNftFarmDetailsReceived(data);
  }, [data]);

  useEffect(() => {
    onNftFarmDetailsLoading(loading);
  }, [loading]);

  const handleTokenSelected = (token: NftTokenWrapper) => {
    onNftsSelected(computeNewSelectedNfts(allNfts, selectedNfts, token));
  };

  const allNfts = useMemo(() => {
    if (data === undefined) {
      return [];
    }

    return [
      ...data.claimable,
      ...data.unbound,
      ...data.staked,
      ...data.unstaked
    ];
  }, [data]);

  const filterMap = useMemo(() => {
    if (data == undefined) {
      return undefined;
    }

    return {
      [NftTokenState.UNSTAKED]: data.unstaked,
      [NftTokenState.STAKED]: data.staked,
      [NftTokenState.UNBOUND]: data.unbound,
      [NftTokenState.CLAIMABLE]: data.claimable
    };
  }, [data]);

  const filteredNftTokens = useMemo(() => {
    if (selectedFilter === undefined || filterMap === undefined) {
      return allNfts;
    }

    const filteredData = filterMap[selectedFilter];

    if (filteredData.length === 0) {
      setSelectedFilter(undefined);
      return allNfts;
    }

    return filteredData;
  }, [selectedFilter, allNfts, filterMap]);

  useEffect(() => {
    if (success || mintSuccessful != null) {
      refetch();
    }
  }, [success, mintSuccessful]);

  if (loading) {
    return (
      <div>
        <Loader noText={true} />
      </div>
    );
  }

  if (error) {
    return (
      <PageState
        icon={faX}
        className='text-muted'
        title='An error has occurred. Please try again later!'
      />
    );
  }

  if (data === undefined || allNfts.length === 0) {
    return (
      <div className='my-5 d-flex justify-content-center'>
        <span className='text-white font-weight-500 font-size-16p'>
          No BlueChips found
        </span>
      </div>
    );
  }

  if (filtersOpen) {
    return (
      <MobileBlueChipsFilters
        onClose={() => setFiltersOpen(false)}
        nftFarmDetails={data}
        onFilterSelected={setSelectedFilter}
        selectedFilter={selectedFilter}
      />
    );
  }

  return (
    <>
      <div className='d-flex flex-column px-2 px-lg-5 mt-4'>
        <div className='d-flex flex-row w-100 pr-2'>
          <BlueChipsFilters
            nftFarmDetails={data}
            onFilterSelected={setSelectedFilter}
            selectedFilter={selectedFilter}
          />

          <BlueChipActionButtons
            nftFarmDetails={data}
            onUnselectAllClick={() => onNftsSelected(new Map())}
            selectedNfts={selectedNfts}
            hasStakedNfts={data.staked.length > 0}
            hasClaimableNfts={data.claimable.length > 0}
            onUnboundAllClick={onUnboundAllClick}
            onUnstakeAllClick={onUnstakeAllClick}
            onFiltersClick={() => setFiltersOpen(true)}
            farmExpired={farmExpired}
            pending={pending}
            farmAddress={farm.farmAddress}
          />
        </div>

        <div className='container my-3 px-0'>
          <div className='row justify-content-center w-100 mx-0'>
            {filteredNftTokens.map((token: any) => {
              return (
                <BlueChipToken
                  key={token.identifier}
                  token={token}
                  selected={selectedNfts.has(token.identifier)}
                  farmExpired={farmExpired}
                  onTokenSelected={handleTokenSelected}
                  pending={pending}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
