import BigNumber from 'bignumber.js';

export interface EsdtToken {
  identifier: string;
  name: string;
  owner: string;
  ticker: string;
  circulatingSupply: string;
  supply: string;
  decimals: number;
  isPaused: boolean;
  canUpgrade: boolean;
  canMint: boolean;
  canBurn: boolean;
  canChangeOwner: boolean;
  canPause: boolean;
  canFreeze: boolean;
  canWipe: boolean;
  type: string;
  balance?: string;
  assets?: Assets;
}

export class EsdtTokenSimplified {
  identifier = '';
  name = '';
  ticker = '';
  decimals = 0;
  balance: BigNumber = new BigNumber(0);
  icon = '';

  constructor(init?: Partial<EsdtTokenSimplified>) {
    Object.assign(this, init);
  }

  static fromResponse(response: any): EsdtTokenSimplified {
    return new EsdtTokenSimplified({
      name: response.name,
      ticker: response.ticker,
      identifier: response.identifier,
      balance: new BigNumber(response.balance),
      decimals: response.decimals,
      icon: response?.assets?.pngUrl ?? response?.assets?.svgUrl
    });
  }
}

export interface Assets {
  svgUrl?: string;
  pngUrl?: string;
}
