import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useLocalStorage } from '@buidly/dapp-core/dist/hooks';
import { Transaction } from '@multiversx/sdk-core/out';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { CREATE_FAUCET } from 'api/mutations/CreateFaucet';
import { EsdtToken } from 'api/types/pairs';
import { FAUCET_CLIENT_NAME } from 'apollo-client';
import { sendAndSignTransactions } from 'stakegold-core-dapp/helpers';
import { ClaimPolicy } from '../Deposit/types';
import { computeTokenAmount } from '../Deposit/utils';

const useGetCreateFaucetTransaction = () => {
  const { address } = useGetAccount();

  const [sessionId, setSessionId] = useLocalStorage<string | null>(
    'createFaucetSessionId',
    null
  );

  const [createFaucet, { data }] = useMutation(CREATE_FAUCET, {
    context: {
      clientName: FAUCET_CLIENT_NAME
    }
  });

  useEffect(() => {
    const transaction = data?.createFaucet;

    if (transaction === undefined) {
      return;
    }

    const sendAndSign = async () => {
      const { sessionId: returnedSessionId } = await sendAndSignTransactions(
        [transaction as Transaction],
        'Create rewards pool'
      );

      setSessionId(returnedSessionId);
    };

    sendAndSign();
  }, [data]);

  const performCreateFaucet = (
    token: EsdtToken | undefined,
    amount: string,
    claimPolicy: ClaimPolicy | undefined,
    claimableAmount: string,
    maxClaims: string
  ) => {
    if (!token || !claimPolicy) {
      return;
    }

    createFaucet({
      variables: {
        sender: address,
        input: {
          token: {
            identifier: token?.identifier,
            amount: computeTokenAmount(token, amount)
          },
          claimPolicy: claimPolicy,
          claimableAmount: computeTokenAmount(token, claimableAmount),
          maxClaimsPerEpoch:
            claimPolicy === ClaimPolicy.LIFETIME ? undefined : maxClaims
        }
      }
    });
  };

  return { performCreateFaucet, sessionId };
};

export { useGetCreateFaucetTransaction };
