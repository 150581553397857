import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from '@multiversx/sdk-dapp/UI';
import { useGetFaucets } from 'api/hooks/useGetFaucets';
import { FaucetDetails, FaucetStatus } from 'api/types/faucets';
import FaucetMobileButton from './FaucetCard/Components/StickyFaucetButton';
import FaucetCard from './FaucetCard/FaucetCard';
import FaucetHeader from './FaucetHeader/FaucetHeader';
import { useGetClaimFaucetTransaction } from './hooks/useGetClaimFaucetTransaction';

const Faucet = () => {
  const { error, loading, data, refetch } = useGetFaucets();

  const {
    performClaimTransaction,
    error: claimError,
    pending: txLoading,
    success: txSuccess
  } = useGetClaimFaucetTransaction();

  const [selectedFilter, setSelectedFilter] = useState(FaucetStatus.ALL);
  const [selectedFaucet, setSelectedFaucet] = useState<
    FaucetDetails | undefined
  >();

  const faucets = useMemo(() => {
    return data?.faucets?.filter((faucet: FaucetDetails) => {
      if (selectedFilter === FaucetStatus.ALL) {
        return true;
      }

      return faucet.status === selectedFilter;
    });
  }, [data?.faucets, selectedFilter]);

  const totalClaimableUsd = useMemo(() => {
    return selectedFilter === FaucetStatus.ALL ||
      selectedFilter === FaucetStatus.CLAIMABLE
      ? data?.totalClaimableUsd
      : '0';
  }, [data?.totalClaimableUsd, selectedFilter]);

  useEffect(() => {
    if (claimError !== undefined) {
      refetch({ noCache: false });
      setSelectedFaucet(undefined);
    }
  }, [claimError]);

  useEffect(() => {
    if (txSuccess) {
      refetch({ noCache: true });
      setSelectedFaucet(undefined);
    }
  }, [txSuccess]);

  if (loading) {
    return (
      <div className='screen-centered text-white'>
        <Loader />
      </div>
    );
  }

  if (error || faucets === undefined) {
    return (
      <div className='screen-centered text-white'>
        An error has occurred. Please come back later!
      </div>
    );
  }

  const handleOnClaimAll = (payWithEgld: boolean | undefined) => {
    const faucetIds = faucets
      ?.filter((faucet) => faucet.status == FaucetStatus.CLAIMABLE)
      ?.map((faucet) => faucet.faucetId);

    if (faucetIds.length > 0) {
      performClaimFaucetsTransaction(faucetIds, payWithEgld);
    }
  };

  const handleOnClaim = (
    faucet: FaucetDetails,
    payWithEgld: boolean | undefined
  ) => {
    performClaimFaucetsTransaction([faucet.faucetId], payWithEgld);
  };

  const performClaimFaucetsTransaction = (
    ids: string[],
    payWithEgld: boolean | undefined
  ) => {
    performClaimTransaction(ids, payWithEgld);
  };

  const handleOnFaucetSelected = (faucet: FaucetDetails) => {
    setSelectedFaucet(
      selectedFaucet?.faucetId === faucet.faucetId ? undefined : faucet
    );
  };

  return (
    <div className='exrond-text text-white container d-flex flex-grow-1 flex-column py-4'>
      <div className='row flex-grow-1'>
        <div className='col-12 py-spacer m-0'>
          <div className='card-body p-1 mt-5'>
            <FaucetHeader
              onFilterSelected={(newFilter) => {
                setSelectedFilter(newFilter);
                setSelectedFaucet(undefined);
              }}
              selectedFilter={selectedFilter}
              totalClaimableUsd={totalClaimableUsd}
              onClaimAllClicked={handleOnClaimAll}
              pending={txLoading}
            />
            {faucets.length === 0 ? (
              <div className='mt-5  w-100 text-center'>No rewards found.</div>
            ) : (
              <>
                <div className='faucet-container'>
                  {faucets.map((faucet: FaucetDetails) => (
                    <FaucetCard
                      key={faucet.faucetId}
                      details={faucet}
                      onClaimClicked={handleOnClaim}
                      pending={txLoading}
                      onClick={handleOnFaucetSelected}
                      selected={selectedFaucet?.faucetId === faucet.faucetId}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {faucets.length > 0 && (
        <FaucetMobileButton
          faucet={selectedFaucet}
          onClaimClicked={handleOnClaim}
          onClaimAllClicked={handleOnClaimAll}
          pending={txLoading}
          disabled={
            selectedFilter !== FaucetStatus.ALL &&
            selectedFilter !== FaucetStatus.CLAIMABLE
          }
        />
      )}
      <div className='launchpad-footer-banner'></div>
    </div>
  );
};

export default Faucet;
