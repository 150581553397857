export enum ClaimPolicy {
  LIFETIME = 'LIFETIME',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export interface ClaimPolicyDetails {
  value: ClaimPolicy;
  label: string;
}

export const claimPolicies: ClaimPolicyDetails[] = [
  { value: ClaimPolicy.LIFETIME, label: 'Once' },
  { value: ClaimPolicy.DAILY, label: 'Daily' },
  { value: ClaimPolicy.WEEKLY, label: 'Weekly' },
  { value: ClaimPolicy.MONTHLY, label: 'Monthly' }
];

export enum FaucetStep {
  STEP_SELECT_TOKEN,
  STEP_FAUCET_DETAILS
}
