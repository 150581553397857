import { gql } from '@apollo/client';

export const QUERY_STAKING_GROUPS = gql`
  query stakingGroups($address: String!) {
    stakingGroups(address: $address, vmQuery: false) {
      farmingToken {
        details {
          identifier
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
        canBeLocked
        canBeUnlocked
      }
      rewardToken {
        details {
          identifier
          decimals
          name
          balance
          ticker
          assets {
            svgUrl
            pngUrl
          }
        }
        canBeLocked
        canBeUnlocked
      }
      totalValueLockedUsd
      apr {
        lowestApr
        highestApr
        type
      }
      myStakedAmount
      myEarnedAmount
      creationDate
      expiryDate
      groupId
      isCompoundAllowed
    }
  }
`;
