import React from 'react';
import BigNumber from 'bignumber.js';
import { ReactComponent as ExrondLogoResponsive } from 'assets/img/ex.svg';
import colors from 'assets/sass/variables.module.scss';
import FaucetClaimAllButton from './FaucetClaimAllButton';

export interface FaucetClaimAllViewProps {
  valueUsd: string;
  onClaimAllClicked: (payWithEgld: boolean | undefined) => void;
  pending: boolean;
  disabled: boolean;
}

const FaucetClaimAllView = ({
  valueUsd,
  onClaimAllClicked,
  pending,
  disabled
}: FaucetClaimAllViewProps) => {
  const formattedValue = new BigNumber(valueUsd).decimalPlaces(4).toFixed();

  return (
    <div
      className='d-none d-lg-flex flex-row align-items-center position-relative justify-content-center justify-content-lg-end'
      style={{ width: '370px' }}
    >
      <span className='reward-claimable-button'>
        Claimable ${formattedValue}
      </span>
      <FaucetClaimAllButton
        onClaimAllClicked={onClaimAllClicked}
        pending={pending}
        disabled={disabled}
      />
      <div className='p-2 claim-all-button-logo'>
        <ExrondLogoResponsive
          fill={colors.colorPrimaryLight}
          width={'20px'}
          height={'20px'}
        />
      </div>
    </div>
  );
};

export default FaucetClaimAllView;
