import { useEffect, useState } from 'react';

export const useSticky = (stickyRef: React.RefObject<HTMLElement>): boolean => {
  const [isSticky, setIsSticky] = useState<boolean>(false);

  useEffect(() => {
    checkIsSticky();

    window.addEventListener('scroll', checkIsSticky);
    return () => {
      window.removeEventListener('scroll', checkIsSticky);
    };
  }, [stickyRef]);

  const checkIsSticky = () => {
    if (stickyRef.current) {
      const { bottom } = stickyRef.current.getBoundingClientRect();
      setIsSticky(bottom >= window.innerHeight);
    }
  };

  return isSticky;
};
