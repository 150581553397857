import React, { useContext } from 'react';
import './SwapPanel.scss';
import { SwapPanelContext } from 'pages/SwapV2/Context';
import SwapAmount from '../SwapAmount/SwapAmount';
import SwapSelect from '../SwapSelect/SwapSelect';

interface SwapPanelProps {
  title: string;
  className?: string;
}

const SwapPanel = ({ title, className }: SwapPanelProps) => {
  const { mainColor, otherCardExpanded, expanded } =
    useContext(SwapPanelContext);

  if (otherCardExpanded) {
    return null;
  }

  return (
    <div
      className={`swap-panel swap-panel--${mainColor} ${className || ''} ${
        expanded ? 'swap-panel--expanded' : ''
      }`}
    >
      {!expanded && (
        <span
          className={`swap-panel__title swap-panel__title--${mainColor} d-block`}
        >
          {title}
        </span>
      )}
      <div>
        <SwapSelect
          className={`swap-panel__select swap-panel__select-token--${
            expanded ? 'expanded' : mainColor
          }`}
        />
        {!expanded && (
          <SwapAmount
            className={`swap-panel__select swap-panel__select-amount--${mainColor}`}
          />
        )}
      </div>
    </div>
  );
};

export default SwapPanel;
