import { NftTokenState } from '@buidly/ioiom-staking-dapp/types';
import colors from 'assets/sass/variables.module.scss';

export const getBlueChipTokenColors = (
  state: NftTokenState,
  isSelected: boolean,
  farmExpired: boolean
): {
  borderColor: string | undefined;
  markColor: string;
  textColor: string;
} => {
  switch (state) {
    case NftTokenState.STAKED:
      return {
        borderColor: farmExpired ? colors.colorError : '#6BEAFD',
        markColor: '#6BEAFD',
        textColor: colors.colorPrimary
      };
    case NftTokenState.UNBOUND:
      return {
        borderColor: colors.colorWarning,
        markColor: colors.colorWarning,
        textColor: colors.colorPrimary
      };
    case NftTokenState.CLAIMABLE:
      return {
        borderColor: colors.colorSuccess,
        markColor: colors.colorSuccess,
        textColor: colors.colorPrimary
      };
    default:
      return {
        borderColor: isSelected ? '#004DE2' : undefined,
        markColor: '#004DE2',
        textColor: 'text-white'
      };
  }
};
