import { useEffect, useState } from 'react';
import { EsdtToken } from 'api/types/pairs';
import { EGLD, EXRD_TOKEN_ID } from 'config';
import { SwapPair } from '../types';

const useGetDefaultPair = (
  tokens: EsdtToken[]
): [
  SwapPair | undefined,
  React.Dispatch<React.SetStateAction<SwapPair | undefined>>
] => {
  const [currentPair, setCurrentPair] = useState<SwapPair | undefined>();

  useEffect(() => {
    if (!tokens || tokens.length === 0) {
      return;
    }

    if (!currentPair) {
      setCurrentPair((prev) => {
        return {
          ...prev,
          tokenIn: tokens.find((token) => token.identifier === EGLD.identifier),
          tokenOut: tokens.find((token) => token.identifier === EXRD_TOKEN_ID)
        };
      });
    }
  }, [tokens]);

  return [currentPair, setCurrentPair];
};

export default useGetDefaultPair;
