import React from 'react';
import '../ExrPage.scss';
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'assets/sass/variables.module.scss';
import { MintButtonContainer } from 'components/BlueChips/MintButtonContainer';
import { useMediaQuery } from 'hooks';

interface ExrPageHeaderMobileProps {
  setVestingClicked: (value: boolean) => void;
  vestingClicked: boolean;
  setTokenomicsClicked: (value: boolean) => void;
  tokenomicsClicked: boolean;
  controlsShown: boolean;
  showControls: (show: boolean) => void;
  mintSuccessful: () => void;
}

const ExrPageHeaderMobile = ({
  setVestingClicked,
  vestingClicked,
  setTokenomicsClicked,
  tokenomicsClicked,
  controlsShown,
  showControls,
  mintSuccessful
}: ExrPageHeaderMobileProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');

  if (vestingClicked || tokenomicsClicked) {
    return (
      <>
        <div className='blue-chips-header blue-chips-header__mobile blue-chips-header__mobile__mini col-12'>
          <div className='blue-chips-header__right-top-star blue-chips-header__right-top-star__mobile blue-chips-header__right-top-star__mobile__mini'></div>
          <div className='blue-chips-header__left-down-crystals blue-chips-header__left-down-crystals__mobile blue-chips-header__left-down-crystals__mobile__mini'></div>
          <div className='blue-chips-header__left-top-star blue-chips-header__left-top-star__mobile blue-chips-header__left-top-star__mobile__mini'></div>
          <div className='blue-chips-header__right-down-crystals blue-chips-header__right-down-crystals__mobile blue-chips-header__right-down-crystals__mobile__mini'></div>
          <div className='d-flex flex-column mx-auto mt-2'>
            <div className='blue-chips-header__mint-btn-container blue-chips-header__mint-btn-container__mobile blue-chips-header__mint-btn-container__mobile__mini'>
              <MintButtonContainer
                miniHeader={true}
                controlsShown={controlsShown}
                showControls={showControls}
                showStickyButtons={false}
                mintSuccessful={mintSuccessful}
              />
            </div>
            <div className='d-flex flex-row mt-3 justify-content-around'>
              <button
                className={`col-6 mr-1 d-flex flex-row justify-content-center align-items-center blue-chips-header__bottom-buttons blue-chips-header__bottom-buttons__mobile blue-chips-header__bottom-buttons__mobile__mini ${
                  tokenomicsClicked &&
                  'blue-chips-header__bottom-buttons__mobile--clicked'
                }`}
                onClick={() => {
                  setTokenomicsClicked(!tokenomicsClicked);
                  setVestingClicked(false);
                }}
              >
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  $EXR Tokenomics
                  {tokenomicsClicked ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      width='6px'
                      height='10px'
                      color={colors.colorWhite}
                      className='ml-1'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      width='6px'
                      height='10px'
                      color={colors.colorWhite}
                      className='ml-1'
                    />
                  )}
                </div>
              </button>
              <button
                className={`col-6 ml-1 d-flex flex-row justify-content-center align-items-center blue-chips-header__bottom-buttons 
            blue-chips-header__bottom-buttons__mobile blue-chips-header__bottom-buttons__mobile__mini
             blue-chips-header__bottom-buttons__vesting blue-chips-header__bottom-buttons__vesting__mobile ${
               vestingClicked &&
               'blue-chips-header__bottom-buttons__mobile--clicked'
             }`}
                onClick={() => {
                  setVestingClicked(!vestingClicked);
                  setTokenomicsClicked(false);
                }}
              >
                Liquid Vesting
                {vestingClicked ? (
                  <FontAwesomeIcon
                    icon={faXmark}
                    width='6px'
                    height='10px'
                    color={colors.colorWhite}
                    className='ml-2'
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    width='6px'
                    height='10px'
                    color={colors.colorWhite}
                    className='ml-2'
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='blue-chips-header blue-chips-header__mobile col-12'>
        <div className='blue-chips-header__right-top-star blue-chips-header__right-top-star__mobile'></div>
        <div className='blue-chips-header__left-down-crystals blue-chips-header__left-down-crystals__mobile'></div>
        <div className='blue-chips-header__center-stars blue-chips-header__center-stars__mobile'></div>
        <div className='blue-chips-header__left-tokens blue-chips-header__left-tokens__mobile'></div>
        <div className='blue-chips-header__left-top-star blue-chips-header__left-top-star__mobile'></div>
        <div className='blue-chips-header__right-down-crystals blue-chips-header__right-down-crystals__mobile'></div>
        <button
          className='blue-chips-header__bottom-buttons blue-chips-header__bottom-buttons__mobile'
          onClick={() => {
            setTokenomicsClicked(true);
          }}
        >
          <div className='d-flex ml-2 flex-row justify-content-center align-items-center'>
            $EXR Tokenomics
            <FontAwesomeIcon
              icon={faChevronRight}
              width='6px'
              height='10px'
              color={colors.colorWhite}
              className='ml-2'
            />
          </div>
        </button>
        <button
          className='d-flex flex-row justify-content-center align-items-center blue-chips-header__bottom-buttons blue-chips-header__bottom-buttons__mobile blue-chips-header__bottom-buttons__vesting blue-chips-header__bottom-buttons__vesting__mobile'
          onClick={() => {
            setVestingClicked(true);
          }}
        >
          Liquid Vesting
          <FontAwesomeIcon
            icon={faChevronRight}
            width='6px'
            height='10px'
            color={colors.colorWhite}
            className='ml-2'
          />
        </button>
        <div className='blue-chips-header__mint-btn-container blue-chips-header__mint-btn-container__mobile'>
          <MintButtonContainer
            controlsShown={controlsShown}
            showControls={showControls}
            showStickyButtons={controlsShown && !isDesktop}
            mintSuccessful={mintSuccessful}
          />
        </div>
      </div>
    </>
  );
};

export default ExrPageHeaderMobile;
