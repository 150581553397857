import { useContext, useMemo } from 'react';
import { EsdtToken } from 'api/types/pairs';
import { useAccountDetails } from 'hooks';
import { SwapContext } from '../Context';
import { getSwapTokenBalance, isSwapOptionDisabled } from '../helpers';
import { SwapCardType } from '../types';
import { useGetCardToken } from './useGetCardToken';

const useGetWalletAndZeroBalanceTokens = (
  search: string,
  cardType: SwapCardType
): {
  walletTokens: EsdtToken[];
  zeroBalanceTokens: EsdtToken[];
} => {
  const { tokens, currentPair } = useContext(SwapContext);
  const { selectedToken } = useGetCardToken(cardType);
  const { accountDetails } = useAccountDetails();

  const filteredTokens = useMemo(() => {
    return tokens
      .filter((swapToken) => {
        if (!currentPair || !selectedToken) {
          return true;
        }

        const otherToken =
          currentPair.tokenIn?.identifier === selectedToken.identifier
            ? currentPair.tokenOut
            : currentPair.tokenIn;

        return !isSwapOptionDisabled(swapToken, otherToken);
      })
      .filter((swapToken) => {
        const searchLowerCase = search.toLocaleLowerCase();

        if (search.length === 0) {
          return true;
        }

        return (
          swapToken.identifier.toLocaleLowerCase().includes(searchLowerCase) ||
          swapToken.name.toLocaleLowerCase().includes(searchLowerCase)
        );
      });
  }, [tokens, currentPair, selectedToken, search]);

  const [walletTokens, zeroBalanceTokens] = useMemo(() => {
    const wallet: EsdtToken[] = [];
    const zeroBalance: EsdtToken[] = [];

    filteredTokens.map((swapToken) => {
      const balance = getSwapTokenBalance(swapToken, accountDetails);

      if (!balance.isZero()) {
        wallet.push({
          ...swapToken,
          balance: balance.toFixed()
        });
      } else {
        zeroBalance.push({ ...swapToken });
      }
    });

    return [wallet, zeroBalance];
  }, [filteredTokens, accountDetails]);

  return { walletTokens, zeroBalanceTokens };
};

export { useGetWalletAndZeroBalanceTokens };
