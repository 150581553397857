import * as React from 'react';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import colors from 'assets/sass/variables.module.scss';
import { routeNames } from 'routes';

const AddGroupButton = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(routeNames.registerGroup);
  };

  return (
    <div className='card swap-form pt-0 pb-3 col-md-5 col-lg-4 col-xl-3 mx-3'>
      <div className='card-body d-flex flex-row mt-2 py-3 px-1'>
        <FontAwesomeIcon
          style={{ color: colors.colorPrimary, height: '40px' }}
          icon={faCirclePlus}
        />
        <div className='exrond-text text-white ml-3'>
          List your token and<br></br>setup your smart contracts
        </div>
      </div>
      <button className='exrond-btn' onClick={handleOnClick}>
        <span className='exrond-text text-white font-size-1 font-weight-600'>
          List new token
        </span>
      </button>
    </div>
  );
};

export default AddGroupButton;
