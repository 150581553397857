import { gql } from '@apollo/client';

export const CREATE_MERGE_TOKENS_TRANSACTION = gql`
  mutation mergeTokens(
    $sender: String!
    $tokens: [InputTokenModel!]!
    $payWithEgld: Boolean!
  ) {
    transactions: mergeTokens(
      sender: $sender
      tokens: $tokens
      payWithEgld: $payWithEgld
    ) {
      nonce
      value
      sender
      receiver
      gasPrice
      gasLimit
      data
      chainID
      version
      options
    }
  }
`;
