import { EsdtToken, Pair, SwapTokens } from 'api/types/pairs';
import { SwapCardType } from '../types';

const useGetCardToken = (
  currentPair: SwapTokens | Pair | undefined,
  direction: 'in' | 'out',
  cardType: SwapCardType
): EsdtToken | undefined => {
  if (!currentPair) {
    return undefined;
  }

  if (cardType === SwapCardType.SENDER) {
    return direction === 'in'
      ? currentPair.firstToken
      : currentPair.secondToken;
  } else {
    return direction === 'in'
      ? currentPair.secondToken
      : currentPair.firstToken;
  }
};

export { useGetCardToken };
