import React, { useEffect } from 'react';
import { IoiomProvider } from '@buidly/ioiom-staking-dapp/context/IoiomProvider';
import { store } from '@multiversx/sdk-dapp/reduxStore/store';
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import { useGetAppState } from 'api/hooks/useGetAppState';
import { NFT_STAKING_CLIENT_NAME } from 'apollo-client';
import { ReactComponent as MiniLogo } from 'assets/img/ex.svg';
import { ReactComponent as Logo } from 'assets/img/exrond_logo.svg';
import { ReactComponent as InactiveLogo } from 'assets/img/exrond_logo_inactive.svg';
import colors from 'assets/sass/variables.module.scss';
import Layout from 'components/Layout';
import MaintenancePage from 'components/MaintenancePage';
import UpdateNotification from 'components/UpdateNotification';
import {
  API_ADDRESS,
  API_GRAPHQL,
  API_MULTIVERSX,
  API_NFT_STAKING_GRAPHQL,
  DECIMALS,
  EGLD,
  ENVIRONMENT,
  TOKEN_PLACEHOLDER,
  TRACKING_ID,
  WALLET_CONNECT_V2_PROJECT_ID
} from 'config';
import PageNotFound from 'pages/PageNotFound';
import routes, { routeNames } from 'routes';

ReactGA.initialize(TRACKING_ID);

const App = () => {
  const apiAddresses = {
    [EnvironmentsEnum.devnet]: 'https://devnet-api.multiversx.com',
    [EnvironmentsEnum.testnet]: 'https://testnet-api.multiversx.com',
    [EnvironmentsEnum.mainnet]: 'https://api.multiversx.com'
  };
  const appState = useGetAppState();

  useEffect(() => {
    modifyViewportForIOS();
  }, []);

  const modifyViewportForIOS = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    if (isIOS) {
      const metaTag = document.querySelector('meta[name="viewport"]');
      metaTag?.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1'
      );
    }
  };

  return (
    <>
      <UpdateNotification />
      <Router>
        <DappProvider
          environment={ENVIRONMENT}
          dappConfig={{
            shouldUseWebViewProvider: true
          }}
          customNetworkConfig={{
            name: 'customConfig',
            apiTimeout: 6000,
            apiAddress: apiAddresses[ENVIRONMENT],
            walletConnectV2ProjectId: WALLET_CONNECT_V2_PROJECT_ID
          }}
        >
          <IoiomProvider
            value={{
              apiAddress: API_ADDRESS,
              apiMultiversx: API_MULTIVERSX,
              apiGraphql: API_GRAPHQL,
              apiNftStakingGraphql: API_NFT_STAKING_GRAPHQL,
              decimals: DECIMALS,
              environment: ENVIRONMENT,
              nftStakingClientName: NFT_STAKING_CLIENT_NAME,
              tokenPlaceholder: TOKEN_PLACEHOLDER,
              routes: {
                register: '/register',
                registerNft: '/register/nft',
                stakingContracts: '/staking/contracts',
                deployNftContract: '/deploy/nft',
                swap: routeNames.swap,
                nftStaking: routeNames.nftsStaking
              },
              egldDecimals: EGLD.decimals,
              theme: {
                colorPrimaryLight: colors.colorPrimaryLight,
                colorPrimary: colors.colorPrimary,
                colorPrimaryDark: colors.colorPrimaryDark,
                colorWarning: colors.colorWarning,
                colorSuccess: colors.colorSuccess,
                colorError: colors.colorError,
                textPrimaryLightColor: colors.colorWhite,
                textPrimaryLightColorStyle: 'text-white'
              },
              appMiniLogo: (
                <MiniLogo fill='#000' className='wallet-mini-logo' />
              ),
              appLogo: <Logo />,
              appLogoInactive: <InactiveLogo />,
              appName: 'EXROND'
            }}
          >
            <Provider store={store}>
              <Layout showNavbar={appState !== 'MAINTENANCE'}>
                <TransactionsToastList />
                <NotificationModal />
                <SignTransactionsModals className='custom-class-for-modals' />
                <Routes>
                  {appState === 'MAINTENANCE' ? (
                    <Route path='*' element={<MaintenancePage />} />
                  ) : (
                    <>
                      <Route
                        path='/'
                        element={<Navigate replace to='/swap' />}
                      />
                      {routes.map((route: any, index: number) => (
                        <Route
                          path={route.path}
                          key={'route-key-' + index}
                          element={<route.component />}
                        />
                      ))}
                      <Route
                        path='/unlock'
                        element={<Navigate replace to='/' />}
                      />
                      <Route path='*' element={<PageNotFound />} />
                    </>
                  )}
                </Routes>
              </Layout>
            </Provider>
          </IoiomProvider>
        </DappProvider>
      </Router>
    </>
  );
};

export default App;
