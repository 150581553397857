import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PAIR_ANALYTICS_QUERY } from 'api/queries/pairAnalytics';
import { PairAnalytics } from '../types/PairAnalytics';

const useGetPairAnalytics = () => {
  const { data, loading, error } = useQuery(PAIR_ANALYTICS_QUERY);

  return useMemo(() => {
    const analytics = data?.pairAnalytics
      ? PairAnalytics.fromResponse(data?.pairAnalytics)
      : new PairAnalytics();

    return { analytics, loading, error };
  }, [data, loading, error]);
};

export { useGetPairAnalytics };
