import React, { useEffect, useMemo, useState } from 'react';
import { TokenWithBuyAmount } from 'api/types';
import { useGetSwapQuery, useGetTokenLiquidities } from '../hooks';
import useGetDefaultPair from '../hooks/useGetDefaultPair';
import { useSendSwapTransactions } from '../hooks/useSendSwapTransactions';
import { SwapPairAmounts } from '../types';
import { SwapContext } from './SwapContext';

type SwapProviderProps = {
  tokensWithBuyAmount: TokenWithBuyAmount[];
  children: React.ReactNode;
};

export const SwapProvider = ({
  tokensWithBuyAmount,
  children
}: SwapProviderProps) => {
  const { liquidities } = useGetTokenLiquidities();

  const tokens = useMemo(() => {
    return tokensWithBuyAmount.map((response) => response.token);
  }, [tokensWithBuyAmount]);

  const [currentPair, setCurrentPair] = useGetDefaultPair(tokens);

  const [swapPairAmounts, setSwapPairAmounts] = useState<SwapPairAmounts>({
    amountIn: '',
    amountOut: ''
  });

  const [cardError, setCardError] = useState({
    sender: false,
    receiver: false
  });

  const {
    debouncedSwap,
    swapData,
    swapLoading,
    hasTransactionsReady,
    stopSwapPolling,
    resetSwapData
  } = useGetSwapQuery(setSwapPairAmounts);

  useEffect(() => {
    resetSwapData();
  }, [currentPair]);

  const { sendSwapTransactions, transactionsPending } = useSendSwapTransactions(
    swapData?.transactions
  );

  const swapDisabled = useMemo(() => {
    return !hasTransactionsReady || cardError.receiver || cardError.sender;
  }, [hasTransactionsReady, cardError]);

  const changeDirection = () => {
    stopSwapPolling();

    setSwapPairAmounts({
      amountIn: '',
      amountOut: ''
    });

    setCurrentPair((prev) => {
      return {
        ...prev,
        tokenIn: prev?.tokenOut,
        tokenOut: prev?.tokenIn
      };
    });
  };

  const contextValue = {
    tokens,
    tokensWithBuyAmount,
    currentPair,
    swapPairAmounts,
    swapLoading,
    swapDisabled,
    transactionsPending,
    swapData,
    liquidities,
    setSwapPairAmounts,
    setCurrentPair,
    debouncedSwap,
    changeDirection,
    stopSwapPolling,
    setCardError,
    sendSwapTransactions
  };

  return (
    <SwapContext.Provider value={contextValue}>{children}</SwapContext.Provider>
  );
};
