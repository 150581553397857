import React from 'react';
import { FaucetDetails } from 'api/types/faucets';
import FaucetButton from './Components/FaucetButton';
import FaucetName from './Components/FaucetName';
import FaucetReward from './Components/FaucetReward';
import FaucetRewardPool from './Components/FaucetRewardPool';
import FaucetValue from './Components/FaucetValue';
import { getFaucetDetailsByStatus } from './utils';

export interface FaucetCardProps {
  details: FaucetDetails;
  onClaimClicked: (
    faucet: FaucetDetails,
    payWithEgld: boolean | undefined
  ) => void;
  onClick: (faucet: FaucetDetails) => void;
  pending: boolean;
  selected: boolean;
}

const FaucetCard = ({
  details,
  pending,
  selected,
  onClaimClicked,
  onClick
}: FaucetCardProps) => {
  const { color } = getFaucetDetailsByStatus(details.status);

  return (
    <div
      className='card card-rewards d-flex flex-row py-2 mt-1 reward-border'
      style={{ borderColor: selected ? `${color}` : '' }}
      onClick={() => onClick(details)}
    >
      <div className='mark my-4' style={{ background: color }} />
      <div
        className='d-flex py-2 flex-column flex-lg-row flex-fill align-items-lg-center justify-content-center px-4'
        style={{ minHeight: '100px' }}
      >
        <FaucetName
          token={details.token}
          progress={details.progress}
          status={details.status}
          reward={details.reward}
          valueUsd={details.valueUsd}
        />
        <FaucetRewardPool
          rewardPool={details.rewardPool}
          token={details.token}
        />
        <FaucetValue valueUsd={details.valueUsd} />
        <FaucetReward
          reward={details.reward}
          token={details.token}
          className={'d-none d-lg-flex'}
          valueUsd={details.valueUsd}
        />
        <FaucetButton
          actionButton={details.actionButton}
          onClaimClicked={(payWithEgld) => onClaimClicked(details, payWithEgld)}
          pending={pending}
          containerClassName='d-none d-lg-block'
        />
      </div>
    </div>
  );
};

export default FaucetCard;
