import { gql } from '@apollo/client';

export const TOKEN_LIQUIDITIES = gql`
  query tokenLiquidities {
    tokenLiquidities {
      identifier
      relations {
        identifier
        status
      }
    }
  }
`;
