import { EsdtToken } from 'api/types/pairs';
import { EGLD, WEGLD } from 'config';

export const isEgldorWegld = (token: EsdtToken | undefined) => {
  return (
    token?.identifier === WEGLD.identifier ||
    token?.identifier === EGLD.identifier
  );
};

export const isSwapOptionDisabled = (
  currentToken: EsdtToken,
  otherToken: EsdtToken | undefined
) => {
  const isOtherTokenEgld = isEgldorWegld(otherToken);
  const isCurrentTokenEgld = isEgldorWegld(currentToken);

  return (
    (isCurrentTokenEgld && isOtherTokenEgld) ||
    currentToken.identifier === otherToken?.identifier
  );
};
