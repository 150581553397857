import React, { useState } from 'react';
import AccountModal from 'components/AccountModal';

const AccountButton = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <a
        className={'btn btn-primary exrond-connect-btn'}
        onClick={handleShowModal}
      >
        My Account
      </a>

      <AccountModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

export default AccountButton;
