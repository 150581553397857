import React, { useState } from 'react';
import { Transaction } from '@multiversx/sdk-core/out';
import { FormatAmount } from '@multiversx/sdk-dapp/UI/FormatAmount';
import { Collapse } from 'react-bootstrap';
import Select from 'react-select';
import { EsdtToken, EsdtTokenSimplified } from 'api/tokens/esdtToken.model';
import {
  FarmState,
  StakingContract,
  VestingContract
} from 'api/types/AdminSettings';
import { DECIMALS } from 'config';
import { useQueryParams } from 'hooks';
import RegisterContract from 'pages/Register/RegisterContract/RegisterContract';
import { selectColourStyles } from '../../../utils';
import { formatOptionLabel } from '../../../utils/formatOptionLabel';

interface StakingContractProps {
  stakingContract: StakingContract;
  vestingContract?: VestingContract;
  transactionStatus: any;
  groupToken?: EsdtToken;
  refetchingData: boolean;
  processBatchTransactions: (
    transactions: Transaction[][],
    vestingAddress?: string,
    farmingTokenLocked?: boolean,
    rewardTokenLocked?: boolean,
    rewardTokenId?: string
  ) => void;
  availableTokens: EsdtTokenSimplified[];
}

const StakingContractItem = ({
  stakingContract,
  transactionStatus,
  groupToken,
  refetchingData,
  vestingContract,
  processBatchTransactions,
  availableTokens
}: StakingContractProps) => {
  const { farmingTokenLocked, rewardTokenLocked, rewardTokenId } =
    useQueryParams();

  const [expanded, setExpanded] = useState(
    stakingContract.state !== FarmState.SETUP_COMPLETE &&
      farmingTokenLocked === stakingContract.farmingTokenLocked.toString() &&
      rewardTokenLocked === stakingContract.rewardTokenLocked.toString() &&
      rewardTokenId === stakingContract.rewardTokenId
  );

  const [selectedRewardTokenId, setSelectedRewardTokenId] = useState(
    stakingContract.rewardTokenId
  );

  const onTokenSelected = (selectedOption: any) => {
    setSelectedRewardTokenId(
      (selectedOption as EsdtTokenSimplified).identifier
    );
  };

  const buttonClass =
    stakingContract.state === FarmState.SETUP_COMPLETE
      ? 'btn-primary'
      : 'btn-success';

  return (
    <>
      <div className='mb-4'>
        <div
          className='card swap-form d-flex flex-row'
          style={{
            zIndex:
              !stakingContract.rewardTokenId &&
              !stakingContract.farmingTokenLocked
                ? 2
                : undefined
          }}
          aria-controls='details'
          aria-expanded={expanded}
        >
          <div className='d-flex py-2 flex-column flex-lg-row flex-fill align-items-lg-center justify-content-lg-between'>
            <div className='header-item header-item-small mx-1 d-flex flex-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Farming token</span>
              <div>
                {stakingContract.farmingTokenLocked ? 'Locked' : 'Unlocked'}
              </div>
            </div>
            <div className='header-item d-flex flex-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1 mx-1'>
              {!stakingContract.rewardTokenId ? (
                <Select
                  className={'exrond-select exrond-select-dark w-100'}
                  classNamePrefix={'exrond'}
                  formatOptionLabel={formatOptionLabel}
                  options={availableTokens}
                  styles={selectColourStyles()}
                  onChange={onTokenSelected}
                  placeholder={'Select Reward token'}
                  getOptionValue={(option) => option.identifier}
                />
              ) : (
                <>
                  <span className='font-weight-bold'>Reward token</span>
                  <div>
                    {stakingContract.rewardTokenId === groupToken?.identifier
                      ? stakingContract.rewardTokenLocked
                        ? 'Locked'
                        : 'Unlocked'
                      : stakingContract.rewardTokenId}
                  </div>
                </>
              )}
            </div>
            <div className='header-item mx-1 d-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>APR</span>
              <div>
                {stakingContract.apr
                  ? `${Math.floor(stakingContract.apr).toString()}%`
                  : '-'}
              </div>
            </div>
            <div className='header-item mx-1 d-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Rewards left</span>
              <div>
                {stakingContract.rewardsLeft ? (
                  <FormatAmount
                    value={stakingContract.rewardsLeft}
                    decimals={
                      stakingContract?.rewardTokenDecimals ??
                      groupToken?.decimals ??
                      0
                    }
                    egldLabel={' '}
                    digits={DECIMALS}
                    showLastNonZeroDecimal={false}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className='header-item mx-1 d-flex flex-column flex-sm-row flex-lg-column justify-content-between mb-2 mb-lg-0 flex-shrink-1'>
              <span className='font-weight-bold'>Total value staked</span>
              <div>
                {stakingContract.totalValueStaked ? (
                  <FormatAmount
                    value={stakingContract.totalValueStaked}
                    decimals={groupToken?.decimals ?? 0}
                    egldLabel={' '}
                    digits={DECIMALS}
                    showLastNonZeroDecimal={false}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>
            {stakingContract.state !== FarmState.SETUP_COMPLETE && (
              <div>
                <button
                  type='submit'
                  className={`btn btn-sm btn-stake-action m-0 ${buttonClass} m-0 w-100 w-lg-30`}
                  onClick={() => setExpanded((prev) => !prev)}
                  disabled={!selectedRewardTokenId}
                >
                  Deploy
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <Collapse in={expanded}>
            <div id='details'>
              {selectedRewardTokenId && (
                <RegisterContract
                  processBatchTransactions={(transactions) =>
                    processBatchTransactions(
                      transactions,
                      undefined,
                      stakingContract.farmingTokenLocked,
                      stakingContract.rewardTokenLocked,
                      selectedRewardTokenId
                    )
                  }
                  transactionStatus={transactionStatus}
                  contractRegistered={() => {
                    setExpanded(false);
                  }}
                  stakingContract={stakingContract}
                  vestingContract={vestingContract}
                  refetchingData={refetchingData}
                  rewardTokenId={selectedRewardTokenId}
                />
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default StakingContractItem;
