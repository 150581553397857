import axios from 'axios';
import { API_ADDRESS } from 'config';

export const getTokenValue = async (token: string, amount: string) => {
  return await axios.get(`${API_ADDRESS}/api/token-value`, {
    params: {
      token,
      amount
    }
  });
};
