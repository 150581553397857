import { useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { debounce } from 'lodash';
import { POLLING_INTERVAL } from 'api/hooks/useGetPairs';
import { SWAP_QUERY } from 'components/SwapWidget/swap-token';
import { SwapData, SwapPairAmounts } from '../types';

const useGetSwapQuery = (
  setSwapPairAmounts: React.Dispatch<React.SetStateAction<SwapPairAmounts>>
) => {
  const { address } = useGetAccount();

  const [swapData, setSwapData] = useState<SwapData | undefined>();

  const [
    performSwapQuery,
    {
      data,
      loading,
      startPolling: startSwapPolling,
      stopPolling: stopSwapPolling
    }
  ] = useLazyQuery(SWAP_QUERY);

  useEffect(() => {
    if (data?.swap === undefined) {
      setSwapData(undefined);
      return;
    }

    setSwapData(SwapData.fromResponse(data.swap));
  }, [data]);

  const resetSwapData = () => {
    setSwapData(undefined);
  };

  const hasTransactionsReady = useMemo(() => {
    return swapData?.transactions && swapData?.transactions?.length > 0;
  }, [swapData]);

  const debouncedSwap = useRef(
    debounce(async (inputArgs: any) => {
      performSwapQuery({
        variables: {
          sender: address,
          input: inputArgs
        }
      });
    }, 800)
  ).current;

  useEffect(() => {
    if (!swapData) {
      return;
    }

    startSwapPolling(POLLING_INTERVAL);

    setSwapPairAmounts({
      amountIn: new BigNumber(swapData.amountIn)
        .shiftedBy(-swapData.tokenInMetadata.decimals)
        .toFixed(),
      amountOut: new BigNumber(swapData.amountOut)
        .shiftedBy(-swapData.tokenOutMetadata.decimals)
        .toFixed()
    });
  }, [swapData]);

  useEffect(() => {
    return () => {
      stopSwapPolling();
      debouncedSwap.cancel();
    };
  }, [debouncedSwap]);

  return {
    debouncedSwap,
    swapData,
    swapLoading: loading,
    hasTransactionsReady,
    stopSwapPolling,
    resetSwapData
  };
};

export { useGetSwapQuery };
