import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useGroups } from 'hooks/useGroups';
import { routeNames } from 'routes';

const AdminButton = () => {
  const [hasGroupCreated, setHasGroupCreated] = useState(false);
  const { groups } = useGroups();

  useEffect(() => {
    setHasGroupCreated(groups?.length > 0);
  }, [groups]);

  return (
    <>
      {hasGroupCreated ? (
        <NavLink
          to={routeNames.tokens}
          className='btn btn-primary exrond-admin-btn ml-1'
        >
          My Staking Farms
        </NavLink>
      ) : (
        <NavLink
          to={routeNames.registerGroup}
          className='btn btn-primary exrond-admin-btn ml-1'
        >
          Create Staking Farm
        </NavLink>
      )}
    </>
  );
};

export default AdminButton;
